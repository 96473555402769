import { CommonService } from "../../core/services";
import { HttpHeaders } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/common.service";
var UsersService = /** @class */ (function () {
    function UsersService(commonService) {
        this.commonService = commonService;
        this.getMasterDataByNameURL = "api/MasterData/MasterDataByName";
        this.createURL = "Staffs/CreateUpdateStaff";
        this.deleteURL = "Staffs/DeleteStaff";
        this.getStaffByIdURL = "Staffs/GetStaffById";
        this.deleteAppointmentDetailURL = "api/PatientAppointments/DeleteAppointment";
        this.deleteTaskUsingEventIdURL = "api/PatientAppointments/DeleteTaskUsingEventId";
        this.getAllURL = "Staffs/GetStaffs";
        this.getStaffHeaderInfoURL = "Staffs/GetStaffHeaderData";
        this.getStaffLeavesURL = "StaffLeave/GetStaffLeaveList";
        this.updateLeaveStatusURL = "StaffLeave/UpdateLeaveStatus";
        this.getStaffLeaveByIdURL = "StaffLeave/GetAppliedStaffLeaveById";
        this.deleteStaffLeaveByIdURL = "StaffLeave/DeleteAppliedLeave";
        this.saveStaffLeaveURL = "StaffLeave/SaveStaffAppliedLeave";
        this.getMasterDataAutoComplete = "/api/MasterData/GetAutoComplateSearchingValues";
        this.getStaffCustomLabelsURL = "StaffCustomLabel/GetStaffCustomLabels";
        this.saveStaffCustomLabelsURL = "StaffCustomLabel/SaveCustomLabels";
        this.getStaffLocationURL = "Staffs/GetAssignedLocationsById";
        this.getStaffAvailabilityByLocationURL = "AvailabilityTemplates/GetStaffAvailabilityWithLocation";
        this.getStaffAvailabilityWithShiftAndLocationURL = "AvailabilityTemplates/GetStaffAvailabilityWithShiftAndLocation";
        this.saveStaffAvailabilityByLocationURL = "AvailabilityTemplates/SaveUpdateAvailabilityWithLocation";
        this.getStaffPayrollRateURL = "StaffPayrollRateForActivity/GetStaffPayRateOfActivity";
        this.getRolePayrollRateURL = "RolePayRollRate/GetRolePayRollRate";
        this.saveUpdatePayrollRateURL = "StaffPayrollRateForActivity/SaveUpdateStaffPayrollRateForActivity";
        this.saveUpdateRolePayrollRateURL = "RolePayRollRate/SaveUpdateRolePayRollRate";
        this.getUserDocumentsURL = "userDocument/GetUserDocuments";
        this.getUserByLocationURL = "api/PatientAppointments/GetStaffAndPatientByLocation";
        this.getUserDocumentURL = "PatientAppointments/DownloadTaskDocument";
        this.getUserDocumentByIdURL = "UserDocument/GetUserDocument";
        this.deleteUserDocumentURL = "userDocument/DeleteUserDocument";
        this.uploadUserDocumentURL = "userDocument/UploadUserDocuments";
        this.getTaskDocumentURL = "api/PatientAppointments/DownloadTaskDocument";
        this.getStaffProfileURL = "Staffs/GetStaffProfileData";
        this.updateUserStatusURL = "user/UpdateUserStatus";
        this.updateUserActiveStatusURL = "staffs/UpdateStaffActiveStatus";
        this.getStaffAndPatientByLocationURL = "api/PatientAppointments/GetStaffAndPatientByLocation";
        this.getStaffsAndPatientByLUnitURL = "api/PatientAppointments/GetStaffAndPatientByUnit";
        this.getAllMyFollowingTaskURL = "api/PatientAppointments/GetTaskCreatedList";
        this.getMyTaskListURL = "api/PatientAppointments/GetMyTaskList";
        this.setPassword = "PasswordSetup";
        // Task Notepad 
        this.getTaskNotePadListURL = "TaskController/GetTaskNotePadList";
        this.createUpdateTaskNotePadURL = "TaskController/CreateUpdateTaskNotePad";
        this.getTaskNotePadByIdURL = "TaskController/GetTaskNotePadById";
        this.deleteTaskNotePadById = "TaskController/DeleteTaskNotePadById";
        //IpLocation
        this.getLocationIpAddressesURL = "Login/LocationIpAddresses";
    }
    UsersService.prototype.create = function (data) {
        return this.commonService.post(this.createURL, data);
    };
    UsersService.prototype.delete = function (id) {
        return this.commonService.patch(this.deleteURL + "/" + id, {});
    };
    UsersService.prototype.deleteStaff = function (id) {
        return this.commonService.post(this.deleteURL + "?id=" + id, {});
    };
    UsersService.prototype.getStaffLeaves = function (filterModel, staffId) {
        var url = this.getStaffLeavesURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&staffId=" +
            staffId +
            "&fromDate=" +
            "1990-01-01" +
            "&toDate=" +
            "2018-11-28";
        return this.commonService.getAll(url, {});
    };
    UsersService.prototype.deleteStaffLeave = function (id) {
        return this.commonService.patch(this.deleteStaffLeaveByIdURL + "?StaffLeaveId=" + id, {});
    };
    UsersService.prototype.getStaffLeaveById = function (id) {
        return this.commonService.getById(this.getStaffLeaveByIdURL + "?StaffLeaveId=" + id, {});
    };
    UsersService.prototype.saveStaffLeave = function (data) {
        return this.commonService.post(this.saveStaffLeaveURL, data);
    };
    UsersService.prototype.getAll = function (filterModel, tags, roleIds) {
        var url = this.getAllURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            // '&LocationIDs=' +
            // locationId +
            "&searchKey=" +
            (filterModel.searchText || "") +
            "&RoleIds=" +
            roleIds +
            "&Tags=" +
            tags;
        return this.commonService.getAll(url, {});
    };
    UsersService.prototype.getAllMyFollowingTask = function (filterModel) {
        return this.commonService.post(this.getAllMyFollowingTaskURL, filterModel);
    };
    UsersService.prototype.getMyTaskList = function (filterModel) {
        return this.commonService.post(this.getMyTaskListURL, filterModel);
    };
    UsersService.prototype.getMasterData = function (value) {
        if (value === void 0) { value = ""; }
        return this.commonService.post(this.getMasterDataByNameURL, {
            masterdata: value,
        });
    };
    UsersService.prototype.getStaffCustomLabels = function (staffId) {
        var url = this.getStaffCustomLabelsURL + "?staffId=" + staffId;
        return this.commonService.getAll(url, {});
    };
    UsersService.prototype.getStaffById = function (id) {
        return this.commonService.getById(this.getStaffByIdURL + "?id=" + id, {});
    };
    UsersService.prototype.getStaffHeaderInfo = function (id) {
        return this.commonService.getById(this.getStaffHeaderInfoURL + "?id=" + id, {});
    };
    UsersService.prototype.saveCustomLabels = function (data) {
        return this.commonService.post(this.saveStaffCustomLabelsURL, data);
    };
    UsersService.prototype.getStaffLocations = function (staffId) {
        return this.commonService.getById(this.getStaffLocationURL + "?Id=" + staffId, {});
    };
    UsersService.prototype.getStaffAvailabilityByLocation = function (staffId, locationId) {
        return this.commonService.getById(this.getStaffAvailabilityByLocationURL +
            "?staffId=" +
            staffId +
            "&locationId=" +
            locationId +
            "&isLeaveNeeded=false", {});
    };
    UsersService.prototype.getStaffAvailabilityWithShiftAndLocation = function (staffId, locationId, shiftId) {
        return this.commonService.getById(this.getStaffAvailabilityWithShiftAndLocationURL +
            "?staffId=" +
            staffId +
            "&locationId=" +
            locationId +
            "&shiftId=" +
            shiftId +
            "&isLeaveNeeded=false", {});
    };
    UsersService.prototype.saveStaffAvailability = function (data) {
        return this.commonService.post(this.saveStaffAvailabilityByLocationURL, data);
    };
    UsersService.prototype.updateLeaveStatus = function (data) {
        return this.commonService.post(this.updateLeaveStatusURL, data);
    };
    UsersService.prototype.getStaffPayrollRate = function (staffId) {
        return this.commonService.getById(this.getStaffPayrollRateURL + "?staffId=" + staffId, {});
    };
    UsersService.prototype.getRolePayrollRate = function (roleId) {
        return this.commonService.getById(this.getRolePayrollRateURL + "?RoleId=" + roleId, {});
    };
    UsersService.prototype.saveStaffPayrollRate = function (data) {
        return this.commonService.post(this.saveUpdatePayrollRateURL, data);
    };
    UsersService.prototype.saveRolePayrollRate = function (data) {
        return this.commonService.post(this.saveUpdateRolePayrollRateURL, data);
    };
    UsersService.prototype.getUserDocuments = function (userId, from, toDate) {
        return this.commonService.getAll(this.getUserDocumentsURL +
            "?userId=" +
            userId +
            "&from=" +
            from +
            "&to=" +
            toDate, {});
    };
    UsersService.prototype.getUserByLocation = function (locationId) {
        var url = this.getUserByLocationURL +
            "?locationIds=" +
            locationId +
            "&permissionKey=SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES&isActiveCheckRequired=YES";
        return this.commonService.getAll(url, {});
    };
    UsersService.prototype.getUserDocument = function (id) {
        return this.commonService.download(this.getUserDocumentURL + "?id=" + id, {});
    };
    UsersService.prototype.getUserDocumentById = function (id) {
        return this.commonService.download(this.getUserDocumentByIdURL + "?id=" + id, {});
    };
    UsersService.prototype.getTaskDocument = function (id) {
        return this.commonService.download(this.getTaskDocumentURL + "?id=" + id, {});
    };
    UsersService.prototype.deleteUserDocument = function (id) {
        return this.commonService.patch(this.deleteUserDocumentURL + "?id=" + id, {});
    };
    UsersService.prototype.uploadUserDocuments = function (data) {
        return this.commonService.post(this.uploadUserDocumentURL, data);
    };
    UsersService.prototype.getStaffProfileData = function (id) {
        return this.commonService.getById(this.getStaffProfileURL + "/" + id, {});
    };
    UsersService.prototype.downloadFile = function (blob, filetype, filename) {
        var newBlob = new Blob([blob], { type: filetype });
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        var data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(data);
        }, 100);
    };
    UsersService.prototype.getUserScreenActionPermissions = function (moduleName, screenName) {
        return this.commonService.getUserScreenActionPermissions(moduleName, screenName);
    };
    UsersService.prototype.updateUserStatus = function (userId, status) {
        return this.commonService.patch(this.updateUserStatusURL + "/" + userId + "/" + status, {});
    };
    UsersService.prototype.updateUserActiveStatus = function (staffId, status) {
        return this.commonService.patch(this.updateUserActiveStatusURL +
            "?staffId=" +
            staffId +
            "&isActive=" +
            status, {});
    };
    UsersService.prototype.getMasterDataAutocomplete = function (tableName, columnName, searchText) {
        return this.commonService.getById(this.getMasterDataAutoComplete +
            "?tableName=" +
            tableName +
            "&columnName=" +
            columnName +
            "&searchText=" +
            searchText, {});
    };
    UsersService.prototype.getStaffAndPatientByLocation = function (locationIds, permissionKey) {
        if (permissionKey === void 0) { permissionKey = "SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES"; }
        var queryParams = "?locationIds=" + locationIds + "&permissionKey=" + permissionKey + "&isActiveCheckRequired=YES";
        return this.commonService.getAll(this.getStaffAndPatientByLocationURL + queryParams, {});
    };
    UsersService.prototype.getPatientByUnitIds = function (unitIds, permissionKey) {
        if (permissionKey === void 0) { permissionKey = "SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES"; }
        var queryParams = "?unitIds=" + unitIds + "&permissionKey=" + permissionKey + "&isActiveCheckRequired=YES";
        return this.commonService.getAll(this.getStaffsAndPatientByLUnitURL + queryParams, {});
    };
    UsersService.prototype.setPasswordbyUserId = function (id) {
        var headers = new HttpHeaders({
            businessToken: localStorage.getItem("business_token"),
        });
        return this.commonService.getAll(this.setPassword + "?UserId=" + id, {
            headers: headers,
        });
    };
    UsersService.prototype.deleteAppointmentDetails = function (appointmentId, parentAppointmentId, deleteSeries, isAdmin) {
        var queryParams = "?appointmentId=" + appointmentId + "&parentAppointmentId=" + parentAppointmentId + "&deleteSeries=" + deleteSeries + "&isAdmin=" + isAdmin;
        return this.commonService.patch(this.deleteAppointmentDetailURL + queryParams, {});
    };
    UsersService.prototype.deleteAppointmentDetailsUsingEventId = function (appointmentId, eventId, isAdmin) {
        var queryParams = "?appointmentId=" + appointmentId + "&eventId=" + eventId + "&isAdmin=" + isAdmin;
        return this.commonService.patch(this.deleteTaskUsingEventIdURL + queryParams, {});
    };
    //IPaddress
    UsersService.prototype.GetLocationIpAddresses = function () {
        return this.commonService.getAll(this.getLocationIpAddressesURL, {});
    };
    // Task Notepad APIS 
    UsersService.prototype.GetTaskNotePadList = function (filterModel) {
        return this.commonService.postwithLoader(this.getTaskNotePadListURL, filterModel, true);
    };
    UsersService.prototype.CreateUpdateTaskNotePad = function (data) {
        return this.commonService.post(this.createUpdateTaskNotePadURL, data);
    };
    UsersService.prototype.GetTaskNotePadById = function (id) {
        return this.commonService.getById(this.getTaskNotePadByIdURL + "?id=" + id, {});
    };
    UsersService.prototype.DeleteTaskNotePadById = function (id) {
        return this.commonService.patch(this.deleteTaskNotePadById + "?id=" + id, {});
    };
    UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.CommonService)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
