import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SubDomainService } from "../subDomain.service";
import { AuthenticationService } from "../super-admin-portal/auth/auth.service";
import { CommonService } from "../super-admin-portal/core/services";
var LandingPageComponent = /** @class */ (function () {
    function LandingPageComponent(formBuilder, subDomainService, router, commonService, authenticationService) {
        this.formBuilder = formBuilder;
        this.subDomainService = subDomainService;
        this.router = router;
        this.commonService = commonService;
        this.authenticationService = authenticationService;
    }
    LandingPageComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            organizationName: [
                "",
                {
                    validators: [Validators.required],
                    asyncValidators: [this.validateOrganization.bind(this)],
                    updateOn: "change",
                },
            ],
            rememberOrg: [false],
        });
        if (localStorage.getItem("RememberOrganization") == "true" &&
            localStorage.getItem("business-url") &&
            localStorage.getItem("business-url") !== "organization-selec") {
            var businessName = localStorage.getItem("business-url");
            this.router.navigate(["/" + businessName + "/web/login"]);
        }
    };
    LandingPageComponent.prototype.validateOrganization = function (ctrl) {
        var _this = this;
        return new Promise(function (resolve) {
            if (!ctrl.dirty) {
                resolve();
            }
            else {
                _this.authenticationService
                    .validateOrganization(ctrl.value)
                    .subscribe(function (response) {
                    if (response.statusCode !== 202)
                        resolve({ uniqueName: true });
                    else
                        resolve();
                });
            }
        });
    };
    Object.defineProperty(LandingPageComponent.prototype, "f", {
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    LandingPageComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.loginForm.invalid) {
            return;
        }
        var businessName = this.f.organizationName.value;
        this.authenticationService
            .validateOrganization(businessName)
            .subscribe(function (respo) {
            if (respo.statusCode == 202) {
                console.log("Response", respo.data);
                localStorage.clear();
                localStorage.setItem("business-url", respo.data.businessUrl);
                if (_this.f.rememberOrg.value) {
                    localStorage.setItem("RememberOrganization", "true");
                }
                else {
                    localStorage.setItem("RememberOrganization", "false");
                }
                _this.subDomainService
                    .verifyBusinessName(respo.data.businessUrl)
                    .subscribe();
                _this.router.navigate(["/" + respo.data.businessUrl + "/web/login"]);
            }
        });
        // location.reload()
    };
    return LandingPageComponent;
}());
export { LandingPageComponent };
