import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ImagePopupComponent = /** @class */ (function () {
    function ImagePopupComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ImagePopupComponent.prototype.ngOnInit = function () {
    };
    ImagePopupComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return ImagePopupComponent;
}());
export { ImagePopupComponent };
