<div class="custom_modal">
  <div class="MDS-Heading">
    <h3 mat-dialog-title>Accept Fax</h3>
    <i
      class="fa fa-times"
      (click)="closeDialog('close')"
      aria-hidden="true"
    ></i>
  </div>

  <div mat-dialog-content>
    <div style="margin-top: 10px" class="row">
      New Fax Received
      <button mat-button (click)="getUserDocument()" title="Please review document">
        <i  class="fa fa-eye fa-2x edit-purple"></i>
      </button>
    </div>

    <form
      id="ReferralForm"
      #documentForm="ngForm"
      class="readmin-form custom_form"
      [formGroup]="ReferralForm"
    >
      <div class="row">
        <div class="col-sm-6" style="padding: 0; margin-top: 10px">
          <mat-form-field class="example-full-width">
            <input
              placeholder="Search {{ tabName }}"
              aria-label="healthCardNumberSearch"
              matInput
              formControlName="healthCardNumberSearch"
              [matAutocomplete]="auto"
            />
            <div>
              <img
                src="../../../../../../assets/img/search.png"
                class="search-icon-input"
              />
            </div>
            <mat-autocomplete
              class="Search_patient_name"
              #auto="matAutocomplete"
              (optionSelected)="onPatientSelectedHCNumber($event)"
              [displayWith]="displayFn"
            >
              <mat-option *ngIf="isloadingPatients" class="is-loading">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-option>
              <ng-container>
                <mat-option
                  *ngFor="let option of filterHealthCardNo$ | async"
                  [value]="option"
                >
                  <span> {{ option.fullName }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <!-- <mat-error *ngIf="formControls.healthCardNumberSearch.errors?.required">
        Please select {{tabName}}
      </mat-error>
      <mat-error *ngIf="formControls.healthCardNumberSearch.errors?.match && !(formControls.healthCardNumberSearch.errors?.required)">
        Please select {{tabName}}
      </mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-sm-3 require">
          <mat-checkbox class="inlineInherit" formControlName="isLab">
            Lab
          </mat-checkbox>
        </div>
        <div class="col-sm-3 require">
          <mat-checkbox class="inlineInherit" formControlName="isDocument">
            Document
          </mat-checkbox>
        </div>       
      </div>
      <div class="row" style="margin-top: -15px;margin-bottom: 20px;">
        <div class="col-sm-6 require"></div>
        <div class="col-sm-3 require">
          <mat-checkbox class="inlineInherit"  formControlName="isCarePlan">
            Care Plan
          </mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6" style="padding: 0; margin-top: 10px">
          <mat-form-field class="example-full-width">
            <input
              placeholder="Search Staff"
              aria-label="staffNameSearch"
              matInput
              formControlName="staffNameSearch"
              [matAutocomplete]="auto1"
              required
            />
            <div>
              <img
                src="../../../../../../assets/img/search.png"
                class="search-icon-input"
              />
            </div>
            <mat-autocomplete
              class="Search_staff_name"
              #auto1="matAutocomplete"
              (optionSelected)="onStaffSelectedName($event)"
              [displayWith]="displayFnStaff"
            >
              <mat-option *ngIf="isloadingStaff" class="is-loading">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-option>
              <ng-container>
                <mat-option
                  *ngFor="let option of filterStaffName$ | async"
                  [value]="option"
                >
                  <span> {{ option.fullName }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <!-- <mat-error *ngIf="formControls.healthCardNumberSearch.errors?.required">
          Please select {{tabName}}
        </mat-error>
        <mat-error *ngIf="formControls.healthCardNumberSearch.errors?.match && !(formControls.healthCardNumberSearch.errors?.required)">
          Please select {{tabName}}
        </mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-sm-3 require">
          <mat-checkbox (change)="checkedInbox($event)" class="inlineInherit" formControlName="isInBox">
            Task
          </mat-checkbox>
        </div>
        <div class="col-sm-3 require">
          <mat-checkbox class="inlineInherit" formControlName="isReferral">
            Referral
          </mat-checkbox>
        </div>
      </div>
    </form>
  </div>
  <div class="submit_referal">
    <button
      color="primary"
      name="save"
      (click)="onSubmit($event)"
      class="add-primary-btn"
      form="ReferralForm"
      mat-raised-button
    >
      Submit
    </button>
  </div>
</div>
