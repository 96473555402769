<div class="custom_modal">
  <!-- <button class="close" mat-button (click)="closeDialog('close')">
        <mat-icon style="color: white;">close</mat-icon>
    </button> -->
  <h3 mat-dialog-title>Session Timeout Warning</h3>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="readmin-form custom_form">
          <div class="col-sm-12">
            <h2>{{ data.idel }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div>
      <button
        style="
          margin-right: 15px;
          background-color: #ff5454 !important;
          color: white;
        "
        mat-raised-button
        (click)="closeDialog('logout')"
        cdkFocusInitial
      >
        Logout
      </button>
    </div>
    <button mat-raised-button color="primary" (click)="closeDialog('continue')">
      Stay Logged In
    </button>
  </div>
</div>
