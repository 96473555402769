import "hammerjs";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppMaterialModule } from "./app.material.module";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { SubDomainGuard } from "./subDomain.guard";
import { HttpClientModule } from "@angular/common/http";
import { SubDomainService } from "./subDomain.service";
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { SafePipe } from "./shared/pipes/safe.pipe";
import { CallHubConnection } from "./shared/layout/callHub.service";
import { CommonService } from "./platform/modules/core/services";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from "angular2-moment";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { AgmCoreModule } from "@agm/core";

// import { AwesomeTooltipComponent } from './tooltip/tooltip.component';
/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: "right",
      distance: 12,
    },
    vertical: {
      position: "bottom",
      distance: 12,
      gap: 10,
    },
  },
  theme: "material",
  behaviour: {
    autoHide: 5000,
    onClick: "hide",
    onMouseover: "pauseAutoHide",
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: "slide",
      speed: 300,
      easing: "ease",
    },
    hide: {
      preset: "fade",
      speed: 300,
      easing: "ease",
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: "ease",
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [AppComponent, LandingPageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppMaterialModule,
    AppRoutingModule,
    SharedModule,
    NotifierModule.withConfig(customNotifierOptions),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
  ],
  providers: [
    SubDomainGuard,
    SubDomainService,
    CallHubConnection,
    CommonService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
