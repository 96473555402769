/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./call-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./call-notification.component";
import * as i9 from "@angular/common/http";
import * as i10 from "@angular/router";
import * as i11 from "../../../platform/modules/core/services/common.service";
var styles_CallNotificationComponent = [i0.styles];
var RenderType_CallNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallNotificationComponent, data: {} });
export { RenderType_CallNotificationComponent as RenderType_CallNotificationComponent };
export function View_CallNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "custom_modal modal-lg custom-modal-design"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["INCOMING CALL NOTIFICATION"])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "buttons mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Video Call from ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "accept"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptcall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Accept "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "decline cancel-btn mat-raised-button mat-button-base"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.declinecall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Decline "]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_4 = "primary"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.InitiatorName; _ck(_v, 7, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 12).disabled || null); var currVal_6 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_5, currVal_6); }); }
export function View_CallNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-call-notification", [], null, null, null, View_CallNotificationComponent_0, RenderType_CallNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i8.CallNotificationComponent, [i9.HttpClient, i2.MatDialogRef, i2.MatDialog, i10.Router, i11.CommonService, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CallNotificationComponentNgFactory = i1.ɵccf("app-call-notification", i8.CallNotificationComponent, View_CallNotificationComponent_Host_0, {}, {}, []);
export { CallNotificationComponentNgFactory as CallNotificationComponentNgFactory };
