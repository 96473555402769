import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/platform/modules/core/services';
import { ClientsService } from 'src/app/platform/modules/agency-portal/clients/clients.service';
import { OpenLabModel } from './open-lab.model';
var OpenLabComponent = /** @class */ (function () {
    function OpenLabComponent(dialogRef, notifier, formBuilder, activatedRoute, commonService, clientService, router, notifierService, data, clientsService) {
        this.dialogRef = dialogRef;
        this.notifier = notifier;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.commonService = commonService;
        this.clientService = clientService;
        this.router = router;
        this.notifierService = notifierService;
        this.data = data;
        this.clientsService = clientsService;
        this.submitted = false;
        this.save = 'save';
        this.openLabModel = new OpenLabModel();
        this.openLabModel = data;
        this.patientId = data.patientId;
        this.staffId = data.staffId;
    }
    OpenLabComponent.prototype.ngOnInit = function () {
        var userInfo = this.commonService.getLoginUserInfo();
        //console.log(userInfo);   
        this.LabForm = this.formBuilder.group({
            id: [this.openLabModel.id],
            typeOfTest: [this.openLabModel.typeOfTest],
            orderDate: [this.openLabModel.orderDate],
            labName: [this.openLabModel.labName],
            physicianName: [this.openLabModel.physicianName]
        });
    };
    Object.defineProperty(OpenLabComponent.prototype, "formControls", {
        //get the form controls on html page
        get: function () { return this.LabForm.controls; },
        enumerable: true,
        configurable: true
    });
    OpenLabComponent.prototype.closeDialog = function (action) {
        this.dialogRef.close(action);
    };
    OpenLabComponent.prototype.onSubmit = function (event) {
        var _this = this;
        if (!this.LabForm.invalid) {
            var clickType_1 = event.currentTarget.name;
            this.submitted = true;
            this.openLabModel = this.LabForm.value;
            this.openLabModel.patientId = this.patientId;
            this.openLabModel.staffId = this.staffId;
            this.openLabModel.base64 = this.commonService.getFaxFileObj(this.data.faxFileBase64);
            this.clientService.createLabResult(this.openLabModel).subscribe(function (response) {
                _this.submitted = false;
                if (response.statusCode == 200) {
                    _this.notifier.notify('success', response.message);
                    if (clickType_1 == "Save")
                        _this.closeDialog('save');
                }
                else {
                    _this.notifier.notify('error', response.message);
                }
            });
        }
    };
    return OpenLabComponent;
}());
export { OpenLabComponent };
