import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material";
import { TermsAndConditionsComponent } from "src/app/platform/modules/auth/terms-and-conditions/terms-and-conditions.component";

@Component({
  selector: "app-terms-conditions-reminder",
  templateUrl: "./terms-conditions-reminder.component.html",
  styleUrls: ["./terms-conditions-reminder.component.css"],
})
export class TermsConditionsReminderComponent implements OnInit {
  constructor(
    private dialogModalRef: MatDialogRef<TermsConditionsReminderComponent>,
    private inventoryDialog: MatDialog
  ) {}

  ngOnInit() {}
  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
  declineTerms() {
    this.closeDialog("close");
  }

  createReminderModel() {
    const userRoleModalPopup = this.inventoryDialog.open(
      TermsAndConditionsComponent,
      {
        hasBackdrop: true,
        backdropClass: "backdropBackground",
      }
    );

    userRoleModalPopup.afterClosed().subscribe((result) => {});
  }
}
