import { Component, OnInit, Input, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";

import { NotifierService } from "angular-notifier";
import { DialogService } from "src/app/shared/layout/dialog/dialog.service";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/platform/modules/core/services";

@Component({
  selector: "app-call-notification",
  templateUrl: "./call-notification.component.html",
  styleUrls: ["./call-notification.component.css"],
})
export class CallNotificationComponent implements OnInit {
  private subscription: Subscription;
  isStaffs = false;
  isAdminLogin: boolean;
  isClient: boolean = false;
  appointmentId: any;
  currentLoginUserId: number;
  InitiatorName: string;
  patientname: string;
  isVpSales: boolean;
  constructor(
    private http: HttpClient,
    private dialogRef: MatDialogRef<CallNotificationComponent>,
    private diaglog: MatDialog,
    private router: Router,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.appointmentId = data.appointmentId;
    this.InitiatorName = data.InitiatorName;
    this.patientname = data.patientname;
  }

  ngOnInit() {
    this.subscription = this.commonService.currentLoginUserInfo.subscribe(
      (user: any) => {
        if (user) {
          this.currentLoginUserId = user.users3.id;
          this.isAdminLogin =
            user.users3 &&
            user.users3.userRoles &&
            (user.users3.userRoles.userType || "").toUpperCase() == "ADMIN";
          // if (
          //   user.users3 &&
          //   user.users3.userRoles &&
          //   (user.users3.userRoles.value || "").toUpperCase() == "ADMIN"
          // ) {
          //   this.isStaffs = true;
          // }
          if (
            user.users3 &&
            user.users3.userRoles &&
            (user.users3.userRoles.value || "").toUpperCase() == "CLIENT"
          ) {
            this.isClient = true;
          }
          // if (user.users3 && user.users3.userRoles && (user.users3.userRoles.value || '').toUpperCase() == "REGIONAL VP SALES/MARKETING") {
          //   this.isVpSales = true;
          //   }
        }
      }
    );
  }

  acceptcall(): void {
    if (this.isStaffs || this.isAdminLogin) {
      this.dialogRef.close();
      // this.router.navigate(["/web/encounter/video-call"], {
      //   queryParams: {
      //     apptId: this.appointmentId,
      //     isPatient: false,
      //   },
      // });
      window.open(
        window.location.origin +"/"+ this.commonService.getBusinessUrl()+
        "/web/encounter/video-call?apptId=" +
        this.appointmentId +
        "&isPatient=" +
        false,
      "_blank"
      );
    } else {
      if (this.isClient) {
        this.dialogRef.close();
        // this.router.navigate(["/web/client/patient-encounter/video-call"], {
        //   queryParams: {
        //     apptId: this.appointmentId,
        //     isPatient: true,
        //   },
        // });
        window.open(
          window.location.origin +"/"+ this.commonService.getBusinessUrl()+
            "/web/client/patient-encounter/video-call?apptId=" +
            this.appointmentId +
            "&isPatient=" +
            true,
            "_blank"
        );
      }
    }
  }

  declinecall(): void {
    this.dialogRef.close();
  }
}
