<div class="custom_modal">
  <h3 mat-dialog-title>Add Signature</h3>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <!-- <form
          (ngSubmit)="onSubmit()"
          id="signForm"
          #documentForm="ngForm"
          class="readmin-form custom_form"
          [formGroup]="signForm"
        > -->
        <div class="row">
          <div class="col-sm-12">
            <signature-pad
              [options]="signaturePadOptions"
              (onEndEvent)="drawComplete()"
            >
            </signature-pad>
          </div>
        </div>
        <div *ngIf="showError" class="row">
          <div class="col-sm-12 text-center">
            <span style="color: red">Please Add Signature</span>
          </div>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div>
      <button class="cancel-btn" mat-raised-button (click)="onClose()">
        Close
      </button>
      <button class="cancel-btn ml-3" mat-raised-button (click)="onClear()">
        Clear
      </button>
    </div>
    <button
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="submitted"
      cdkFocusInitial
    >
      {{ submitted ? "Submiting..." : "Submit" }}
    </button>
  </div>
</div>
