export class SubscriptionPaymentModel {
  id: number;
  cardNumber: string;
  expMonth: number;
  expYear: number;
  cardCVV: number;
  streetAddress: string;
  city: string;
  state: string;
  countryId: number;
  zipCode: string;
  cardName: string;
  typeCard: string;
  plantype: string;
  amountofsubscription: number;
  iscardsavedetails: boolean;
}

export class SubscriptionPaymentFilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  key: string = "";
}
