import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { CommonService } from "src/app/platform/modules/core/services";
import { ClientsService } from "src/app/platform/modules/agency-portal/clients/clients.service";
import {
  PatientPreferenceFilterModel,
  ResponseModel,
} from "src/app/platform/modules/core/modals/common-model";
import {
  ReferralModel,
  RefModel,
} from "src/app/platform/modules/agency-portal/refference/outgoing-referal/add-referal/add-referral.model";
import { Observable, of } from "rxjs";
import {
  startWith,
  debounceTime,
  tap,
  switchMap,
  finalize,
  map,
  catchError,
} from "rxjs/operators";
import { format, getTime } from "date-fns";
import { autoCompleteValidatorAsync$$ } from "src/app/platform/modules/agency-portal/clients/diagnosis/diagnosis-modal/diagnosis-modal.component";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
} from "@angular/material";
import { OpenLabComponent } from "./open-lab/open-lab.component";
import { OpenReferralComponent } from "./open-referral/open-referral.component";
import { OpenDocumentComponent } from "./open-document/open-document.component";
import { Navigation } from "selenium-webdriver";
import { DialogService } from "../dialog/dialog.service";

@Component({
  selector: "app-accept-reject-referal",
  templateUrl: "./accept-reject-referal.component.html",
  styleUrls: ["./accept-reject-referal.component.css"],
  providers: [ClientsService],
})
export class AcceptRejectReferalComponent implements OnInit {
  filterModel: PatientPreferenceFilterModel;
  caption = "";
  clientId: number;
  patientId: number;
  StaffId: number;
  referralModel: ReferralModel;
  refModel: RefModel;
  submitted: boolean = false;
  ReferralForm: FormGroup;
  _clickType = "";
  filterHealthCardNo$: Observable<any[]>;
  filterStaffName$: Observable<any[]>;
  isloadingPatients: boolean = false;
  isloadingStaff: boolean = false;
  tabName: any;
  faxFileName = "";
  faxFileData="";

  constructor(
    private dialogRef: MatDialogRef<AcceptRejectReferalComponent>,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private clientService: ClientsService,
    private router: Router,
    private openLabDialog: MatDialog,
    private notifierService: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientsService: ClientsService,
    private dialogService: DialogService
  ) {
    this.referralModel = new ReferralModel();
    //  console.log("Patient Id from cont",data)
    this.clientId = data.patientId;
  }

  ngOnInit() {
    var userInfo = this.commonService.getLoginUserInfo();
    this.tabName = this.commonService.getOrganizationTypeName();
    // console.log(userInfo);
    this.ReferralForm = this.formBuilder.group({
      id: [this.referralModel.id],
      isLab: [""],
      isReferral: [""],
      isDocument: [""],
      isInBox: [""],
      isCarePlan: [""],
      healthCardNumberSearch: [""],
      staffNameSearch: [
        "",
        autoCompleteValidatorAsync$$.valueSelected([], "staffNameSearch", true),
      ],
      // healthCardNumberSearch: ['', autoCompleteValidatorAsync$$.valueSelected([], 'healthCardNumber', true)],
      // staffNameSearch: ['', autoCompleteValidatorAsync$$.valueSelected([], 'staffNameSearch', true)]
    });
    this.filterModel = new PatientPreferenceFilterModel();
    this.filterModel.patientId = this.clientId;
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 10;
    this.getClientHeaderInfo();

    this._filter("");
    this.filterHealthCardNo$ = this.ReferralForm.get(
      "healthCardNumberSearch"
    ).valueChanges.pipe(
      startWith(""),
      debounceTime(100),
      tap((value) => {
        value.length > 3
          ? (this.isloadingPatients = true)
          : (this.isloadingPatients = false);
      }),
      switchMap((value) => {
        if (value.length > 2) {
          let list: Observable<any>;
          list = this._filter(value).pipe(
            finalize(() => (this.isloadingPatients = false))
          );
          list.subscribe((_value) => {
            this.ReferralForm.controls.healthCardNumberSearch.setValidators([
              autoCompleteValidatorAsync$$.valueSelected(
                _value,
                "healthCardNumber",
                true
              ),
            ]);
            // this.ReferralForm.controls.healthCardNumberSearch.setValidators(
            //   [autoCompleteValidatorAsync$$.valueSelected(_value, 'healthCardNumber', true)]);
          });
          return list;
          // return this._filter(value).pipe(finalize(() => (this.isloadingPatients = false)));
        } else {
          return of(null);
        }
      })
    );

    this._filterStaff("");
    this.filterStaffName$ = this.ReferralForm.get(
      "staffNameSearch"
    ).valueChanges.pipe(
      startWith(""),
      debounceTime(100),
      tap((value) => {
        value.length > 3
          ? (this.isloadingStaff = true)
          : (this.isloadingStaff = false);
      }),
      switchMap((value) => {
        if (value.length > 2) {
          let list: Observable<any>;
          list = this._filterStaff(value).pipe(
            finalize(() => (this.isloadingStaff = false))
          );
          list.subscribe((_value) => {
            this.ReferralForm.controls.staffNameSearch.setValidators([
              autoCompleteValidatorAsync$$.valueSelected(
                _value,
                "staffNameSearch",
                true
              ),
            ]);
            // this.ReferralForm.controls.healthCardNumberSearch.setValidators(
            //   [autoCompleteValidatorAsync$$.valueSelected(_value, 'healthCardNumber', true)]);
          });
          return list;
          // return this._filter(value).pipe(finalize(() => (this.isloadingPatients = false)));
        } else {
          return of(null);
        }
      })
    );

    this.ReferralForm.controls.isLab.valueChanges.subscribe((value) => {
      if (value == true) {
        this.createOpenLabModal();
      }
    });

    this.ReferralForm.controls.isReferral.valueChanges.subscribe((value) => {
      if (value == true) {
        this.createOpenReferralModal();
      }
    });

    this.ReferralForm.controls.isDocument.valueChanges.subscribe((value) => {
      if (value == true) {
        this.createOpenDocumentModal(false);
      }
    });
    this.ReferralForm.controls.isCarePlan.valueChanges.subscribe((value) => {
      if (value == true) {
        this.createOpenDocumentModal(true);
      }
    });
  }

  displayFn(client?: any): string | undefined {
    this.patientId = client.patientId;
    return client ? client.fullName : undefined;
  }

  private _filter(value: string): Observable<any> {
    const filterValue = value.toLowerCase();
    return this.clientService.getpatientsearchRecord(filterValue).pipe(
      map((response: any) => {
        if (response.statusCode == 201) return of(null);
        else
          return (response.data || []).map((clientObj: any) => {
            const Obj: any = clientObj;
            return Obj;
          });
      }),
      catchError((_) => {
        return of(null);
      })
    );
  }

  displayFnStaff(client?: any): string | undefined {
    this.StaffId = client.staffId;
    return client ? client.fullName : undefined;
  }

  private _filterStaff(value: string): Observable<any> {
    const filterValue = value.toLowerCase();
    return this.clientService.getstaffsearchRecord(filterValue).pipe(
      map((response: any) => {
        if (response.statusCode == 201) return of(null);
        else
          return (response.data || []).map((clientObj: any) => {
            const Obj: any = clientObj;
            return Obj;
          });
      }),
      catchError((_) => {
        return of(null);
      })
    );
  }

  get formControls() {
    return this.ReferralForm.controls;
  }

  get ReferralValidation() {
    return (
      !this.formControls.ReferralDate.value &&
      !this.formControls.ReferredTo.value &&
      !this.formControls.ReasonsForReferral.value &&
      !this.formControls.ReferralNotes.value
    );
  }

  getClientHeaderInfo() {
    console.log("Check patient id", this.clientId);
    this.clientsService
      .getClientHeaderInfo(this.clientId)
      .subscribe((response: ResponseModel) => {
        if (response != null && response.statusCode == 200) {
          // console.log('header info', response.data.patientBasicHeaderInfo);
          if (response.data.patientBasicHeaderInfo) {
            this.referralModel.patientID =
              response.data.patientBasicHeaderInfo.patientID;
            this.referralModel.patientName =
              response.data.patientBasicHeaderInfo.name;
            this.referralModel.patientdob =
              response.data.patientBasicHeaderInfo.dob;
            this.referralModel.sex =
              response.data.patientBasicHeaderInfo.gender;
            this.referralModel.healthCardNumber =
              response.data.patientBasicHeaderInfo.phcNo;
            // console.log('header info bind', this.referralModel);
          }
        }
      });
  }

  onPatientSelectedHCNumber(event) {
    this.clientId = event.option.value.patientId;
    // this.SearchHealthCardNumber(event.option.value);
  }

  onStaffSelectedName(event) {
    this.StaffId = event.option.value.staffId;
    // this.SearchStaffNameWiseData(event.option.value);
  }

  tempvar: any;
  SearchHealthCardNumber(inputval: any) {
    if (inputval.healthCardNumber != "") {
      this.clientService
        .getpatientAdminissionbyHeathCardNobyData(inputval.healthCardNumber)
        .subscribe((response: ResponseModel) => {
          if (response.statusCode == 200) {
            if (
              response != null &&
              response.data != null &&
              response.data.length > 0
            ) {
              //console.log("patient name wise data get", response.data);
              // this.referralModel.patientID = response.data[0].patientID;
              //this.referralModel.patientName = response.data[0].residentName;
              // this.referralModel.healthCardNumber = response.data[0].healthCardNumber;
              this.clientId = response.data[0].patientID;
            } else {
              // console.log("patient name wise data", response.data);
              this.notifierService.notify("error", response.message);
              this.ReferralForm.reset();
              // this.referralModel.patientName = null;
              // this.referralModel.healthCardNumber = null;
            }
          }
        });
    } else {
      this.notifierService.notify(
        "error",
        "Please enter " + this.commonService.getOrganizationTypeName() + " name"
      );
    }
  }

  onSubmit(event: any) {
    let clickType = event.currentTarget.name;
    if (!this.ReferralForm.invalid) {
      this.submitted = true;
      this.refModel = this.ReferralForm.value;
      this.refModel.patientID = this.clientId;
      this.refModel.staffId = this.StaffId;
      this.clientService
        .createIncomingReferForm(this.refModel)
        .subscribe((response: any) => {
          this.submitted = false;
          if (response.statusCode == 200) {
            this.notifier.notify("success", response.message);
            if (clickType == "save") this.closeDialog("save");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
    }
  }

  closeDialog(action: string): void {
    this.dialogRef.close(action);
  }

  accept() {
    this.router.navigate(["web/refferal"], {});
    this.closeDialog("close");
  }

  getUserDocument() {
    this.clientService.fetchFaxDetails(this.data.faxFileName).subscribe(res => {
      this.faxFileData = res.data;
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(res.data) + "'></iframe>"
      )
      // const source = `data:application/pdf;base64,${res.data}`;
      // const link = document.createElement("a");
      // link.href = source;
      // link.download = 'new_fax_recieved_' + new Date().toString() + '.pdf'
      // link.click();
    });
  }

  createOpenLabModal() {
    if (this.faxFileData) {
      const userRoleModalPopup = this.openLabDialog.open(OpenLabComponent, {
        hasBackdrop: true,
        data: {
          patientId: this.clientId,
          staffId: this.StaffId,
          faxFileBase64: this.faxFileData
        },

        maxHeight: 1400,
        maxWidth: 1000,
      });
      userRoleModalPopup.afterClosed().subscribe((result) => {
        if (result === "save") {
          this.ReferralForm.controls.isLab.setValue(false);
          this.openLabDialog.closeAll();
        }
      });
    } else {
      this.ReferralForm.controls.isLab.setValue(false);
      alert('Please review document first');
    }

  }

  createOpenReferralModal() {
    const userRoleModalPopup = this.openLabDialog.open(OpenReferralComponent, {
      hasBackdrop: true,
      data: {
        patientId: this.clientId,
        staffId: this.StaffId,
        faxFileBase64: this.faxFileData
      },

      maxHeight: 1400,
      maxWidth: 1000,
    });
    userRoleModalPopup.afterClosed().subscribe((result) => {
      if (result === "save") {
        this.ReferralForm.controls.isReferral.setValue(false);
        this.openLabDialog.closeAll();
      }
    });
  }

  createOpenDocumentModal(isCarePlan: boolean) {
    if (this.faxFileData) {
    const userRoleModalPopup = this.openLabDialog.open(OpenDocumentComponent, {
      hasBackdrop: true,
      data: {
        patientId: this.clientId,
        staffId: this.StaffId,
        isCarePlan: isCarePlan,
        faxFileBase64: this.faxFileData
      },

      maxHeight: 1400,
      maxWidth: 1000,
    });
    userRoleModalPopup.afterClosed().subscribe((result) => {
      if (result === "save" && isCarePlan) {
        this.ReferralForm.controls.isCarePlan.setValue(false);
        this.openLabDialog.closeAll();
      } else if (result === "save" && !isCarePlan) {
        this.ReferralForm.controls.isDocument.setValue(false);
      }
    });
   }
   else {
    this.ReferralForm.controls.isCarePlan.setValue(false);
    alert('Please review document first');
    this.ReferralForm.controls.isDocument.setValue(false);
    alert('Please review document first');
   }
  }
  
  checkedInbox(event) {
    if (event.checked) {
      this.dialogService
        .confirm(`Are you sure you want to go to Add/Edit Task screen?`)
        .subscribe((result: any) => {
          if (result == true) {
            let businessName = localStorage.getItem("business-url");
            this.router.navigate(["/" + businessName + "/web/add-edit-task"],
            {
              queryParams:{
                faxFileName:this.commonService.encryptValue(this.data.faxFileName,true)
              }
            });
            this.closeDialog("save")
          } else {
            this.ReferralForm.get('isInBox').setValue(false);
          }
        });
    }
  }
}
