import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ClientsService } from '../../../../clients.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-cross-out-reason',
  templateUrl: './cross-out-reason.component.html',
  styleUrls: ['./cross-out-reason.component.css']
})
export class CrossOutReasonComponent implements OnInit {
  reasonForm: FormGroup;
  adlTrackingToolId: number;
  masterCrossOutReasonlist: any[];
  patientId: number;
  crossOutReasonId: number;
  isCross: boolean;
  submitted: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogModalRef: MatDialogRef<CrossOutReasonComponent>,
    private clientService: ClientsService,
    private notifier: NotifierService
  ) {
    this.adlTrackingToolId = data.adlTrackingId;
    this.patientId = data.patientId;
  }

  ngOnInit() {
    this.getMasterData();
    this.reasonForm = new FormGroup({
      crossOutReasonId: new FormControl()
    });
  }

  getMasterData() {
    let data = "MEDICATION";
    this.clientService.getMasterData(data).subscribe((response: any) => {
      if (response != null) {
        this.masterCrossOutReasonlist = response.medicationsList.filter(
          (s) => s.type == "CrossOut Reason"
        );
      }
    });
  }

  onSubmit(event: any) {
    if (!this.reasonForm.invalid) {
      this.submitted = true;
      this.crossOutReasonId = this.reasonForm.value.crossOutReasonId;
      // this.crossOutReasonModel.id = this.adlTrackingToolId; 
      // this.crossOutReasonModel.patientID=this.patientId;
      this.clientService.updateAdlTrackingToolCrossOutReason(this.adlTrackingToolId, this.crossOutReasonId)
        .subscribe((response: any) => {
          if (response.statusCode == 200) {
            const model = {
              adlTrackingToolId: this.adlTrackingToolId
            }
            this.clientService
              .crossADLTrackingTool(model)
              .subscribe((response: any) => {
                this.submitted = false;
                this.closeDialog("close");
              });
            this.notifier.notify("success", response.message);
          } else {
            this.submitted = false;
            this.notifier.notify("error", response.message);
          }
        });
    }
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
}
