/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tooltip.component";
var styles_AwesomeTooltipComponent = [i0.styles];
var RenderType_AwesomeTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AwesomeTooltipComponent, data: { "animation": [{ type: 7, name: "tooltip", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: 300 }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_AwesomeTooltipComponent as RenderType_AwesomeTooltipComponent };
export function View_AwesomeTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["width", "300px"]], [[8, "src", 4], [24, "@tooltip", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AwesomeTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "awesome-tooltip", [], null, null, null, View_AwesomeTooltipComponent_0, RenderType_AwesomeTooltipComponent)), i1.ɵdid(1, 49152, null, 0, i2.AwesomeTooltipComponent, [], null, null)], null, null); }
var AwesomeTooltipComponentNgFactory = i1.ɵccf("awesome-tooltip", i2.AwesomeTooltipComponent, View_AwesomeTooltipComponent_Host_0, { image: "image", text: "text" }, {}, []);
export { AwesomeTooltipComponentNgFactory as AwesomeTooltipComponentNgFactory };
