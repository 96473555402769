var SubscriptionPaymentModel = /** @class */ (function () {
    function SubscriptionPaymentModel() {
    }
    return SubscriptionPaymentModel;
}());
export { SubscriptionPaymentModel };
var SubscriptionPaymentFilterModel = /** @class */ (function () {
    function SubscriptionPaymentFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
        this.key = "";
    }
    return SubscriptionPaymentFilterModel;
}());
export { SubscriptionPaymentFilterModel };
