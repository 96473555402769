import { Component, OnInit,ViewChild, Inject } from '@angular/core';
import { DICOMViewerComponent } from 'ng-dicomviewer';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/first';

declare const cornerstone;
declare const cornerstoneWADOImageLoader;
@Component({
  selector: 'app-dicom-viewer',
  templateUrl: './dicom-viewer.component.html',
  styleUrls: ['./dicom-viewer.component.css']
})
export class DicomViewerComponent implements OnInit {

  constructor(private dialogModalRef: MatDialogRef<DicomViewerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(DICOMViewerComponent, { static: true }) viewPort: DICOMViewerComponent;


  ngOnInit() {
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone; // inicializa WADO Image loader

    // configura codecs e web workers
    cornerstoneWADOImageLoader.webWorkerManager.initialize({
        webWorkerPath: './assets/cornerstone/webworkers/cornerstoneWADOImageLoaderWebWorker.js',
        taskConfiguration: {
            'decodeTask': {
                codecsPath: '../codecs/cornerstoneWADOImageLoaderCodecs.js'
            }
        }
    });

  }

  /**
   * Load selected DICOM images
   *
   * @param files list of selected dicom files
   */
  loadDICOMImages(files: FileList) {
    if (files && files.length > 0) {
      let imageList = [];
      const fileList:Array<File> = Array.from(files);
      fileList.sort((a,b) => {
        if ( a.name > b.name ) return 1;
        if ( b.name > a.name ) return -1;
        return 0;
      })
      //cornerstoneWADOImageLoader.wadouri.fileManager.purge();
      cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.purge();

      // loop thru the File list and build a list of wadouri imageIds (dicomfile:)
      for (let i = 0; i < fileList.length; i++) {
        const dicomFile: File = fileList[i];
        const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(dicomFile);
        imageList.push(imageId);
      }

      this.viewPort.resetAllTools();

      // now load all Images, using their wadouri
      this.viewPort.loadStudyImages(imageList);

    } else alert('Escolha imagens DICOM a exibir.');

  
  //   this.fetchDicomImage('')
  //   console.log(this.data.file)
  //   if(this.data.file)
  //   {
  //     // let file=new FileList
  //     var files=[];
  //     // file[0]=this.data.file
      
  //     // this.loadDICOMImages(this.data.file)
  //   }
  //   console.log(cornerstoneWADOImageLoader)
  //   cornerstoneWADOImageLoader.external.cornerstone = cornerstone; // inicializa WADO Image loader

  //   // configura codecs e web workers
  //   cornerstoneWADOImageLoader.webWorkerManager.initialize({
  //       webWorkerPath: './assets/cornerstone/webworkers/cornerstoneWADOImageLoaderWebWorker.js',
  //       taskConfiguration: {
  //           'decodeTask': {
  //               codecsPath: './assets/cornerstone/codecs/cornerstoneWADOImageLoaderCodecs.js'
  //           }
  //       }
  //   });

  // }
  
  // fetchDicomImage(url: string) {
  //   console.log(`fetching ${url}`)
  //   url='E:\Access EMR\MS-AccessEMR_backend2\AccessEMR.Main\AccessEMR.Web//Documents//PatientResultRadiologyDicomDocument//smartData_Enterprises_07_28_08.6839501.txt'

  //   this.imageData= Observable.fromPromise(cornerstone.loadAndCacheImage(`wadouri:${url}`)).first();

  // }
  // imageData:any
  // loadDICOMImages(files: FileList) {
  //   console.log(files)
  //   if (files && files.length > 0) {
  //     let imageList = [];
  //     const fileList:Array<File> = Array.from(files);
  //     fileList.sort((a,b) => {
  //       if ( a.name > b.name ) return 1;
  //       if ( b.name > a.name ) return -1;
  //       return 0;
  //     })
  //     //cornerstoneWADOImageLoader.wadouri.fileManager.purge();
  //     cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.purge();

  //     // loop thru the File list and build a list of wadouri imageIds (dicomfile:)
  //     for (let i = 0; i < fileList.length; i++) {
  //       const dicomFile: File = fileList[i];
  //       const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(dicomFile);
  //       imageList.push(imageId);
  //     }

  //     this.viewPort.resetAllTools();

  //     // now load all Images, using their wadouri
  //     this.viewPort.loadStudyImages(imageList);

  //   } 
  //    else if(files)
  //    {
  //     var fi:any=files
  //     var fin:File=fi
  //  let file:any=[]
  //  file.push(fin)
  //       let imageList = [];
  //       const fileList:Array<File> = Array.from(file);
  //       fileList.sort((a,b) => {
  //         if ( a.name > b.name ) return 1;
  //         if ( b.name > a.name ) return -1;
  //         return 0;
  //       })
  //       //cornerstoneWADOImageLoader.wadouri.fileManager.purge();
  //       cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.purge();
  
  //       // loop thru the File list and build a list of wadouri imageIds (dicomfile:)
  //       for (let i = 0; i < fileList.length; i++) {
  //         const dicomFile: File = fileList[i];
  //         const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(dicomFile);
  //         imageList.push(imageId);
  //       }
  
  //       this.viewPort.resetAllTools();
  
  //       // now load all Images, using their wadouri
  //       this.viewPort.loadStudyImages(imageList);
  //     }
     
  //   else alert('Error occured');
  }

   closeDialog(action: string): void {
     this.dialogModalRef.close(action);
   }

}
