<div class="custom_modal">
  <h3 mat-dialog-title>Lab</h3>
  <div mat-dialog-content>

    <div class="row">
      <div class="col-sm-12">
        <form id="LabForm" #documentForm="ngForm" class="readmin-form custom_form" [formGroup]="LabForm">

          <div class="row">

            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Type Of Test" formControlName="typeOfTest"
                  [ngClass]="{ 'is-invalid': submitted && formControls.typeOfTest.errors }" required />
                <mat-error *ngIf="formControls.typeOfTest.errors?.required">
                  Please enter type of test
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="orderDate" placeholder="Order Date" readonly
                  formControlName="orderDate" [max]="LabForm.controls.orderDate.value" required>
                <mat-datepicker-toggle matSuffix [for]="orderDate"></mat-datepicker-toggle>
                <mat-datepicker #orderDate></mat-datepicker>
                <mat-error *ngIf="formControls.orderDate.errors?.required">
                  Please enter valid date
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Lab Name" formControlName="labName"
                  [ngClass]="{ 'is-invalid': submitted && formControls.labName.errors }" required />
                <mat-error *ngIf="formControls.labName.errors?.required">
                  Please enter lab name
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Physician Name" formControlName="physicianName"
                  [ngClass]="{ 'is-invalid': submitted && formControls.physicianName.errors }" required />
                <mat-error *ngIf="formControls.physicianName.errors?.required">
                  Please enter physician name
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button class="cancel-btn" (click)="closeDialog('close')">Close</button>
    <div>
      <button name="Save" mat-raised-button color="primary" (click)="onSubmit($event)" form="LabForm"
        class="text-uppercase" [disabled]="submitted" cdkFocusInitial>{{submitted ? 'Saving...' : 'Save'}}</button>
    </div>
  </div>
</div>