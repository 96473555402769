var NotificationsModel = /** @class */ (function () {
    function NotificationsModel() {
    }
    return NotificationsModel;
}());
export { NotificationsModel };
var UserDocumentNotificationModel = /** @class */ (function () {
    function UserDocumentNotificationModel() {
    }
    return UserDocumentNotificationModel;
}());
export { UserDocumentNotificationModel };
var MessageNotificationModel = /** @class */ (function () {
    function MessageNotificationModel() {
    }
    return MessageNotificationModel;
}());
export { MessageNotificationModel };
var TaskNotification = /** @class */ (function () {
    function TaskNotification() {
    }
    return TaskNotification;
}());
export { TaskNotification };
var ChatNotificationModel = /** @class */ (function () {
    function ChatNotificationModel() {
    }
    return ChatNotificationModel;
}());
export { ChatNotificationModel };
var StaffExpiredDocNotification = /** @class */ (function () {
    function StaffExpiredDocNotification() {
    }
    return StaffExpiredDocNotification;
}());
export { StaffExpiredDocNotification };
