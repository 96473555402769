<div class="custom_modal">
  <h3 mat-dialog-title>{{ headerText }}</h3>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="readmin-form custom_form">
          <h2 style="font-size: 16px; text-align: center">
            {{ messageText }}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button class="cancel-btn" (click)="closeDialog('close')">
      No
    </button>
    <button
      name="Save"
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
      class="text-uppercase"
      [disabled]="submitted"
    >
      {{ submitted ? "btnText" : "Yes" }}
    </button>
  </div>
</div>
