var CommonFilterModel = /** @class */ (function () {
    function CommonFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return CommonFilterModel;
}());
export { CommonFilterModel };
var FilterModel = /** @class */ (function () {
    function FilterModel() {
        this.pageNumber = 1;
        this.pageSize = 6;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return FilterModel;
}());
export { FilterModel };
var ADTPatientFilterModel = /** @class */ (function () {
    function ADTPatientFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 6;
        this.sortColumn = "";
        this.sortOrder = "";
    }
    return ADTPatientFilterModel;
}());
export { ADTPatientFilterModel };
var TrackingFilterModel = /** @class */ (function () {
    function TrackingFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 6;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return TrackingFilterModel;
}());
export { TrackingFilterModel };
var InventoryFilterModel = /** @class */ (function () {
    function InventoryFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return InventoryFilterModel;
}());
export { InventoryFilterModel };
var InventoryListFilterModel = /** @class */ (function () {
    function InventoryListFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return InventoryListFilterModel;
}());
export { InventoryListFilterModel };
var InventoryTrackFilterModel = /** @class */ (function () {
    function InventoryTrackFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 6;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return InventoryTrackFilterModel;
}());
export { InventoryTrackFilterModel };
var ReorderTrackFilterModel = /** @class */ (function () {
    function ReorderTrackFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 6;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return ReorderTrackFilterModel;
}());
export { ReorderTrackFilterModel };
var PatientPreferenceFilterModel = /** @class */ (function () {
    function PatientPreferenceFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
        this.toDate = null;
        this.fromDate = null;
    }
    return PatientPreferenceFilterModel;
}());
export { PatientPreferenceFilterModel };
var PatientFormFilterModel = /** @class */ (function () {
    function PatientFormFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
        this.categoryIds = "";
    }
    return PatientFormFilterModel;
}());
export { PatientFormFilterModel };
var PatientVitalDateFilter = /** @class */ (function () {
    function PatientVitalDateFilter() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
        this.toDate = null;
        this.fromDate = null;
    }
    return PatientVitalDateFilter;
}());
export { PatientVitalDateFilter };
var PatientVitalDateFilter2 = /** @class */ (function () {
    function PatientVitalDateFilter2() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return PatientVitalDateFilter2;
}());
export { PatientVitalDateFilter2 };
var MonthFilterModel = /** @class */ (function () {
    function MonthFilterModel() {
    }
    return MonthFilterModel;
}());
export { MonthFilterModel };
var ResponseModel = /** @class */ (function () {
    function ResponseModel() {
        this.data = [];
        this.message = "";
        this.appError = "";
    }
    return ResponseModel;
}());
export { ResponseModel };
var Metadata = /** @class */ (function () {
    function Metadata() {
        this.pageSize = 5;
        this.defaultPageSize = 5;
    }
    return Metadata;
}());
export { Metadata };
var EmarFilterModel = /** @class */ (function () {
    function EmarFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 6;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return EmarFilterModel;
}());
export { EmarFilterModel };
var TimeSheetFilterModel = /** @class */ (function () {
    function TimeSheetFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return TimeSheetFilterModel;
}());
export { TimeSheetFilterModel };
var OpenShiftFilterModel = /** @class */ (function () {
    function OpenShiftFilterModel() {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.sortColumn = "";
        this.sortOrder = "";
        this.searchText = "";
    }
    return OpenShiftFilterModel;
}());
export { OpenShiftFilterModel };
