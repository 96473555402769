import { Injectable } from "@angular/core";
import { CommonService } from "src/app/super-admin-portal/core/services";
@Injectable({
  providedIn: "root",
})
export class SuperAdminPasswordChangeService {
  private updateNewPasswordURL = "api/Organization/UpdateUserPassword";
  private GetPasswordPoliciesURL = "/PasswordPolicies/GetPasswordPolicies";
  constructor(private commonService: CommonService) {}
  updateNewPassword(data: any) {
    return this.commonService.post(this.updateNewPasswordURL, data);
  }

  getPasswordPolicyByToken() {
    return this.commonService.getById(this.GetPasswordPoliciesURL, {});
  }
}
