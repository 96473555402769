<div class="custom_modal">
  <h3 mat-dialog-title>Consent</h3>
  <div mat-dialog-content>
    <form
      id="acceptTerms"
      #documentForm="ngForm"
      class="readmin-form custom_form"
      [formGroup]="acceptTerms"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="readmin-form custom_form">
            <div [innerHTML]="content"></div>
            <div class="row">
              <div class="col-sm-6">
                <mat-checkbox formControlName="isChecked" required>
                  I read and accept.
                </mat-checkbox>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-6">
                    Date:
                    {{ acceptTerms.controls.date.value | date: "MM/dd/yyyy" }}
                  </div>
                  <div class="col-sm-6">
                    <button
                      (click)="openSignDialog()"
                      class="save-btn-custom mat-raised-button mat-primary"
                      color="primary"
                      name="Save"
                      ng-reflect-disabled="false"
                      ng-reflect-color="primary"
                    >
                      <span class="mat-button-wrapper">Add Signature</span>
                      <div
                        class="mat-button-ripple mat-ripple"
                        ng-reflect-centered="false"
                        ng-reflect-disabled="false"
                      ></div>
                      <div class="mat-button-focus-overlay"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showError" class="row">
              <div class="col-sm-12 text-center">
                <span style="color: red">Please Add Signature</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <div>
          <button
            style="margin-right: 15px"
            class="cancel-btn"
            mat-raised-button
            (click)="declineTerms()"
            cdkFocusInitial
          >
            Exit
          </button>
        </div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="isValid"
          form="acceptTerms"
          (click)="onSubmit()"
        >
          Continue
        </button>
      </div>
    </form>
  </div>
</div>
