import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { AuthenticationService } from "src/app/super-admin-portal/auth/auth.service";
import { CommonService } from "../../core/services";
import { ResponseModel } from "src/app/super-admin-portal/core/modals/common-model";
import { AddSubsciptionComponent } from "../../agency-portal/masters/subscription-payment-details/add-subscriptiondetails/add-subscriptionpayment.component";
import { SubscriptionPaymentModel } from "../../agency-portal/masters/subscription-payment-details/subscriptionpayment.model";
import * as format from "date-fns/format";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./subscription-notification.component.html",
  styleUrls: ["./subscription-notification.component.css"],
})
export class SubscriptionExpireationComponent implements OnInit {
  getSubscriptionExpireUrl =
    "/TermsAndConditions/GetSubscriptionExpirationDetails";
  content: any;
  unitMasterModel: SubscriptionPaymentModel;

  email: string;
  fax: string;
  orgAddress: string;
  organizationName: string;
  phone: string;
  planName: string;
  price: number;
  startDate: string;
  endDate: string;
  userInfo: any;
  currency: string;
  note: string;

  constructor(
    // private authService:AuthenticationService,
    private commonService: CommonService,
    private unitmasterDialog: MatDialog,
    private dialogModalRef: MatDialogRef<SubscriptionExpireationComponent>
  ) {}

  ngOnInit() {
    this.getSubscriptionExpireUrlAnynomusly();
  }

  createModel(unitmastersModel: SubscriptionPaymentModel) {
    this.closeDialog("tempclose");
    const modalPopup = this.unitmasterDialog.open(AddSubsciptionComponent, {
      hasBackdrop: true,
      data: {
        unitmastersModel: unitmastersModel,
        // refreshGrid: this.refreshGrid.bind(this),
      },
    });

    modalPopup.afterClosed().subscribe((result) => {
      if (result === "save") {
        // this.getAllMasterTaxList(this.filterModel);
      }
    });
  }

  openDialog(id?: number) {
    // if (id != null && id > 0) {
    //   this.pharmacyService.getMasterTaxById(id).subscribe((response: any) => {
    //     if (response != null && response.data != null) {
    //       this.unitMasterModel = response.data;
    //       this.createModel(this.unitMasterModel);
    //     }
    //   });
    // } else {
    this.unitMasterModel = new SubscriptionPaymentModel();
    this.createModel(this.unitMasterModel);
  }

  getSubscriptionExpireUrlAnynomusly() {
    this.commonService
      .getAll(this.getSubscriptionExpireUrl, {})
      .subscribe((response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          this.planName = response.data.planName;
          this.startDate = format(response.data.startDate, "MM/DD/YYYY");
          this.endDate = format(response.data.endDate, "MM/DD/YYYY");
          this.price = response.data.price;
          this.currency = response.data.iso;

          let date = new Date(this.endDate);
          let currentDate = new Date();
          if (currentDate <= date) {
            let days = Math.floor(
              (date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24
            );
            if (days <= 7 && days >= 0) {
              this.note =
                "You have " +
                days +
                " more days left.Please renew before your subscription expire's ";
            }
          }
        }
      });

    this.userInfo = this.commonService.getLoginUserInfo();
    if (this.userInfo) {
      this.organizationName = this.userInfo.data.organization.organizationName;
      this.orgAddress =
        (this.userInfo.data.organization.apartmentNumber || "") +
        " " +
        (this.userInfo.data.organization.address1 || "") +
        ", " +
        (this.userInfo.data.organization.city || "") +
        ", " +
        (this.userInfo.data.organization.stateName || "") +
        ", " +
        (this.userInfo.data.organization.zip || "");
      this.phone = this.userInfo.data.organization.phone;
      this.fax = this.userInfo.data.organization.fax;
      this.email = this.userInfo.data.organization.email;
    }
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
}
