import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
var CommonService = /** @class */ (function () {
    function CommonService(http) {
        var _this = this;
        this.http = http;
        this.SytemInfoSubject = new BehaviorSubject({});
        this.sytemInfo = this.SytemInfoSubject.asObservable().pipe(distinctUntilChanged());
        this.loginUserSubject = new BehaviorSubject({});
        this.loginUser = this.loginUserSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.loginSecuritySubject = new BehaviorSubject({});
        this.loginSecurity = this.loginSecuritySubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.isAuthenticatedSubject = new ReplaySubject(1);
        this.isAuthenticated = this.isAuthenticatedSubject.asObservable();
        this.loadingStateSubject = new BehaviorSubject(false);
        this.loadingState = this.loadingStateSubject.asObservable();
        // for update the client side navigations ...
        this.updateClientNavigationSubject = new BehaviorSubject(null);
        this.updateClientNavigation = this.updateClientNavigationSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        // this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
        //   this.SytemInfoSubject.next({ ipAddress: res.ip });
        // });
        SystemIpAddress.then(function (value) {
            _this.SytemInfoSubject.next({ ipAddress: value });
        }).catch(function (e) { return console.error(e); });
    }
    CommonService.prototype.getUserScreenActionPermissions = function (moduleName, screenName) {
        var returnObj = {};
        var loginData = this.loginUserSubject.value;
        if (loginData.userPermission) {
            var modules = loginData.userPermission.modulePermissions || [], screens = loginData.userPermission.screenPermissions || [], actions = loginData.userPermission.actionPermissions || [], userRoleName = loginData.data.users3 && loginData.data.users3.userRoles.userType, isAdminLogin_1 = (userRoleName || "").toUpperCase() === "ADMIN";
            var moduleobj_1 = modules.find(function (obj) { return obj.moduleKey == moduleName; });
            var screenObj_1 = screens.find(function (obj) {
                return obj.screenKey == screenName &&
                    obj.moduleId == (moduleobj_1 && moduleobj_1.moduleId);
            });
            var actionPermissions = actions.filter(function (obj) { return obj.screenId == (screenObj_1 && screenObj_1.screenId); }) || [];
            actionPermissions.forEach(function (obj) {
                returnObj[obj.actionKey] = obj.permission || isAdminLogin_1;
            });
        }
        return returnObj;
    };
    CommonService.prototype.initializeAuthData = function () {
        var _this = this;
        if (localStorage.getItem("super-user-token")) {
            return this.http
                .get(environment.api_url + "/GetUserByToken")
                .subscribe(function (response) {
                // login successful if there's a jwt token in the response
                if (response && response.access_token) {
                    _this.setAuth(response);
                }
                else {
                    _this.purgeAuth();
                }
                return response;
            });
        }
    };
    CommonService.prototype.isValidFileType = function (fileName, fileType) {
        // Create an object for all extension lists
        var extentionLists = { video: [], image: [], pdf: [], excel: [], xml: [] };
        var isValidType = false;
        extentionLists.video = ["m4v", "avi", "mpg", "mp4"];
        extentionLists.image = ["jpg", "jpeg", "bmp", "png", "ico"];
        extentionLists.pdf = ["pdf"];
        extentionLists.excel = ["excel"];
        extentionLists.xml = ["xml"];
        //get the extension of the selected file.
        var fileExtension = fileName.split(".").pop().toLowerCase();
        isValidType = extentionLists[fileType].indexOf(fileExtension) > -1;
        return isValidType;
    };
    CommonService.prototype.logout = function () {
        // remove user from local storage to log user out
        this.purgeAuth();
    };
    CommonService.prototype.setAuth = function (user) {
        localStorage.setItem("super-user-token", JSON.stringify(user.access_token));
        // Set current user data into observable
        this.loginUserSubject.next(user);
        // Set isAuthenticated to true
        this.isAuthenticatedSubject.next(true);
    };
    CommonService.prototype.purgeAuth = function () {
        localStorage.removeItem("super-user-token");
        // Set current user to an empty object
        this.loginUserSubject.next({});
        // Set auth status to false
        this.isAuthenticatedSubject.next(false);
    };
    CommonService.prototype.getLoginUserInfo = function () {
        return this.loginUserSubject.value;
    };
    CommonService.prototype.getCurrentLoginLocationId = function () {
        var locationId = "";
        var loginData = this.loginUserSubject.value;
        if (loginData && loginData.data) {
            locationId =
                loginData.data.staffLocation[0] &&
                    loginData.data.staffLocation[0].locationID;
        }
        return locationId.toString();
    };
    CommonService.prototype.getSystemIpAddress = function () {
        return this.SytemInfoSubject.value && this.SytemInfoSubject.value.ipAddress;
    };
    CommonService.prototype.updateClientNaviagations = function (clientId) {
        this.updateClientNavigationSubject.next(clientId);
    };
    Object.defineProperty(CommonService.prototype, "getAdditionalHeaders", {
        get: function () {
            var additionalHeaders = JSON.stringify({
                Offset: new Date().getTimezoneOffset().toString(),
                Timezone: calculateTimeZone(),
                IPAddress: this.getSystemIpAddress(),
            });
            return additionalHeaders;
        },
        enumerable: true,
        configurable: true
    });
    CommonService.prototype.post = function (url, data) {
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        return this.http.post(environment.api_url + "/" + url, data, {
            headers: headers,
        });
    };
    CommonService.prototype.getdatata = function (url, data, isLoading) {
        var _this = this;
        if (isLoading === void 0) { isLoading = true; }
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        isLoading && this.loadingStateSubject.next(true);
        return this.http
            .post(environment.api_url + "/" + url, data, { headers: headers })
            .pipe(map(function (res) {
            isLoading && _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.put = function (url, data) {
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        return this.http.put(environment.api_url + "/" + url, data, {
            headers: headers,
        });
    };
    CommonService.prototype.getById = function (url, data) {
        var _this = this;
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        this.loadingStateSubject.next(true);
        return this.http
            .get(environment.api_url + "/" + url, { headers: headers })
            .pipe(map(function (res) {
            _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.getAll = function (url, data) {
        var _this = this;
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        this.loadingStateSubject.next(true);
        return this.http
            .get(environment.api_url + "/" + url, { headers: headers })
            .pipe(map(function (res) {
            _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.delete = function (url, data) {
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        return this.http.delete(environment.api_url + "/" + url, {
            headers: headers,
        });
    };
    CommonService.prototype.patch = function (url, data) {
        var _this = this;
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        this.loadingStateSubject.next(true);
        return this.http
            .patch(environment.api_url + "/" + url, data, { headers: headers })
            .pipe(map(function (res) {
            _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.download = function (url, headers) {
        return this.http.get(environment.api_url + "/" + url, {
            headers: headers,
            responseType: "blob",
        });
    };
    return CommonService;
}());
export { CommonService };
var ɵ0 = function (r) {
    var w = window, a = new (w.RTCPeerConnection ||
        w.mozRTCPeerConnection ||
        w.webkitRTCPeerConnection)({ iceServers: [] }), b = function () { };
    a.createDataChannel("");
    a.createOffer(function (c) { return a.setLocalDescription(c, b, b); }, b);
    a.onicecandidate = function (c) {
        try {
            c.candidate.candidate
                .match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g)
                .forEach(r);
        }
        catch (e) { }
    };
};
var SystemIpAddress = new Promise(ɵ0);
function calculateTimeZone(dateInput) {
    var dateObject = dateInput || new Date(), dateString = dateObject + "", tzAbbr = 
    // Works for the majority of modern browsers
    dateString.match(/\(([^\)]+)\)$/) ||
        // IE outputs date strings in a different format:
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
        // Old Firefox uses the long timezone name (e.g., "Central
        // Daylight Time" instead of "CDT")
        tzAbbr = tzAbbr[1];
    }
    if (tzAbbr.includes("Daylight")) {
        var da = tzAbbr.split(" ");
        var index = da.indexOf("Daylight");
        if (index > -1) {
            da[index] = "Standard";
        }
        tzAbbr = da.toString().replace(/,/g, " ");
    }
    return tzAbbr;
}
export { ɵ0 };
