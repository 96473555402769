<div class="custom_modal">
  <div class="MDS-Heading">
    <button (click)="closeDialog('close')" mat-icon-button class="close-button">
      <mat-icon class="close-icon" style="color: white">close</mat-icon>
    </button>
    <h3 mat-dialog-title>Terms & Conditions</h3>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="readmin-form custom_form">
          <div>
            <div [innerHtml]="content"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div mat-dialog-actions>
          <div>
              <button style=" margin-right: 15px;" class="cancel-btn" mat-raised-button
                  (click)="closeDialog('close')" cdkFocusInitial>
                  Exit
              </button>
          </div>
          <button mat-raised-button color="primary"  (click)="closeDialog('ok')">Continue</button>
      </div> -->
  </div>
</div>
