import { Routes } from "@angular/router";
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SubDomainGuard } from "./subDomain.guard";
var ɵ0 = function () {
    return import("./platform/platform.module.ngfactory").then(function (m) { return m.PlatformModuleNgFactory; });
}, ɵ1 = function () {
    return import("./super-admin-portal/super-admin-portal.module.ngfactory").then(function (m) { return m.SuperAdminPortalModuleNgFactory; });
};
var routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/organization-select"
    },
    // const routes: Routes = [
    //   { path: '', component: HomeComponent },
    //   { path: '**', component: NotFoundComponent },
    // ];
    {
        path: "organization-select",
        component: LandingPageComponent
    },
    {
        path: ":type/web",
        canActivate: [SubDomainGuard],
        loadChildren: ɵ0
    },
    {
        path: "webadmin",
        loadChildren: ɵ1
    },
    // otherwise redirect to home
    { path: "**", redirectTo: "/" }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
