export class OpenReferralModel{    
    id: number;
    patientId: number;
    staffId:number;
    referredBy:string;
    referredDate:Date;
    pathUrl:string;
    referredToId:string;
    base64: string;
}

