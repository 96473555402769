import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { SignaturePad } from "angular2-signaturepad/signature-pad";

@Component({
  selector: "app-signature-dialog",
  templateUrl: "./signature-dialog.component.html",
  styleUrls: ["./signature-dialog.component.css"],
})
export class SignatureDialogComponent implements OnInit {
  signForm: FormGroup;
  SignatoryLists: Array<any>;
  StaffLists: Array<any>;
  clientDetails: any;
  signDataUrl: string = "";
  submitted: boolean;
  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;

  signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    dotSize: parseFloat("0.1"),
    minWidth: 5,
    canvasWidth: 600,
    canvasHeight: 300,
  };
  showError: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public dialogPopup: MatDialogRef<SignatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.signDataUrl = null;
    this.submitted = false;
    this.SignatoryLists = data.SignatoryLists;
    this.StaffLists = data.staffsList || [];
    this.clientDetails = data.clientDetails || null;
  }

  ngOnInit() {
    // this.signForm = this.formBuilder.group({
    //   Signatory: [""],
    //   StaffID: [""],
    //   Client: [this.clientDetails && this.clientDetails.value],
    //   Guardian: [""],
    // });
  }

  // get formControls() {
  //   return this.signForm.controls;
  // }

  onSubmit() {
    this.submitted = true;
    this.showError = false;
    if (this.signDataUrl == "" || this.signDataUrl == null) {
      this.showError = true;
      this.submitted = false;
      return null;
    }
    const result = {
      bytes: this.signDataUrl,
    };
    this.dialogPopup.close(result);
  }

  onClose() {
    this.dialogPopup.close();
  }

  onClear() {
    this.signaturePad.clear();
    this.signDataUrl = null;
  }

  // sign pad
  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
    this.signDataUrl = this.signaturePad.toDataURL();
  }
}
