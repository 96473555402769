<div class="custom_modal modal-lg custom-modal-design">
  <h3 mat-dialog-title>INCOMING CALL NOTIFICATION</h3>
  <div class="buttons" mat-dialog-content>
    <h5>Video Call from {{ InitiatorName }}</h5>
    <!-- <h6>Caller: {{ InitiatorName }}</h6>
    <h6>Patient's Name: {{ patientname }}</h6> -->
    <button
      mat-raised-button
      color="primary"
      class="accept"
      (click)="acceptcall()"
    >
      Accept
    </button>
    <button
      mat-raised-button
      class="decline cancel-btn mat-raised-button mat-button-base"
      (click)="declinecall()"
    >
      Decline
    </button>
  </div>
</div>
