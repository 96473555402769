import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services';
var TermsAndConditionsComponent = /** @class */ (function () {
    function TermsAndConditionsComponent(
    // private authService:AuthenticationService,
    commonService, dialogModalRef) {
        this.commonService = commonService;
        this.dialogModalRef = dialogModalRef;
        this.getTermsAndConditionUrl = "/TermsAndConditions/GetTermsAndCondition";
    }
    TermsAndConditionsComponent.prototype.ngOnInit = function () {
        this.getTermsAndConditionAnynomusly();
    };
    TermsAndConditionsComponent.prototype.getTermsAndConditionAnynomusly = function () {
        var _this = this;
        this.commonService
            .getAll(this.getTermsAndConditionUrl, {})
            .subscribe(function (response) {
            if (response && response.statusCode == 200) {
                _this.content = response.data.content;
            }
        });
    };
    TermsAndConditionsComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    return TermsAndConditionsComponent;
}());
export { TermsAndConditionsComponent };
