import { OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { PasswordPolicyModel } from "src/app/super-admin-portal/add-password-policy/password-policy.model";
import { CommonService } from "src/app/super-admin-portal/core/services";
import { SuperAdminPasswordChangeService } from "./superadmin-change-password.service";
var SuperAdminPasswordChangeComponent = /** @class */ (function () {
    function SuperAdminPasswordChangeComponent(notifier, dialogModalRef, commonService, formBuilder, changePasswordService) {
        this.notifier = notifier;
        this.dialogModalRef = dialogModalRef;
        this.commonService = commonService;
        this.formBuilder = formBuilder;
        this.changePasswordService = changePasswordService;
        this.submitted = false;
    }
    SuperAdminPasswordChangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.changePasswordForm = this.formBuilder.group({
            currentPassword: [],
            newPassword: [],
            confirmNewPassword: [],
        }, { validator: this.validateForm.bind(this) });
        this.subscription = this.commonService.loginUser.subscribe(function (user) {
            if (user.data) {
                _this.userId = user.data.userID;
            }
        });
        this.getPasswordPolicy();
    };
    Object.defineProperty(SuperAdminPasswordChangeComponent.prototype, "formControls", {
        get: function () {
            return this.changePasswordForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    SuperAdminPasswordChangeComponent.prototype.validateForm = function (formGroup) {
        var pass = formGroup.controls.newPassword.value;
        var confirmPass = formGroup.controls.confirmNewPassword.value;
        if (confirmPass == undefined || !confirmPass.length)
            return null;
        return pass && confirmPass && pass === confirmPass
            ? null
            : formGroup.controls.confirmNewPassword.setErrors({ notSame: true });
    };
    SuperAdminPasswordChangeComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.changePasswordForm.invalid) {
            var data = this.changePasswordForm.value;
            data.userID = this.userId;
            this.submitted = true;
            this.changePasswordService
                .updateNewPassword(data)
                .subscribe(function (response) {
                _this.submitted = false;
                if (response.statusCode == 200) {
                    _this.notifier.notify("success", response.message);
                    _this.closeDialog("save");
                }
                else
                    _this.notifier.notify("error", response.message);
            });
        }
    };
    SuperAdminPasswordChangeComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    SuperAdminPasswordChangeComponent.prototype.getPasswordPolicy = function () {
        var _this = this;
        this.changePasswordService
            .getPasswordPolicyByToken()
            .subscribe(function (response) {
            _this.submitted = false;
            if (response.statusCode == 200) {
                _this.passwordModel = new PasswordPolicyModel();
                _this.passwordModel = response.data;
                var regex = _this.passwordModel.regularExpression;
                _this.strongPassword = new RegExp(regex);
                console.log("Generated Regx", _this.strongPassword);
                _this.errorMessage = "Password must contain";
                if (_this.passwordModel.isNumber) {
                    _this.errorMessage = _this.errorMessage + " number,";
                }
                if (_this.passwordModel.isLowerCase) {
                    _this.errorMessage = _this.errorMessage + " lower case,";
                }
                if (_this.passwordModel.isUpperCase) {
                    _this.errorMessage = _this.errorMessage + " upper case,";
                }
                if (_this.passwordModel.isSpecialCharacters) {
                    _this.errorMessage =
                        _this.errorMessage + " special character (! @ # $ % ^ & *),";
                }
                if (_this.passwordModel.minimumLength > 0) {
                    _this.errorMessage =
                        _this.errorMessage +
                            " minimum length " +
                            _this.passwordModel.minimumLength +
                            ",";
                }
                if (_this.passwordModel.maximumLength > 0) {
                    _this.errorMessage =
                        _this.errorMessage +
                            " maximum length " +
                            _this.passwordModel.maximumLength +
                            ".";
                }
            }
        });
    };
    return SuperAdminPasswordChangeComponent;
}());
export { SuperAdminPasswordChangeComponent };
