import { OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
var SignatureDialogComponent = /** @class */ (function () {
    function SignatureDialogComponent(formBuilder, dialogPopup, data) {
        this.formBuilder = formBuilder;
        this.dialogPopup = dialogPopup;
        this.data = data;
        this.signDataUrl = "";
        this.signaturePadOptions = {
            // passed through to szimek/signature_pad constructor
            dotSize: parseFloat("0.1"),
            minWidth: 5,
            canvasWidth: 600,
            canvasHeight: 300,
        };
        this.signDataUrl = null;
        this.submitted = false;
        this.SignatoryLists = data.SignatoryLists;
        this.StaffLists = data.staffsList || [];
        this.clientDetails = data.clientDetails || null;
    }
    SignatureDialogComponent.prototype.ngOnInit = function () {
        // this.signForm = this.formBuilder.group({
        //   Signatory: [""],
        //   StaffID: [""],
        //   Client: [this.clientDetails && this.clientDetails.value],
        //   Guardian: [""],
        // });
    };
    // get formControls() {
    //   return this.signForm.controls;
    // }
    SignatureDialogComponent.prototype.onSubmit = function () {
        this.submitted = true;
        this.showError = false;
        if (this.signDataUrl == "" || this.signDataUrl == null) {
            this.showError = true;
            this.submitted = false;
            return null;
        }
        var result = {
            bytes: this.signDataUrl,
        };
        this.dialogPopup.close(result);
    };
    SignatureDialogComponent.prototype.onClose = function () {
        this.dialogPopup.close();
    };
    SignatureDialogComponent.prototype.onClear = function () {
        this.signaturePad.clear();
        this.signDataUrl = null;
    };
    // sign pad
    SignatureDialogComponent.prototype.ngAfterViewInit = function () {
        // this.signaturePad is now available
        this.signaturePad.set("minWidth", 5); // set szimek/signature_pad options at runtime
        this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    };
    SignatureDialogComponent.prototype.drawComplete = function () {
        // will be notified of szimek/signature_pad's onEnd event
        // console.log(this.signaturePad.toDataURL());
        this.signDataUrl = this.signaturePad.toDataURL();
    };
    return SignatureDialogComponent;
}());
export { SignatureDialogComponent };
