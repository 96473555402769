import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-session-timeout",
  templateUrl: "./session-timeout.component.html",
  styleUrls: ["./session-timeout.component.css"],
})
export class SessionTimeoutComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogModalRef: MatDialogRef<SessionTimeoutComponent>
  ) {
    console.log("SessionTimeOut : ", data);
  }

  ngOnInit() {}

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
}
