<div class="custom_modal">
    
    <h3 mat-dialog-title>Terms & Conditions</h3>
    <div mat-dialog-content>
        
        <div class="row">
            <div class="col-sm-12">
                <div class="readmin-form custom_form">
                    <h2 style="font-size: 16px;">By continuing you agree to the terms and conditions of <span class="font-blue" (click)="createReminderModel()">confidentiality and information access agreement</span> </h2>
                    
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <div>
                <button style=" margin-right: 15px;" class="cancel-btn" mat-raised-button
                    (click)="closeDialog('close')" cdkFocusInitial>
                    Exit
                </button>
            </div>
            <button mat-raised-button color="primary"  (click)="closeDialog('ok')">Continue</button>
        </div>
    
    </div>
    
</div>