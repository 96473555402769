import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/platform/modules/core/services';
import { ClientsService } from 'src/app/platform/modules/agency-portal/clients/clients.service';
import { OpenDocumentModel } from './open-document.model';
var OpenDocumentComponent = /** @class */ (function () {
    function OpenDocumentComponent(dialogRef, notifier, formBuilder, activatedRoute, commonService, clientService, router, notifierService, data, clientsService) {
        this.dialogRef = dialogRef;
        this.notifier = notifier;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.commonService = commonService;
        this.clientService = clientService;
        this.router = router;
        this.notifierService = notifierService;
        this.data = data;
        this.clientsService = clientsService;
        this.submitted = false;
        this.masterDocumentTypes = [];
        this.save = 'save';
        this.close = 'close';
        this.openDocumentModel = new OpenDocumentModel();
        this.openDocumentModel = data;
        this.patientId = data.patientId;
        this.staffId = data.staffId;
        this.isCarePlan = data.isCarePlan;
    }
    OpenDocumentComponent.prototype.ngOnInit = function () {
        var userInfo = this.commonService.getLoginUserInfo();
        // console.log(userInfo);   
        this.docFaxForm = this.formBuilder.group({
            id: [this.openDocumentModel.id],
            documentTypeId: [this.openDocumentModel.documentTypeId],
            expirationDate: [this.openDocumentModel.expirationDate],
            title: [this.openDocumentModel.title]
        });
        this.getMasterData();
    };
    Object.defineProperty(OpenDocumentComponent.prototype, "formControls", {
        //get the form controls on html page
        get: function () { return this.docFaxForm.controls; },
        enumerable: true,
        configurable: true
    });
    OpenDocumentComponent.prototype.closeDialog = function (action) {
        this.dialogRef.close(action);
    };
    OpenDocumentComponent.prototype.onSubmit = function (event) {
        var _this = this;
        if (!this.docFaxForm.invalid) {
            var clickType_1 = event.currentTarget.name;
            this.submitted = true;
            this.openDocumentModel = this.docFaxForm.value;
            this.openDocumentModel.patientId = this.patientId;
            this.openDocumentModel.staffId = this.staffId;
            this.openDocumentModel.base64 = this.commonService.getFaxFileObj(this.data.faxFileBase64);
            this.clientService.createDocumentResult(this.openDocumentModel).subscribe(function (response) {
                _this.submitted = false;
                if (response.statusCode == 200) {
                    _this.notifier.notify('success', response.message);
                    if (clickType_1 == "Save")
                        _this.closeDialog('save');
                }
                else {
                    _this.notifier.notify('error', response.message);
                }
            });
        }
    };
    OpenDocumentComponent.prototype.getMasterData = function () {
        var _this = this;
        this.clientService.getMasterData("MASTERDOCUMENTTYPESSTAFF").subscribe(function (response) {
            if (response != null)
                _this.masterDocumentTypes = response.masterDocumentTypesStaff = response.masterDocumentTypesStaff != null ? response.masterDocumentTypesStaff : [];
            if (_this.isCarePlan) {
                _this.masterDocumentTypes = _this.masterDocumentTypes.filter(function (s) { return s.type == 'Care Plan'; });
                _this.docFaxForm.controls.documentTypeId.setValue(14);
            }
            else {
                _this.masterDocumentTypes = _this.masterDocumentTypes.filter(function (s) { return s.type != 'Care Plan'; });
            }
        });
    };
    return OpenDocumentComponent;
}());
export { OpenDocumentComponent };
