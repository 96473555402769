export class OpenLabModel {
    id: number;
    patientId: number;
    staffId: number;
    typeOfTest: string;
    orderDate: Date;
    labName: string;
    physicianName: string;
    pathUrl: string;
    base64: string;
}

