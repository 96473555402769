import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonService } from "src/app/platform/modules/core/services";
import { ResponseModel } from "src/app/super-admin-portal/core/modals/common-model";
import { NotifierService } from "angular-notifier";
import { TaskNotification } from "../../models";
import { ClientsService } from "src/app/platform/modules/agency-portal/clients/clients.service";

@Component({
  selector: "app-task-accept",
  templateUrl: "./task-accept.component.html",
  styleUrls: ["./task-accept.component.css"]
})
export class TaskAcceptComponent implements OnInit {
  taskDetails: TaskNotification;
  eventId: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    private commonService: CommonService,
    private dialogModalRef: MatDialogRef<TaskAcceptComponent>,
    private notifier: NotifierService,
    private clientService: ClientsService
  ) {
    this.eventId = data;  
    this.taskDetails = new TaskNotification();
  }
  ngOnInit() {
    this.getTaskDetailsById(this.eventId);  
  }

  getTaskDetailsById(taskId: number) {  
    this.clientService.getTaskDetailsById(taskId)
      .subscribe((response: ResponseModel) => {
        if (
          response &&
          response.data != undefined &&
          response.statusCode == 200
        ) {
          this.taskDetails = response.data.taskNotification[0];
        }
      });
  }

  updateTask(key) {
    let statusId = 0;
    if (key === "ACCEPT") {
      statusId = 3;
    } else if (key === "DECLINE") {
      statusId = 6;
    }

    this.clientService.updateTask(this.eventId,statusId)
      .subscribe((response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          this.closeDialog("close");
          if ((response.data.taskStatusId = 3)) {
            this.notifier.notify("success", "Task Accepted");
          } else if ((response.data.taskStatusId = 6)) {
            this.notifier.notify("warning", "Task Declined");
          }
        }
      });
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  } 
}
