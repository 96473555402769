export class PasswordPolicyModel{
    id:number;
    maximumLength:number;
    minimumLength:number;
    isSpecialCharacters:boolean;
    isLowerCase:boolean;
    isUpperCase:boolean;
    isNumber:boolean;
    regularExpression:string;
    passwordPolicyName:string;
}