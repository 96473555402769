import { OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { CommonService } from "../../core/services";
import { AddSubsciptionComponent } from "../../agency-portal/masters/subscription-payment-details/add-subscriptiondetails/add-subscriptionpayment.component";
import { SubscriptionPaymentModel } from "../../agency-portal/masters/subscription-payment-details/subscriptionpayment.model";
import * as format from "date-fns/format";
var SubscriptionUserExpireationComponent = /** @class */ (function () {
    function SubscriptionUserExpireationComponent(
    // private authService:AuthenticationService,
    commonService, unitmasterDialog, dialogModalRef) {
        this.commonService = commonService;
        this.unitmasterDialog = unitmasterDialog;
        this.dialogModalRef = dialogModalRef;
        this.getSubscriptionExpireUrl = "/TermsAndConditions/GetSubscriptionExpirationDetails";
    }
    SubscriptionUserExpireationComponent.prototype.ngOnInit = function () {
        this.getSubscriptionExpireUrlAnynomusly();
    };
    SubscriptionUserExpireationComponent.prototype.createModel = function (unitmastersModel) {
        this.closeDialog("tempclose");
        var modalPopup = this.unitmasterDialog.open(AddSubsciptionComponent, {
            hasBackdrop: true,
            data: {
                unitmastersModel: unitmastersModel,
            },
        });
        modalPopup.afterClosed().subscribe(function (result) {
            if (result === "save") {
                // this.getAllMasterTaxList(this.filterModel);
            }
        });
    };
    SubscriptionUserExpireationComponent.prototype.openDialog = function (id) {
        // if (id != null && id > 0) {
        //   this.pharmacyService.getMasterTaxById(id).subscribe((response: any) => {
        //     if (response != null && response.data != null) {
        //       this.unitMasterModel = response.data;
        //       this.createModel(this.unitMasterModel);
        //     }
        //   });
        // } else {
        this.unitMasterModel = new SubscriptionPaymentModel();
        this.createModel(this.unitMasterModel);
    };
    SubscriptionUserExpireationComponent.prototype.getSubscriptionExpireUrlAnynomusly = function () {
        var _this = this;
        this.commonService
            .getAll(this.getSubscriptionExpireUrl, {})
            .subscribe(function (response) {
            if (response && response.statusCode == 200) {
                _this.planName = response.data.planName;
                _this.startDate = format(response.data.startDate, "MM/DD/YYYY");
                _this.endDate = format(response.data.endDate, "MM/DD/YYYY");
                _this.price = response.data.price;
                _this.currency = response.data.iso;
                var date = new Date(_this.endDate);
                var currentDate = new Date();
                if (currentDate <= date) {
                    var days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
                    if (days <= 7 && days >= 0) {
                        _this.note =
                            "You have " +
                                days +
                                " more days left.Please renew before your subscription expire's ";
                    }
                }
            }
        });
        this.userInfo = this.commonService.getLoginUserInfo();
        if (this.userInfo) {
            this.organizationName = this.userInfo.data.organization.organizationName;
            this.orgAddress =
                (this.userInfo.data.organization.apartmentNumber || "") +
                    " " +
                    (this.userInfo.data.organization.address1 || "") +
                    ", " +
                    (this.userInfo.data.organization.city || "") +
                    ", " +
                    (this.userInfo.data.organization.stateName || "") +
                    ", " +
                    (this.userInfo.data.organization.zip || "");
            this.phone = this.userInfo.data.organization.phone;
            this.fax = this.userInfo.data.organization.fax;
            this.email = this.userInfo.data.organization.email;
        }
    };
    SubscriptionUserExpireationComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    return SubscriptionUserExpireationComponent;
}());
export { SubscriptionUserExpireationComponent };
