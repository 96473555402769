import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, ReplaySubject, Observable, Subject, throwError } from "rxjs";
import { catchError, distinctUntilChanged, map } from "rxjs/operators";
import { LoginUser } from "../modals/loginUser.modal";
import { environment } from "../../../../../environments/environment";
import * as CryptoJS from "crypto-js";
import base64 from "base-64";
import utf8 from "utf8";

@Injectable()
export class CommonService {
  private SytemInfoSubject = new BehaviorSubject<any>({});
  public sytemInfo = this.SytemInfoSubject.asObservable().pipe(
    distinctUntilChanged()
  );

  private documentUrl: string = "";

  public getHeaderNotificationSubject$ = new Subject();

  public loginUserSubject = new BehaviorSubject<LoginUser>({} as LoginUser);
  public loginUser = this.loginUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public currentLoginUserInfoSubject = new BehaviorSubject<any>(null);
  public currentLoginUserInfo = this.currentLoginUserInfoSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public loginSecuritySubject = new BehaviorSubject<any>({} as object);
  public loginSecurity = this.loginSecuritySubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  public loadingStateSubject = new Subject<boolean>();
  public loadingState = this.loadingStateSubject.asObservable();

  // for update the client side navigations ...
  private updateClientNavigationSubject = new BehaviorSubject<any>({} as any);
  public updateClientNavigation = this.updateClientNavigationSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public updateClientHeader = new Subject<boolean>();
  public _updateClientHeaderObs = this.updateClientHeader.asObservable();

  constructor(private http: HttpClient) {
    SystemIpAddress.then((value) => {
      this.SytemInfoSubject.next({ ipAddress: value });
    }).catch((e) => console.error(e));
    // this.http
    //   .get("https://api.ipify.org/?format=json")
    //   .subscribe((res: any) => {
    //     this.SytemInfoSubject.next({ ipAddress: res.ip });
    //   });
  }

  getOrganizationTypeName() {
    if (localStorage.getItem("business_token_object")) {
      var tokenObject = JSON.parse(
        localStorage.getItem("business_token_object")
      );
      // console.log('organizationTypeName',tokenObject.organization.organizationTypeName)
      return tokenObject.organization.organizationTypeName;
    }
  }
  getOrganizationTypeTabName() {
    if (localStorage.getItem("business_token_object")) {
      var tokenObject = JSON.parse(
        localStorage.getItem("business_token_object")
      );
      // console.log(
      //   "organizationTypeTabName",
      //   tokenObject.organization.organizationTypeTabName
      // );
      return tokenObject.organization.organizationTypeTabName;
    }
  }
  getOrganizationName() {
    if (localStorage.getItem("business_token_object")) {
      var tokenObject = JSON.parse(
        localStorage.getItem("business_token_object")
      );
      // console.log(
      //   "organizationName",
      //   tokenObject.organization.organizationName
      // );
      return tokenObject.organization.organizationName;
    }
  }

  public getClientIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json", {
      headers: { skip: "true" },
    });
  }

  // Allow only alphabet
  allowAlphabetOnly(res) {
    const regexp = new RegExp('^[0-9!@#$&()\\-`.+,/"]*$');
    const result = regexp.test(res.key);
    if (result) {
      res.preventDefault();
      return false;
    }
  }

  /** Allow only Number */
  allowOnlyNumber(res) {
    const regexp = new RegExp("^([0-9])$");
    const result = regexp.test(res.key);
    if (!result) {
      res.preventDefault();
      return false;
    }
  }

  public getDateTimeString = (
    date: string | Date | null | undefined
  ): string => {
    if (!date) return "";

    const dt = new Date(date);
    const year = this.padZero(dt.getFullYear(), 4);
    const month = this.padZero(dt.getMonth() + 1, 2);
    const day = this.padZero(dt.getDate(), 2);
    const hours = this.padZero(dt.getHours(), 2);
    const minutes = this.padZero(dt.getMinutes(), 2);
    const seconds = this.padZero(dt.getSeconds(), 2);

    return `${year}-${month}-${day}T${"00"}:${"00"}:${"00"}.000`;
  };

  padZero = (value: number, width: number): string => {
    const val = "" + value;

    if (width > val.length) return val.padStart(width, "0");

    return val;
  };

  /* Allow only Number with decimal point */
  allowOnlyNumberWithDecimal(res) {
    const regexp = new RegExp(/^\d*\.?\d*$/);
    const result = regexp.test(res.key);
    if (!result) {
      res.preventDefault();
      return false;
    }
  }

  initializeAuthData() {
    if (localStorage.getItem("access_token")) {
      return this.http
        .get<any>(`${environment.api_url}/GetUserByToken`)
        .subscribe((response) => {
          // login successful if there's a jwt token in the response
          if (response && response.access_token) {
            this.setAuth(response);
          } else {
            //console.log('c');
            this.purgeAuth();
          }
          return response;
        });
    }
  }

  isValidFileType(fileName, fileType): boolean {
    // Create an object for all extension lists
    let extentionLists = { video: [], image: [], pdf: [], excel: [], xml: [] };
    let isValidType = false;
    extentionLists.video = ["m4v", "avi", "mpg", "mp4"];
    extentionLists.image = ["jpg", "jpeg", "bmp", "png", "ico"];
    extentionLists.pdf = ["pdf"];
    extentionLists.excel = ["excel"];
    extentionLists.xml = ["xml"];
    //get the extension of the selected file.
    let fileExtension = fileName.split(".").pop().toLowerCase();
    isValidType = extentionLists[fileType].indexOf(fileExtension) > -1;
    return isValidType;
  }
  logout() {
    //console.log('c1');
    // remove user from local storage to log user out
    this.purgeAuth();
  }

  setAuth(user: LoginUser) {
    localStorage.setItem("access_token", JSON.stringify(user.access_token));
    // Set current user data into observable
    this.loginUserSubject.next(user);

    if (user.data) {
      this.currentLoginUserInfoSubject.next({
        ...user.data,
        currentLocationId:
          user.data.staffLocation &&
          user.data.staffLocation[0] &&
          user.data.staffLocation[0].locationID,
        userLocations: user.userLocations || [],
      });
    }
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    localStorage.removeItem("access_token");
    // Set current user to an empty object
    this.loginUserSubject.next({} as LoginUser);

    this.currentLoginUserInfoSubject.next(null);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  setSecurityQuestions(securityQuestionObj: object) {
    this.loginSecuritySubject.next(securityQuestionObj);
  }

  getLoginUserInfo(): any {
    return this.loginUserSubject.value;
  }

  getCurrentLoginLocationId(): string {
    let locationId: string = "";
    const loginData: any = this.currentLoginUserInfoSubject.value;
    if (loginData) {
      locationId = loginData.currentLocationId || 0;
    }
    return locationId.toString();
  }

  getSystemIpAddress(): string {
    return this.SytemInfoSubject.value && this.SytemInfoSubject.value.ipAddress;
  }

  updateCurrentLoginUserInfo(locationId: number) {
    const loginData: any = this.currentLoginUserInfoSubject.value;
    if (loginData) {
      const newUserObj = {
        ...loginData,
        currentLocationId: locationId,
      };
      this.currentLoginUserInfoSubject.next(newUserObj);
    }
  }

  updateClientNaviagations(clientId: number, userId: number = null) {
    this.updateClientNavigationSubject.next({ clientId, userId });
  }

  get getAdditionalHeaders(): string {
    const additionalHeaders = JSON.stringify({
      Offset: new Date().getTimezoneOffset().toString(),
      Timezone: calculateTimeZone(),
      IPAddress: this.getSystemIpAddress(),
      LocationID: this.getCurrentLoginLocationId(),
    });

    // console.log("Additional headers", additionalHeaders);
    return additionalHeaders;
  }

  post(url, data): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    return this.http.post<any>(`${environment.api_url}/${url}`, data, {
      headers: headers,
    });
  }

  postwithLoader(url, data, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });

    isLoading && this.loadingStateSubject.next(true);

    return this.http.post<any>(`${environment.api_url}/${url}`, data, {
      headers: headers,
    }).pipe(
      map((res) => {
        isLoading && this.loadingStateSubject.next(false);  // Stop loading state
        return res;
      }),
      catchError((error) => {
        isLoading && this.loadingStateSubject.next(false);  // Stop loading on error
        return throwError(error);  // Handle error
      }));
  }

  put(url, data): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    return this.http.put<any>(`${environment.api_url}/${url}`, data, {
      headers: headers,
    });
  }

  getById(url, data, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .get<any>(`${environment.api_url}/${url}`, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }

  getAll(url, data, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .get<any>(`${environment.api_url}/${url}`, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }
  getAllWithputLoader(url, data, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    // isLoading && this.loadingStateSubject.next(true);
    return this.http
      .get<any>(`${environment.api_url}/${url}`, { headers: headers })
      .pipe(
        map((res) => {
          // isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }
  getdatata(url, data, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .post<any>(`${environment.api_url}/${url}`, data, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }
  postLoader(url, data, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .post<any>(`${environment.api_url}/${url}`, data, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }
  delete(url, data): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    return this.http.delete<any>(`${environment.api_url}/${url}`, {
      headers: headers,
    });
  }
  patch(url, data, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .patch<any>(`${environment.api_url}/${url}`, data, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }

  download(url, headers): Observable<Blob> {
    const ccdaheaders = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    return this.http.get(`${environment.api_url}/${url}`, {
      headers: ccdaheaders,
      responseType: "blob",
    });
  }
  calculateFileSizehandler(files): boolean {
    let totalFileSize = 0;
    let fileSizeInKB;
    // @desc - define const max upload file size 5MB
    let totalFileSizeInMB;
    const fileSize5MB = "5.00";
    files.map((obj, i) => {
      totalFileSize = totalFileSize + obj.size;
      totalFileSizeInMB = (totalFileSize / 1024 / 1024).toFixed(2);
      fileSizeInKB = Math.round(obj.size / 1024); // converted bytes(incoming file size) to KB.
      // 1024kb = 1MB
      let fileSizeInMB = Math.round(obj.size / 1024 / 1024);
      let fileSizeInMBFixTo2Decimal = fileSizeInMB.toFixed(2);
      return fileSizeInKB >= 1024
        ? `${fileSizeInMBFixTo2Decimal}MB`
        : `${fileSizeInKB}KB`;
    });

    if (
      parseFloat(fileSizeInKB) === 0.0 ||
      parseFloat(totalFileSizeInMB) > parseFloat(fileSize5MB)
    ) {
      return false;
    } else {
      return true;
    }
  }

  encryptValue(value: any, isEncrypt: boolean = true): any {
    let response: any;
    if (value != null && value != "") {
      let pwd = "HCPRODUCT#!_2018@";
      let bytes: any;
      if (isEncrypt) {
        bytes = utf8.encode(value.toString());
        response = base64.encode(bytes);
      } else {
        bytes = base64.decode(value);
        response = utf8.decode(bytes);
      }
    }
    return response;
  }

  getUserScreenActionPermissions(moduleName: string, screenName: string): any {
    const returnObj = {};
    const loginData: LoginUser = this.loginUserSubject.value;
    if (loginData.userPermission) {
      const modules = loginData.userPermission.modulePermissions || [],
        screens = loginData.userPermission.screenPermissions || [],
        actions = loginData.userPermission.actionPermissions || [],
        userRoleName =
          loginData.data.users3 && loginData.data.users3.userRoles.userType,
        isAdminLogin = (userRoleName || "").toUpperCase() === "ADMIN";

      const moduleobj = modules.find((obj) => obj.moduleKey == moduleName);
      const screenObj = screens.find(
        (obj) =>
          obj.screenKey == screenName &&
          obj.moduleId == (moduleobj && moduleobj.moduleId)
      );

      const actionPermissions =
        actions.filter(
          (obj) => obj.screenId == (screenObj && screenObj.screenId)
        ) || [];

      actionPermissions.forEach((obj) => {
        returnObj[obj.actionKey] = obj.permission || isAdminLogin;
      });
    }
    return returnObj;
  }

  getBusinessUrl(): string {
    let domainName = "";
    if (localStorage.getItem("business-url")) {
      domainName = localStorage.getItem("business-url");
    }
    return domainName;
  }

  getBusinessType(): string {
    let type = "";
    if (localStorage.getItem("business-type")) {
      type = localStorage.getItem("business-type");
    }
    return type;
  }

  isRoutePermission(routeName: string): boolean {
    let businessName = localStorage.getItem("business-url");
    let changedRouteName = routeName.replace("/" + businessName + "/web", "");
    if (changedRouteName.length) {
      const index = changedRouteName.indexOf("?");
      changedRouteName = changedRouteName.substring(
        0,
        index != -1 ? index : changedRouteName.length
      );
    }
    let permission = false;
    const loginData: LoginUser = this.loginUserSubject.value,
      userRoleName =
        loginData.data &&
        loginData.data.users3 &&
        loginData.data.users3.userRoles.userType;
    if (
      loginData.userPermission &&
      ((userRoleName || "").toUpperCase() == "STAFF" ||
        (userRoleName || "").toUpperCase() == "ADMIN")
    ) {
      const modules = loginData.userPermission.modulePermissions || [],
        screens = loginData.userPermission.screenPermissions || [],
        isAdminLogin = (userRoleName || "").toUpperCase() == "ADMIN";

      const moduleobj = modules.find(
        (obj) => obj.navigationLink == changedRouteName
      );
      var templateFormModuleObj = modules.filter(item => item.moduleKey == "ORGANIZATION_TEMPLATE_FORMS");
      if (moduleobj) {
        permission = moduleobj.permission || isAdminLogin;
      } else if ((templateFormModuleObj.length > 0 && templateFormModuleObj[0].permission == true) || isAdminLogin) {
        permission = true;
      } else {
        // routing changes due to some conditions ....
        if (changedRouteName.includes("/Masters"))
          changedRouteName = changedRouteName.replace("/Masters", "");
        if (changedRouteName.includes("/Manage-Users"))
          changedRouteName = changedRouteName.replace("/Manage-Users", "");
        if (changedRouteName.includes("/Billing"))
          changedRouteName = changedRouteName.replace("/Billing", "");
        if (changedRouteName.includes("/Logs"))
          changedRouteName = changedRouteName.replace("/Logs", "");

        const screenObj = screens.find(
          (obj) => obj.navigationLink == changedRouteName
        );
        permission = (screenObj && screenObj.permission) || isAdminLogin;
      }

      if (changedRouteName == "/user" && !permission) {
        const encryptId = decodeURIComponent(routeName).replace(
          "/web/Manage-Users/user?id=",
          ""
        ),
          decUserId =
            encryptId &&
            !encryptId.includes("/web/Manage-Users/user") &&
            this.encryptValue(encryptId, false);
        if (decUserId == loginData.data.id) permission = true;
      }
    }
    return permission;
  }

  isRoutePermissionForClient(routeName: string): boolean {
    routeName = routeName.replace("/web", "");
    if (routeName.length) {
      const index = routeName.indexOf("?");
      routeName = routeName.substring(
        0,
        index != -1 ? index : routeName.length
      );
    }
    let permission = false;
    let businessName = this.getBusinessUrl();
    const loginData: LoginUser = this.loginUserSubject.value,
      userRoleName =
        loginData.data &&
        loginData.data.users3 &&
        loginData.data.users3.userRoles.userType;
    if (
      loginData.userPermission &&
      (userRoleName || "").toUpperCase() == "CLIENT"
    ) {
      const screens = [
        {
          navigationLink: "/" + businessName + "/client/dashboard",
          permission: true,
        },
        // { navigationLink: '/client/my-scheduling', permission: true },
        {
          navigationLink: "/" + businessName + "/client/my-profile",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/patient-profile",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/encounter/video-call",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/mailbox",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/assigned-documents",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/billing",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/health-record",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/care-provider",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/care-summary",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/treatment-timeline",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/virtual-consult",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/purchase-order",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/activity-schedules",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/food-dairy",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/presonal-inventory",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/reports",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/service-feedback",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/local-resource",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/patient-documents",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/consent-prefernces",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/labs",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/cart",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/payment-details",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/home-monitoring",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/health",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/allergy",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/diagnosi",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/medications",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/care-plan-goals",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/cares-plans",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/my-appointment",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/virtual-consults",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/nutrition-Calendar",
          permission: true,
        },
        {
          navigationLink: "/" + businessName + "/client/my-symptom-tracker",
          permission: true,
        },

        //{ navigationLink: '/client/unit-activity-calender', permission: true }
      ];

      const screenObj = screens.find((obj) => obj.navigationLink == routeName);
      permission = screenObj && screenObj.permission;
    }
    return permission;
  }
  getFaxFileObj(faxFileBase64) {
    let dic = [];
    dic.push(
      `"${faxFileBase64}": ".pdf"`
    );
    let newObj = dic.reduce((acc, cur, index) => {
      acc[index] = cur;
      return acc;
    }, {});
    return newObj;
  }
}

const SystemIpAddress = new Promise((r) => {
  const w: any = window,
    a = new (w.RTCPeerConnection ||
      w.mozRTCPeerConnection ||
      w.webkitRTCPeerConnection)({ iceServers: [] }),
    b = () => { };
  a.createDataChannel("");
  a.createOffer((c) => a.setLocalDescription(c, b, b), b);
  a.onicecandidate = (c) => {
    try {
      c.candidate.candidate
        .match(
          /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
        )
        .forEach(r);
    } catch (e) { }
  };
});

function calculateTimeZone(dateInput?: Date): string {
  var dateObject = dateInput || new Date(),
    dateString = dateObject + "",
    tzAbbr: any =
      // Works for the majority of modern browsers
      dateString.match(/\(([^\)]+)\)$/) ||
      // IE outputs date strings in a different format:
      dateString.match(/([A-Z]+) [\d]{4}$/);

  if (tzAbbr) {
    // Old Firefox uses the long timezone name (e.g., "Central
    // Daylight Time" instead of "CDT")
    tzAbbr = tzAbbr[1];
  }
  if (tzAbbr.includes("Daylight")) {
    var da = tzAbbr.split(" ");
    var index = da.indexOf("Daylight");
    if (index > -1) {
      da[index] = "Standard";
    }

    tzAbbr = da.toString().replace(/,/g, " ");
  }
  return tzAbbr;
}
