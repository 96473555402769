
<div class="custom_modal">
    <button class="close" mat-button (click)="closeDialog('close')">
        <mat-icon style="color: white;">close</mat-icon>
    </button>
    <h3 mat-dialog-title>Reason To CrossOut</h3>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <form id="reasonForm" #documentForm="ngForm" class="readmin-form custom_form" [formGroup]="reasonForm">
                    <div class="row">
                        <div class="col-sm-6">
                            <mat-form-field class="example-full-width">
                                <mat-select placeholder="Reason To Cross Out" formControlName="crossOutReasonId"
                                    matInput required>
                                    <mat-option *ngFor="let rel of masterCrossOutReasonlist" [value]="rel.id">
                                        {{ rel.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div>
            <button mat-raised-button class="cancel-btn" (click)="closeDialog('close')">Close</button>
        </div>
        &nbsp;
        <button name="Save" mat-raised-button color="primary" (click)="onSubmit(reasonForm)" form="reasonForm" class=""
            [disabled]="submitted" cdkFocusInitial>{{submitted ? 'Saving...' : 'Save'}}</button>
    </div>
</div>