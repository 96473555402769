import { OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
var SessionTimeoutComponent = /** @class */ (function () {
    function SessionTimeoutComponent(data, dialogModalRef) {
        this.data = data;
        this.dialogModalRef = dialogModalRef;
        console.log("SessionTimeOut : ", data);
    }
    SessionTimeoutComponent.prototype.ngOnInit = function () { };
    SessionTimeoutComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    return SessionTimeoutComponent;
}());
export { SessionTimeoutComponent };
