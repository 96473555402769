import { Component, Inject, OnInit } from "@angular/core";
import { ResponseModel } from "../../../../super-admin-portal/core/modals/common-model";
import { Subscription } from "rxjs";
import { CommonService } from "../../core/services";
import { LoginUser } from "../../core/modals/loginUser.modal";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { UsersService } from "../users/users.service";


@Component({
  selector: "app-clock-out",
  templateUrl: "./clock-out.component.html",
  styleUrls: ["./clock-out.component.css"],
})
export class ClockOutComponent implements OnInit {
  submitted: boolean = false;
  subscription: Subscription;
  userId: number;
  headerText: string = "";
  isClockIn: boolean = false;
  messageText: string;
  btnText: string;
  userInfo: any;
  ipAddress: string;
  isExist:boolean;
  constructor(
    private notifier: NotifierService,
    private dialogModalRef: MatDialogRef<ClockOutComponent>,
    private commonService: CommonService,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isClockIn = data.isClockIn;
    this.headerText = this.isClockIn ? "CLOCK IN" : "CLOCK OUT";
    this.btnText = this.isClockIn ? "Clocking In..." : "Clocking Out...";
    this.messageText = this.isClockIn
      ? "Are you sure you want to start your shift for the day?"
      : "Are you sure you want to end your shift for the day?";
  }

  ngOnInit() {
    this.subscription = this.commonService.loginUser.subscribe(
      (user: LoginUser) => {
        if (user.data) {
          this.userId = user.data.userID;
        }
      }
    );
    
    this.getLocationDetails();
    this.getClientIPAddress();
  }


  public getLocationDetails(){
    this.usersService.GetLocationIpAddresses().subscribe((response:any)=>{
      if(response.statusCode == 200){
        this.userInfo = response.data;
      }else{
        this.notifier.notify("warning", "Data Not Found");
      }
  });
  }


  public getClientIPAddress() {
    this.commonService.getClientIPAddress().subscribe((ip: any) => {
      this.ipAddress = ip.ip;
    });
  }

  onSubmit() {
      let apiUrl = "";
      apiUrl = this.isClockIn
        ? "/Login/ClockInStatus"
        : "/Login/UpdateClockOut";

      if(this.userInfo){
        this.isExist=this.userInfo.find((element)=>element.ipAddress==this.ipAddress);
        if(this.isExist)
        {
          this.commonService
          .post(apiUrl, {})
          .subscribe((response: ResponseModel) => {
            if (response && response.statusCode == 200) {
              this.notifier.notify("success", response.message);
              this.closeDialog("save");
              if (this.isClockIn == false) {
                this.commonService.logout();
                localStorage.removeItem("neverShowPopUp");
                location.reload();
              }
            }
          });
        }
        else{
          this.notifier.notify("warning", "You don't have access to Clock In/Clock Out.");
          this.closeDialog('close');
        }
      }else{
          this.notifier.notify("warning", "You don't have access to Clock In/Clock Out.");
          this.closeDialog('close');
      }     
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
