import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { CommonService } from "../core/services/common.service";
import { environment } from "../../../environments/environment";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, commonService) {
        this.http = http;
        this.commonService = commonService;
        this.GetPasswordPoliciesURL = "/PasswordPolicies/GetPasswordPolicies";
    }
    AuthenticationService.prototype.login = function (postData) {
        var _this = this;
        return this.http
            .post(environment.api_url + "/api/SuperAdmin/Login", postData)
            .pipe(map(function (response) {
            // login successful if there's a jwt token in the response
            if (response && response.access_token) {
                _this.commonService.setAuth(response);
            }
            return response;
        }));
    };
    AuthenticationService.prototype.refreshAuthToken = function () {
        var _this = this;
        return this.http
            .get(environment.api_url + "/GetUserByToken")
            .subscribe(function (response) {
            // login successful if there's a jwt token in the response
            if (response && response.access_token) {
                _this.commonService.setAuth(response);
            }
            else {
                _this.commonService.purgeAuth();
            }
            return response;
        });
    };
    AuthenticationService.prototype.forgotPassword = function (postData) {
        var headers = new HttpHeaders({
            businessToken: localStorage.getItem("business_token"),
        });
        return this.http
            .post(environment.api_url + "/ForgotPassword", postData, {
            headers: headers,
        })
            .pipe(map(function (response) {
            // login successful if there's a jwt token in the response
            return response;
        }));
    };
    AuthenticationService.prototype.forgotPasswordSuperAdmin = function (postData) {
        var headers = new HttpHeaders({
            businessToken: localStorage.getItem("business_token"),
        });
        return this.http
            .post(environment.api_url + "/api/SuperAdmin/ForgotPassword", postData, {
            headers: headers,
        })
            .pipe(map(function (response) {
            // login successful if there's a jwt token in the response
            return response;
        }));
    };
    AuthenticationService.prototype.validateOrganization = function (postData) {
        var headers = new HttpHeaders({
            businessToken: localStorage.getItem("business_token"),
        });
        return this.http
            .get(environment.api_url + "/ValidateOrganization?OrganizationName=" + postData, {
            headers: headers,
        })
            .pipe(map(function (response) {
            // login successful if there's a jwt token in the response
            return response;
        }));
    };
    AuthenticationService.prototype.getPasswordPolicyByToken = function () {
        return this.commonService.getById(this.GetPasswordPoliciesURL, {});
    };
    AuthenticationService.prototype.logout = function () {
        // remove user from local storage to log user out
        this.commonService.purgeAuth();
    };
    AuthenticationService.prototype.resetPassword = function (postData) {
        var headers = new HttpHeaders({
            businessToken: localStorage.getItem("business_token"),
        });
        return this.http
            .post(environment.api_url + "/ResetPassword", postData, {
            headers: headers,
        })
            .pipe(map(function (response) {
            // login successful if there's a jwt token in the response
            return response;
        }));
    };
    AuthenticationService.prototype.resetPasswordSuperAdmin = function (postData) {
        var headers = new HttpHeaders({
            businessToken: localStorage.getItem("business_token"),
        });
        return this.http
            .post(environment.api_url + "/api/SuperAdmin/ResetPassword", postData, {
            headers: headers,
        })
            .pipe(map(function (response) {
            // login successful if there's a jwt token in the response
            return response;
        }));
    };
    return AuthenticationService;
}());
export { AuthenticationService };
