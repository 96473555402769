import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { Subscription } from "rxjs";
import { SubscriptionPaymentModel } from "../subscriptionpayment.model";
import { CommonService } from "../../../../core/services";
import { ClientsService } from "../../../clients/clients.service";
import { UsersService } from "../../../users/users.service";
import { CreditCardValidators } from "angular-cc-library";
import { PharmacyService } from "../../pharmacy/pharmacy.service";
import { ResponseModel } from "src/app/platform/modules/core/modals/common-model";

@Component({
  selector: "app-add-tags",
  templateUrl: "./add-subscriptionpayment.component.html",
  styleUrls: ["./add-subscriptionpayment.component.css"],
  providers: [CommonService, ClientsService],
})
export class AddSubsciptionComponent implements OnInit {
  masterTaxModel: SubscriptionPaymentModel;
  careproviderForm: FormGroup;
  getSubscriptionExpireUrl =
    "/TermsAndConditions/GetSubscriptionExpirationDetails";
  //card: FormGroup;
  submitted: boolean = false;
  headerText: string = "Add Tax";
  statusModel: any[];
  formatting: boolean = true;
  debug: boolean = false;
  masterLocation: any = [];
  masterCountry: any;
  masterState: any[];
  userId: any;
  clientId: number;
  currentLocationId: number;
  subscription: Subscription;
  dataURL: any;
  imagePreview: any;
  email: string;
  fax: string;
  orgAddress: string;
  organizationName: string;
  phone: string;
  plantype: string;
  amountofsubscription: number;
  startDate: string;
  currency: string;

  @Output() refreshGrid: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private careproviderDialogModalRef: MatDialogRef<AddSubsciptionComponent>,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private clientService: ClientsService,
    private pharmacyService: PharmacyService,
    private userService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifier: NotifierService
  ) {
    this.masterTaxModel = new SubscriptionPaymentModel();
    this.masterTaxModel = data.unitmastersModel;
    if (this.masterTaxModel.id != null && this.masterTaxModel.id > 0)
      this.headerText = "Edit Tags";
    else this.headerText = "Add Tags";
  }

  ngOnInit() {
    this.getMasterData();
    this.subscription = this.commonService.currentLoginUserInfo.subscribe(
      (user: any) => {
        if (user) {
          this.clientId = user.id;
        }
      }
    );

    this.careproviderForm = this.formBuilder.group({
      id: [this.masterTaxModel.id],
      cardNumber: [this.masterTaxModel.cardNumber],
      expMonth: [this.masterTaxModel.expMonth],
      expYear: [this.masterTaxModel.expYear],
      cardName: [this.masterTaxModel.cardName],
      cardCVV: [this.masterTaxModel.cardCVV],
      streetAddress: [this.masterTaxModel.streetAddress],
      city: [this.masterTaxModel.city],
      state: [this.masterTaxModel.state],
      countryId: [this.masterTaxModel.countryId, Validators.required],
      zipCode: [this.masterTaxModel.zipCode],
      typeCard: [this.masterTaxModel.typeCard],
      amountofsubscription: [this.masterTaxModel.amountofsubscription],
      plantype: [this.masterTaxModel.plantype],
      iscardsavedetails: [this.masterTaxModel.iscardsavedetails],
    });
    this.getSubscriptionExpireUrlAnynomusly();
  }

  //get the form controls on html page
  get formControls() {
    return this.careproviderForm.controls;
  }
  saveAndMore = "close";

  getMasterData() {
    let data = "MASTERCOUNTRY";
    this.pharmacyService.getMasterData(data).subscribe((response: any) => {
      if (response != null) {
        this.masterCountry =
          response.masterCountry != null ? response.masterCountry : [];
      } else {
        this.masterCountry = [];
      }
    });
  }

  getSubscriptionExpireUrlAnynomusly() {
    this.commonService
      .getAll(this.getSubscriptionExpireUrl, {})
      .subscribe((response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          this.email = response.data.email;
          this.fax = response.data.fax;
          this.orgAddress = response.data.orgAddress;
          this.phone = response.data.phone;
          this.careproviderForm.controls.plantype.setValue(
            response.data.planName
          );
          this.fax = response.data.fax;
          this.startDate = response.data.startDate;
          this.organizationName = response.data.organizationName;
          this.currency = response.data.iso;
          this.careproviderForm.controls.amountofsubscription.setValue(
            response.data.price
          );
        }
      });
  }

  onCardtypeChange(value: string) {}

  onSubmit(event: any) {
    if (!this.careproviderForm.invalid) {
      let clickType = event.currentTarget.name;
      this.submitted = true;
      this.masterTaxModel = this.careproviderForm.value;
      //this.patientsTagModel.patientId = this.clientId;
      this.clientService
        .createSubscriptionPayment(this.masterTaxModel)
        .subscribe((response: any) => {
          this.submitted = false;
          if (response.statusCode == 200) {
            this.notifier.notify("success", response.message);
            if (clickType == "Save") this.closeDialog("save");
            // else if (clickType == "SaveAddMore") {
            //   this.refreshGrid.next();
            //   this.saveAndMore='save'
            //   this.unitmasterForm.reset();
            // }
          } else {
            this.notifier.notify("error", response.message);
          }
        });
    }
  }

  //close popup
  closeDialog(action: string): void {
    this.careproviderDialogModalRef.close(action);
  }
}
