import { OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/app/platform/modules/core/services";
var CallNotificationComponent = /** @class */ (function () {
    function CallNotificationComponent(http, dialogRef, diaglog, router, commonService, data) {
        this.http = http;
        this.dialogRef = dialogRef;
        this.diaglog = diaglog;
        this.router = router;
        this.commonService = commonService;
        this.data = data;
        this.isStaffs = false;
        this.isClient = false;
        this.appointmentId = data.appointmentId;
        this.InitiatorName = data.InitiatorName;
        this.patientname = data.patientname;
    }
    CallNotificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.commonService.currentLoginUserInfo.subscribe(function (user) {
            if (user) {
                _this.currentLoginUserId = user.users3.id;
                _this.isAdminLogin =
                    user.users3 &&
                        user.users3.userRoles &&
                        (user.users3.userRoles.userType || "").toUpperCase() == "ADMIN";
                // if (
                //   user.users3 &&
                //   user.users3.userRoles &&
                //   (user.users3.userRoles.value || "").toUpperCase() == "ADMIN"
                // ) {
                //   this.isStaffs = true;
                // }
                if (user.users3 &&
                    user.users3.userRoles &&
                    (user.users3.userRoles.value || "").toUpperCase() == "CLIENT") {
                    _this.isClient = true;
                }
                // if (user.users3 && user.users3.userRoles && (user.users3.userRoles.value || '').toUpperCase() == "REGIONAL VP SALES/MARKETING") {
                //   this.isVpSales = true;
                //   }
            }
        });
    };
    CallNotificationComponent.prototype.acceptcall = function () {
        if (this.isStaffs || this.isAdminLogin) {
            this.dialogRef.close();
            // this.router.navigate(["/web/encounter/video-call"], {
            //   queryParams: {
            //     apptId: this.appointmentId,
            //     isPatient: false,
            //   },
            // });
            window.open(window.location.origin + "/" + this.commonService.getBusinessUrl() +
                "/web/encounter/video-call?apptId=" +
                this.appointmentId +
                "&isPatient=" +
                false, "_blank");
        }
        else {
            if (this.isClient) {
                this.dialogRef.close();
                // this.router.navigate(["/web/client/patient-encounter/video-call"], {
                //   queryParams: {
                //     apptId: this.appointmentId,
                //     isPatient: true,
                //   },
                // });
                window.open(window.location.origin + "/" + this.commonService.getBusinessUrl() +
                    "/web/client/patient-encounter/video-call?apptId=" +
                    this.appointmentId +
                    "&isPatient=" +
                    true, "_blank");
            }
        }
    };
    CallNotificationComponent.prototype.declinecall = function () {
        this.dialogRef.close();
    };
    return CallNotificationComponent;
}());
export { CallNotificationComponent };
