import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SubDomainGuard } from "./subDomain.guard";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/organization-select"
  },

  // const routes: Routes = [
  //   { path: '', component: HomeComponent },
  //   { path: '**', component: NotFoundComponent },
  // ];
  {
    path: "organization-select",
    component:LandingPageComponent
  },

  {
    path: ":type/web",
    canActivate: [SubDomainGuard],
    loadChildren: () =>
      import("./platform/platform.module").then(m => m.PlatformModule)
  },
  {
    path: "webadmin",
    loadChildren: () =>
      import("./super-admin-portal/super-admin-portal.module").then(
        m => m.SuperAdminPortalModule
      )
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "/" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
