<div class="custom_modal">
  <div class="MDS-Heading">
    <button (click)="declineTerms()" mat-icon-button class="close-button">
      <mat-icon class="close-icon" style="color: white">close</mat-icon>
    </button>
    <h3 mat-dialog-title>Terms & Conditions</h3>
  </div>
  <div mat-dialog-content>
    <form
      id="acceptTerms"
      #documentForm="ngForm"
      class="readmin-form custom_form"
      [formGroup]="acceptTerms"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="readmin-form custom_form">
            <div [innerHTML]="content"></div>
            <div class="row">
              <div class="col-sm-12">
                <mat-checkbox formControlName="isChecked" required>
                  I read and accept to terms and conditions.
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <div>
          <button
            style="margin-right: 15px"
            class="cancel-btn"
            mat-raised-button
            (click)="declineTerms()"
            cdkFocusInitial
          >
            Exit
          </button>
        </div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="isValid"
          form="acceptTerms"
          (click)="onSubmit()"
        >
          Continue
        </button>
      </div>
    </form>
  </div>
</div>
