import { OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { format } from "date-fns";
import { CommonService } from "src/app/platform/modules/core/services";
import { AppointmentDetailsModel } from "../appointment.details.model";
var NotificationDetailsComponent = /** @class */ (function () {
    function NotificationDetailsComponent(data, dialogModalRef, commonService) {
        this.data = data;
        this.dialogModalRef = dialogModalRef;
        this.commonService = commonService;
        this.getClientHeaderInfoURL = "Patients/GetPatientHeaderInfo";
        console.log("Details Data : ", data.appointmentDetails);
        this.appointmentDetails = new AppointmentDetailsModel();
        this.appointmentDetails = data.appointmentDetails;
    }
    NotificationDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userInfo = this.commonService.getLoginUserInfo();
        var combinedNames = "";
        if (this.appointmentDetails.staffNames) {
            combinedNames = combinedNames + this.appointmentDetails.staffNames;
        }
        if (this.appointmentDetails.patientNames) {
            combinedNames = combinedNames + this.appointmentDetails.patientNames;
        }
        if (userInfo && userInfo.data.value) {
            combinedNames = combinedNames.replace(userInfo.data.value, "");
            combinedNames = combinedNames.replace(",,", ",");
            combinedNames = combinedNames.replace("  ", " ");
            this.appointmentDetails.message = this.appointmentDetails.message.replace("[DATE]", format(this.appointmentDetails.appointmentDate, "MM/DD/YYYY"));
            this.appointmentDetails.message = this.appointmentDetails.message.replace("[TIME]", format(this.appointmentDetails.appointmentDate, "hh:mm a"));
            combinedNames = combinedNames.replace("  ", " ");
            this.appointmentDetails.message = this.appointmentDetails.message.replace("[Names]", combinedNames);
        }
        else {
            this.getClientHeaderInfoApi(userInfo.data.id).subscribe(function (response) {
                if (response != null && response.statusCode == 200) {
                    var res = response.data.patientBasicHeaderInfo;
                    if (res && res.name) {
                        combinedNames = combinedNames.replace(res.name, "");
                        combinedNames = combinedNames.replace(",,", ",");
                        combinedNames = combinedNames.replace("  ", " ");
                        _this.appointmentDetails.message = _this.appointmentDetails.message.replace("[DATE]", format(_this.appointmentDetails.appointmentDate, "MM/DD/YYYY"));
                        _this.appointmentDetails.message = _this.appointmentDetails.message.replace("[TIME]", format(_this.appointmentDetails.appointmentDate, "hh:mm a"));
                        combinedNames = combinedNames.replace("  ", " ");
                        _this.appointmentDetails.message = _this.appointmentDetails.message.replace("[Names]", combinedNames);
                    }
                }
            });
        }
        console.log("Combined Name", combinedNames);
    };
    NotificationDetailsComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    NotificationDetailsComponent.prototype.getClientHeaderInfoApi = function (id) {
        return this.commonService.getById(this.getClientHeaderInfoURL + "?id=" + id, {});
    };
    return NotificationDetailsComponent;
}());
export { NotificationDetailsComponent };
