import { Router } from '@angular/router';
import { SubDomainService } from './subDomain.service';
var SubDomainGuard = /** @class */ (function () {
    function SubDomainGuard(router, subDomainService) {
        this.router = router;
        this.subDomainService = subDomainService;
    }
    SubDomainGuard.prototype.canActivate = function (route, state) {
        if (this.subDomainService.getSubDomainUrl()) {
            return true;
        }
        this.router.navigate(['/webadmin']);
        return false;
    };
    return SubDomainGuard;
}());
export { SubDomainGuard };
