<div class="custom_modal">
  <!-- <button class="close" mat-button (click)="closeDialog('close')">
          <mat-icon style="color: white;">close</mat-icon>
      </button> -->
  <h3 mat-dialog-title>Notification Details</h3>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="readmin-form custom_form">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <h6>{{ appointmentDetails.message }}</h6>
              </div>
              <!-- <div class="col-sm-6">
                <span style="font-weight: bold">Date: </span>
                <span>{{
                  appointmentDetails.appointmentDate | date: "MM/dd/yyyy"
                }}</span>
              </div>
              <div class="col-sm-6">
                <span style="font-weight: bold">Time: </span>
                <span>{{
                  appointmentDetails.appointmentDate | date: "shortTime"
                }}</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button class="cancel-btn" (click)="closeDialog('close')">
      Close
    </button>
  </div>
</div>
