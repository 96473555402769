import { CommonService } from "src/app/super-admin-portal/core/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../super-admin-portal/core/services/common.service";
var SuperAdminPasswordChangeService = /** @class */ (function () {
    function SuperAdminPasswordChangeService(commonService) {
        this.commonService = commonService;
        this.updateNewPasswordURL = "api/Organization/UpdateUserPassword";
        this.GetPasswordPoliciesURL = "/PasswordPolicies/GetPasswordPolicies";
    }
    SuperAdminPasswordChangeService.prototype.updateNewPassword = function (data) {
        return this.commonService.post(this.updateNewPasswordURL, data);
    };
    SuperAdminPasswordChangeService.prototype.getPasswordPolicyByToken = function () {
        return this.commonService.getById(this.GetPasswordPoliciesURL, {});
    };
    SuperAdminPasswordChangeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SuperAdminPasswordChangeService_Factory() { return new SuperAdminPasswordChangeService(i0.ɵɵinject(i1.CommonService)); }, token: SuperAdminPasswordChangeService, providedIn: "root" });
    return SuperAdminPasswordChangeService;
}());
export { SuperAdminPasswordChangeService };
