<div class="login_bg">
  <div class="login-wrapper">
    <div class="col-sm-9 mx-auto">
      <div class="row">
        <div class="col-xl-7 col-lg-6 col-sm-12 login-content">
          <div class="loginlogo">
            <p>Welcome to</p>
            <h2>Access<span>EMR</span></h2>
            <!-- <img src={{logoUrl}} alt="" /> -->
          </div>
          <!-- <p><span>smartHealth</span> system (SH) is a Cloud Based system that facilitates managing the functioning of
              the Agencies. <span>smartHealth</span> system will help in making the whole functioning paperless. It
              integrates all the information regarding patients, doctors, staff, agency administrative details etc. into
              one place</p>
            <p>Safe and Secure Process to safe guard patients privacy and rights by following <span>HIPAA.</span></p>
            <p>smartHealth is a Product of <a class="smartlink" href="https://www.smartdatainc.com/" target="_blank"><span
                  className="smartlink">smartData Enterprises.</span></a> </p>
            <p>Copyright @ 2018 All Right Reserved.</p> -->
        </div>
        <div class="col-xl-5 col-lg-12 col-sm-12">
          <div class="login-fields org_select">
            <div class="login-headtabs">
              <div class="login-headtab active col-sm-12">
                <a class="active" aria-current="page">ENTER ORGANIZATION</a>
              </div>
            </div>
            <form
              [formGroup]="loginForm"
              class="readmin-form"
              (ngSubmit)="onSubmit()"
            >
              <div class="form-group">
                <mat-form-field
                  class="example-full-width"
                  hintLabel="{{
                    f.organizationName.pending ? 'Validating...' : ''
                  }}"
                >
                  <input
                    matInput
                    placeholder="Organization Name"
                    formControlName="organizationName"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                  />
                  <mat-error *ngIf="f.organizationName.errors?.uniqueName">
                    Organization Name Not Valid or Not Available.
                  </mat-error>
                  <mat-error *ngIf="f.organizationName.errors?.required">
                    Organization Name is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-checkbox formControlName="rememberOrg">
                  Remember Organization Name
                </mat-checkbox>
              </div>

              <div class="d-flex justify-content-center">
                <button
                  class="example-full-width login-btn"
                  mat-raised-button
                  color="primary"
                  [disabled]="f.organizationName.errors?.uniqueName"
                >
                  {{
                    !f.organizationName.errors?.uniqueName
                      ? "Navigate"
                      : "Navigate"
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
