<div class="custom_modal">
  <h3 mat-dialog-title>Document</h3>
  <div mat-dialog-content>

    <div class="row">
      <div class="col-sm-12">
        <form id="docFaxForm" #documentForm="ngForm" class="readmin-form custom_form" [formGroup]="docFaxForm">

          <div class="row">

            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <mat-select placeholder="Document Type" formControlName="documentTypeId" matInput
                  [ngClass]="{ 'is-invalid': submitted && formControls.documentTypeId.errors }" required>
                  <mat-option *ngFor="let doc of masterDocumentTypes" [value]="doc.id">
                    {{doc.value}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formControls.documentTypeId.errors">
                  Please select document type
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="expirationDate" placeholder="Expiration Date" readonly
                  formControlName="expirationDate" [max]="docFaxForm.controls.expirationDate.value" required>
                <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
                <mat-datepicker #expirationDate></mat-datepicker>
                <mat-error *ngIf="formControls.expirationDate.errors?.required">
                  Please enter valid date
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Document Name" formControlName="title"
                  [ngClass]="{ 'is-invalid': submitted && formControls.title.errors }" required />
                <mat-error *ngIf="formControls.title.errors?.required">
                  Please enter title
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button class="cancel-btn" (click)="closeDialog(close)">Close</button>
    <div>
      <button name="Save" mat-raised-button color="primary" (click)="onSubmit($event)" form="docFaxForm"
        class="text-uppercase" [disabled]="submitted" cdkFocusInitial>{{submitted ? 'Saving...' : 'Save'}}</button>
    </div>
  </div>
</div>