/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/angular2-signaturepad/signature-pad.ngfactory";
import * as i4 from "angular2-signaturepad/signature-pad";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./signature-dialog.component";
import * as i12 from "@angular/forms";
var styles_SignatureDialogComponent = [i0.styles];
var RenderType_SignatureDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureDialogComponent, data: {} });
export { RenderType_SignatureDialogComponent as RenderType_SignatureDialogComponent };
function View_SignatureDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please Add Signature"]))], null, null); }
export function View_SignatureDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { signaturePad: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "custom_modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Add Signature"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "signature-pad", [], null, [[null, "onEndEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEndEvent" === en)) {
        var pd_0 = (_co.drawComplete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_SignaturePad_0, i3.RenderType_SignaturePad)), i1.ɵdid(12, 1097728, [[1, 4]], 0, i4.SignaturePad, [i1.ElementRef], { options: [0, "options"] }, { onEndEvent: "onEndEvent" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureDialogComponent_1)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 11, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "cancel-btn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Close "])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["class", "cancel-btn ml-3"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Clear "])), (_l()(), i1.ɵeld(24, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(25, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(26, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_1 = _co.signaturePadOptions; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.showError; _ck(_v, 14, 0, currVal_2); var currVal_9 = _co.submitted; var currVal_10 = "primary"; _ck(_v, 25, 0, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0); var currVal_3 = (i1.ɵnov(_v, 19).disabled || null); var currVal_4 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_3, currVal_4); var currVal_5 = (i1.ɵnov(_v, 22).disabled || null); var currVal_6 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); _ck(_v, 21, 0, currVal_5, currVal_6); var currVal_7 = (i1.ɵnov(_v, 25).disabled || null); var currVal_8 = (i1.ɵnov(_v, 25)._animationMode === "NoopAnimations"); _ck(_v, 24, 0, currVal_7, currVal_8); var currVal_11 = (_co.submitted ? "Submiting..." : "Submit"); _ck(_v, 26, 0, currVal_11); }); }
export function View_SignatureDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signature-dialog", [], null, null, null, View_SignatureDialogComponent_0, RenderType_SignatureDialogComponent)), i1.ɵdid(1, 4308992, null, 0, i11.SignatureDialogComponent, [i12.FormBuilder, i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureDialogComponentNgFactory = i1.ɵccf("app-signature-dialog", i11.SignatureDialogComponent, View_SignatureDialogComponent_Host_0, {}, {}, []);
export { SignatureDialogComponentNgFactory as SignatureDialogComponentNgFactory };
