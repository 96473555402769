export class ReferralModel{
    patientName:string;
    patientdob:string;
    id: number;
    patientID: number;
    healthCardNumber:number;
    prescriberName:string;
    sex:string;    
    birthDate:Date;
    roomNumberUnit:string;
    genderId:number;
    referralDate:Date;
    reasonsForReferral:string;
    referredToId:number;
    referralNotes:string;
    referralSituation:string;
    referralBackground:string;
    referralAction:string;
    referralRecommendations:string;
    isCarePlan:boolean;
    isFunctionalStatus:boolean;
    isAllergies:boolean;
    isMedication:boolean;    
    isDiagnosis:boolean;
    isVitalSigns:boolean;
    referredBy:string;
    UploadPath:string;
    uploadPath:string;
    saveType:string;
}
export class RefModel{
    patientID:number;
    ReferralDate:Date;
    ReferredTo:string;
    ReferredBy:string;
    ReferralNotes:string;
    ReasonsForReferral:string;
    UploadPath:string;
    IsMedicationList:boolean;
    IsAllergies:boolean;
    IsDiagnosis:boolean;
    IsFunctionalStatus:boolean;
    uploadPath:string;
    saveType:string;
    staffId:number;
}

