<div class="custom_modal">
  <h3 mat-dialog-title>CHANGE PASSWORD</h3>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <form
          id="changePasswordForm"
          #documentForm="ngForm"
          class="readmin-form custom_form"
          [formGroup]="changePasswordForm"
        >
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  autofocus
                  type="password"
                  placeholder="Current Password"
                  maxlength="30"
                  formControlName="currentPassword"
                  [ngClass]="{
                    'is-invalid':
                      submitted && formControls.currentPassword.errors
                  }"
                  required
                />
                <mat-error
                  *ngIf="formControls.currentPassword.errors?.required"
                >
                  Please enter current password
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col-sm-6">
                <mat-form-field class="example-full-width">
                  <input matInput autofocus type="password" [pattern]="strongPassword" placeholder="Password" formControlName="newPassword"
                    [ngClass]="{ 'is-invalid': submitted && formControls.newPassword.errors }" required />
                    <mat-error *ngIf="formControls.newPassword.errors?.required && formControls.newPassword.errors?.pattern">
                        Please enter password
                      </mat-error>
                      <mat-error *ngIf="formControls.newPassword.errors?.required==undefined && formControls.newPassword.errors?.pattern">
                          {{errorMessage}}
                        </mat-error>
                  <mat-error *ngIf="formControls.newPassword.errors?.required">
                    Please enter new password
                  </mat-error>
                </mat-form-field>
              </div> -->
          </div>
          <div class="row" style="margin-bottom: 20px">
            <div class="col-sm-6">
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  autofocus
                  type="password"
                  [pattern]="strongPassword"
                  placeholder="New Password"
                  formControlName="newPassword"
                  [ngClass]="{
                    'is-invalid': submitted && formControls.newPassword.errors
                  }"
                  required
                />
                <mat-error *ngIf="formControls.newPassword.errors?.required">
                  Please enter password
                </mat-error>
                <mat-error
                  *ngIf="
                    formControls.newPassword.errors?.required == undefined &&
                    formControls.newPassword.errors?.pattern
                  "
                >
                  {{ errorMessage }}
                </mat-error>
                <!-- <mat-error *ngIf="formControls.newPassword.errors?.required">
                    Please enter new password
                  </mat-error> -->
              </mat-form-field>
              <small *ngIf="!formControls.newPassword.errors?.pattern">{{
                errorMessage
              }}</small>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  autofocus
                  type="password"
                  placeholder="Confirm Password"
                  formControlName="confirmNewPassword"
                  [ngClass]="{
                    'is-invalid':
                      submitted && formControls.confirmNewPassword.errors
                  }"
                  required
                />
                <mat-error
                  *ngIf="formControls.confirmNewPassword.errors?.required"
                >
                  Please re-enter new password
                </mat-error>
                <mat-error
                  *ngIf="formControls.confirmNewPassword.errors?.notSame"
                >
                  Password doesn't match
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button class="cancel-btn" (click)="closeDialog('close')">
      Close
    </button>
    <button
      name="Save"
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
      form="changePasswordForm"
      class="text-uppercase"
      [disabled]="submitted"
    >
      {{ submitted ? "Saving..." : "Save" }}
    </button>
  </div>
</div>
