/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dicom-viewer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng-dicomviewer/ng-dicomviewer.ngfactory";
import * as i3 from "ng-dicomviewer";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./dicom-viewer.component";
import * as i10 from "@angular/material/dialog";
var styles_DicomViewerComponent = [i0.styles];
var RenderType_DicomViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DicomViewerComponent, data: {} });
export { RenderType_DicomViewerComponent as RenderType_DicomViewerComponent };
export function View_DicomViewerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { viewPort: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["style", "height:100%; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["style", "display: flex; margin-left: 120px; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "choose_file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Choose Your DICOM Files "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["accept", "application/dicom"], ["class", "hide_file"], ["id", "imagens"], ["multiple", ""], ["title", "selecione arquivos de imagens"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.loadDICOMImages($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["style", "display: flex;height: calc(100% - 90px); width:100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "dicom-viewer", [["style", "height:100%; width:100%; margin: 10px;"]], null, null, null, i2.View_DICOMViewerComponent_0, i2.RenderType_DICOMViewerComponent)), i1.ɵdid(9, 114688, [[1, 4]], 0, i3.DICOMViewerComponent, [], { enableViewerTools: [0, "enableViewerTools"], maxImagesToLoad: [1, "maxImagesToLoad"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "cancel-btn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog("close") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Close"])), (_l()(), i1.ɵted(-1, null, [" --> "])), (_l()(), i1.ɵted(-1, null, [" --> --> "]))], function (_ck, _v) { var currVal_0 = true; var currVal_1 = 20; _ck(_v, 9, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 11).disabled || null); var currVal_3 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_2, currVal_3); }); }
export function View_DicomViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dicom-viewer", [], null, null, null, View_DicomViewerComponent_0, RenderType_DicomViewerComponent)), i1.ɵdid(1, 114688, null, 0, i9.DicomViewerComponent, [i10.MatDialogRef, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DicomViewerComponentNgFactory = i1.ɵccf("app-dicom-viewer", i9.DicomViewerComponent, View_DicomViewerComponent_Host_0, {}, {}, []);
export { DicomViewerComponentNgFactory as DicomViewerComponentNgFactory };
