export class OpenDocumentModel{    
    id: number;
    patientId: number;
    staffId:number;
    documentTypeId:number;
    expirationDate:Date;    
    title:string;   
    pathUrl:string;
    base64: string;
}

