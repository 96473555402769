<div class="custom_modal">
  <h3 mat-dialog-title>Referral</h3>
  <div mat-dialog-content>

    <div class="row">
      <div class="col-sm-12">
        <form id="ReferralForm" #documentForm="ngForm" class="readmin-form custom_form" [formGroup]="ReferralForm">

          <div class="row">

            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input placeholder="Referred To" type="text" matInput formControlName="referredToId"
                  [matAutocomplete]="referredToSearch" required>
                <div>
                  <img src="../../../../../../assets/img/search.png" class="search-icon-input" />
                </div>
                <mat-autocomplete class="Search_staff_name" #referredToSearch="matAutocomplete"
                  [displayWith]="displayFnStaff" (optionSelected)="onReferredToSelectedName($event)">
                  <mat-option *ngFor="let option of filterStaffName | async" [value]="option">
                    {{option.fullName}}
                  </mat-option>
                </mat-autocomplete>
                <!-- <mat-option *ngIf="isloadingStaff" class="is-loading">
                  <mat-spinner diameter="50"></mat-spinner>
                </mat-option> -->
                <mat-error *ngIf="formControls.referredToId.errors">
                  Please select referred to
                </mat-error>
              </mat-form-field>

              <!-- <mat-form-field class="example-full-width"> -->
              <!-- <mat-select placeholder="Referred To" formControlName="referredToId" matInput
                [ngClass]="{ 'is-invalid': submitted && formControls.referredToId.errors }" required>
                <mat-option *ngFor="let option of staffList" [value]="option.id">
                  {{option.fullName}}
                </mat-option>
              </mat-select> -->
              <!-- </mat-form-field> -->
            </div>
          </div>
          <div class="row">

            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Assigned Provider" formControlName="referredBy"
                  [ngClass]="{ 'is-invalid': submitted && formControls.referredBy.errors }" required />
                <mat-error *ngIf="formControls.referredBy.errors?.required">
                  Please enter assigned provider
                </mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-6 col-sm-6  custom-card custom-card-header">
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="referredDate" placeholder="Referred Date" readonly
                  formControlName="referredDate" [max]="ReferralForm.controls.referredDate.value" required>
                <mat-datepicker-toggle matSuffix [for]="referredDate"></mat-datepicker-toggle>
                <mat-datepicker #referredDate></mat-datepicker>
                <mat-error *ngIf="formControls.referredDate.errors?.required">
                  Please enter valid date
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button class="cancel-btn" (click)="closeDialog(close)">Close</button>
    <div>
      <button name="Save" mat-raised-button color="primary" (click)="onSubmit($event)" form="ReferralForm"
        class="text-uppercase" [disabled]="submitted" cdkFocusInitial>{{submitted ? 'Saving...' : 'Save'}}</button>
    </div>
  </div>
</div>