import { NavItem } from "./navItem";

export interface HeaderInfo {
  user: object;
  userLocations?: Array<object>;
  userNavigations?: NavItem[];
}
export class NotificationsModel {
  messageNotification: MessageNotificationModel[];
  userDocumentNotification: UserDocumentNotificationModel[];
}
export class UserDocumentNotificationModel {
  documentId: number;
  message: string;
  documentName: string;
  expiration: string;
}
export class MessageNotificationModel {
  messageId: number;
  parentMessageId: number;
  thumbNail: string;
  fromName: string;
  subject: string;
  key: string;
  messageDate: Date;
  unread:boolean;
}
export class TaskNotification {
  staffId: number;
  taskId: number;
  eventId:number;
  taskName: string;
  taskTypeId: number;
  patientName: string;
  roomNumber: string;
  createdDate?: Date;
  updatedDate?: Date;
  taskStatusId:number;
  totalRecords: number;
  message:string;
}
export class ChatNotificationModel {
  chatId: number;
  chatDate: string;
  fromUserId: number;
  toUserId: number;
  message: string;
  fromName: string;
  thumbnail: string;
  totalMessageCount: number;
  userMessageCount: number;
}

export class StaffExpiredDocNotification {
  messageId: number;
  fromName: string;
  subject: string;
  key: string;
  messageDate: Date;
  unread:boolean;
  totalRecords: number;
  parentMessageId: number;
  thumbNail: string;
}


