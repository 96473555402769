var ReferralModel = /** @class */ (function () {
    function ReferralModel() {
    }
    return ReferralModel;
}());
export { ReferralModel };
var RefModel = /** @class */ (function () {
    function RefModel() {
    }
    return RefModel;
}());
export { RefModel };
