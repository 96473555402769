
import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from '../../platform/modules/core/services';
import { ChatHistoryModel, GroupChatHistoryModel } from '../../platform/modules/agency-portal/clients/profile/chat-history.model';
import { HubConnection } from "../../hubconnection.service";
import { ScrollbarComponent } from 'ngx-scrollbar';

@Component({
  selector: 'app-group-chat-widget',
  templateUrl: './group-chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss'],
  encapsulation: ViewEncapsulation.None

})

export class GroupChatWidgetComponent implements OnInit, AfterViewInit {
  @Input() fromUserId: number;
  @Input() toUserId: number;
  @Input() allMessageArray: Array<ChatHistoryModel>;
  @Input() imgSource: string = '';
  @Input() badge: number;
  @Input() title: string = '';
  @Input() sessionId: string = '';

  @Input() subTitle: string = '';
  @Input() showCloseButton: boolean = false;
  @Input() autoFocus: boolean = true;
  @Output() onDocumentGet = new EventEmitter();

  message: string;
  hubConnection: HubConnection;
  @ViewChild("scrollbar", { static: false }) scrollbarRef: ScrollbarComponent;

  showChatModal: boolean;

  constructor() {
    this.showChatModal = false;
    this.message = '';
    this.hubConnection = new HubConnection();
  }

  onToggleChatModal() {
    this.showChatModal = !this.showChatModal;
  }

  ngOnInit() {
    console.log('console log',this.allMessageArray)
    this.createHubConnection();
  }

  createHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.createGroupHubConnection(JSON.parse(localStorage.getItem('access_token')))
        .then(
          response => {
            this.hubConnection.getHubConnection().onclose(() => {
              this.ReconnectOnClose(this.fromUserId);
            });
            this.hubConnection.ConnectToServerWithUserIdAndSessionId(this.fromUserId,this.sessionId).then(res => {
            //  alert('called')
              this.getMessageNotifications();
            });
          }
        )
    }
  }

  ngAfterViewInit() {
    // this.scrollbarRef.scrollState.subscribe(e => console.log(e))
    console.log('this.sessionId',this.sessionId)

  }
  fileList: any = [];
  dataURL: any;

  handleImageChange(e) {
    //if (this.commonService.isValidFileType(e.target.files[0].name, "image")) {
    let fileExtension = e.target.files[0].name
      .split(".")
      .pop()
      .toLowerCase();
    var input = e.target;
    var reader = new FileReader();
    reader.onload = () => {
      this.dataURL = reader.result;
      this.fileList.push({
        data: this.dataURL,
        ext: fileExtension,
        fileName: e.target.files[0].name
      });
    };
    reader.readAsDataURL(input.files[0]);
  }
  removeFile(index: number) {
    this.fileList.splice(index, 1);
  }
  sendMessage(event) {

 
    let model=
    {
      fromUserId:this.fromUserId,
      sessionId:this.sessionId,
      message:this.message,
      // base64:this.fileList,
      isFileMessage:false,
      documentTitle:"",
      Extenstion:''
    }
    let dic = [];
    
    // model.base64.forEach((element, index) => {
    //   dic.push(
    //     `"${element.data.replace(
    //       /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,/,
    //       ""
    //     )}": "${element.ext}"`
    //   );
    // });
    // let newObj = dic.reduce((acc, cur, index) => {
    //   acc[index] = cur;
    //   return acc;
    // }, {});
    // model.base64 = newObj;
    if (!this.message || !this.message.trim())
      return false;
    this.handleNewUserMessage(model);
    const messageObj: ChatHistoryModel = {
      message: this.message,
      isRecieved: false,
    }
    this.allMessageArray.push(messageObj);
    this.message = '';
  }


  handleNewUserMessage(message: any) {
    console.log(JSON.stringify(message))
    if (this.hubConnection.isConnected()) {
      this.hubConnection.getHubConnection()
        .invoke('SendMessageMobile', message.message,message.fromUserId,message.sessionId)
        .catch((err) => console.error(err, 'ReceiveMessageReceiveMessageerror'));
      return message;
    } else {
      this.hubConnection.restartHubConnection().then(() => {
        this.hubConnection.getHubConnection()
          .invoke('SendMessageMobile', message)
          .catch((err) => console.error(err, 'ReceiveMessageReceiveMessageerror'));
        return message;
      });
    }
  }

  
  getMessageNotifications() {
    this.hubConnection.getHubConnection().on('ReceiveGroupMessage', (result, fromUserId,username) => {
      console.log('message from server', result, fromUserId, this.toUserId);
      const actionObj = {
        result,
        fromUserId,username
      };
      this.onDocumentGet.emit(actionObj);
      // if (fromUserId == this.toUserId) {
        const messageObj: GroupChatHistoryModel = {
          message: result,
          isRecieved: true,
          userName:username
        }
        this.allMessageArray.push(messageObj);
      // }
    });
  }
  getFileNotifiatiopn() {
    this.hubConnection.getHubConnection().on('ReceiveChatDocument', (result, fromUserId,username) => {
      console.log('file resoponse form server from server', result, fromUserId, this.toUserId);
      // if (fromUserId == this.toUserId) {
        const messageObj: GroupChatHistoryModel = {
          message: result,
          isRecieved: true,
          userName:username
        }
        this.allMessageArray.push(messageObj);
      // }
    });
  }
  ReconnectOnClose(fromUserId) {
    setTimeout(() => {
      this.hubConnection.restartHubConnection().then(() => {
        this.hubConnection.ConnectToServerWithUserIdAndSessionId(fromUserId,this.sessionId).then(() => {
          // console.log('Restart Connection: user id sent to server : ' + fromUserId);
        });
      });
    }, 5000);
  }
}
