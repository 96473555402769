<div class="custom_modal">
    <button class="close" mat-button (click)="closeDialog('close')">
        <mat-icon style="color: white;">close</mat-icon>
    </button>
    <h3 mat-dialog-title>Task Details</h3>
    <div mat-dialog-content>

        <div class="row mb-4">
            <div class="col-sm-12">
                <div class="readmin-form custom_form">

                    <div class="col-sm-12">
                        <h2 class="mb-2" *ngIf="taskDetails.taskStatusId == 5">Please accept task to begin</h2>
                        <h2 class="mb-2" *ngIf="taskDetails.taskStatusId == 1">Task is Completed</h2>
                        <div class="row">
                            <div class="col-sm-5">
                                Task Name
                            </div>
                            <div class="col-sm-2">
                                :
                            </div>
                            <div class="col-sm-5">
                                {{taskDetails.taskName}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-5">
                                Patient Name
                            </div>
                            <div class="col-sm-2">
                                :
                            </div>
                            <div class="col-sm-5">
                                {{taskDetails.patientName}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-5">
                                Room Number
                            </div>
                            <div class="col-sm-2">
                                :
                            </div>
                            <div class="col-sm-5">
                                {{taskDetails.roomNumber}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions *ngIf="taskDetails.taskStatusId == 5">

        <div>
            <button style=" margin-right: 15px;background-color: #ff5454 !important; color: white;" mat-raised-button
                (click)="updateTask('DECLINE')" cdkFocusInitial>
                Decline
            </button>
        </div>
        <button mat-raised-button color="primary" (click)="updateTask('ACCEPT')">Accept</button>
    </div>
</div>