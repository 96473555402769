import { OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material";
import { FormBuilder, Validators } from "@angular/forms";
import { CommonService } from "src/app/platform/modules/core/services";
import { SignatureDialogComponent } from "../signature-dialog/signature-dialog.component";
import { ConsentModel } from "./consent.model";
var ConsentModalComponent = /** @class */ (function () {
    function ConsentModalComponent(data, commonService, dialogModalRef, formBuilder, signDailog) {
        this.data = data;
        this.commonService = commonService;
        this.dialogModalRef = dialogModalRef;
        this.formBuilder = formBuilder;
        this.signDailog = signDailog;
        this.acceptTermsAndConditionsURL = "/ConcentPolicy/UpdatePatientConsent";
        this.isValid = true;
        this.staffId = "";
        this.signdata = "";
        //console.log("From data",data)
        this.content = data.content;
    }
    ConsentModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.commonService.currentLoginUserInfo.subscribe(function (user) {
            console.log("User", user);
            if (user) {
                _this.clientId = user.id;
            }
        });
        this.acceptTerms = this.formBuilder.group({
            isChecked: [false, Validators.required],
            date: [new Date(), Validators.required],
            signature: ["", Validators.required],
            patientID: [this.clientId, Validators.required],
            policy: [this.content, Validators.required],
        });
        this.acceptTerms.controls.isChecked.valueChanges.subscribe(function (value) {
            if (value) {
                _this.isValid = false;
            }
            else {
                _this.isValid = true;
            }
        });
    };
    ConsentModalComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.acceptTerms.invalid) {
            var data = new ConsentModel();
            data = this.acceptTerms.value;
            this.showError = false;
            this.commonService
                .post(this.acceptTermsAndConditionsURL, data)
                .subscribe(function (response) {
                if (response && response.statusCode == 200) {
                    _this.closeDialog("ok");
                }
            });
            //console.log("AcceptClick")
        }
        else {
            this.showError = true;
        }
    };
    ConsentModalComponent.prototype.declineTerms = function () {
        var _this = this;
        console.log("Consent Form", this.acceptTerms.value);
        var data = new ConsentModel();
        data = this.acceptTerms.value;
        this.commonService
            .post(this.acceptTermsAndConditionsURL, data)
            .subscribe(function (response) {
            if (response && response.statusCode == 200) {
                _this.closeDialog("close");
            }
            else {
                _this.closeDialog("close");
            }
        });
    };
    ConsentModalComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    ConsentModalComponent.prototype.openSignDialog = function () {
        var _this = this;
        var staffsList = [{}];
        var clientDetails = {};
        var modalPopup = this.signDailog.open(SignatureDialogComponent, {
            hasBackdrop: true,
            data: {
                staffsList: staffsList,
                clientDetails: clientDetails,
            },
        });
        modalPopup.afterClosed().subscribe(function (result) {
            if (result) {
                _this.signdata = result.bytes;
                if (_this.signdata == "") {
                    _this.signatureError = true;
                }
                else {
                    _this.signatureError = false;
                }
                _this.acceptTerms.controls.signature.setValue(_this.signdata);
                _this.acceptTerms.controls.date.setValue(new Date());
                //this.QuartelyAssessmentForm.controls.DynamicaAssessmentInformation['signature'] = this.signdata;
            }
            else {
                if (_this.signdata == "") {
                    _this.signatureError = true;
                }
                else {
                    _this.signatureError = false;
                }
            }
        });
    };
    return ConsentModalComponent;
}());
export { ConsentModalComponent };
