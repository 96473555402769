import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { SubDomainService } from "./subDomain.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "src/environments/environment";
var AppComponent = /** @class */ (function () {
    function AppComponent(subDomainService, changeDetectorRef, media, route, location) {
        this.subDomainService = subDomainService;
        this.route = route;
        this.location = location;
        this.title = "healthcare-frontend-angular";
        this.isLoadingSubdomain = false;
        this.isDarkTheme = false;
        this.mobileQuery = media.matchMedia("(max-width: 600px)");
        this._mobileQueryListener = function () { return changeDetectorRef.detectChanges(); };
        this.mobileQuery.addListener(this._mobileQueryListener);
        var pathString = location.path();
        var localPart = pathString.slice(1, pathString.indexOf("/w"));
        localStorage.removeItem("business-url");
        localStorage.setItem("business-url", localPart);
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.redirectToSecurePort();
        var subDomainUrl = this.subDomainService.getSubDomainUrl();
        if (subDomainUrl) {
            this.isLoadingSubdomain = true;
            this.subDomainService.verifyBusinessName(subDomainUrl).subscribe();
            this.subscription = this.subDomainService
                .getSubDomainInfo()
                .subscribe(function (domainInfo) {
                if (domainInfo)
                    _this.subDomainService.updateFavicon(domainInfo.favicon64);
            });
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
        this.mobileQuery.removeListener(this._mobileQueryListener);
    };
    AppComponent.prototype.redirectToSecurePort = function () {
        if (environment.production) {
            if (location.protocol === "http:") {
                window.location.href = location.href.replace("http", "https");
            }
        }
    };
    return AppComponent;
}());
export { AppComponent };
