import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CommonService } from "src/app/platform/modules/core/services";
import { ResponseModel } from "src/app/super-admin-portal/core/modals/common-model";
import { SignatureDialogComponent } from "../signature-dialog/signature-dialog.component";
import { ConsentModel } from "./consent.model";

@Component({
  selector: "app-consent-modal",
  templateUrl: "./consent-modal.component.html",
  styleUrls: ["./consent-modal.component.css"],
})
export class ConsentModalComponent implements OnInit {
  acceptTermsAndConditionsURL = "/ConcentPolicy/UpdatePatientConsent";
  acceptTerms: FormGroup;
  content: string;

  isValid: boolean = true;
  signatureError: boolean;
  showError: boolean;
  userInfo: any;
  clientId: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private dialogModalRef: MatDialogRef<ConsentModalComponent>,
    private formBuilder: FormBuilder,
    private signDailog: MatDialog
  ) {
    //console.log("From data",data)
    this.content = data.content;
  }

  ngOnInit() {
    this.commonService.currentLoginUserInfo.subscribe((user: any) => {
      console.log("User", user);
      if (user) {
        this.clientId = user.id;
      }
    });
    this.acceptTerms = this.formBuilder.group({
      isChecked: [false, Validators.required],
      date: [new Date(), Validators.required],
      signature: ["", Validators.required],
      patientID: [this.clientId, Validators.required],
      policy: [this.content, Validators.required],
    });
    this.acceptTerms.controls.isChecked.valueChanges.subscribe((value) => {
      if (value) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    });
  }
  onSubmit() {
    if (!this.acceptTerms.invalid) {
      let data = new ConsentModel();
      data = this.acceptTerms.value;
      this.showError = false;
      this.commonService
        .post(this.acceptTermsAndConditionsURL, data)
        .subscribe((response: ResponseModel) => {
          if (response && response.statusCode == 200) {
            this.closeDialog("ok");
          }
        });
      //console.log("AcceptClick")
    } else {
      this.showError = true;
    }
  }

  declineTerms() {
    console.log("Consent Form", this.acceptTerms.value);

    let data = new ConsentModel();
    data = this.acceptTerms.value;
    this.commonService
      .post(this.acceptTermsAndConditionsURL, data)
      .subscribe((response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          this.closeDialog("close");
        } else {
          this.closeDialog("close");
        }
      });
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }

  staffId: string = "";
  signdata: string = "";
  openSignDialog() {
    const staffsList = [{}];
    const clientDetails = {};
    const modalPopup = this.signDailog.open(SignatureDialogComponent, {
      hasBackdrop: true,
      data: {
        staffsList,
        clientDetails,
      },
    });

    modalPopup.afterClosed().subscribe((result) => {
      if (result) {
        this.signdata = result.bytes;
        if (this.signdata == "") {
          this.signatureError = true;
        } else {
          this.signatureError = false;
        }
        this.acceptTerms.controls.signature.setValue(this.signdata);
        this.acceptTerms.controls.date.setValue(new Date());
        //this.QuartelyAssessmentForm.controls.DynamicaAssessmentInformation['signature'] = this.signdata;
      } else {
        if (this.signdata == "") {
          this.signatureError = true;
        } else {
          this.signatureError = false;
        }
      }
    });
  }
}
