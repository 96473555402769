import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ClientsService } from '../../clients.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-multidisciplinary-cross-out-reason',
  templateUrl: './multidisciplinary-cross-out-reason.component.html',
  styleUrls: ['./multidisciplinary-cross-out-reason.component.css']
})
export class MultidisciplinaryCrossOutReasonComponent implements OnInit {
  reasonForm: FormGroup;
  progressNoteId: number;
  masterCrossOutReasonlist: any[];
  patientId: number;
  crossOutReasonId: number;
  isCross: boolean;
  submitted: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogModalRef: MatDialogRef<MultidisciplinaryCrossOutReasonComponent>,
    private clientService: ClientsService,
    private notifier: NotifierService
  ) {
    this.progressNoteId = data.progressNoteId;
    this.patientId = data.patientId;
  }

  ngOnInit() {
    this.getMasterData();
    this.reasonForm = new FormGroup({
      crossOutReasonId: new FormControl()
    });
  }

  getMasterData() {
    let data = "MEDICATION";
    this.clientService.getMasterData(data).subscribe((response: any) => {
      if (response != null) {
        this.masterCrossOutReasonlist = response.medicationsList.filter(
          (s) => s.type == "CrossOut Reason"
        );
      }
    });
  }

  onSubmit(event: any) {
    if (!this.reasonForm.invalid) {
      this.submitted = true;
      this.crossOutReasonId = this.reasonForm.value.crossOutReasonId;
      this.clientService.updateProgressNoteCrossOutReason(this.progressNoteId, this.crossOutReasonId)
        .subscribe((response: any) => {
          if (response.statusCode == 200) {
            const model = {
              patientsProgressNotesId: this.progressNoteId,
              patientID:this.patientId
            }
            this.clientService
              .crossProgesNotes(model)
              .subscribe((response: any) => {
                this.submitted = false;
                this.closeDialog("close");
              });
            this.notifier.notify("success", response.message);
          } else {
            this.submitted = false;
            this.notifier.notify("error", response.message);
          }
        });
    }
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }

}
