<div class="custom_modal">
    <button class="close" mat-button (click)="closeDialog('close')">
        <mat-icon style="color: white;">close</mat-icon>
    </button>
    <h3 mat-dialog-title>Pending Task Details</h3>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <div class="readmin-form custom_form">

                    <div *ngIf="isResidentSelected" class="col-sm-12">
                        <span>
                            Please fill out the TAR form or mark the task as complete.
                        </span>
                    </div>
                    <div *ngIf="!isResidentSelected" class="col-sm-12">
                        <span>
                            Kindly click on Complete to mark this task as finished.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>


        <button mat-raised-button color="primary" *ngIf="isResidentSelected" (click)="redirectToForm()">Fill Form</button>
        <div>
            <button style=" margin-left: 15px;background-color: #ff5454 !important; color: white;" mat-raised-button
                (click)="updateTask()" cdkFocusInitial>
                Complete
            </button>
        </div>
    </div>
</div>