import { Injectable } from "@angular/core";
import { CommonService } from "../../core/services";
import { FilterModel } from "../../core/modals/common-model";
import {
  UserModel,
  StaffPayrollRateModel,
  AddUserDocumentModel,
  StaffLeaveModel,
} from "./users.model";
import { StaffCustomLabel } from "./staff-custom-label.model";
import { StaffAvailabilityModel } from "./staff-availability.model";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { NotePadModel, TaskFilterModels } from "./task-note-pad/TaskNotePad Models/notepad.model";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private getMasterDataByNameURL = "api/MasterData/MasterDataByName";
  private createURL = "Staffs/CreateUpdateStaff";
  private deleteURL = "Staffs/DeleteStaff";
  private getStaffByIdURL = "Staffs/GetStaffById";
  private deleteAppointmentDetailURL =
    "api/PatientAppointments/DeleteAppointment";
  private deleteTaskUsingEventIdURL = 
    "api/PatientAppointments/DeleteTaskUsingEventId";
  private getAllURL = "Staffs/GetStaffs";
  private getStaffHeaderInfoURL = "Staffs/GetStaffHeaderData";
  private getStaffLeavesURL = "StaffLeave/GetStaffLeaveList";
  private updateLeaveStatusURL = "StaffLeave/UpdateLeaveStatus";
  private getStaffLeaveByIdURL = "StaffLeave/GetAppliedStaffLeaveById";
  private deleteStaffLeaveByIdURL = "StaffLeave/DeleteAppliedLeave";
  private saveStaffLeaveURL = "StaffLeave/SaveStaffAppliedLeave";
  private getMasterDataAutoComplete =
    "/api/MasterData/GetAutoComplateSearchingValues";

  private getStaffCustomLabelsURL = "StaffCustomLabel/GetStaffCustomLabels";
  private saveStaffCustomLabelsURL = "StaffCustomLabel/SaveCustomLabels";

  private getStaffLocationURL = "Staffs/GetAssignedLocationsById";
  private getStaffAvailabilityByLocationURL =
    "AvailabilityTemplates/GetStaffAvailabilityWithLocation";
  private getStaffAvailabilityWithShiftAndLocationURL =
    "AvailabilityTemplates/GetStaffAvailabilityWithShiftAndLocation";

  private saveStaffAvailabilityByLocationURL =
    "AvailabilityTemplates/SaveUpdateAvailabilityWithLocation";

  private getStaffPayrollRateURL =
    "StaffPayrollRateForActivity/GetStaffPayRateOfActivity";
  private getRolePayrollRateURL = "RolePayRollRate/GetRolePayRollRate";
  private saveUpdatePayrollRateURL =
    "StaffPayrollRateForActivity/SaveUpdateStaffPayrollRateForActivity";

  private saveUpdateRolePayrollRateURL =
    "RolePayRollRate/SaveUpdateRolePayRollRate";

  private getUserDocumentsURL = "userDocument/GetUserDocuments";
  private getUserByLocationURL =
    "api/PatientAppointments/GetStaffAndPatientByLocation";
  private getUserDocumentURL = "PatientAppointments/DownloadTaskDocument";
  private getUserDocumentByIdURL = "UserDocument/GetUserDocument";
  private deleteUserDocumentURL = "userDocument/DeleteUserDocument";
  private uploadUserDocumentURL = "userDocument/UploadUserDocuments";
  private getTaskDocumentURL = "api/PatientAppointments/DownloadTaskDocument";

  private getStaffProfileURL = "Staffs/GetStaffProfileData";

  private updateUserStatusURL = "user/UpdateUserStatus";
  private updateUserActiveStatusURL = "staffs/UpdateStaffActiveStatus";

  private getStaffAndPatientByLocationURL =
    "api/PatientAppointments/GetStaffAndPatientByLocation";

  private getStaffsAndPatientByLUnitURL =
    "api/PatientAppointments/GetStaffAndPatientByUnit";
  private getAllMyFollowingTaskURL =
    "api/PatientAppointments/GetTaskCreatedList";
  private getMyTaskListURL = "api/PatientAppointments/GetMyTaskList";
  private setPassword = "PasswordSetup";

  // Task Notepad 
  private getTaskNotePadListURL = "TaskController/GetTaskNotePadList";
  private createUpdateTaskNotePadURL = "TaskController/CreateUpdateTaskNotePad";
  private getTaskNotePadByIdURL = "TaskController/GetTaskNotePadById";
  private deleteTaskNotePadById = "TaskController/DeleteTaskNotePadById";

  //IpLocation
  private getLocationIpAddressesURL ="Login/LocationIpAddresses"; 

  constructor(private commonService: CommonService) {}
  create(data: UserModel) {
    return this.commonService.post(this.createURL, data);
  }

  delete(id: number) {
    return this.commonService.patch(this.deleteURL + "/" + id, {});
  }
  deleteStaff(id: number) {
    return this.commonService.post(this.deleteURL + "?id=" + id, {});
  }

  getStaffLeaves(filterModel: FilterModel, staffId: number) {
    let url =
      this.getStaffLeavesURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&staffId=" +
      staffId +
      "&fromDate=" +
      "1990-01-01" +
      "&toDate=" +
      "2018-11-28";
    return this.commonService.getAll(url, {});
  }
  deleteStaffLeave(id: number) {
    return this.commonService.patch(
      this.deleteStaffLeaveByIdURL + "?StaffLeaveId=" + id,
      {}
    );
  }
  getStaffLeaveById(id: number) {
    return this.commonService.getById(
      this.getStaffLeaveByIdURL + "?StaffLeaveId=" + id,
      {}
    );
  }
  saveStaffLeave(data: StaffLeaveModel) {
    return this.commonService.post(this.saveStaffLeaveURL, data);
  }
  getAll(filterModel: FilterModel, tags: any, roleIds: any) {
    let url =
      this.getAllURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      // '&LocationIDs=' +
      // locationId +
      "&searchKey=" +
      (filterModel.searchText || "") +
      "&RoleIds=" +
      roleIds +
      "&Tags=" +
      tags;
    return this.commonService.getAll(url, {});
  }
  getAllMyFollowingTask(filterModel: any) {
    return this.commonService.post(this.getAllMyFollowingTaskURL, filterModel);
  }
  getMyTaskList(filterModel: any) {
    return this.commonService.post(this.getMyTaskListURL, filterModel);
  }
  getMasterData(value: string = "") {
    return this.commonService.post(this.getMasterDataByNameURL, {
      masterdata: value,
    });
  }
  getStaffCustomLabels(staffId: number) {
    let url = this.getStaffCustomLabelsURL + "?staffId=" + staffId;
    return this.commonService.getAll(url, {});
  }
  getStaffById(id: number) {
    return this.commonService.getById(this.getStaffByIdURL + "?id=" + id, {});
  }

  getStaffHeaderInfo(id: number) {
    return this.commonService.getById(
      this.getStaffHeaderInfoURL + "?id=" + id,
      {}
    );
  }

  saveCustomLabels(data: StaffCustomLabel[]) {
    return this.commonService.post(this.saveStaffCustomLabelsURL, data);
  }
  getStaffLocations(staffId: number) {
    return this.commonService.getById(
      this.getStaffLocationURL + "?Id=" + staffId,
      {}
    );
  }
  getStaffAvailabilityByLocation(staffId: number, locationId: number) {
    return this.commonService.getById(
      this.getStaffAvailabilityByLocationURL +
        "?staffId=" +
        staffId +
        "&locationId=" +
        locationId +
        "&isLeaveNeeded=false",
      {}
    );
  }

  getStaffAvailabilityWithShiftAndLocation(
    staffId: number,
    locationId: number,
    shiftId: number
  ) {
    return this.commonService.getById(
      this.getStaffAvailabilityWithShiftAndLocationURL +
        "?staffId=" +
        staffId +
        "&locationId=" +
        locationId +
        "&shiftId=" +
        shiftId +
        "&isLeaveNeeded=false",
      {}
    );
  }
  saveStaffAvailability(data: any) {
    return this.commonService.post(
      this.saveStaffAvailabilityByLocationURL,
      data
    );
  }
  updateLeaveStatus(data: any) {
    return this.commonService.post(this.updateLeaveStatusURL, data);
  }

  getStaffPayrollRate(staffId: number) {
    return this.commonService.getById(
      this.getStaffPayrollRateURL + "?staffId=" + staffId,
      {}
    );
  }

  getRolePayrollRate(roleId: number) {
    return this.commonService.getById(
      this.getRolePayrollRateURL + "?RoleId=" + roleId,
      {}
    );
  }

  saveStaffPayrollRate(data: StaffPayrollRateModel[]) {
    return this.commonService.post(this.saveUpdatePayrollRateURL, data);
  }

  saveRolePayrollRate(data: any) {
    return this.commonService.post(this.saveUpdateRolePayrollRateURL, data);
  }
  getUserDocuments(userId: number, from: string, toDate: string) {
    return this.commonService.getAll(
      this.getUserDocumentsURL +
        "?userId=" +
        userId +
        "&from=" +
        from +
        "&to=" +
        toDate,
      {}
    );
  }

  getUserByLocation(locationId: number) {
    let url =
      this.getUserByLocationURL +
      "?locationIds=" +
      locationId +
      "&permissionKey=SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES&isActiveCheckRequired=YES";
    return this.commonService.getAll(url, {});
  }
  getUserDocument(id: number) {
    return this.commonService.download(
      this.getUserDocumentURL + "?id=" + id,
      {}
    );
  }
  getUserDocumentById(id: number) {
    return this.commonService.download(
      this.getUserDocumentByIdURL + "?id=" + id,
      {}
    );
  }
  getTaskDocument(id: number) {
    return this.commonService.download(
      this.getTaskDocumentURL + "?id=" + id,
      {}
    );
  }
  deleteUserDocument(id: number) {
    return this.commonService.patch(
      this.deleteUserDocumentURL + "?id=" + id,
      {}
    );
  }

  uploadUserDocuments(data: AddUserDocumentModel) {
    return this.commonService.post(this.uploadUserDocumentURL, data);
  }
  getStaffProfileData(id: number) {
    return this.commonService.getById(this.getStaffProfileURL + "/" + id, {});
  }
  downloadFile(blob: Blob, filetype: string, filename: string) {
    var newBlob = new Blob([blob], { type: filetype });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  getUserScreenActionPermissions(moduleName: string, screenName: string): any {
    return this.commonService.getUserScreenActionPermissions(
      moduleName,
      screenName
    );
  }

  updateUserStatus(userId: number, status: boolean) {
    return this.commonService.patch(
      this.updateUserStatusURL + "/" + userId + "/" + status,
      {}
    );
  }

  updateUserActiveStatus(staffId: number, status: boolean) {
    return this.commonService.patch(
      this.updateUserActiveStatusURL +
        "?staffId=" +
        staffId +
        "&isActive=" +
        status,
      {}
    );
  }
  getMasterDataAutocomplete(
    tableName: string,
    columnName: string,
    searchText: string
  ) {
    return this.commonService.getById(
      this.getMasterDataAutoComplete +
        "?tableName=" +
        tableName +
        "&columnName=" +
        columnName +
        "&searchText=" +
        searchText,
      {}
    );
  }

  getStaffAndPatientByLocation(
    locationIds: string,
    permissionKey: string = "SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES"
  ): Observable<any> {
    const queryParams = `?locationIds=${locationIds}&permissionKey=${permissionKey}&isActiveCheckRequired=YES`;
    return this.commonService.getAll(
      this.getStaffAndPatientByLocationURL + queryParams,
      {}
    );
  }

  getPatientByUnitIds(
    unitIds: string,
    permissionKey: string = "SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES"
  ): Observable<any> {
    const queryParams = `?unitIds=${unitIds}&permissionKey=${permissionKey}&isActiveCheckRequired=YES`;
    return this.commonService.getAll(
      this.getStaffsAndPatientByLUnitURL + queryParams,
      {}
    );
  }

  setPasswordbyUserId(id: number) {
    const headers = new HttpHeaders({
      businessToken: localStorage.getItem("business_token"),
    });
    return this.commonService.getAll(this.setPassword + "?UserId=" + id, {
      headers: headers,
    });
  }

  deleteAppointmentDetails(
    appointmentId: number,
    parentAppointmentId: number,
    deleteSeries: boolean,
    isAdmin: boolean
  ): Observable<any> {
    const queryParams = `?appointmentId=${appointmentId}&parentAppointmentId=${parentAppointmentId}&deleteSeries=${deleteSeries}&isAdmin=${isAdmin}`;
    return this.commonService.patch(
      this.deleteAppointmentDetailURL + queryParams,
      {}
    );
  }
  
  deleteAppointmentDetailsUsingEventId(
    appointmentId: number,
    eventId:number,
    isAdmin: boolean
  ): Observable<any> {
    const queryParams = `?appointmentId=${appointmentId}&eventId=${eventId}&isAdmin=${isAdmin}`;
    return this.commonService.patch(
      this.deleteTaskUsingEventIdURL + queryParams,
      {}
    );
  }


  //IPaddress
  GetLocationIpAddresses(){
    return this.commonService.getAll(this.getLocationIpAddressesURL,{});
  }

  // Task Notepad APIS 
  GetTaskNotePadList(filterModel: TaskFilterModels) {
    return this.commonService.postwithLoader(this.getTaskNotePadListURL, filterModel,true);
  }
  CreateUpdateTaskNotePad(data: NotePadModel) {
    return this.commonService.post(this.createUpdateTaskNotePadURL, data);
  }

  GetTaskNotePadById(id: number) {
    return this.commonService.getById(this.getTaskNotePadByIdURL + "?id=" + id,{});
  }

  DeleteTaskNotePadById(id:number){
    return this.commonService.patch(this.deleteTaskNotePadById + "?id=" + id,{});
  }
}
