import { environment } from "../environments/environment";
var signalR = require("@aspnet/signalr");
// import * as signalR from '@aspnet/signalr/dist/browser/signalr.js';
// import signalR from '@aspnet/signalr';
var HubConnection = /** @class */ (function () {
    function HubConnection() {
        var _this = this;
        this.createHubConnection = function (access_token) {
            _this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(environment.api_url + "/chathub", {
                accessTokenFactory: function () { return access_token; },
            })
                .configureLogging(signalR.LogLevel.Error)
                .build();
            _this.hubConnection.serverTimeoutInMilliseconds = 600000; // 10 minutes
            return _this.hubConnection.start();
        };
        this.createGroupHubConnection = function (access_token) {
            _this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(environment.api_url + "/groupChatHub", {
                accessTokenFactory: function () { return access_token; },
            })
                .configureLogging(signalR.LogLevel.Error)
                .build();
            _this.hubConnection.serverTimeoutInMilliseconds = 600000; // 10 minutes
            return _this.hubConnection.start();
        };
        //this.hubConnection = new HubConnection();
        this.createHubConnection = this.createHubConnection.bind(this);
        // this.createGroupHubConnection=this.createGroupHubConnection.bind(this)
    }
    HubConnection.prototype.getHubConnection = function () {
        return this.hubConnection;
    };
    HubConnection.prototype.restartHubConnection = function () {
        var self = this;
        return new Promise(function (resolve, reject) {
            if (self.isDisconnected()) {
                self.hubConnection
                    .start()
                    .then(function (hubConnection) {
                    resolve(hubConnection);
                })
                    .catch(function (err) {
                    console.log("signalr Error", err);
                    reject(err);
                });
            }
        });
    };
    //   getHandler() {
    //     return this.sb;
    //   }
    HubConnection.prototype.isConnected = function () {
        return (this.hubConnection && this.hubConnection.connection.connectionState === 1);
    };
    HubConnection.prototype.isDisconnected = function () {
        return (this.hubConnection && this.hubConnection.connection.connectionState === 2);
    };
    HubConnection.prototype.ConnectToServerWithUserId = function (userId) {
        return this.hubConnection.invoke("Connect", userId);
    };
    HubConnection.prototype.ConnectToServerWithUserIdAndSessionId = function (userId, sessionId) {
        return this.hubConnection.invoke("Connect", userId, sessionId);
    };
    return HubConnection;
}());
export { HubConnection };
