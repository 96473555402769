import { OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { ClientsService } from '../../../clients.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NotifierService } from "angular-notifier";
var IncidentCrossOutReasonComponent = /** @class */ (function () {
    function IncidentCrossOutReasonComponent(data, dialogModalRef, clientService, notifier) {
        this.data = data;
        this.dialogModalRef = dialogModalRef;
        this.clientService = clientService;
        this.notifier = notifier;
        this.incidentId = data.incidentId;
        this.patientId = data.patientId;
    }
    IncidentCrossOutReasonComponent.prototype.ngOnInit = function () {
        this.getMasterData();
        this.reasonForm = new FormGroup({
            crossOutReasonId: new FormControl()
        });
    };
    IncidentCrossOutReasonComponent.prototype.getMasterData = function () {
        var _this = this;
        var data = "MEDICATION";
        this.clientService.getMasterData(data).subscribe(function (response) {
            if (response != null) {
                _this.masterCrossOutReasonlist = response.medicationsList.filter(function (s) { return s.type == "CrossOut Reason"; });
            }
        });
    };
    IncidentCrossOutReasonComponent.prototype.onSubmit = function (event) {
        var _this = this;
        if (!this.reasonForm.invalid) {
            this.submitted = true;
            this.crossOutReasonId = this.reasonForm.value.crossOutReasonId;
            // this.crossOutReasonModel.patientID=this.patientId;
            this.clientService.updateIncidentCrossOutReason(this.incidentId, this.crossOutReasonId)
                .subscribe(function (response) {
                if (response.statusCode == 200) {
                    var model = {
                        id: _this.incidentId
                    };
                    _this.clientService
                        .crossIncidentReport(model)
                        .subscribe(function (response) {
                        _this.submitted = false;
                        _this.closeDialog("close");
                    });
                    _this.notifier.notify("success", response.message);
                }
                else {
                    _this.submitted = false;
                    _this.notifier.notify("error", response.message);
                }
            });
        }
    };
    IncidentCrossOutReasonComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    return IncidentCrossOutReasonComponent;
}());
export { IncidentCrossOutReasonComponent };
