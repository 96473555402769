import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent, FooterComponent, SidebarComponent } from "./layout";
import { SharedService } from "./shared.service";
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatExpansionModule,
  MatTableModule,
  MatDialogModule,
  MatSortModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogRef,
  MatSelectModule,
  MatSlideToggle,
  MatSlideToggleModule,
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTabsModule,
  MatAutocompleteModule,
} from "@angular/material";
import { MenuListItemComponent } from "./layout/menu-list-item/menu-list-item.component";
import { DataTableComponent } from "./data-table/data-table.component";
import { DialogComponent } from "./layout/dialog/dialog.component";
import { NumbersOnlyDirective } from "./directives/numbers-only.directive";
import { DialogService } from "./layout/dialog/dialog.service";
import { StatusPipe } from "./pipes/status.pipe";
import { PhoneNumberDirective } from "./directives/phone-number.directive";
import { SsnDirective } from "./directives/ssn.directive";
import { ZipcodeDirective } from "./directives/zipcode.directive";
import { RateDirective } from "./directives/rate.directive";
import { MrnNumberDirective } from "./directives/mrn-number.directive";
import { SuperAdminHeaderComponent } from "./layout/super-admin-header.component";
import { ScrollbarModule } from "ngx-scrollbar";
import { LineChartComponent } from "./line-chart/line-chart.component";
import { ChartsModule } from "ng2-charts";
import { ClientHeaderLayoutComponent } from "./layout/client-header-layout/client-header-layout.component";
import { SpanPipe } from "./pipes/span.pipe";
import { PasswordValidator } from "./password-validator";
import { ChatWidgetComponent } from "./chat-widget/chat-widget.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleAddressInputComponent } from "./controls/google-address-input/google-address-input.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DicomViewerComponent } from "./dicom-viewer/dicom-viewer.component";
import { DICOMViewerComponent, DicomViewerModule } from "ng-dicomviewer";
import { CornerstoneDirective } from "./directives/cornerstone.directive";
import { SafePipe } from "./pipes/safe.pipe";
import { MatStepperModule } from "@angular/material/stepper";
import { AwesomeTooltipDirective } from "../platform/modules/agency-portal/clients/new-assessment/interRAI/tooltip/tooltip.directive";
import { AwesomeTooltipComponent } from "../platform/modules/agency-portal/clients/new-assessment/interRAI/tooltip/tooltip.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { TaskAcceptComponent } from "./layout/task-accept/task-accept.component";
import { TaskCompletedComponent } from "./layout/task-completed/task-completed.component";
import { GroupChatWidgetComponent } from "./group-chat-widget/group-chat-widget.component";
import { TermsConditionsComponent } from "./layout/terms-conditions/terms-conditions.component";
import { TermsConditionsReminderComponent } from "./layout/terms-conditions-reminder/terms-conditions-reminder.component";
import { ClockOutComponent } from "../platform/modules/agency-portal/clock-out/clock-out.component";
import { TermsAndConditionsComponent } from "../platform/modules/auth/terms-and-conditions/terms-and-conditions.component";
import { AcceptRejectReferalComponent } from "./layout/accept-reject-referal/accept-reject-referal.component";
import { OpenLabComponent } from "./layout/accept-reject-referal/open-lab/open-lab.component";
import { OpenReferralComponent } from "./layout/accept-reject-referal/open-referral/open-referral.component";
import { OpenDocumentComponent } from "./layout/accept-reject-referal/open-document/open-document.component";
import { CallNotificationComponent } from "./layout/call-notification/call-notification.component";
import { ConsentModalComponent } from "./layout/consent-modal/consent-modal.component";
import { SignatureDialogComponent } from "./layout/signature-dialog/signature-dialog.component";
import { SignaturePadModule } from "angular2-signaturepad";
import { SessionTimeoutComponent } from "./layout/session-timeout/session-timeout.component";
import { NotificationDetailsComponent } from "./layout/notification-details/notification-details.component";
import { SubscriptionExpireationComponent } from "../platform/modules/auth/subscription-notification/subscription-notification.component";
import { SubscriptionUserExpireationComponent } from "../platform/modules/auth/subscription-usernotification/subscription-usernotification.component";
import { SuperAdminPasswordChangeComponent } from "./layout/super-admin-password-change/super-admin-password-change.component";
import { FoodCalenderComponent } from "../platform/modules/agency-portal/meal-suite/food-calender/food-calender.component";
import { CalendarModule } from "angular-calendar";
import { CrossOutReasonComponent } from "../platform/modules/agency-portal/clients/point-of-care/mds-tracking/Adl-tracking-tool/cross-out-reason/cross-out-reason.component";
import { MabCrossOutReasonComponent } from "../platform/modules/agency-portal/clients/point-of-care/mds-tracking/mab-tracking-tool/mab-cross-out-reason/mab-cross-out-reason.component";
import { NursingCareFlowReasonComponent } from "../platform/modules/agency-portal/clients/point-of-care/nursing-care/nursing-care-flow-reason/nursing-care-flow-reason.component";
import { TarCrossOutReasonComponent } from "../platform/modules/agency-portal/clients/point-of-care/tar/tar-cross-out-reason/tar-cross-out-reason.component";
import { IncidentCrossOutReasonComponent } from "../platform/modules/agency-portal/clients/point-of-care/incident-report/incident-cross-out-reason/incident-cross-out-reason.component";
import { MultidisciplinaryCrossOutReasonComponent } from "../platform/modules/agency-portal/clients/progress-notes/multidisciplinary-cross-out-reason/multidisciplinary-cross-out-reason.component";
import { MomentModule } from "angular2-moment";
import { FoodDiariesCalendarComponent } from "../platform/modules/agency-portal/meal-management-home/food-diaries-calendar/food-diaries-calendar.component";
import { ImagePopupComponent } from "../platform/modules/agency-portal/meal-suite/image-popup/image-popup.component";

@NgModule({
  imports: [
    DicomViewerModule,
    CommonModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    ScrollbarModule,
    ChartsModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    OverlayModule,
    MatAutocompleteModule,
    SignaturePadModule,
    CalendarModule
  ],
  declarations: [
    CornerstoneDirective,
    HeaderComponent,
    SuperAdminHeaderComponent,
    FooterComponent,
    SidebarComponent,
    MenuListItemComponent,
    DataTableComponent,
    DialogComponent,
    NumbersOnlyDirective,
    StatusPipe,
    PhoneNumberDirective,
    SsnDirective,
    ZipcodeDirective,
    RateDirective,
    MrnNumberDirective,
    LineChartComponent,
    ClientHeaderLayoutComponent,
    SpanPipe,
    ChatWidgetComponent,
    GoogleAddressInputComponent,
    DicomViewerComponent,
    CornerstoneDirective,
    SafePipe,
    AwesomeTooltipDirective,
    AwesomeTooltipComponent,
    TaskAcceptComponent,
    TaskCompletedComponent,
    GroupChatWidgetComponent,
    TermsConditionsComponent,
    TermsConditionsReminderComponent,
    ClockOutComponent,
    TermsAndConditionsComponent,
    SubscriptionExpireationComponent,
    SubscriptionUserExpireationComponent,
    AcceptRejectReferalComponent,
    OpenLabComponent,
    OpenReferralComponent,
    OpenDocumentComponent,
    CallNotificationComponent,
    ConsentModalComponent,
    SignatureDialogComponent,
    SessionTimeoutComponent,
    NotificationDetailsComponent,
    SuperAdminPasswordChangeComponent,
    FoodCalenderComponent,
    CrossOutReasonComponent,
    MabCrossOutReasonComponent,
    NursingCareFlowReasonComponent,
    TarCrossOutReasonComponent,
    IncidentCrossOutReasonComponent,
    MultidisciplinaryCrossOutReasonComponent,
    FoodDiariesCalendarComponent,
    ImagePopupComponent
  ],
  providers: [SharedService, DialogService],
  entryComponents: [
    DialogComponent,
    ChatWidgetComponent,
    DicomViewerComponent,
    AwesomeTooltipComponent,
    TaskAcceptComponent,
    TaskCompletedComponent,
    GroupChatWidgetComponent,
    TermsConditionsComponent,
    TermsConditionsReminderComponent,
    ClockOutComponent,
    TermsAndConditionsComponent,
    SubscriptionExpireationComponent,
    SubscriptionUserExpireationComponent,
    AcceptRejectReferalComponent,
    OpenLabComponent,
    OpenReferralComponent,
    OpenDocumentComponent,
    CallNotificationComponent,
    ConsentModalComponent,
    SignatureDialogComponent,
    SessionTimeoutComponent,
    NotificationDetailsComponent,
    SuperAdminPasswordChangeComponent,
    CrossOutReasonComponent,
    MabCrossOutReasonComponent,
    NursingCareFlowReasonComponent,
    TarCrossOutReasonComponent,
    IncidentCrossOutReasonComponent,
    MultidisciplinaryCrossOutReasonComponent,
    ImagePopupComponent
  ],
  exports: [
    DicomViewerModule,
    CommonModule,
    MomentModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    HeaderComponent,
    SuperAdminHeaderComponent,
    FooterComponent,
    SidebarComponent,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    DataTableComponent,
    DialogComponent,
    NumbersOnlyDirective,
    StatusPipe,
    PhoneNumberDirective,
    ZipcodeDirective,
    SsnDirective,
    MrnNumberDirective,
    MatSelectModule,
    MatCheckboxModule,
    MatStepperModule,
    ChartsModule,
    LineChartComponent,
    ClientHeaderLayoutComponent,
    ChatWidgetComponent,
    GoogleAddressInputComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    DicomViewerComponent,
    SafePipe,
    GroupChatWidgetComponent,
    FoodCalenderComponent,
    FoodDiariesCalendarComponent,
    ImagePopupComponent
  ],
})
export class SharedModule {}
