import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/platform/modules/core/services';
import { ClientsService } from 'src/app/platform/modules/agency-portal/clients/clients.service';
import { OpenLabModel } from './open-lab.model';

@Component({
  selector: 'app-open-lab',
  templateUrl: './open-lab.component.html',
  styleUrls: ['./open-lab.component.css'],
  providers: [ClientsService]
})
export class OpenLabComponent implements OnInit {
  clientId: number;
  StaffId: number;
  openLabModel: OpenLabModel;
  submitted: boolean = false;
  LabForm: FormGroup;
  patientId: number;
  staffId: number;

  constructor(
    private dialogRef: MatDialogRef<OpenLabComponent>,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private clientService: ClientsService,
    private router: Router,
    private notifierService: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientsService: ClientsService) {
    this.openLabModel = new OpenLabModel();
    this.openLabModel = data;
    this.patientId = data.patientId;
    this.staffId = data.staffId;
  }

  ngOnInit() {
    var userInfo = this.commonService.getLoginUserInfo();

    //console.log(userInfo);   

    this.LabForm = this.formBuilder.group({
      id: [this.openLabModel.id],
      typeOfTest: [this.openLabModel.typeOfTest],
      orderDate: [this.openLabModel.orderDate],
      labName: [this.openLabModel.labName],
      physicianName: [this.openLabModel.physicianName]
    });
  }

  //get the form controls on html page
  get formControls() { return this.LabForm.controls; }
  save = 'save';

  closeDialog(action: string): void {
    this.dialogRef.close(action);
  }

  onSubmit(event: any) {
    if (!this.LabForm.invalid) {
      let clickType = event.currentTarget.name;
      this.submitted = true;
      this.openLabModel = this.LabForm.value;
      this.openLabModel.patientId = this.patientId;
      this.openLabModel.staffId = this.staffId;
      this.openLabModel.base64 = this.commonService.getFaxFileObj(this.data.faxFileBase64);
      this.clientService.createLabResult(this.openLabModel).subscribe((response: any) => {
        this.submitted = false;
        if (response.statusCode == 200) {
          this.notifier.notify('success', response.message)
          if (clickType == "Save")
            this.closeDialog('save');
        } else {
          this.notifier.notify('error', response.message)
        }
      });
    }
  }
}
