import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/platform/modules/core/services';
import { ClientsService } from 'src/app/platform/modules/agency-portal/clients/clients.service';
import { OpenReferralModel } from './open-referral.model';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
var OpenReferralComponent = /** @class */ (function () {
    function OpenReferralComponent(dialogRef, notifier, formBuilder, activatedRoute, commonService, clientService, router, notifierService, data, clientsService) {
        this.dialogRef = dialogRef;
        this.notifier = notifier;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.commonService = commonService;
        this.clientService = clientService;
        this.router = router;
        this.notifierService = notifierService;
        this.data = data;
        this.clientsService = clientsService;
        this.submitted = false;
        this.staffList = [];
        this.isloadingStaff = false;
        this.save = 'save';
        this.close = 'close';
        this.openReferralModel = new OpenReferralModel();
        this.openReferralModel = data;
        this.patientId = data.patientId;
        this.staffId = data.staffId;
    }
    OpenReferralComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userInfo = this.commonService.getLoginUserInfo();
        // console.log(userInfo);   
        this.ReferralForm = this.formBuilder.group({
            id: [this.openReferralModel.id],
            //typeOfTest: [this.openReferralModel.typeOfTest],		
            referredBy: [this.openReferralModel.referredBy],
            referredDate: [this.openReferralModel.referredDate],
            referredToId: [this.openReferralModel.referredToId]
            //	physicianName: [this.openReferralModel.physicianName]			
        });
        this.GetStaffList();
        this.filterStaffName = this.ReferralForm.get('referredToId').valueChanges.pipe(startWith(''), map(function (value) { return (typeof value === 'string' ? value : value.name); }), map(function (name) { return (name ? _this._filter(name) : _this.staffList.slice()); }));
    };
    OpenReferralComponent.prototype._filter = function (name) {
        var filterValue = name.toLowerCase();
        return this.staffList.filter(function (option) { return option.fullName.toLowerCase().includes(filterValue); });
    };
    Object.defineProperty(OpenReferralComponent.prototype, "formControls", {
        //get the form controls on html page
        get: function () { return this.ReferralForm.controls; },
        enumerable: true,
        configurable: true
    });
    OpenReferralComponent.prototype.closeDialog = function (action) {
        this.dialogRef.close(action);
    };
    OpenReferralComponent.prototype.onSubmit = function (event) {
        var _this = this;
        if (!this.ReferralForm.invalid) {
            var clickType_1 = event.currentTarget.name;
            this.submitted = true;
            var submittedData = this.ReferralForm.value;
            this.openReferralModel = this.ReferralForm.value;
            this.openReferralModel.referredToId = submittedData.referredToId.id;
            this.openReferralModel.patientId = this.patientId;
            this.openReferralModel.staffId = this.staffId;
            this.openReferralModel.base64 = this.commonService.getFaxFileObj(this.data.faxFileBase64);
            this.clientService.createReferralResult(this.openReferralModel).subscribe(function (response) {
                _this.submitted = false;
                if (response.statusCode == 200) {
                    _this.notifier.notify('success', response.message);
                    if (clickType_1 == "Save")
                        _this.closeDialog('save');
                }
                else {
                    _this.notifier.notify('error', response.message);
                }
            });
        }
    };
    OpenReferralComponent.prototype.GetStaffList = function () {
        var _this = this;
        // this.loadingMasterData = true;
        var masterData = { masterdata: "MASTERSTAFF" };
        this.clientService.getStaffList(masterData).subscribe(function (response) {
            // this.loadingMasterData = false;       
            _this.staffList = response.data || [];
        });
    };
    OpenReferralComponent.prototype.onReferredToSelectedName = function (event) {
        this.StaffId = event.option.value.id;
        this.staffId = event.option.value.id;
    };
    OpenReferralComponent.prototype.displayFnStaff = function (client) {
        return client ? client.fullName : undefined;
    };
    return OpenReferralComponent;
}());
export { OpenReferralComponent };
