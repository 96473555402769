import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from '../../platform/modules/core/services';
import { ChatHistoryModel } from '../../platform/modules/agency-portal/clients/profile/chat-history.model';
import { HubConnection } from "../../hubconnection.service";
import { ScrollbarComponent } from 'ngx-scrollbar';

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatWidgetComponent implements OnInit, AfterViewInit {
  @Input() fromUserId: number;
  @Input() toUserId: number;
  @Input() allMessageArray: Array<ChatHistoryModel>;
  @Input() imgSource: string = '';
  @Input() badge: number;
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() showCloseButton: boolean = false;
  @Input() autoFocus: boolean = true;
  message: string;
  hubConnection: HubConnection;
  @ViewChild("scrollbar", { static: false }) scrollbarRef: ScrollbarComponent;

  showChatModal: boolean;

  constructor() {
    this.showChatModal = false;
    this.message = '';
    this.hubConnection = new HubConnection();
  }

  onToggleChatModal() {
    this.showChatModal = !this.showChatModal;
  }

  ngOnInit() {
    this.createHubConnection();
  }

  createHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.createHubConnection(JSON.parse(localStorage.getItem('access_token')))
        .then(
          response => {
            this.hubConnection.getHubConnection().onclose(() => {
              this.ReconnectOnClose(this.fromUserId);
            });
            this.hubConnection.ConnectToServerWithUserId(this.fromUserId).then(res => {
              this.getMessageNotifications();
            });
          }
        )
    }
  }

  ngAfterViewInit() {
    // this.scrollbarRef.scrollState.subscribe(e => console.log(e))
  }
  sendMessage(event) {
    if (!this.message || !this.message.trim())
      return false;
    this.handleNewUserMessage(this.message);
    const messageObj: ChatHistoryModel = {
      message: this.message,
      isRecieved: false,
    }
    this.allMessageArray.push(messageObj);
    this.message = '';
  }
  handleNewUserMessage(message: string = '') {
    if (this.hubConnection.isConnected()) {
      this.hubConnection.getHubConnection()
        .invoke('SendMessage', message, this.fromUserId, this.toUserId)
        .catch((err) => console.error(err, 'ReceiveMessageReceiveMessageerror'));
      return message;
    } else {
      this.hubConnection.restartHubConnection().then(() => {
        this.hubConnection.getHubConnection()
          .invoke('SendMessage', message, this.fromUserId, this.toUserId)
          .catch((err) => console.error(err, 'ReceiveMessageReceiveMessageerror'));
        return message;
      });
    }
  }
  getMessageNotifications() {
    this.hubConnection.getHubConnection().on('ReceiveMessage', (result, fromUserId) => {
     // console.log('message from server', result, fromUserId, this.toUserId);
      if (fromUserId == this.toUserId) {
        const messageObj: ChatHistoryModel = {
          message: result,
          isRecieved: true,
        }
        this.allMessageArray.push(messageObj);
      }
    });
  }
  ReconnectOnClose(fromUserId) {
    setTimeout(() => {
      this.hubConnection.restartHubConnection().then(() => {
        this.hubConnection.ConnectToServerWithUserId(fromUserId).then(() => {
          // console.log('Restart Connection: user id sent to server : ' + fromUserId);
        });
      });
    }, 5000);
  }
}
