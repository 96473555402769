import { OnInit } from "@angular/core";
import { CommonService } from "../../core/services";
import { MatDialogRef } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { UsersService } from "../users/users.service";
var ClockOutComponent = /** @class */ (function () {
    function ClockOutComponent(notifier, dialogModalRef, commonService, usersService, data) {
        this.notifier = notifier;
        this.dialogModalRef = dialogModalRef;
        this.commonService = commonService;
        this.usersService = usersService;
        this.data = data;
        this.submitted = false;
        this.headerText = "";
        this.isClockIn = false;
        this.isClockIn = data.isClockIn;
        this.headerText = this.isClockIn ? "CLOCK IN" : "CLOCK OUT";
        this.btnText = this.isClockIn ? "Clocking In..." : "Clocking Out...";
        this.messageText = this.isClockIn
            ? "Are you sure you want to start your shift for the day?"
            : "Are you sure you want to end your shift for the day?";
    }
    ClockOutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.commonService.loginUser.subscribe(function (user) {
            if (user.data) {
                _this.userId = user.data.userID;
            }
        });
        this.getLocationDetails();
        this.getClientIPAddress();
    };
    ClockOutComponent.prototype.getLocationDetails = function () {
        var _this = this;
        this.usersService.GetLocationIpAddresses().subscribe(function (response) {
            if (response.statusCode == 200) {
                _this.userInfo = response.data;
            }
            else {
                _this.notifier.notify("warning", "Data Not Found");
            }
        });
    };
    ClockOutComponent.prototype.getClientIPAddress = function () {
        var _this = this;
        this.commonService.getClientIPAddress().subscribe(function (ip) {
            _this.ipAddress = ip.ip;
        });
    };
    ClockOutComponent.prototype.onSubmit = function () {
        var _this = this;
        var apiUrl = "";
        apiUrl = this.isClockIn
            ? "/Login/ClockInStatus"
            : "/Login/UpdateClockOut";
        if (this.userInfo) {
            this.isExist = this.userInfo.find(function (element) { return element.ipAddress == _this.ipAddress; });
            if (this.isExist) {
                this.commonService
                    .post(apiUrl, {})
                    .subscribe(function (response) {
                    if (response && response.statusCode == 200) {
                        _this.notifier.notify("success", response.message);
                        _this.closeDialog("save");
                        if (_this.isClockIn == false) {
                            _this.commonService.logout();
                            localStorage.removeItem("neverShowPopUp");
                            location.reload();
                        }
                    }
                });
            }
            else {
                this.notifier.notify("warning", "You don't have access to Clock In/Clock Out.");
                this.closeDialog('close');
            }
        }
        else {
            this.notifier.notify("warning", "You don't have access to Clock In/Clock Out.");
            this.closeDialog('close');
        }
    };
    ClockOutComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    ClockOutComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return ClockOutComponent;
}());
export { ClockOutComponent };
