import { OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { CommonService } from "src/app/platform/modules/core/services";
import { NotifierService } from "angular-notifier";
import { TaskNotification } from "../../models";
import { ClientsService } from "src/app/platform/modules/agency-portal/clients/clients.service";
var TaskAcceptComponent = /** @class */ (function () {
    function TaskAcceptComponent(data, commonService, dialogModalRef, notifier, clientService) {
        this.data = data;
        this.commonService = commonService;
        this.dialogModalRef = dialogModalRef;
        this.notifier = notifier;
        this.clientService = clientService;
        this.eventId = data;
        this.taskDetails = new TaskNotification();
    }
    TaskAcceptComponent.prototype.ngOnInit = function () {
        this.getTaskDetailsById(this.eventId);
    };
    TaskAcceptComponent.prototype.getTaskDetailsById = function (taskId) {
        var _this = this;
        this.clientService.getTaskDetailsById(taskId)
            .subscribe(function (response) {
            if (response &&
                response.data != undefined &&
                response.statusCode == 200) {
                _this.taskDetails = response.data.taskNotification[0];
            }
        });
    };
    TaskAcceptComponent.prototype.updateTask = function (key) {
        var _this = this;
        var statusId = 0;
        if (key === "ACCEPT") {
            statusId = 3;
        }
        else if (key === "DECLINE") {
            statusId = 6;
        }
        this.clientService.updateTask(this.eventId, statusId)
            .subscribe(function (response) {
            if (response && response.statusCode == 200) {
                _this.closeDialog("close");
                if ((response.data.taskStatusId = 3)) {
                    _this.notifier.notify("success", "Task Accepted");
                }
                else if ((response.data.taskStatusId = 6)) {
                    _this.notifier.notify("warning", "Task Declined");
                }
            }
        });
    };
    TaskAcceptComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    return TaskAcceptComponent;
}());
export { TaskAcceptComponent };
