import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var DialogComponent = /** @class */ (function () {
    function DialogComponent(dialogPopup, data) {
        this.dialogPopup = dialogPopup;
        this.data = data;
        this.message = '';
        this.actions = (data && data.length) ? data : deafultActions;
    }
    DialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dialogPopup.backdropClick().subscribe(function (result) {
            _this.dialogPopup.close();
        });
    };
    DialogComponent.prototype.handleClick = function (value) {
        this.dialogPopup.close(value);
    };
    return DialogComponent;
}());
export { DialogComponent };
var deafultActions = [{
        name: 'yes',
        value: true,
    }, {
        name: 'Cancel',
        value: false,
    }];
