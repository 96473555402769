import { OnInit, AfterViewInit } from '@angular/core';
import { HubConnection } from "../../hubconnection.service";
import { ScrollbarComponent } from 'ngx-scrollbar';
var ChatWidgetComponent = /** @class */ (function () {
    function ChatWidgetComponent() {
        this.imgSource = '';
        this.title = '';
        this.subTitle = '';
        this.showCloseButton = false;
        this.autoFocus = true;
        this.showChatModal = false;
        this.message = '';
        this.hubConnection = new HubConnection();
    }
    ChatWidgetComponent.prototype.onToggleChatModal = function () {
        this.showChatModal = !this.showChatModal;
    };
    ChatWidgetComponent.prototype.ngOnInit = function () {
        this.createHubConnection();
    };
    ChatWidgetComponent.prototype.createHubConnection = function () {
        var _this = this;
        if (this.hubConnection) {
            this.hubConnection.createHubConnection(JSON.parse(localStorage.getItem('access_token')))
                .then(function (response) {
                _this.hubConnection.getHubConnection().onclose(function () {
                    _this.ReconnectOnClose(_this.fromUserId);
                });
                _this.hubConnection.ConnectToServerWithUserId(_this.fromUserId).then(function (res) {
                    _this.getMessageNotifications();
                });
            });
        }
    };
    ChatWidgetComponent.prototype.ngAfterViewInit = function () {
        // this.scrollbarRef.scrollState.subscribe(e => console.log(e))
    };
    ChatWidgetComponent.prototype.sendMessage = function (event) {
        if (!this.message || !this.message.trim())
            return false;
        this.handleNewUserMessage(this.message);
        var messageObj = {
            message: this.message,
            isRecieved: false,
        };
        this.allMessageArray.push(messageObj);
        this.message = '';
    };
    ChatWidgetComponent.prototype.handleNewUserMessage = function (message) {
        var _this = this;
        if (message === void 0) { message = ''; }
        if (this.hubConnection.isConnected()) {
            this.hubConnection.getHubConnection()
                .invoke('SendMessage', message, this.fromUserId, this.toUserId)
                .catch(function (err) { return console.error(err, 'ReceiveMessageReceiveMessageerror'); });
            return message;
        }
        else {
            this.hubConnection.restartHubConnection().then(function () {
                _this.hubConnection.getHubConnection()
                    .invoke('SendMessage', message, _this.fromUserId, _this.toUserId)
                    .catch(function (err) { return console.error(err, 'ReceiveMessageReceiveMessageerror'); });
                return message;
            });
        }
    };
    ChatWidgetComponent.prototype.getMessageNotifications = function () {
        var _this = this;
        this.hubConnection.getHubConnection().on('ReceiveMessage', function (result, fromUserId) {
            // console.log('message from server', result, fromUserId, this.toUserId);
            if (fromUserId == _this.toUserId) {
                var messageObj = {
                    message: result,
                    isRecieved: true,
                };
                _this.allMessageArray.push(messageObj);
            }
        });
    };
    ChatWidgetComponent.prototype.ReconnectOnClose = function (fromUserId) {
        var _this = this;
        setTimeout(function () {
            _this.hubConnection.restartHubConnection().then(function () {
                _this.hubConnection.ConnectToServerWithUserId(fromUserId).then(function () {
                    // console.log('Restart Connection: user id sent to server : ' + fromUserId);
                });
            });
        }, 5000);
    };
    return ChatWidgetComponent;
}());
export { ChatWidgetComponent };
