import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { Subscription } from "rxjs";
import { PasswordPolicyModel } from "src/app/super-admin-portal/add-password-policy/password-policy.model";
import { ResponseModel } from "src/app/super-admin-portal/core/modals/common-model";
import { LoginUser } from "src/app/super-admin-portal/core/modals/loginUser.modal";
import { CommonService } from "src/app/super-admin-portal/core/services";
import { SuperAdminPasswordChangeService } from "./superadmin-change-password.service";

@Component({
  selector: "app-super-admin-password-change",
  templateUrl: "./super-admin-password-change.component.html",
  styleUrls: ["./super-admin-password-change.component.css"],
  providers: [SuperAdminPasswordChangeService],
})
export class SuperAdminPasswordChangeComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted: boolean = false;
  subscription: Subscription;
  userId: number;
  passwordModel: PasswordPolicyModel;
  strongPassword: RegExp;
  errorMessage: string;
  constructor(
    private notifier: NotifierService,
    private dialogModalRef: MatDialogRef<SuperAdminPasswordChangeComponent>,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private changePasswordService: SuperAdminPasswordChangeService
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group(
      {
        currentPassword: [],
        newPassword: [],
        confirmNewPassword: [],
      },
      { validator: this.validateForm.bind(this) }
    );

    this.subscription = this.commonService.loginUser.subscribe(
      (user: LoginUser) => {
        if (user.data) {
          this.userId = user.data.userID;
        }
      }
    );

    this.getPasswordPolicy();
  }
  get formControls() {
    return this.changePasswordForm.controls;
  }
  validateForm(formGroup: FormGroup) {
    let pass = formGroup.controls.newPassword.value;
    let confirmPass = formGroup.controls.confirmNewPassword.value;

    if (confirmPass == undefined || !confirmPass.length) return null;

    return pass && confirmPass && pass === confirmPass
      ? null
      : formGroup.controls.confirmNewPassword.setErrors({ notSame: true });
  }

  onSubmit() {
    if (!this.changePasswordForm.invalid) {
      let data = this.changePasswordForm.value;
      data.userID = this.userId;
      this.submitted = true;
      this.changePasswordService
        .updateNewPassword(data)
        .subscribe((response: ResponseModel) => {
          this.submitted = false;
          if (response.statusCode == 200) {
            this.notifier.notify("success", response.message);
            this.closeDialog("save");
          } else this.notifier.notify("error", response.message);
        });
    }
  }
  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }

  getPasswordPolicy() {
    this.changePasswordService
      .getPasswordPolicyByToken()
      .subscribe((response: any) => {
        this.submitted = false;
        if (response.statusCode == 200) {
          this.passwordModel = new PasswordPolicyModel();
          this.passwordModel = response.data;
          let regex = this.passwordModel.regularExpression;
          this.strongPassword = new RegExp(regex);
          console.log("Generated Regx", this.strongPassword);

          this.errorMessage = "Password must contain";
          if (this.passwordModel.isNumber) {
            this.errorMessage = this.errorMessage + " number,";
          }
          if (this.passwordModel.isLowerCase) {
            this.errorMessage = this.errorMessage + " lower case,";
          }
          if (this.passwordModel.isUpperCase) {
            this.errorMessage = this.errorMessage + " upper case,";
          }
          if (this.passwordModel.isSpecialCharacters) {
            this.errorMessage =
              this.errorMessage + " special character (! @ # $ % ^ & *),";
          }
          if (this.passwordModel.minimumLength > 0) {
            this.errorMessage =
              this.errorMessage +
              " minimum length " +
              this.passwordModel.minimumLength +
              ",";
          }
          if (this.passwordModel.maximumLength > 0) {
            this.errorMessage =
              this.errorMessage +
              " maximum length " +
              this.passwordModel.maximumLength +
              ".";
          }
        }
      });
  }
}
