import { Injectable } from "@angular/core";
import { CommonService } from "../../core/services";
import {
  ClientModel,
  AddUserDocumentModel,
  SocialHistoryModel,
} from "./client.model";
import { GuardianModel } from "./guardian.model";
import { AddressModel } from "./address.model";
import { DiagnosisModel } from "./diagnosis/diagnosis.model";
import { PatientInsuranceModel } from "./insurance.model";
import { ClientCustomLabelModel } from "./clientCustomLabel.model";
import { PatientMedicalFamilyHistoryModel } from "./family-history/family-history.model";
import { ImmunizationModel } from "./immunization/immunization.model";
import {
  FilterModel,
  InventoryFilterModel,
  PatientPreferenceFilterModel,
  MonthFilterModel,
  EmarFilterModel,
  TrackingFilterModel,
  InventoryListFilterModel,
  InventoryTrackFilterModel,
  ReorderTrackFilterModel,
  ADTPatientFilterModel,
} from "../../core/modals/common-model";
import {
  VitalModel,
  VitalModelAdd,
  CardiovascularModel,
} from "./vitals/vitals.model";
import { AllergyModel, AllergyCheckModel } from "./allergies/allergies.model";
import { AuthModel } from "./authorization/authorization.model";
import { MedicationModel } from "./medication/medication.model";
import { ClientLedgerPaymentDetailsModel } from "./client-ledger/client-ledger.model";
import { InventoryModel } from "./personal-inventory/personalinventory.model";
import { PersonalInventoryModel } from "./personal-inventory/personalinventorymodal.model";
import { HospitalizationHistoryModel } from "./hospitlization-history/hospitlization-history.model";
import { SurgicalHistoryModel } from "./surgical-history/surgical-history.model";
import { ConsentsPreferenceModel } from "./consents-preference/consents-preference.model";
import { PatientHistoryModel, PatientImmunizationV1 } from "./history/history-model";
import { of, Observable } from "rxjs";
import { CarePlanGoalModel } from "./care-plan/care-plan-goal/carePlanGoal.model";
import { CreateNewNotesModel } from "./progress-notes/create-new-notes/create-new-notes.model";
import { PatientsMDSAdmissionModel } from "./patient-admission/patient-admission.model";
import { CommuinicationModel } from "./care-plan/care-plan-list/communication-careplan/communication.model";
import { eRxmodel } from "./e-rx/current-medication/add-new-e-rx/add-new-e-rx.model";
import {
  pRxmodel,
  PatientsMedicationModel,
} from "./e-rx/current-medication/new-p-rx/new-p-rx.model";
import { AdlTrackingToolModel } from "./point-of-care/mds-tracking/Adl-tracking-tool/adl-tracking-tool-model";
import { CareSummaryModel } from "./progress-notes/care-summry-modal/care-summary-modal.model";
import { routinesCarePlanModel } from "./care-plan/care-plan-list/routine-careplan/routine-careplan-model";
import { CognitiveCarePlanModel } from "./care-plan/care-plan-list/cognitive-status-careplan/cognitive-status-careplan-model";
import { BehaviorsCarePlanModel } from "./care-plan/care-plan-list/behaviors-careplan/behaviors-careplan-model";
import { DressingCarePlanModel } from "./care-plan/care-plan-list/dressing-careplan/dressing-careplan-model";
import { MoodAndBehaviorModel } from "./point-of-care/mds-tracking/mab-tracking-tool/mood-and-behaviour-model";
import { EsasRatingModel } from "./point-of-care/edmonton-symptom/esas-ratings/esas-rating-model";
//import { SafetyCarePlanModel } from './care-plan/care-plan-list/safety-careplan/dressing-careplan-model';
import { SafetyCarePlanModel } from "./care-plan/care-plan-list/safety-careplan/safety-careplan-model";
import { BathingCarePlanModel } from "./care-plan/care-plan-list/bathing-careplan/bathing-careplan-model";
import { NutritionCarePlanModel } from "./care-plan/care-plan-list/nutrition-hydration-careplan/nutrition-careplan-model";
import { HygieneCarePlanModel } from "./care-plan/care-plan-list/hygiene-careplan/hygiene-careplan-model";
import { SkinCarePlanModel } from "./care-plan/care-plan-list/skin-careplan/skin-careplan-model";
import { MobilityCarePlanModel } from "./care-plan/care-plan-list/mobility-careplan/mobility-careplan-model";
import { TransferCarePlanModel } from "./care-plan/care-plan-list/transfer-careplan/transfer-careplan-model";
import { ToiletingCarePlanModel } from "./care-plan/care-plan-list/toileting-careplan/toileting-careplan-model";
import { BladderCarePlanModel } from "./care-plan/care-plan-list/bladder-continence-careplan/bladder-careplan-model";
import { SafetyAndSecurityModel } from "./point-of-care/nursing-care/safety-and-security/safetyandsecurity-model";
import { PersonalHygieneModel } from "./point-of-care/nursing-care/personal-hygiene/personal-hygiene-model";
import { ActivityAndExerciseModel } from "./point-of-care/nursing-care/activity-and-exercise/activity-and-exercise-model";
import { SleepRestComfortModel } from "./point-of-care/nursing-care/sleep-rest-comfort/sleep-rest-comfort.model";
import { BowelCarePlanModel } from "./care-plan/care-plan-list/bowel-continence-careplan/bowel-careplan-model";
import { NutritionAndHydrationModel } from "./point-of-care/nursing-care/nutrition-and-hydration/nutrition-and-hydration.model";
import { RefModel } from "../refference/outgoing-referal/add-referal/add-referral.model";
import { MasterInventoryModel } from "../add-inventory/master-inventory.model";
import { StockOutModel } from "../inventory-list/stock-out/stock-out.model";
import { ReorderModel } from "../inventory-list/reorder-inventory/reorder.model";
import {
  emrPopupModel,
  emrModel,
} from "./emr/emr-schedule/emr.component.model";
import {
  MedicationFilterModel,
  ProgressNoteFilterModel,
  FilterModelMedicationPassed,
  FilterNewModelReminder,
  IncidentReportFilter,
  FilterModelTimeLineSummary,
} from "src/app/super-admin-portal/core/modals/common-model";
import { TARModel } from "./point-of-care/tar/create-new/TAR-model";
import { UnitMasterModel } from "../masters/unit-master/unitmaster.model";
import { IncidentReportModel } from "./point-of-care/incident-report/incident-report.model";
import { foodDiaryModel } from "../meal-suite/food-diary/food-diary.component.model";
import { CareProviderModel } from "../../client-portal/care-provider/care-provider.model";
import { ServiceFeedBackModel } from "../../client-portal/service-feedback/add-edit-complain/service-feedback.model";
import {
  PatientsTagModel,
  PatientsTagModel2,
} from "../../client-portal/patient-profile/patient-tag.model";
import { PatientsNotesModel } from "../../client-portal/patient-profile/patient-notes.model";
import { LocalResourcesModel } from "../masters/local-resources/localresources.model";
import { DementiaModel } from "./vital-card/cqm/cqm-add-dementia/dementia.model";
import { HeartFailModel } from "./vital-card/cqm/cqm-add-heart-fail/heart-fail.model";
import { CareCoordinatorModel } from "../masters/care-coordinator/carecoordinator.model";
import { AppointmentModel } from "../../scheduling/scheduler/scheduler.model";
import { PatientAppointmentModel } from "./patient-appointment/patient-appointment.model";
import { OpenLabModel } from "src/app/shared/layout/accept-reject-referal/open-lab/open-lab.model";
import { OpenReferralModel } from "src/app/shared/layout/accept-reject-referal/open-referral/open-referral.model";
import { OpenDocumentModel } from "src/app/shared/layout/accept-reject-referal/open-document/open-document.model";
import { MealManagmentModel } from "../meal-managment/add-meal-plan/meal-managment.model";
import { ManageTagsModel } from "../masters/manage-tags/managetags.model";
import { TaxMastersModel } from "../masters/tax-master/taxmaster.model";
import { DiscountMastersModel } from "../masters/discount-master/discountmaster.model";
import { SubscriptionPaymentModel } from "../masters/subscription-payment-details/subscriptionpayment.model";
import { IncomingFaxDocumentModel } from "../notifications/incoming-fax-notification/incoming-fax-document.model";
import { ColorModel } from "../masters/color/edit-color-modal/color.model";
import { PatientReportingModel } from "./patient-reporting/patient-reporting.model";
import { AddAdmissionModel } from "./adt-admission/new-adt-admission/add-admission.model";
import { AddDischargeModel } from "./adt-discharge/new-adt-discharge/add-discharge.model";

//import { AppointmentModel } from '../../scheduling/scheduler/add-appointment/appointment.model';

@Injectable({
  providedIn: "root",
})
export class ClientsService {
  private getPatientFamilyEducation = "Drugs/GetAllPatientDrugFamilyEducation";
  private GetStaffAvailabilityMobileURL = "AvailabilityTemplates/GetStaffAllAvailabilityMobile";
  private GetOpenShiftURL = "AvailabilityTemplates/GetOpenShifts";
  private GetStaffAvailabilityURL = "AvailabilityTemplates/GetStaffAvailability";
  private GetStaffUnAvailabilityURL = "AvailabilityTemplates/GetStaffUnAvailability";
  private approveStaffAvailabilityURL = "AvailabilityTemplates/ApproveStaffAvailability";
  private approveStaffUnAvailabilityURL = "AvailabilityTemplates/ApproveStaffUnAvailability";
  private getMasterDataByNameURL = "api/MasterData/MasterDataByName";
  private getMasterInventoryTypeDataURL =
    "api/MasterData/GetMasterInventoryType";
  private getMasterSuppliesDataURL = "api/MasterData/GetMasterSuppliesData";
  private getMasterSuppliesMedicalDataURL =
    "api/MasterData/GetMasterSuppliesById";
  private getMasterSuppliesMedicationsDataURL =
    "api/MasterData/GetMasterSuppliesmedicalById";
  private getMasterSuppliesMedicationsListDataURL =
    "api/MasterData/GetMasterSuppliesmedicationsById";
  private createURL = "Patients/CreateUpdatePatient";
  private getClientByIdURL = "Patients/GetPatientById";
  private getPatientsTagsByIdURL = "Patients/GetPatientTagData";
  private getPatientsNotesByIdURL = "Patients/GetPatientNotesData";
  private getFollowUpByClientIdURL = "Patients/GetFollowUpAppointmentDetails";
  private getClientHeaderInfoURL = "Patients/GetPatientHeaderInfo";
  private getClientProfileInfoURL = "Patients/GetPatientById";
  private getpatientdetailsurl = "Patients/GetPatientsDetails";
  private getCheckHealthCardPatientUrl = "Patients/CheckHealthCardPatient";
  private getPatientHistoryInfoUrl = "PatientHistory/GetPatientHistory";
  private updateClientStatusURL = "patients/UpdatePatientActiveStatus";
  private updateUserStatusURL = "user/UpdateUserStatus";
  private getPatientCCDAURL = "patients/GetPatientCCDA";
  private getPatientImmunizationURL = "patients/PatientImmunization";
  private savetPatientImmunizationURL = "patients/SavePatientImmunization";
  private deletePatientImmunizationURL = "patients/DeletePatientImmunization";

  private unblockResident = "ConcentPolicy/UnblockPatient";
  private updatePatientPortalVisibilityURL =
    "patients/UpdatePatientPortalVisibility";

  private getEMRPatientMedicationPassProgressListURL =
    "PatientsMedication/GetEMRPatientMedicationPassProgressList";

  private getEMRPatientMissedMedicationListURL =
    "PatientsMedication/GetEMRPatientMissedMedicatioList";

  private getEMRPatientReminderListURL =
    "PatientsMedication/GetAllReminderList";

  private getEMRPatientPRNAdministeredListURL =
    "PatientsMedication/GetEMRPatientPRNAdministeredList";

  private getEMRPatientMedicationDiscontinueOrderListURL =
    "PatientsMedication/GetEMRPatientMedicationListDiscontinueOrder";

  private getEMRPatientMedicationIncidentReportLogListURL =
    "PatientsMedication/GetMedicationIncidentReportLog";

  private getEMRMedicationReorderListURL =
    "PatientsMedication/GetAllMedicationReorderList";

  private createUpdateNurseOrderURL = "Plan/CreateUpdateNurseOrder";

  private createPatientHistoryUrl = "PatientHistory/SavePatientHistory";

  private deleteReferralURL = "PatientReferral/DeleteReferral";
  private getReferralByIdURL = "PatientReferral/GetPatientReferalDataById";

  //Get Availbale Time for Patient's EMAR list
  private getStaffAvailableTimeURL =
    "AvailabilityTemplates/GetStaffAvailableTime";

  //Point of care
  private createUpdateAdlTrackingToolUrl =
    "PointOfCare/SaveUpdateADLTrackingTool";
    private ADLTrackingToolIsCrossURL = "NoticeBoard/ADLTrackingToolIsCross";
    private updateAdlTrackingToolCrossOutReasonUrl =
    "PointOfCare/UpdateAdlTrackingToolCrossOutReason";
  private SaveUpdateMoodAndBehaviorUrl =
    "PointOfCare/SaveUpdateMoodAndBehavior";
  private updateMabTrackingToolCrossOutReasonUrl="PointOfCare/UpdateMabCrossOutReason";
  private BehaviorTrackingToolCrossURL = "NoticeBoard/BehaviorTrackingToolCross";
  private getAdlTrackingToolUrl = "PointOfCare/GetAllADLTrackingTool";
  private deleteAdlTrackingToolUrl = "PointOfCare/DeleteADLTrackingToolById";
  private cdssFallMessage = "SafetyAndSecurity/GetCDSSMessageForFall";
  private cdssPressureUlcerMessageURL =
    "SafetyAndSecurity/GetCDSSMessageForPressureUlcerNew";
  private cdssBehaviorMessageURL =
    "SafetyAndSecurity/GetCDSSMessageForBehavior";
  private cdssActivityMessageURL =
    "SafetyAndSecurity/GetCDSSMessageForActivity";
  private cdssNewPainMessageURL = "SafetyAndSecurity/GetCDSSMessageForNewPain";
  private cdsOngoingPainMessageURL =
    "SafetyAndSecurity/GetCDSSMessageForOngoingPain";
  private cdssPressureUlcerOngoingMessageURL =
    "SafetyAndSecurity/GetCDSSMessageForPressureUlcerOngoing";
  private getAdlTrackingToolUrlByPatientId =
    "PointOfCare/GetADLTrackingToolByPatientId";
  private getStatusOfPOCUrlByPatientId =
    "PersonalHygiene/GetNCFIsDraftStatusMobile";
  private getESASRatingGraphDataUrl = "ESASRatings/GetESASRatingsByPatientId";
  private GetViewESASRatingsByPatientId =
    "ESASRatings/GetViewESASRatingsByPatientId";
  private getESASRatingCurrentDayGraphDataUrl =
    "ESASRatings/GetESASCurrentDateDays";
  private createsafetyAndSecurityNursingFlowUrl =
    "SafetyAndSecurity/SaveUpdateSafetyAndSecurity";
  private updateNurisngCareFlowCrossOutReasonUrl =
    "SafetyAndSecurity/UpdateNurisngCareFlowCrossOutReason";
  private PreviousNursingCareFlowIsCrossURL = "NoticeBoard/PreviousNursingCareFlowIsCross";
  private getSafetyAndSecurityByPatientIdUrl =
    "SafetyAndSecurity/GetSafetyAndSecurityByPatientId";
  private getMoodAndBehaviorByPatientIdUrl =
    "PointOfCare/GetMoodAndBehaviorByPatientId";
  private DiscardMoodAndBehaviorByIdURL =
    "PointOfCare/DiscardMoodAndBehaviorById";
  // private getESASRatingsUrlByPatientId =
  //   "ESASRatings/GetESASRatingsByPatientId";
  private getESASRatingsUrlByPatientId =
    "ESASRatings/GetESASRatingsByPatientId";

  private getViewESASRatingsUrlByPatientId =
    "ESASRatings/GetViewESASRatingsByPatientId";

  private getPatientLocationByPatientId = "MasterShift/GetPatientLocationById";
  private getPatientUnitByPatientId = "MasterShift/GetPatientUnitById";
  private GetESASRatingsByPatientIdAsDraft =
    "ESASRatings/GetESASRatingsByPatientIdAsDraft";
  private SaveUpdateESASRatingsUrl = "ESASRatings/SaveUpdateESASRatings";
  private saveUpdateAsDraftUrl = "ESASRatings/SaveUpdateESASRatingsSaveAsDraft";
  private DeleteESASRatingsByIdUrl = "ESASRatings/DeleteESASRatingsById";
  private createPersonalHygieneNursingFlowUrl =
    "PersonalHygiene/SaveUpdatePersonalHygiene";
  private getPersonalHygieneNursingCareFlowUrl =
    "PersonalHygiene/GetPersonalHygieneByPatientId";

  private createActivityExersiceNursingFlowUrl =
    "ActivityExercise/SaveUpdateActivityExercise";
  private getActivityExersiceNursingFlowUrlByPatientId =
    "ActivityExercise/GetActivityExerciseByPatientId";
  private createSleepAndComfortNursingFlowUrl =
    "SleepRestAndComfort/SaveUpdateSleepRestAndComfort";
  private createUpdateIncidentReportUrl =
    "IncidentReport/SaveUpdateIncidentReport";
  private updateIncidentCrossOutReasonUrl="IncidentReport/UpdateIncidentCrossOutReason";
  private getSleepAndRestByPatientIdUrl =
    "SleepRestAndComfort/GetSleepRestAndComfortByPatientId";
  private SaveUpdateNutritionAndHydrationURL =
    "NutritionAndHydration/SaveUpdateNutritionAndHydration";
  private getNutritionAndHydrationNursingCareFlowUrl =
    "NutritionAndHydration/GetNutritionAndHydrationSaveAsDraft";
  //Eligibility Enquiry Methods
  private getPayerByPatientURL = "GetPayerByPatient";
  private getEligibilityEnquiryServiceCodesURL =
    "EligibilityCheck/GetEligibilityEnquiryServiceCodes";
  private download270URL = "EligibilityCheck/Download270";

  //Allergy URL
  private createAllergyURL = "PatientsAllergy/SaveAllergy";
  private createCheckAllergyURL = "PatientsAllergy/SaveAllergyCheck";
  private getAllergyListURL = "PatientsAllergy/GetAllergies";

  private getAllergyCheckListURL = "PatientsAllergy/GetAllergiesCheck";
  private getAllergyByIdURL = "PatientsAllergy/GetAllergyById";
  private deleteAllergyURL = "PatientsAllergy/DeleteAllergy";

  //mastershift
  private getMasterShiftDataURL = "MasterShift/GetShiftByLocationIds";
  private getMasterShiftDataByUnitURL = "MasterShift/GetShiftByUnitId";
  private getStaffShiftDataByUnitURL = "MasterShift/GetStaffShiftByUnitId";

  private GetShiftByUnitIdDataURL = "MasterShift/GetShiftByUnitId";

  // Inventory URL
  private getpersonalinventoryURL = "PersonalInventory/GetPersonalInventory";

  private getPersonalInventoryByIdURL =
    "PersonalInventory/GetPersonalInventoryById";

  private createInventoryURL = "PatientsAllergy/SaveInventory";
  private createPersonalnventoryURL = "PersonalInventory/SavePersonalInventory";

  private createUnitMasterURL = "AdminDashboard/SaveUnitMaster";

  private createLabResultURL = "PatientReferral/SaveLabResultRecord";
  private createDocumentResultURL = "PatientReferral/SaveDocumentResultRecord";

  private createReferralResultURL = "PatientReferral/SaveReferralResultRecord";

  private createLocalResourcesMasterURL = "Patients/SaveLocalResourcesData";

  private createCareCoordinatorMasterURL = "Patients/SaveCareCoordinatorData";
  private createPatientCareProviderURL = "Patients/SavePatientCareProviderData";
  private createPatientTagURL = "Patients/SavePatientTagData";
  private createPatientNotesURL = "Patients/SavePatientNotesData";

  private createManageTagsURL = "MasterTag/SaveMasterTag";
  private createMasterTaxURL = "MasterTaxDetail/SaveMasterTaxDetails";
  private subscriptionDetailsURL =
    "SubscriptionDetails/SaveUpdateSubscriptionsPaymentDetails";
  private createMasterDiscountURL =
    "MasterDiscountDetail/SaveMasterDiscountDetails";
  private createPatientAppointmentURL = "Patients/SavePatientsAppointmentsData";

  //Authorization
  private getAllAuthorizationsForPatientURL =
    "Authorizations/GetAllAuthorizationsForPatient";
  private getAuthorizationByIdURL = "Authorizations/GetAuthorizationById";
  private deleteAuthorizationURL = "Authorizations/DeleteAutorization";
  private getPatientPayerServiceCodesAndModifiersURL =
    "patients/GetPatientPayerServiceCodesAndModifiers";
  private createAuthorizationURL = "Authorizations/SaveAuthorization";
  //referURL
  private createReferURL = "PatientReferral/SavePatientReferalData";
  private createIncomingReferURL =
    "PatientReferral/SavePatientIncomingReferalData";

  private createMealPlanURL = "MealPlan/SaveUpdateMealPlan";

  private createMealCategoryURL = "MealPlan/SaveUpdateMealCategory";

  private createMealItemURL = "MealPlan/SaveUpdateMealItem";

  private createPatientTransferURL = "Patients/SavePatientTransferData";

  private createIncidentLogReportURL =
    "PatientsMedication/SaveMedicationIncidentLog";
  //ShiftById

  private getshiftbyId = "MasterShift/GetShiftWithShiftId";
  //Vitals URL
  private createVitalURL = "PatientsVitals/SaveVital";
  private createTARUrl = "TAR/CreateTAR";
  private updateTarCrossOutReasonUrl="TAR/UpdateTarCrossOutReason";
  private createPatientServiceFeedBackURL =
    "Patients/SavePatientServiceFeedBack";

  private getVitalListURL = "PatientsVitals/GetVitals";
  private getVitalGraphURL = "PatientsVitals/GetVitalsGraphData";
  private getVitalByIdURL = "PatientsVitals/GetVitalById";
  private deleteVitalURL = "PatientsVitals/DeleteVital";
  private getLatestVitalByIdURL = "PatientsVitals/GetLatestVitalById";
  //Lab Documents URl
  private getLabDocumentURL =
    "PatientResultLabDocument/GetPatientResultLabDocument";
  private getRadiologyReportURL =
    "PatientResultRadiologyReport/GetPatientResultRadiologyReportDocument";
  private getRadiologyDicomURL =
    "PatientResultRadiologyDicom/GetPatientResultRadiologyDicomDocument";
  private getCardiacDocumentURL =
    "PatientResultCardiacDocument/GetPatientCardiacDocument";
  private getLabsResultDocumentURL =
    "PatientResultCardiacDocument/GetPatientLabsResultDocument";

  private getPathologyDocumentURL =
    "PatientResultPathology/GetPatientResultPathologyDocument";
  private getOtherDocumentURL =
    "PatientResultOtherDocument/GetPatientResultOtherDocument";
  private deleteRadiologyDocumentURl =
    "PatientResultRadiologyReport/DeleteRadiologyReportDocument";
  private deleteRadiologyDicomURl =
    "PatientResultRadiologyDicom/DeleteRadiologyDicomDocument";
  private deletePathologyDocumentURl =
    "PatientResultPathology/DeleteResultPathologyDocument";
  private deleteCardiacDocumentURl =
    "PatientResultCardiacDocument/DeleteResultCardiacDocument";
  private deletePatientLabDocumentURl =
    "PatientResultLabDocument/DeleteResultLabDocument";
  private deletePatientOtherDocumentURl =
    "PatientResultOtherDocument/DeleteResultOtherDocument";
  //CarePlanGoalURL
  private getCarePlanGoalByIdURL = "CarePlanGoalController/GetCarePlanGoalById";
  private getCarePlanGoalByGoalIdURL = "CarePlanGoalController/GetCarePlanGoalByGoalId";
  private deleteCarePlanGoalByGoalIdURL = "CarePlanGoalController/DeleteCarePlanGoal";
  private createCarePlanGoalURl =
    "CarePlanGoalController/CreateUpdateCarePlanGoal";
  //Immunization URL
  private createImmunizationURL =
    "PatientsImmunization/SavePatientImmunization";
  private getImmunizationListURL = "PatientsImmunization/GetImmunization";
  private getImmunizationByIdURL = "PatientsImmunization/GetImmunizationById";
  private deleteImmunizationURL = "PatientsImmunization/DeleteImmunization";

  private deleteCurrentmedicationURL = "Plan/DeleteCurrentmedication";

  //OverCounterMedication URL
  private createSaveOverCounterMedicationURL =
    "PatientsMedication/SaveOverCounterMedication";
  private createSaveHerbalSupplementURL =
    "PatientsMedication/SaveHerbalSupplement";
  private getOverCounterMedicationURL =
    "PatientsMedication/GetOverCounterMedication";
  private getMedicationNameListURL = "PatientsMedication/GetMedicationNameList";

  private getLevelOfEducationListURL = "Patients/GetLevelofEducationList";
  private getAllergyTypeListtURL = "PatientsAllergy/GetAllergyTypeList";
  private getAllernListtURL = "PatientsAllergy/GetAllergyTypeList";
  private getDiagnosisListtURL = "PatientsDiagnosis/GetDiagnosisTypeList";
  private getSurgeryListURL = "PatientsDiagnosis/GetSurgeryList";
  // private getAllernListtURL = "PatientsAllergy/GetAllergenList";
  private getSeverityListtURL = "PatientsAllergy/GetSeverityList";
  private getAllergyStatusListURL = "PatientsAllergy/GetAllergyStatusList";

  private getMedicationListbyPatientIdURL = "PatientsMedication/GetMedication";

  private getHerbalSupplementURL = "PatientsMedication/GetHerbalSupplement";
  // private getMedicationByIdURL = "PatientsMedication/GetMedicationById";
  //private deleteMedicationURL = "PatientsMedication/DeleteMedication";

  //Medication URL
  private createMedicationURL = "PatientsMedication/SaveMedication";
  private createEmarMedicationURL = "PatientsMedication/SaveEmarMedication";
  private geteEmarMedicationURL = "PatientsMedication/GetEmarMedication";

  private geteMealPlanPopUpDataURL = "MealPlan/GetAllMealPlanListPopUpData";

  private createUpdateERXURL = "Plan/CreateUpdateERx";

  private getMedicationListURL = "PatientsMedication/GetMedication";
  private GetMedicationNamesListURL = "PatientsMedication/GetMedicationNames";
  private getMedicationByIdURL = "PatientsMedication/GetMedicationById";
  private deleteMedicationURL = "PatientsMedication/DeleteMedication";

  private getherbalMedicationByIdURL =
    "PatientsMedication/GetHerbalMedicationById";
  private deleteherbalMedicationURL =
    "PatientsMedication/DeleteHerbalMedication";

  private getoverMedicationByIdURL = "PatientsMedication/GetOverMedicationById";
  private deleteoverMedicationURL = "PatientsMedication/DeleteOverMedication";

  private getTreatmentTimeLineURL = "Patients/GetPatientTeatmentTimelineById";
  private getPatientMedicationListURL =
    "PatientsMedication/GetMedicationByPatientId";

  private getEMRPatientListURL = "PatientsMedication/GetEMRPatientList";
  private getEMRPatientDateListURL =
    "PatientsMedication/GetEMRPatientListByDueTime";

  private getAllCurrentMedicationPrescribingHistoryURL =
    "Plan/GetAllCurrentMedicationPrescribingHistory";

  private getAllPreviousMedicationPrescribingHistoryURL =
    "Plan/GetAllPrescribingMedicationPrescribingHistory";

  private getOutgoingReferralListURL = "PatientReferral/GetPatientReferalData";
  private getIncomingReferralDataURL =
    "PatientReferral/GetPatientIncomingReferalData";
  // private getAllCurrentMedicationPrescribingHistoryURL = "Plan/GetAllCurrentMedicationPrescribingHistory";
  private getFaxAchievesDataURL =
    "PatientReferral/GetFaxAchievesData";
  //Guardian URL
  private createGuardianURL = "PatientsGuardian/CreateUpdatePatientGuardian";
  private getGuardianListURL = "PatientsGuardian/GetPatientGuardian";
  private getGuardianByIdURL = "PatientsGuardian/GetPatientGuardianById";
  private deleteGuardianURL = "PatientsGuardian/DeletePatientGuardian";

  //Address URL
  private getAddressAndPhoneNumbersURL =
    "PatientsAddress/GetPatientPhoneAddress";
  private saveAddressAndPhoneNumbersURL = "PatientsAddress/SavePhoneAddress";

  // social history
  private getPatientSocialHistoryURL =
    "PatientsSocialHistory/GetPatientSocialHistory";
  private savePatientSocialHistoryURL =
    "PatientsSocialHistory/SavePatientSocialHistory";

  //Diagnosis URL
  private getDiagnosisListURL = "PatientsDiagnosis/GetDiagnosis";
  private getDiagnosisByIdURL = "PatientsDiagnosis/GetDiagnosisById";
  private createDiagnosisURL = "PatientsDiagnosis/SavePatientDiagnosis";
  private deleteDiagnosisURL = "PatientsDiagnosis/DeleteDiagnosis";
  //Patient Insurance URL
  private getPatientInsurances = "PatientsInsurance/GetPatientInsurances";
  private savePatientInsurances = "PatientsInsurance/SavePatientInsurance";
  // Patient Custom Label
  private getPatientCustomLabels = "PatientsCustomLabel/GetPatientCustomLabels";
  private savePatientCustomLabels =
    "PatientsCustomLabel/SavePatientCustomLabels";

  // Patient Family History URL
  private getPatientMedicalFamilyHistoryListURL =
    "PatientMedicalFamilyHistory/GetPatientMedicalFamilyHistoryById";
  private deletePatientMedicalFamilyHistoryURL =
    "PatientMedicalFamilyHistory/DeletePatientMedicalFamilyHistory";
  private savePatientFamilyHistoryDataURL =
    "PatientMedicalFamilyHistory/SavePatientMedicalfamilyHistory";
  private getAllPatientFamilyHistoryURL =
    "PatientMedicalFamilyHistory/GetAllPatientFamilyHistory";

  //surgical history url
  private savePatientSurgicalHistoryDataURL =
    "PatientSurgicalHistory/SavePatientHospitalizationHistory";
  private getAllPatientSurgicalHistoryListURL =
    "PatientSurgicalHistory/GetAllPatientSurgicalHistory";
  private getPatientSurgicalHistoryByIdListURL =
    "PatientSurgicalHistory/GetPatientSurgicalHistoryById";
  private deletePatientSurgicalHistoryByIdURL =
    "PatientSurgicalHistory/DeletePatientSurgicalHistory";
  // Patient Hospitilization Histroy
  private SavePatientHospitalizationHistoryData =
    "/PatientsHospitilization/SavePatientHospitalizationHistory";
  private DeletePatientHospitalizationHistoryData =
    "PatientsHospitilization/DeletePatientHospitalizationHistory";
  private GetAllPatientHospitalizationHistoryData =
    "/PatientsHospitilization/GetAllPatientHospitalizationHistory";
  private getPatientHospitilizationHistoryListURL =
    "PatientsHospitilization/GetPatientHospitilizationHistoryById";

  //Patient Encounters
  private getPatientEncountersListURL =
    "/patient-encounter/GetPatientEncounter";

  //Ledger URL
  private getClaimsForLedgerURL = "Claim/GetClaimsForPatientLedger";
  private getClaimServiceLineForPatientLedgerURL =
    "Claim/GetClaimServiceLinesForPatientLedger";
  private getPatientGuarantorURL = "patients/GetPatientGuarantor";
  private getPaymentDetailByIdURL = "api/Payment/GetPaymentDetailsById";
  private saveServiceLinePaymentURL = "api/Payment/SaveServiceLinePayment";
  private deleteServiceLinePaymentURL = "api/Payment/DeleteServiceLinePayment";

  private deleteInventoryURL = "PersonalInventory/DeleteInventory";

  //documents
  private getUserDocumentsURL = "userDocument/GetUserDocuments";
  private getPatientTransferDocumentURL = "ADT/GetTransferDocumentById";   
  private getUserCarePlanDocumentsURL = "userDocument/GetUserCarePlanDocuments";
  private getWoundImgURL = "Assessment/GetWoundImages";
  private deleteWoundImg = "Assessment/DeleteWoundImages";
  private downloadWoundImg = "Assessment/DownloadWoundImage";
  private getUserByLocationURL =
    "api/PatientAppointments/GetStaffAndPatientByLocation";
  private getUserDocumentURL = "userDocument/GetUserDocument";
  private deleteUserDocumentURL = "userDocument/DeleteUserDocument";
  private uploadUserDocumentURL = "userDocument/UploadUserDocuments";

  private patientResultLabDocumentURL =
    "PatientResultLabDocument/SavePatientResultLabDocument";
  private patientResultRadiologyDicomURL =
    "PatientResultRadiologyDicom/SavePatientResultRadiologyDicomDocument";
  private PatientResultRadiologyReportsURL =
    "PatientResultRadiologyReport/PatientResultRadiologyReportDocument";
  private PatientCardiacReportsURL =
    "PatientResultCardiacDocument/SavePatientCardiacDocument";
  private PatientPathologyReportsURL =
    "PatientResultPathology/SavePatientResultPathologyDocument";
  private PatientOthersReportsURL =
    "PatientResultOtherDocument/SavePatientResultOtherDocument";
  private getPatientLabDocumentURL =
    "PatientResultLabDocument/DownloadResultLabDocument";
  private getPatientRadiologyDicomURL =
    "PatientResultRadiologyDicom/DownloadRadiologyDicomDocument";
  private getPatientRadiologyReportURL =
    "PatientResultRadiologyReport/DownloadRadiologyReportDocument";
  private getPatientCardiacReportURL =
    "PatientResultCardiacDocument/DownloadPatientCardiacDocument";
  private getPatientPathologyReportURL =
    "PatientResultPathology/DownloadResultPathologyDocument";
  private getPatientOtherReportURL =
    "PatientResultOtherDocument/DownloadPatientResultOtherDocument";
  //chat
  private getChatHistoryURL = "Chat/GetChatHistory";
  private getStateByCountryIdUrl = "api/MasterData/GetStateByCountryID";

  private getRoomNUmberByUnitId = "Patients/GetRoomNumberByUnitId";
  private getRoomNUmberByRoomId = "Patients/GetRoomNumberByRoomId";

  // PatientConsentsPreferences URL
  private getPatientConsentsPreferencesURL = "Patients/GetPatientPreference";

  private getPatientServiceFeedBackURL = "Patients/GetPatientServiceFeedBack";
  private savePatientConsentsPreferencesURL = "Patients/SavePatientPreference";
  private deletePatientConsentsPreferencesURL =
    "Patients/DeletePatientPreference";
  private getPatientConsentsPreferencesByIdURL =
    "Patients/GetPatientPreferenceById";
  private updatePatientPreferenceStatusURL =
    "Patients/UpdatePatientPreferenceStatusById";

  private getRespiratoryAssessmentDropDownListURL =
    "Patients/GetPatientMasterProgressNotes";
  private getStaffListURL = "Patients/GetAllStaffList";
  private getProgressNoteURL = "Patients/GetPatientProgressNote";
  private getTARUrl = "TAR/GetTARByPatientId";
  private getTARbyDateURL = "TAR/GetTARSearchWithDate";
  private getTARSearchURL = "TAR/GetTARSearch";
  private deleteTarByIdtUrl = "TAR/DeleteTARById";
  private getTARByIdUrl = "TAR/GetTARById";
  private getAllIncidentReportURL = "IncidentReport/GetAllIncidentReport";
  private deleteIncidentReportUrl = "IncidentReport/DeleteIncidentReportById";
  private getIncidentReportByIdUrl = "IncidentReport/GetIncidentReportById";
  private getProgressNoteSearchURL = "Patients/GetPatientProgressNoteSearch";
  private getProgressNoteWithDateSearchURL =
    "Patients/GetPatientProgressNoteSearchWithDate";
  private postSaveProgressNoteURL = "Patients/SaveProgressNote";
  private updateProgressNoteCrossOutReasonUrl="Patients/UpdateProgressNoteCrossOutReason";
  private postSaveNoteAsDraftProgressNoteURL =
    "Patients/SaveProgressNoteAsDraft";
  private getRespiratorySkinAssessmentDropDownListURL =
    "Patients/GetPatientSkinAssessment";
  private getCardiovascularProgressNoteURL =
    "Patients/GetPatientProgressNoteForCardiovascular";
  private getRespiratoryProgressNoteURL =
    "Patients/GetPatientProgressNoteForRespiratory";
  private getGastroIntestinalProgressNoteURL =
    "Patients/GetPatientProgressNoteForGastroIntestinal";
  private getGenitourinaryProgressNoteURL =
    "Patients/GetPatientProgressNoteForGenitourinary";
  private getNeurologicalProgressNotesURL =
    "Patients/GetPatientProgressNoteForNeurology";
  private getMusculokeletalProgressNoteURL =
    "Patients/GetPatientProgressNoteForMusculoskeletal";
  private getpatientAdminissionDropDownListURL =
    "Patients/GetPatientsMDSMasterData";

  private createMDSAdmissionURL = "Patients/SaveUpdateMDSAdmission";
  private createAdtAdmissionURL="ADT/SaveUpdateAdtAdmission";
  private getAllAdmissionListURL="ADT/GetAllAdmissionList";
  private getAdmissionByIdURL ="/ADT/GetAdmissionById";
  private deleteAdmissionDetailsURL ="/ADT/DeleteAdmissionDetailsById";

  private createAdtDischargeURL="ADT/SaveUpdatePatientDischarge";
  private getAllDischargeListURL="ADT/GetAllDischargeList";
  private getDischargeDetailsByIdURL ="/ADT/GetDischargeDetailsById";
  private deleteDischargeDetailsURL ="/ADT/DeleteDischargeDetailsById";

  private getAllTransferListURL="ADT/GetAllTransferList";
  private getTransferDetailsByIdURL ="/ADT/GetTransferDetailsById";
  private deleteTransferDetailsURL ="/ADT/DeleteTransferDetailsById";

  private getHeathCardNumberMDSAdmissionURL =
    "Patients/GetPatientsMDSForAdmission";

  private getPatientRecordSearchURL = "MealPlan/GetPatientsRecordSearch";
  private getStaffRecordSearchURL = "MealPlan/GetStaffRecordSearch";

  private createpatientdischargeURL = "Patients/SaveUpdateMDSAdmission";
  private getHeathCardNumberMDSAdmissionCardByDataURL =
    "Patients/GetPatientsMDSForAdmissionUsingHCN";
  private getStaffAvailabilityByLocationURL = "MasterShift/GetShiftWithShiftId";

  private createCommunocationCarePlanURL = "CreateUpdateCommunicationCarePlan";
  //PLAN RX
  private createRXURL = "Plan/CreateUpdateeRX";
  private creatpRXURL = "Plan/CreateUpdatepRX";

  private getCommunocationCarePlanURL = "GetCommunicationCarePlanByPatientId";

  //Care SUMMARY
  private createCarePlanURL = "CarePlan/CreateUpdateCaresummary";
  private getCareSummaryURL = "CarePlan/GetCaresummaryById";
  private getTagsByPatientId = "Patients/GetTagsByPatientId";
  private getCareSummaryGraphURL = "CarePlan/GetCaresummaryGraphByPatientId";

  private createRoutineCarePlanURL = "CarePlan/CreateUpdateRoutinesCarePlan";
  private getRoutineCarePlanURL = "CarePlan/GetRoutineCarePlanByPatientId";

  private createCognitiveStatusCarePlanURL =
    "CarePlan/CreateUpdateCognitiveStatusCarePlan";
  private getCognitiveStatusCarePlanURL =
    "CarePlan/GetCognitiveStatusCarePlanByPatientId";

  private createBehavioursCarePlanURL =
    "CarePlan/CreateUpdateBehaviourCarePlan";
  private getBehavioursCarePlanURL = "CarePlan/GetBehaviourCarePlanByPatientId";

  private createDressingCarePlanURL = "CarePlan/CreateUpdateDressingCarePlan";
  private getDressingCarePlanURL = "CarePlan/GetDressingCarePlanByPatientId";

  private createSafetyCarePlanURL = "CarePlan/CreateUpdateSafetyCarePlan";
  private getSafetyCarePlanURL = "CarePlan/GetSafetyCarePlanByPatientId";

  private createBathingCarePlanURL = "CarePlan/CreateUpdateBathingCarePlan";
  private getBathingCarePlanURL = "CarePlan/GetBathingCarePlanByPatientId";

  private createNutritionCarePlanURL =
    "CarePlan/CreateUpdateNutritionHydrationCarePlan";
  private getNutritionCarePlanURL =
    "CarePlan/GetNutritionHydrationCarePlanByPatientId";

  private createHygieneCarePlanURL = "CarePlan/CreateUpdateHygieneCarePlan";
  private getHygieneCarePlanURL = "CarePlan/GetHygieneCarePlanByPatientId";

  private createSkinCarePlanURL = "CarePlan/CreateUpdateSkinCarePlan";
  private getSkinCarePlanURL = "CarePlan/GetSkinCarePlanByPatientId";

  private createMobilityCarePlanURL = "CarePlan/CreateUpdateMobilityCarePlan";
  private getMobilityCarePlanURL = "CarePlan/GetMobilityCarePlanByPatientId";

  private createTransferCarePlanURL = "CarePlan/CreateUpdateTransferCarePlan";
  private getTransferCarePlanURL = "CarePlan/GetTransferCarePlanByPatientId";

  private createToiletingCarePlanURL = "CarePlan/CreateUpdateToiletingCarePlan";
  private getToiletingCarePlanURL = "CarePlan/GetToiletingCarePlanByPatientId";
  private getShiftDetailsBiIdURL = "MasterShift/GetShiftWithShiftId";
  private GetShiftsTimeBasedOnIdURL = "MasterShift/GetShiftsTimeBasedOnId";
  private GetAllDueTimesURL = "MasterShift/GetAllDueTimes";

  private createBladderCarePlanURL = "CarePlan/CreateUpdateBladderCarePlan";
  private getBladderCarePlanURL = "CarePlan/GetBladderCarePlanByPatientId";

  private createBowelCarePlanURL = "CarePlan/CreateUpdateBowelCarePlan";
  private getBowelCarePlanURL = "CarePlan/GetBowelCarePlanByPatientId";

  private importCCDAURL = "patients/ImportPatientCCDA";

  private changeTaskStatusURL = "api/PatientAppointments/UpdateTaskStatus";
  // get relationship data
  private getrelationshipDataURL =
    "PatientMedicalFamilyHistory/GetRelationshipList";

  private getSubjectiveListListURL = "/Patients/GetSubjectiveList";

  private getGetAssessmentListURL = "/Patients/GetAssessmentList";

  private getRouteListListURL = "/PatientsMedication/GetMedicationRoute";
  private getMedicationsListURL = "/PatientsMedication/GetMedicationFDbName";
  private getDrugNameURL = "/PatientsMedication/GetDrugName";
  private getMasterLanguages = "/Patients/GetLanguagesBySearch";
  private getInventoryNameURL = "PatientsMedication/GetInvaentoryName";
  private getProductsListURL = "/PatientsMedication/GetProductsFDbName";
  private getReligionListURL = "PatientHistory/GetReligionName";
  private getUnitListURL = "/PatientsMedication/GetMasterMedicationUnit";
  private getReasonListURL = "/PatientsMedication/GetMedicationIndicationOfUse";
  private getFrequencyListURL =
    "/PatientsMedication/GetMedicationFrequencyList";
  private saveUpddateMasterInventory =
    "/MasterInventory/SaveUpdateMasterInventory";
  private reorderInventory = "/MasterInventory/ReorderInventory";
  private getMasterInventoryByIdURL =
    "/MasterInventory/GetSaveUpdateMasterInventoryById";

  private GetInventoryDetailsByIdURL =
    "/MasterInventory/GetInventoryDetailsById";
  private getAllInventoryList = "/MasterInventory/GetAllMasterInventoryList";
  private GetAllConcentList = "ConcentPolicy/GetPatientConsent";
  private getAllInventoryTrackList = "InventoryTrack/GetAllInventoryTrack";
  private getAllReorderTrackList = "InventoryTrack/GetAllReorderTrack";
  private deleteInventorymasterURL =
    "/MasterInventory/DeleteMasterInventoryById";
  private deleteIncomingFaxURL ="/PatientReferral/DeleteIncomingFaxByFileName";
  private saveIncomingFaxURL ="/PatientReferral/SaveIncomingFaxDocument";
  private GetPatientNameURL = "/Patients/GetPatientName";
  private saveUpdateStockOutUrl = "/InventoryTrack/SaveUpdateInventoryTrack";
  private deleteInvantoryTrackURL = "InventoryTrack/DeleteInventoryTrackById";
  private GetByInventoryTrackIdURL = "InventoryTrack/GetInventoryTrackById";

  private GetEmrDetailsURL = "PatientsMedication/GetEMRPatientMedicationList";
  private GetEmrtimeDetailsURL =
    "PatientsMedication/GetEMRPatientMedicationListByDueTime";
  private updateEmrDetailsURL = "PatientsMedication/UpdateForMissedMedication";
  private GetEmrDetailsURL1 = "PatientsMedication/UpdateForMissedMedication";
  private getCurrentmedicationIdURL = "/Plan/GetCurrentmedicationById";
  private GetDrugSideEffectBulk = "/Drugs/GetDrugSideEffectBulk";
  private GetDrugDiseaseBulk = "/Drugs/GetDrugDiseaseBulk";
  private GetMinMaxDoseBulk = "/Drugs/GetMinMaxDoseBulk";

  private GetDrugIndicationBulk = "/Drugs/GetDrugIndicationBulk";
  private GetDrugAllergyBulk = "/Drugs/GetDrugAllergyBulk";
  private GetDrugGeriatricsPrecautionBulk =
    "/Drugs/DrugGeriatricsPrecautionBulk";
  private GetDrugFoodFamilyEducationBulk = "/Drugs/DrugFoodFamilyEducationBulk";
  private GetDrugDrugInterationBulk = "/Drugs/GetDrugInterationBulk";
  private GetPatientDrugs = "/Drugs/GetPatientDrugHistory";
  private GetDrugDuplicateTherapyBulk = "/Drugs/GetDrugDuplicateTherapyBulk";
  private GetDrugDrugIntration = "/Drugs/GetDrugDrugIntration";

  private getEMRPatientMedicationOrderChangeListURL =
    "PatientsMedication/GetEMRPatientMedicationOrderChangeList";
  private discontinueCurrentmedicationURL =
    "PatientsMedication/DiscontinuedMedication";

  private getllMasterProviderListURL =
    "PatientReferral/GetAllMasterProviderListForDropDown";

  private getAllStaffListtURL = "PatientReferral/GetAllStaffListReferral";

  private getMasterStatusListURL = "MealPlan/GetAllMasterStatusListForDropDown";

  private getMasterMealCategoryListURL =
    "MealPlan/GetAllMasterMealCategoryForDropDown";

  private getMasterMealItemListURL = "MealPlan/GetAllMasterMealItemForDropDown";
  private getAllLocalResourcesMasterForDropDownURL =
    "Patients/GetAllLocalResourcesMasterForDropDown";

  private getAllLocalResourcesMasterURL = "Patients/GetAllLocalResourcesMaster";

  private getAllLocalResourcesMasterForFamlyPoratlURL =
    "Patients/GetAllLocalResourcesMasterForFamilyPortal";

  // private getMasterMealItemListURL =
  // "MealPlan/GetAllMasterMealItemForDropDown";

  private getMasterTypeofFoodListURL =
    "MealPlan/GetAllMasterTypeofFoodForDropDown";

  private getMasterFluidConsistencyListURL =
    "MealPlan/GetAllMasterFluidConsistencyForDropDown";

  private getIncomingReferralListURL = "PatientReferral/GetAllIncomingReferral";

  private GetFoodDiaryDetailsURL = "MealPlan/GetAllPatientFoodDiary";

  private GetFoodDiaryDetailsByPatientIdURL =
    "MealPlan/GetAllPatientFoodDiaryByPatientId";
  private getUnitsByLocationIdUrl = "MealPlan/GetUnitByLocationId";
  private getStaffAndPatientByLocationUrl = "MealPlan/GetStaffAndPatientByLocation";
  private setPassword = "PasswordSetup";

  private getCareProviderByUnitIdURL = "Patients/GetPatientCareProviderList";
  private getExternalCareProviderByUnitIdURL =
    "Patients/GetPatientExternalCareProviderList";
  private getServiceFeedBackClientIdURL =
    "Patients/GetFollowUpAppointmentDetails";
  getMasterTemplatesURL = "MasterTemplates/GetMasterTemplates";
  getPatientLabTemplatesURL = "MasterTemplates/GetPatientLabTemplates";

  private deletePatientsNotesByIdURL = "Patients/DeletePatientNotesData";
  GetInvestigationListURL =
    "InvestigationCenter/GetAllInvestigationCenterLists";
  GetTemplateByIdURL = "patient-encounter/GetPatientEncounterTemplateData";
  SaveTemplateDataURL = "patient-encounter/SaveEncounterTemplateData";
  getFilledTemplateFormURL = "patient-encounter/GetPatientLabTemplateData";

  // private deletePatientsNotesByIdURL = "Patients/DeletePatientNotesData";

  private getLastVisitPatientsAppointmentsByIdURL =
    "Patients/GetLastVisitPatientsAppointments";
  private getNextVisitPatientsAppointmentsByIdURL =
    "Patients/GetNextVisitPatientsAppointments";
  private getInputOutChartUrl =
    "NutritionAndHydration/InputOutoutChartByPatientId";
  private getInputOutChartTrendUrl =
    "NutritionAndHydration/InputOutputTrendGraph";

  private fetchFaxDetailsUrl = "Patients/FetchFaxDetails";

  private GetCrossProgressNoteUrl = "Patients/CrossOutProgressNote";

  private PreviousTarIsCrossOutUrl = "TAR/PreviousTarIsCrossOut";

  private IncidentReportCrossOutUrl = "IncidentReport/IncidentReportCrossOut";
  private GetColorByIdUrl = "MasterColor/GetColorById";
  private UpdateColorUrl="MasterColor/UpdateColor";
  private getPatientListURL ="PatientReporting/GetPatientList";
  private getPatientReportDetailsURL ="PatientReporting/GetPatientReportDetails";
  
  //notifications
  deleteTaskNotificationURL = '/api/Notification/DeleteTaskNotifications';
  deleteStaffExpiredDocNotificationURL = '/api/Notification/DeleteStaffExpiredDocNotification';
  deleteResidentExpiredDocNotificationURL = 'api/Notification/DeleteResidentExpiredDocNotification';
  deleteURL = '/api/Notification/DeleteNotifications';
  deleteMissedMedNotificationURL = '/api/Notification/DeleteMissedMedicationNotifications';
  private TurnOffNotificationListURL="/api/Notification/TurnOffReadedNotification";
  private taskNotificationUrl = "api/Notification/GetHeaderAllTaskNotification";
  private taskDetailsUrl = "api/Notification/GetTaskDetailsById";
  private updateTaskStatus = "api/PatientAppointments/UpdateTaskStatus";

  constructor(private commonService: CommonService) { }

  crossIncidentReport(reqmodel:any) {
    return this.commonService.post(
      this.IncidentReportCrossOutUrl,
      reqmodel
      // { id: id }
    );
  }

  crossPreviousTar(reqmodel:any) {
    return this.commonService.post(
      this.PreviousTarIsCrossOutUrl,
      reqmodel
      // { id: id }
    );
  }

  crossProgesNotes(reqmodel:any) {
    return this.commonService.post(
      this.GetCrossProgressNoteUrl,
      reqmodel
      // { patientsProgressNotesId: patientsProgressNotesId, patientID: patientId }
    );
  }

  fetchFaxDetails(fileName) {
    return this.commonService.getAll(
      this.fetchFaxDetailsUrl + "?fileName=" + fileName,
      {}
    );
  }

  getMasterTemplates(investigationCenterId?: number) {
    return this.commonService.getAll(
      this.getMasterTemplatesURL + "?investigationId=" + investigationCenterId,
      {}
    );
  }
  getPatientLabTemplates(filterModel: any, patientId: number) {
    //let url = this.getVitalListURL + "?pageNumber=" + filterModel.pageNumber + "&pageSize=" + filterModel.pageSize + "&PatientId=" + clientId + "&SortColumn=" + filterModel.sortColumn + "&SortOrder=" + filterModel.sortOrder;

    return this.commonService.getAll(
      this.getPatientLabTemplatesURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&PatientId=" +
      patientId +
      "&SortColumn=" +
      filterModel.sortColumn +
      "&SortOrder=" +
      filterModel.sortOrder,
      {}
    );
  }
  saveTemplateData(postData: any) {
    return this.commonService.post(this.SaveTemplateDataURL, postData);
  }
  getTemplateForm(enpunterId, templateId: number) {
    const queryParams = `?patientEncounterId=${0}&masterTemplateId=${templateId}`;
    return this.commonService.getById(
      this.GetTemplateByIdURL + queryParams,
      {}
    );
  }
  getFilledTemplateForm(templateId: number) {
    const queryParams = `?patientEncounterId=${templateId}`;
    return this.commonService.getById(
      this.getFilledTemplateFormURL + queryParams,
      {}
    );
  }
  create(data: ClientModel) {
    return this.commonService.post(this.createURL, data);
  }
  getInvestigationList(isLab, isInvestigationCenter) {
    return this.commonService.getById(
      this.GetInvestigationListURL +
      "?isLab=" +
      isLab +
      "&isImagingCenter=" +
      isInvestigationCenter,
      {}
    );
  }
  getSkinAssessmentDroDownList() {
    return this.commonService.getAll(
      this.getRespiratorySkinAssessmentDropDownListURL,
      {}
    );
  }

  deleteStockOut(id: number) {
    return this.commonService.patch(
      this.deleteInvantoryTrackURL + "?Id=" + id,
      {}
    );
  }

  createPatientHistory(data: PatientHistoryModel) {
    return this.commonService.post(this.createPatientHistoryUrl, data);
  }

  createAdlTrackingTool(data: AdlTrackingToolModel) {
    return this.commonService.post(this.createUpdateAdlTrackingToolUrl, data);
  }

  updateAdlTrackingToolCrossOutReason(adlTrackingToolId:number,crossOutReasonId:number) {
    return this.commonService.post(this.updateAdlTrackingToolCrossOutReasonUrl + `?adlTrackingToolId=${adlTrackingToolId}&crossOutReasonId=${crossOutReasonId}`, 
      '');
  }

  crossADLTrackingTool(reqmodel : any) {
    return this.commonService.post(
      this.ADLTrackingToolIsCrossURL,
      reqmodel
    );
  }

  createMoodAndBehaviourTrackingTool(data: MoodAndBehaviorModel) {
    return this.commonService.post(this.SaveUpdateMoodAndBehaviorUrl, data);
  }

  updateMabTrackingToolCrossOutReason(mabTrackingToolId:number,crossOutReasonId:number) {
    return this.commonService.post(this.updateMabTrackingToolCrossOutReasonUrl + `?mabTrackingToolId=${mabTrackingToolId}&crossOutReasonId=${crossOutReasonId}`, 
      '');
  }

  crossBehaviorTrackingTool(reqmodel : any) {
    return this.commonService.post(
      this.BehaviorTrackingToolCrossURL,
      reqmodel
      //{ moodAndBehaviorId: moodAndBehaviorId }
    );
  }
  createsafetyAndSecurityNursingFlow(data: SafetyAndSecurityModel) {
    return this.commonService.post(
      this.createsafetyAndSecurityNursingFlowUrl,
      data
    );
  }

  updateNurisngCareFlowCrossOutReason(safetyAndSecurityId:number,crossOutReasonId:number) {
    return this.commonService.post(this.updateNurisngCareFlowCrossOutReasonUrl + `?safetyAndSecurityId=${safetyAndSecurityId}&crossOutReasonId=${crossOutReasonId}`, 
      '');
  }

  crossPreviousNursingCareFlow(reqmodel : any) {
    return this.commonService.post(
      this.PreviousNursingCareFlowIsCrossURL,
      reqmodel
      //{ sefetyAndSecurityId: sefetyAndSecurityId }
    );
  }

  createSleepAndComfortNursingFlow(data: SleepRestComfortModel) {
    return this.commonService.post(
      this.createSleepAndComfortNursingFlowUrl,
      data
    );
  }

  createUpdateIncidentReport(data: IncidentReportModel) {
    return this.commonService.post(this.createUpdateIncidentReportUrl, data);
  }

  updateIncidentCrossOutReason(incidentId:number,crossOutReasonId:number) {
    return this.commonService.post(this.updateIncidentCrossOutReasonUrl + `?incidentId=${incidentId}&crossOutReasonId=${crossOutReasonId}`, 
      '');
  }

  createPersonalHygieneNursingFlow(data: PersonalHygieneModel) {
    return this.commonService.post(
      this.createPersonalHygieneNursingFlowUrl,
      data
    );
  }

  createNutritaionAndHydrationNursingFlow(data: NutritionAndHydrationModel) {
    return this.commonService.post(
      this.SaveUpdateNutritionAndHydrationURL,
      data
    );
  }

  createActivityExersiceNursingFlow(data: ActivityAndExerciseModel) {
    return this.commonService.post(
      this.createActivityExersiceNursingFlowUrl,
      data
    );
  }

  createCreateESASRating(data: EsasRatingModel) {
    return this.commonService.post(this.SaveUpdateESASRatingsUrl, data);
  }

  saveAsDraftESASRating(data: EsasRatingModel) {
    return this.commonService.post(this.saveUpdateAsDraftUrl, data);
  }
  deleteAdlTrackingTool(id: number) {
    return this.commonService.patch(
      this.deleteAdlTrackingToolUrl + "?Id=" + id,
      {}
    );
  }

  getCdssFallMessage(fallValue: string) {
    return this.commonService.getById(
      this.cdssFallMessage + "?fallValue=" + fallValue,
      {}
    );
  }

  showCdssMessagePressureUlcer(value: string) {
    return this.commonService.getById(
      this.cdssPressureUlcerMessageURL + "?fallValue=" + value,
      {}
    );
  }

  showCdssMessageBehavior(value: string) {
    return this.commonService.getById(
      this.cdssBehaviorMessageURL + "?fallValue=" + value,
      {}
    );
  }

  showCdssMessageActivity(value: string) {
    return this.commonService.getById(
      this.cdssActivityMessageURL + "?fallValue=" + value,
      {}
    );
  }

  showCdssMessageNewPain(value: string) {
    return this.commonService.getById(
      this.cdssNewPainMessageURL + "?fallValue=" + value,
      {}
    );
  }

  showCdssMessageOngoingPain(value: string) {
    return this.commonService.getById(
      this.cdsOngoingPainMessageURL + "?fallValue=" + value,
      {}
    );
  }

  showCdssMessagePressureUlcerOngoing(value: string) {
    return this.commonService.getById(
      this.cdssPressureUlcerOngoingMessageURL + "?fallValue=" + value,
      {}
    );
  }

  getAdlTrackingToolByPatientId(id: number) {
    return this.commonService.getById(
      this.getAdlTrackingToolUrlByPatientId + "?patientId=" + id,
      {}
    );
  }
  getStatusofPOCByPatientId(id: number) {
    return this.commonService.getById(
      this.getStatusOfPOCUrlByPatientId + "?patientId=" + id,
      {}
    );
  }
  getMoodAndBehaviourToolByPatientId(id: number) {
    return this.commonService.getById(
      this.getMoodAndBehaviorByPatientIdUrl + "?patientId=" + id,
      {}
    );
  }
  discardSaveAsDraftMoodBehaviourTool(id: number) {
    return this.commonService.getById(
      this.DiscardMoodAndBehaviorByIdURL + "?Id=" + id,
      {}
    );
  }
  private DiscardADLTrackingToolByIdURL =
    "PointOfCare/DiscardADLTrackingToolById";
  discardSaveAsDraftADLTrackingTool(id: number) {
    return this.commonService.getById(
      this.DiscardADLTrackingToolByIdURL + "?Id=" + id,
      {}
    );
  }
  private DiscardActivityExerciseByIdURL =
    "ActivityExercise/DiscardActivityExerciseById";
  DiscardActivityExerciseById(id: number) {
    return this.commonService.getById(
      this.DiscardActivityExerciseByIdURL + "?Id=" + id,
      {}
    );
  }
  private DiscardNutritionAndHydrationByIdURL =
    "NutritionAndHydration/DiscardNutritionAndHydrationById";
  DiscardNutritionAndHydrationById(id: number) {
    return this.commonService.getById(
      this.DiscardNutritionAndHydrationByIdURL + "?Id=" + id,
      {}
    );
  }
  private DiscardPersonalHygieneByIdURL =
    "/PersonalHygiene/DiscardPersonalHygieneById";
  DiscardPersonalHygieneById(id: number) {
    return this.commonService.getById(
      this.DiscardPersonalHygieneByIdURL + "?Id=" + id,
      {}
    );
  }
  private DiscardSaveAsDraftByIdURL =
    "SafetyAndSecurity/DiscardSaveAsDraftById";
  DiscardSaveAsDraftById(id: number) {
    return this.commonService.getById(
      this.DiscardSaveAsDraftByIdURL + "?Id=" + id,
      {}
    );
  }
  private DiscardSleepRestAndComfortByIdURL =
    "SleepRestAndComfort/DiscardSleepRestAndComfortById";
  DiscardSleepRestAndComfortById(id: number) {
    return this.commonService.getById(
      this.DiscardSleepRestAndComfortByIdURL + "?Id=" + id,
      {}
    );
  }

  GetSafetyAndSecurityByPatientIdUrl(id: number) {
    return this.commonService.getById(
      this.getSafetyAndSecurityByPatientIdUrl + "?patientId=" + id,
      {}
    );
  }

  GetSleepAndRestByPatientIdUrl(id: number) {
    return this.commonService.getById(
      this.getSleepAndRestByPatientIdUrl + "?patientId=" + id,
      {}
    );
  }

  GetPersonalHygieneByPatientIdUrl(id: number) {
    return this.commonService.getById(
      this.getPersonalHygieneNursingCareFlowUrl + "?patientId=" + id,
      {}
    );
  }

  GetNutritionAndHydrationByPatientIdUrl(id: number) {
    return this.commonService.getById(
      this.getNutritionAndHydrationNursingCareFlowUrl + "?patientId=" + id,
      {}
    );
  }

  GetActivityAndExerciseByPatientId(id: number) {
    return this.commonService.getById(
      this.getActivityExersiceNursingFlowUrlByPatientId + "?patientId=" + id,
      {}
    );
  }

  getEsasRatingByPatientId(id: number) {
    return this.commonService.getById(
      this.getESASRatingsUrlByPatientId + "?patientId=" + id,
      {}
    );
  }

  getViewEsasRatingByPatientIdShiftIdLocationId(
    id: number,
    locationId: number,
    shiftId: number,
    unitId: number,
    fromDate: string,
    toDate: string,
    isDateSearch: boolean,
    ratingTime: string
  ) {
    return this.commonService.getById(
      this.getViewESASRatingsUrlByPatientId +
      "?patientId=" +
      id +
      "&locationId=" +
      locationId +
      "&shiftId=" +
      shiftId +
      "&unitId=" +
      unitId +
      "&startDate=" +
      fromDate +
      "&endDate=" +
      toDate +
      "&isDateSearch=" +
      isDateSearch +
      "&ratingTime="+
      ratingTime,

      {}
    );
  }

  getEsasRatingByPatientIdShiftIdLocationId(
    id: number,
    locationId: number,
    //shiftId: number,
    unitId: number,
    selectedTime:string
  ) {
    return this.commonService.getById(
      this.getESASRatingsUrlByPatientId +
      "?patientId=" +
      id +
      "&locationId=" +
      locationId +
      "&unitId=" +
      unitId +
      "&ratingTime=" +
      selectedTime ,
      {}
    );
  }

  deleteOtherProblems(id: number) {
    return this.commonService.patch(`${this.DeleteESASRatingsByIdUrl}?Id=${id}`, {})
  }

  getPatientLocation(patientId) {
    return this.commonService.getById(
      this.getPatientLocationByPatientId + "?patientId=" + patientId,
      {}
    );
  }

  getPatientUnit(patientId) {
    return this.commonService.getById(
      this.getPatientUnitByPatientId + "?patientId=" + patientId,
      {}
    );
  }

  getMasterData(value: string = "") {
    return this.commonService.post(this.getMasterDataByNameURL, {
      masterdata: value,
    });
  }

  CreateUpdateMasterInventory(model: MasterInventoryModel) {
    return this.commonService.getdatata(this.saveUpddateMasterInventory, model);
  }

  ReorderInventory(model: ReorderModel) {
    return this.commonService.post(this.reorderInventory, model);
  }

  getMasterInventoryById(Id: number) {
    return this.commonService.getById(
      this.getMasterInventoryByIdURL + "?Id=" + Id,
      {}
    );
  }

  GetInventoryDetailsById(Id: number) {
    return this.commonService.getById(
      this.GetInventoryDetailsByIdURL + "?Id=" + Id,
      {}
    );
  }

  getMasterShiftData(LocationId: any) {
    return this.commonService.getAll(
      this.getMasterShiftDataURL + "?locationId=" + LocationId,
      {}
    );
  }
  getMasterShiftDataByUnit(LocationId: any, shiftType?: number) {
    return this.commonService.getAll(
      this.getMasterShiftDataByUnitURL +
      "?unitId=" +
      LocationId +
      "&shiftType=" +
      shiftType,
      {}
    );
  }

  getStaffShiftDataByUnit(LocationId: any, shiftType?: number,staffId?:number) {
    return this.commonService.getAll(
      this.getStaffShiftDataByUnitURL +
      "?unitId=" +
      LocationId +
      "&shiftType=" +
      shiftType+
      "&staffId="+
      staffId,
      {}
    );
  }
  getMasterShiftDataByUnitIds(unitIds: any, shiftType?: number) {
    return this.commonService.getAll(
      this.GetShiftByUnitIdDataURL +
      "?unitId=" +
      unitIds +
      "&shiftType=" +
      shiftType,
      {}
    );
  }

  getStaffAvailabilityByLocation(shiftId: number) {
    return this.commonService.getById(
      this.getStaffAvailabilityByLocationURL + "?shiftId=" + shiftId,
      {}
    );
  }
  getShiftDetailsByShiftId(shiftId: number) {
    return this.commonService.getById(
      this.getShiftDetailsBiIdURL + "?shiftId=" + shiftId,
      {}
    );
  }
  GetShiftsTimeBasedOnId(masterShiftId: number) {
    return this.commonService.getById(
      this.GetShiftsTimeBasedOnIdURL + "?masterShiftId=" + masterShiftId,
      {}
    );
  }

  GetAllDueTimes(shiftStartTime: Date, shiftEndTime: Date,unitId:number) {
    return this.commonService.getById(
      this.GetAllDueTimesURL + "?ShiftStartTime=" + shiftStartTime + "&ShiftEndTime=" + shiftEndTime + "&UnitId=" +unitId,
      {}
    );
  }

  // getRespiratoryAssessmentDroDownList() {
  //     return this.commonService.post(this.getRespiratoryAssessmentDropDownListURL,'');
  // }  GetAllDueTimesURL

  getRespiratoryAssessmentDroDownList() {
    return this.commonService.getAll(
      this.getRespiratoryAssessmentDropDownListURL,
      {}
    );
  }

  getAllStaffList() {
    return this.commonService.getAll(this.getStaffListURL, {});
  }

  getMasterSuppliesData(value: string = "") {
    return this.commonService.post(this.getMasterSuppliesDataURL, {
      masterdata: value,
    });
  }

  getMasterSuppliesMedicalData(id: number) {
    return this.commonService.getById(
      this.getMasterSuppliesMedicalDataURL + "?id=" + id,
      {}
    );
  }

  getMasterSuppliesMedicationsData(id: number) {
    return this.commonService.getById(
      this.getMasterSuppliesMedicationsDataURL + "?id=" + id,
      {}
    );
  }

  getMasterSuppliesMedicationsListData(id: number) {
    return this.commonService.getById(
      this.getMasterSuppliesMedicationsListDataURL + "?id=" + id,
      {}
    );
  }

  getMasterInventoryTypeData(value: string = "") {
    return this.commonService.post(this.getMasterInventoryTypeDataURL, {
      masterdata: value,
    });
  }

  updateClientNavigations(id: number, userId: number = null) {
    this.commonService.updateClientNaviagations(id, userId);
  }
  getClientFamilyEducation(id: number) {
    return this.commonService.getById(
      this.getPatientFamilyEducation + "?patientId=" + id,
      {}
    );
  }
  getClientById(id: number) {
    return this.commonService.getById(
      this.getClientByIdURL + "?patientId=" + id,
      {}
    );
  }

  GetPatientsTagsList(patientId: number) {
    return this.commonService.getById(
      this.getPatientsTagsByIdURL + "?patientId=" + patientId,
      {}
    );
  }
  GetPatientsNotesList(patientId: number) {
    return this.commonService.getById(
      this.getPatientsNotesByIdURL + "?patientId=" + patientId,
      {}
    );
  }

  deletetPatientsNotes(id: number) {
    return this.commonService.patch(
      this.deletePatientsNotesByIdURL + "?id=" + id,
      {}
    );
  }

  GetPatientCareProviderList(id: number, patientId: number) {
    return this.commonService.getById(
      // this.getCareProviderByUnitIdURL + "?unitId=" + id,"&isActive=" +
      // patientId

      this.getCareProviderByUnitIdURL +
      "?unitId=" +
      id +
      "&patientId=" +
      patientId,
      {}
    );
  }

  GetAllLocalResourcesMaster(masterData: any): Observable<any> {
    return this.commonService.post(
      this.getAllLocalResourcesMasterURL,
      masterData
    );
  }

  GetAllLocalResourcesMasterForFamilyPortal(masterData: any): Observable<any> {
    return this.commonService.post(
      this.getAllLocalResourcesMasterForFamlyPoratlURL,
      masterData
    );
  }

  GetPatientExternalCareProviderList(id: number, patientId: number) {
    return this.commonService.getById(
      // this.getCareProviderByUnitIdURL + "?unitId=" + id,"&isActive=" +
      // patientId

      this.getExternalCareProviderByUnitIdURL +
      "?unitId=" +
      id +
      "&patientId=" +
      patientId,
      {}
    );
  }

  getFollowUpDetailsByClientId(id: number) {
    return this.commonService.getById(
      this.getFollowUpByClientIdURL + "?patientId=" + id,
      {}
    );
  }

  getLastVisitPatientsAppointmentsById(id: number, currentdate: string) {
    return this.commonService.getById(
      this.getLastVisitPatientsAppointmentsByIdURL +
      "?patientId=" +
      id +
      "&appoDate=" +
      currentdate,
      {}
    );
  }
  getNextVisitPatientsAppointmentsById(id: number, currentdate: string) {
    return this.commonService.getById(
      this.getNextVisitPatientsAppointmentsByIdURL +
      "?patientId=" +
      id +
      "&appoDate=" +
      currentdate,
      {}
    );
  }

  getServiceFeedBackByClientId(id: number) {
    return this.commonService.getById(
      this.getServiceFeedBackClientIdURL + "?patientId=" + id,
      {}
    );
  }

  getPatientHistoryById(id: number) {
    return this.commonService.getById(
      this.getPatientHistoryInfoUrl + "?patientId=" + id,
      {}
    );
  }
  getClientHeaderInfo(id: number) {
    return this.commonService.getById(
      this.getClientHeaderInfoURL + "?id=" + id,
      {}
    );
  }

  getClientProfileInfo(id: number) {
    return this.commonService.getById(
      this.getClientProfileInfoURL + "?patientId=" + id,
      {}
    );
  }

  getPatientProfileInfo(id: number) {
    return this.commonService.getById(
      this.getpatientdetailsurl + "?patientId=" + id,
      {}
    );
  }
  updateClientStatus(patientId: number, isActive: boolean) {
    return this.commonService.patch(
      this.updateClientStatusURL +
      "?patientID=" +
      patientId +
      "&isActive=" +
      isActive,
      {}
    );
  }

  updateUserStatus(patientId: number, isActive: boolean) {
    return this.commonService.patch(
      this.updateUserStatusURL + "/" + patientId + "/" + isActive,
      {}
    );
  }
  getPatientCCDA(patientId: number) {
    return this.commonService.download(
      this.getPatientCCDAURL + "?id=" + patientId,
      {}
    );
  }
  getPatientImmunization(patientId: number) {
    return this.commonService.getAll(
      this.getPatientImmunizationURL + "?id=" + patientId,
      {}
    );
  }
  savePatientImmunization(req: PatientImmunizationV1) {
    return this.commonService.post(this.savetPatientImmunizationURL, req);
  }
  deletePatientImmunization(id: number) {
    return this.commonService.getAll(this.deletePatientImmunizationURL + '?patientImmunizationId=' + id, {});
  }
  unblockPatient(userId: number) {
    return this.commonService.getById(
      this.unblockResident + "?userId=" + userId,
      {}
    );
  }
  updatePatientPortalVisibility(
    patientId: number,
    userId: number,
    value: boolean
  ) {
    let url =
      this.updatePatientPortalVisibilityURL +
      "?patientID=" +
      patientId +
      "&userID=" +
      userId +
      "&isPortalActive=" +
      value;
    return this.commonService.patch(url, {});
  }

  //Eligibility Enquiry Method
  getPayerByPatient(patientID: number, key: string) {
    return this.commonService.getAll(
      this.getPayerByPatientURL + "?PatientID=" + patientID + "&Key=" + key,
      {}
    );
  }

  getEligibilityEnquiryServiceCodes() {
    return this.commonService.getAll(
      this.getEligibilityEnquiryServiceCodesURL,
      {}
    );
  }
  download270(
    patientId: number,
    patientInsuranceId: number,
    serviceTypeCodeIds: string,
    serviceCodeIds: string
  ) {
    let url =
      this.download270URL +
      "?patientId=" +
      patientId +
      "&patientInsuranceId=" +
      patientInsuranceId +
      "&serviceTypeCodeIds=" +
      serviceTypeCodeIds +
      "&serviceCodeIds=" +
      serviceCodeIds;
    return this.commonService.download(url, {});
  }

  //Guardian Method  -- Remove all if not needed
  createGuardian(data: GuardianModel) {
    return this.commonService.post(this.createGuardianURL, data);
  }
  getGuardianList(clientId: number, pageNumber: number, pageSize: number) {
    let url =
      this.getGuardianListURL +
      "?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&PatientId=" +
      clientId;
    return this.commonService.getAll(url, {});
  }
  getGuardianById(id: number) {
    return this.commonService.getById(
      this.getGuardianByIdURL + "?id=" + id,
      {}
    );
  }
  deleteGuardian(id: number) {
    return this.commonService.post(this.deleteGuardianURL + "?id=" + id, {});
  }
  //Address Method  -- Remove all if not needed
  getPatientAddressesAndPhoneNumbers(clientId: number) {
    return this.commonService.getById(
      this.getAddressAndPhoneNumbersURL + "?patientId=" + clientId,
      {}
    );
  }
  //Patient Inurance
  getPatientInsurance(clientId: number) {
    return this.commonService.getById(
      this.getPatientInsurances + "?patientId=" + clientId,
      {}
    );
  }
  //Medication Method
  CreateUpdateNurseOrder(data: PatientsMedicationModel) {
    return this.commonService.post(this.createUpdateNurseOrderURL, data);
  }
  savePatientAddressesAndPhoneNumbers(data: any) {
    return this.commonService.post(this.saveAddressAndPhoneNumbersURL, data);
  }

  // social history
  getPatientSocialHistory(patientId: number) {
    return this.commonService.getById(
      this.getPatientSocialHistoryURL + "?patientId=" + patientId,
      {}
    );
  }
  createSocialHistory(data: SocialHistoryModel) {
    return this.commonService.post(this.savePatientSocialHistoryURL, data);
  }

  //Diagnosis
  getDiagnosisList(clientId: number) {
    // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
    return this.commonService.getAll(
      this.getDiagnosisListURL + "?PatientId=" + clientId,
      {}
    );
  }
  getDiagnosisById(id: number) {
    return this.commonService.getById(
      this.getDiagnosisByIdURL + "?id=" + id,
      {}
    );
  }
  createDiagnosis(data: DiagnosisModel) {
    return this.commonService.post(this.createDiagnosisURL, data);
  }
  deleteDiagnosis(id: number) {
    return this.commonService.patch(this.deleteDiagnosisURL + "?id=" + id, {});
  }
  savePatientInsurance(data: Array<PatientInsuranceModel>) {
    return this.commonService.post(this.savePatientInsurances, data);
  }
  //Patient Custom Label
  getPatientCustomLabel(clientId: number) {
    return this.commonService.getById(
      this.getPatientCustomLabels + "?patientId=" + clientId,
      {}
    );
  }

  savePatientCustomLabel(data: Array<ClientCustomLabelModel>) {
    return this.commonService.post(this.savePatientCustomLabels, data);
  }

  //Patient Medical Family History
  getPatientMedicalFamilyHistoryList(clientId: number) {
    return this.commonService.getAll(
      this.getPatientMedicalFamilyHistoryListURL + "?patientID=" + clientId,
      {}
    );
  }

  //Patient Surgical History
  getPatientSurgicalHistoryList(filterModel) {
    return this.commonService.post(
      this.getAllPatientSurgicalHistoryListURL,
      filterModel
    );
  }
  //Patient Hospitilization History
  getPatientHospitilizationHistoryList(filterModel) {
    return this.commonService.post(
      this.GetAllPatientHospitalizationHistoryData,
      filterModel
    );
  }

  getPatientMedicalFamilyHistoryById(id: number) {
    return this.commonService.getById(
      this.getPatientMedicalFamilyHistoryListURL + "?id=" + id,
      {}
    );
  }

  getPatientSurgicalHistoryHistoryById(id: number) {
    return this.commonService.getById(
      this.getPatientSurgicalHistoryByIdListURL + "?id=" + id,
      {}
    );
  }

  getPatientHospitilizationHistoryById(id: number) {
    return this.commonService.getById(
      this.getPatientHospitilizationHistoryListURL + "?Id=" + id,
      {}
    );
  }
  deletePatientMedicalFamilyHistory(id: number) {
    return this.commonService.patch(
      this.deletePatientMedicalFamilyHistoryURL + "?id=" + id,
      {}
    );
  }

  deletePatientSurgicalHistory(id: number) {
    return this.commonService.patch(
      this.deletePatientSurgicalHistoryByIdURL + "?id=" + id,
      {}
    );
  }

  deletePatientHospitilizationHistory(id: number) {
    return this.commonService.patch(
      this.DeletePatientHospitalizationHistoryData + "?id=" + id,
      {}
    );
  }
  savePatientFamilyHistoryData(data: PatientMedicalFamilyHistoryModel) {
    return this.commonService.post(this.savePatientFamilyHistoryDataURL, data);
  }

  savePatientSurgicalHistoryData(data: SurgicalHistoryModel) {
    return this.commonService.post(
      this.savePatientSurgicalHistoryDataURL,
      data
    );
  }

  savePatientHospitilizationHistoryData(data: HospitalizationHistoryModel) {
    return this.commonService.post(
      this.SavePatientHospitalizationHistoryData,
      data
    );
  }

  downloadFile(blob: Blob, filetype: string, filename: string) {
    var newBlob = new Blob([blob], { type: filetype });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 100);
  }
  openDCMFile(blob: Blob, filetype: string, filename: string) {
    var newBlob = new Blob([blob], { type: filetype });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 100);
    return data;
  }
  //Immunization Method
  createImmunization(data: ImmunizationModel) {
    return this.commonService.post(this.createImmunizationURL, data);
  }
  getImmunizationList(clientId: number) {
    let url = this.getImmunizationListURL + "?patientId=" + clientId;
    return this.commonService.getAll(url, {});
  }
  getImmunizationById(id: number) {
    return this.commonService.getById(
      this.getImmunizationByIdURL + "?id=" + id,
      {}
    );
  }
  deleteImmunization(id: number) {
    return this.commonService.patch(
      this.deleteImmunizationURL + "?id=" + id,
      {}
    );
  }

  getMasterDataDropdwon(value: string = "") {
    return this.commonService.post(this.getMasterData, { masterdata: value });
  }

  //Medication Method
  createMedication(data: PatientsMedicationModel) {
    return this.commonService.post(this.createMedicationURL, data);
  }

  //Medication Method
  createcreateUpdateERX(data: any) {
    return this.commonService.post(this.createUpdateERXURL, data);
  }

  //Medication Method
  createHerbalSupplement(data: MedicationModel) {
    return this.commonService.post(this.createSaveHerbalSupplementURL, data);
  }

  //Medication Method
  createOverCounterMedication(data: MedicationModel) {
    return this.commonService.post(
      this.createSaveOverCounterMedicationURL,
      data
    );
  }
  getMedicationList(clientId: number) {
    let url = this.getMedicationListURL + "?patientId=" + clientId;
    return this.commonService.getAll(url, {});
  }
  getMedicationListNames(clientId: number) {
    let url = this.GetMedicationNamesListURL + "?patientId=" + clientId;
    return this.commonService.getAll(url, {});
  }
  getMedicationByPatientId(clientId: number) {
    let url = this.getPatientMedicationListURL + "?patientId=" + clientId;
    return this.commonService.getAll(url, {});
  }

  // getMedicationListByPatientId(filterModel) {
  //   let url = this.getMedicationListbyPatientIdURL + "?patientId=" + clientId;
  //   return this.commonService.getAll(url, {});
  // }

  //Patient Surgical History
  getMedicationListByPatientId(filterModel) {
    return this.commonService.post(
      this.getMedicationListbyPatientIdURL,
      filterModel
    );
  }

  getTreatmentTimeline(filterModel: FilterModelTimeLineSummary) {
    let url = this.getTreatmentTimeLineURL;
    return this.commonService.post(url, filterModel);
  }

  getDetails() {
    var url = "https://api.1up.health/user-management/v1/user";

    let data = {
      app_user_id: "jitendra.chilate@smartdatainc.net",
      client_id: "391583ccdb48469da163bfbf88e2ef46",
      client_secret: "eQQfhGSYufylgcs3TiYpl1YRkfFFgWuy",
    };
    return this.commonService.getdatata(url, data);
  }

  getOverCounterMedicationList(clientId: number) {
    let url = this.getOverCounterMedicationURL + "?patientId=" + clientId;
    return this.commonService.getAll(url, {});
  }

  getMedicationNameList(searchText: string): Observable<any> {
    // http://108.168.203.227/HC_Patient_Merging/Patients/GetPatients
    const queryParams = `?searchKey=${searchText}`;
    return this.commonService.getAll(
      this.getMedicationNameListURL + queryParams,
      {}
    );
  }

  getLevelofEducationList(searchText: string): Observable<any> {
    const queryParams = `?SearchKey=${searchText}`;
    return this.commonService.getAll(
      this.getLevelOfEducationListURL + queryParams,
      {}
    );
  }

  getHerbalSupplementList(clientId: number) {
    let url = this.getHerbalSupplementURL + "?patientId=" + clientId;
    return this.commonService.getAll(url, {});
  }

  getmedicationById(id: number) {
    return this.commonService.getById(
      this.getMedicationByIdURL + "?id=" + id,
      {}
    );
  }
  deleteMedication(id: number) {
    return this.commonService.patch(this.deleteMedicationURL + "?id=" + id, {});
  }

  getherbalmedicationById(id: number) {
    return this.commonService.getById(
      this.getherbalMedicationByIdURL + "?id=" + id,
      {}
    );
  }

  getovermedicationById(id: number) {
    return this.commonService.getById(
      this.getoverMedicationByIdURL + "?id=" + id,
      {}
    );
  }

  deleteherbal(id: number) {
    return this.commonService.patch(
      this.deleteherbalMedicationURL + "?id=" + id,
      {}
    );
  }

  deleteover(id: number) {
    return this.commonService.patch(
      this.deleteoverMedicationURL + "?id=" + id,
      {}
    );
  }

  //Vitals Method
  createVital(data: VitalModel) {
    return this.commonService.post(this.createVitalURL, data);
  }

  //Vitals Method
  createPatientServiceFeedBack(data: ServiceFeedBackModel) {
    return this.commonService.post(this.createPatientServiceFeedBackURL, data);
  }
  createCommunicationCarePlan(data: CommuinicationModel) {
    return this.commonService.post(this.createCommunocationCarePlanURL, data);
  }
  getCommunicationCarePlan(patientId) {
    return this.commonService.getAll(
      this.getCommunocationCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  getAdlTrackingTool(
    patientPreferenceFilterModel: PatientPreferenceFilterModel
  ) {
    return this.commonService.post(
      this.getAdlTrackingToolUrl,
      patientPreferenceFilterModel
    );
  }

  getInputOutputChart(patientId: number, date: string) {
    return this.commonService.getById(
      this.getInputOutChartUrl + "?patientId=" + patientId + "&Date=" + date,
      {}
    );
  }

  getInputOutputChartTrend(
    patientId: number,
    toDate: string,
    fromDate: string,
    isDateSearch: boolean
  ) {
    return this.commonService.getById(
      this.getInputOutChartTrendUrl +
      "?patientId=" +
      patientId +
      "&toDate=" +
      toDate +
      "&fromDate=" +
      fromDate +
      "&isDateSearch=" +
      isDateSearch,
      {}
    );
  }

  getEsasRatingGraphData(id: number, locationId: number, shiftId: number) {
    return this.commonService.getById(
      this.GetViewESASRatingsByPatientId +
      "?patientId=" +
      id +
      "&locationId=" +
      locationId +
      "&shiftId=" +
      shiftId,
      {}
    );
  }

  getEsasRatingCurrentDayGraphData(monthFilerModel: MonthFilterModel) {
    return this.commonService.post(
      this.getESASRatingCurrentDayGraphDataUrl,
      monthFilerModel
    );
  }

  getVitalList1(clientId: number, filterModel: FilterModel) {
    let url =
      this.getVitalListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&PatientId=" +
      clientId +
      "&SortColumn=" +
      filterModel.sortColumn +
      "&SortOrder=" +
      filterModel.sortOrder;
    return this.commonService.getAll(url, {});
  }

  getLabDocument(filterModel: PatientPreferenceFilterModel) {
    return this.commonService.post(this.getLabDocumentURL, filterModel);
  }
  getRadiologyReports(filterModel: PatientPreferenceFilterModel) {
    return this.commonService.post(this.getRadiologyReportURL, filterModel);
  }
  getRadiologyDicoms(filterModel: PatientPreferenceFilterModel) {
    return this.commonService.post(this.getRadiologyDicomURL, filterModel);
  }
  getCardiacDocuments(filterModel: PatientPreferenceFilterModel) {
    return this.commonService.post(this.getCardiacDocumentURL, filterModel);
  }

  getLabsResult(filterModel: PatientPreferenceFilterModel) {
    return this.commonService.post(this.getLabsResultDocumentURL, filterModel);
  }

  getPathologyDocuments(filterModel: PatientPreferenceFilterModel) {
    return this.commonService.post(this.getPathologyDocumentURL, filterModel);
  }
  getOtherDocuments(filterModel: PatientPreferenceFilterModel) {
    return this.commonService.post(this.getOtherDocumentURL, filterModel);
  }

  deletePatientRadiologyReportDocuments(id: number) {
    return this.commonService.patch(
      this.deleteRadiologyDocumentURl + "?id=" + id,
      {}
    );
  }
  deletePatientRadiologyDicoms(id: number) {
    return this.commonService.patch(
      this.deleteRadiologyDicomURl + "?id=" + id,
      {}
    );
  }
  deletePatientPathologyDocuments(id: number) {
    return this.commonService.patch(
      this.deletePathologyDocumentURl + "?id=" + id,
      {}
    );
  }
  deletePatientCardiacDocuments(id: number) {
    return this.commonService.patch(
      this.deleteCardiacDocumentURl + "?id=" + id,
      {}
    );
  }
  deletePatientLabDocuments(id: number) {
    return this.commonService.patch(
      this.deletePatientLabDocumentURl + "?id=" + id,
      {}
    );
  }
  deletePatientOtherDocuments(id: number) {
    return this.commonService.patch(
      this.deletePatientOtherDocumentURl + "?id=" + id,
      {}
    );
  }
  getVitalList(filterModel: PatientPreferenceFilterModel) {
    //let url = this.getVitalListURL + "?pageNumber=" + filterModel.pageNumber + "&pageSize=" + filterModel.pageSize + "&PatientId=" + clientId + "&SortColumn=" + filterModel.sortColumn + "&SortOrder=" + filterModel.sortOrder;
    return this.commonService.post(this.getVitalListURL, filterModel);
  }

  getVitalGraphData(filterModel: PatientPreferenceFilterModel) {
    //let url = this.getVitalListURL + "?pageNumber=" + filterModel.pageNumber + "&pageSize=" + filterModel.pageSize + "&PatientId=" + clientId + "&SortColumn=" + filterModel.sortColumn + "&SortOrder=" + filterModel.sortOrder;
    return this.commonService.post(this.getVitalGraphURL, filterModel);
  }

  getFunctionalGraphData(filterModel: PatientPreferenceFilterModel) {
    //let url = this.getVitalListURL + "?pageNumber=" + filterModel.pageNumber + "&pageSize=" + filterModel.pageSize + "&PatientId=" + clientId + "&SortColumn=" + filterModel.sortColumn + "&SortOrder=" + filterModel.sortOrder;
    return this.commonService.post(this.getCareSummaryGraphURL, filterModel);
  }
  getVitalById(id: number) {
    return this.commonService.post(this.getVitalByIdURL + "?id=" + id, {});
  }
  deleteVital(id: number) {
    return this.commonService.patch(this.deleteVitalURL + "?id=" + id, {});
  }
  getLatestVitalById(id: number) {
    return this.commonService.getById(
      this.getLatestVitalByIdURL + "?id=" + id,
      {}
    );
  }
  getShiftAvailablityById(id: number) {
    return this.commonService.getById(this.getshiftbyId + "?shiftId=" + id, {});
  }

  getCarePlanGoalById(filterModel: PatientPreferenceFilterModel) {
    return this.commonService.post(this.getCarePlanGoalByIdURL, filterModel);
  }
  getCarePlanGoalByGoalId(id: number) {
    return this.commonService.getById(this.getCarePlanGoalByGoalIdURL + "?id=" + id, {});
  }
  //Allergy Method
  createAllergy(data: AllergyModel) {
    return this.commonService.post(this.createAllergyURL, data);
  }

  //Allergy Method
  createCheckAllergy(data: AllergyCheckModel) {
    return this.commonService.post(this.createCheckAllergyURL, data);
  }
  createInventory(data: InventoryModel) {
    return this.commonService.post(this.createInventoryURL, data);
  }

  getAllergyList(clientId: number, filterModel: FilterModel) {
    let url =
      this.getAllergyListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&PatientId=" +
      clientId +
      "&SortColumn=" +
      filterModel.sortColumn +
      "&SortOrder=" +
      filterModel.sortOrder;
    return this.commonService.getAll(url, {});
  }

  getAllergyCheckList(clientId: number, type: string) {
    let url =
      this.getAllergyCheckListURL + "?PatientID=" + clientId + "&Type=" + type;
    return this.commonService.getAll(url, {});
  }

  getAllPatientFamilyHistoryList(clientId: number, filterModel: FilterModel) {
    let url =
      this.getAllPatientFamilyHistoryURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&PatientId=" +
      clientId +
      "&SortColumn=" +
      filterModel.sortColumn +
      "&SortOrder=" +
      filterModel.sortOrder;
    return this.commonService.getAll(url, {});
  }
  // getAllergyById(id: number) {
  //     return this.commonService.getById(this.getAllergyByIdURL + "?id=" + id, {});
  // }
  // Personal Inventory
  createPersonalInventory(data: PersonalInventoryModel) {
    return this.commonService.post(this.createPersonalnventoryURL, data);
  }

  createUnitMaster(data: UnitMasterModel) {
    return this.commonService.post(this.createUnitMasterURL, data);
  }

  createLabResult(data: any) {
    return this.commonService.post(this.createLabResultURL, data);
  }

  createDocumentResult(data: OpenDocumentModel) {
    return this.commonService.post(this.createDocumentResultURL, data);
  }

  createReferralResult(data: OpenReferralModel) {
    return this.commonService.post(this.createReferralResultURL, data);
  }

  createLocalResourcesMaster(data: LocalResourcesModel) {
    return this.commonService.post(this.createLocalResourcesMasterURL, data);
  }

  createCareCoordinatorMaster(data: CareCoordinatorModel) {
    return this.commonService.post(this.createCareCoordinatorMasterURL, data);
  }

  createPatientCareProvider(data: CareProviderModel) {
    return this.commonService.post(this.createPatientCareProviderURL, data);
  }

  createPatientTag(data: PatientsTagModel2) {
    return this.commonService.post(this.createPatientTagURL, data);
  }

  createManageTags(data: ManageTagsModel) {
    return this.commonService.post(this.createManageTagsURL, data);
  }

  createPatientNotes(data: PatientsNotesModel) {
    return this.commonService.post(this.createPatientNotesURL, data);
  }

  createPatientAppointment(data: PatientAppointmentModel) {
    return this.commonService.post(this.createPatientAppointmentURL, data);
  }

  getpersonalinventory(inventoryFilterModel: InventoryFilterModel) {
    let url =
      this.getpersonalinventoryURL +
      "?pageNumber=" +
      inventoryFilterModel.pageNumber +
      "&pageSize=" +
      inventoryFilterModel.pageSize +
      "&sortColumn=" +
      inventoryFilterModel.sortColumn +
      "&sortOrder=" +
      inventoryFilterModel.sortOrder +
      "&searchKey=" +
      inventoryFilterModel.searchText +
      "&patientId=" +
      inventoryFilterModel.patientId;
    return this.commonService.getAll(url, {}, false);
  }
  getPersonalInventoryById(id: number) {
    return this.commonService.getById(
      this.getPersonalInventoryByIdURL + "?PersonalInventoryId=" + id,
      {}
    );
  }
  deleteinventory(id: number) {
    return this.commonService.patch(
      this.deleteInventoryURL + "?PersonalInventoryId=" + id,
      {}
    );
  }
  deleteCarePlanGoalByGoalId(id: number) {
    return this.commonService.patch(
      this.deleteCarePlanGoalByGoalIdURL + "?CarePlanGoalId=" + id,
      {}
    );
  }

  // Patient Preference
  createPatientPreference(data: ConsentsPreferenceModel) {
    return this.commonService.post(
      this.savePatientConsentsPreferencesURL,
      data
    );
  }

  getPatientPreferenceList(
    patientPreferenceFilterModel: PatientPreferenceFilterModel
  ) {
    let url =
      this.getPatientConsentsPreferencesURL +
      "?pageNumber=" +
      patientPreferenceFilterModel.pageNumber +
      "&pageSize=" +
      patientPreferenceFilterModel.pageSize +
      "&sortColumn=" +
      patientPreferenceFilterModel.sortColumn +
      "&sortOrder=" +
      patientPreferenceFilterModel.sortOrder +
      "&searchKey=" +
      patientPreferenceFilterModel.searchText +
      "&patientId=" +
      patientPreferenceFilterModel.patientId;
    return this.commonService.getAll(url, {}, false);
    // return this.commonService.post(this.getPatientConsentsPreferencesURL, patientPreferenceFilterModel);
  }

  getPatientServiceFeedBackList(
    patientPreferenceFilterModel: PatientPreferenceFilterModel
  ) {
    let url =
      this.getPatientServiceFeedBackURL +
      "?pageNumber=" +
      patientPreferenceFilterModel.pageNumber +
      "&pageSize=" +
      patientPreferenceFilterModel.pageSize +
      "&sortColumn=" +
      patientPreferenceFilterModel.sortColumn +
      "&sortOrder=" +
      patientPreferenceFilterModel.sortOrder +
      "&searchKey=" +
      patientPreferenceFilterModel.searchText +
      "&patientId=" +
      patientPreferenceFilterModel.patientId;
    return this.commonService.getAll(url, {}, false);
    // return this.commonService.post(this.getPatientConsentsPreferencesURL, patientPreferenceFilterModel);
  }

  deletePatientPreference(id: number) {
    return this.commonService.patch(
      this.deletePatientConsentsPreferencesURL +
      "?PatientConsentsPreferencesID=" +
      id,
      {}
    );
  }

  getPatientPreference(id: number) {
    return this.commonService.getById(
      this.getPatientConsentsPreferencesByIdURL +
      "?PatientConsentsPreferencesID=" +
      id,
      {}
    );
  }

  updatePatientPreferenceStatus(id: number, status: boolean) {
    console.log("from call" + status);
    return this.commonService.patch(
      this.updatePatientPreferenceStatusURL +
      "?PatientConsentsPreferencesID=" +
      id +
      "&isActive=" +
      status,
      {}
    );
  }

  deleteAllergy(id: number) {
    return this.commonService.patch(this.deleteAllergyURL + "?id=" + id, {});
  }

  getAllergyById(id: number) {
    return this.commonService.getById(this.getAllergyByIdURL + "?id=" + id, {});
  }

  //authorization Methods
  getAllAuthorization(clientId: number, filterModel: any) {
    let url =
      this.getAllAuthorizationsForPatientURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&patientId=" +
      clientId +
      "&SortColumn=" +
      filterModel.sortColumn +
      "&SortOrder=" +
      filterModel.sortOrder +
      "&authType=" +
      filterModel.authType;
    return this.commonService.getAll(url, {});
  }
  getAuthorizationById(authorizationId: number) {
    return this.commonService.getById(
      this.getAuthorizationByIdURL + "?authorizationId=" + authorizationId,
      {}
    );
  }
  deleteAuthorization(id: number) {
    return this.commonService.patch(
      this.deleteAuthorizationURL + "?id=" + id,
      {}
    );
  }
  getPatientPayerServiceCodesAndModifiers(
    clientId: number,
    payerId: number,
    patientInsuranceId: number
  ) {
    let url =
      this.getPatientPayerServiceCodesAndModifiersURL +
      "?patientId=" +
      clientId +
      "&PayerPreference=" +
      "Primary" +
      "&date=" +
      new Date() +
      "&payerId=" +
      payerId +
      "&patientInsuranceId=" +
      patientInsuranceId;
    return this.commonService.getAll(url, {});
  }
  createAuthorization(data: AuthModel) {
    return this.commonService.post(this.createAuthorizationURL, data);
  }

  //client encounter Methods
  getClientEncounters(
    filterModel: any,
    clientId: number,
    appointmentType: number,
    staffName: string,
    status: string,
    fromDate: string,
    toDate: string
  ) {
    let url =
      this.getPatientEncountersListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&patientId=" +
      clientId +
      "&SortColumn=" +
      filterModel.sortColumn +
      "&SortOrder=" +
      filterModel.sortOrder;
    if (appointmentType && appointmentType > 0)
      url = url + "&appointmentType=" + appointmentType;
    if (staffName && staffName != "") url = url + "&staffName=" + staffName;
    if (status && status != "") url = url + "&status=" + status;
    if (fromDate && fromDate != "") url = url + "&fromDate=" + fromDate;
    if (toDate && toDate != "") url = url + "&toDate=" + toDate;
    return this.commonService.getAll(url, {});
  }

  getClaimsForClientLegder(clientId: number, filterModel: FilterModel) {
    let url =
      this.getClaimsForLedgerURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&patientId=" +
      clientId +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder;
    return this.commonService.getAll(url, {});
  }
  getClaimServiceLinesForPatient(claimId: number) {
    return this.commonService.getById(
      this.getClaimServiceLineForPatientLedgerURL + "?claimId=" + claimId,
      {}
    );
  }
  getPaymentDetailById(paymentDetailId: number) {
    return this.commonService.getById(
      this.getPaymentDetailByIdURL + "?paymentDetailId=" + paymentDetailId,
      {}
    );
  }
  getPatientGuarantor(patientId: number) {
    return this.commonService.getById(
      this.getPatientGuarantorURL + "?patientId=" + patientId,
      {}
    );
  }
  saveServiceLinePayment(paymentModel: ClientLedgerPaymentDetailsModel) {
    return this.commonService.post(
      this.saveServiceLinePaymentURL,
      paymentModel
    );
  }
  deleteServiceLinePaymentDetail(id: number) {
    return this.commonService.patch(
      this.deleteServiceLinePaymentURL + "/" + id,
      {}
    );
  }

  getUserDocuments(userId: number, from: string, toDate: string) {
    return this.commonService.getAll(
      this.getUserDocumentsURL +
      "?userId=" +
      userId +
      "&from=" +
      from +
      "&to=" +
      toDate,
      {}
    );
  }
  getUserCareDocuments(userId: number, pageNumber: number, pageSize: number, searchText: string) {
    return this.commonService.getAll(
      this.getUserCarePlanDocumentsURL +
      "?userId=" +
      userId +
      "&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&searchText=" +
      searchText,
      {}
    );
  }
  getWoundImages(userId: number, from: string, toDate: string) {
    return this.commonService.getAll(
      this.getWoundImgURL +
      "?userId=" +
      userId +
      "&from=" +
      from +
      "&to=" +
      toDate,
      {}
    );
  }

  getUserByLocation(locationId: number) {
    let url =
      this.getUserByLocationURL +
      "?locationIds=" +
      locationId +
      "&permissionKey=SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES&isActiveCheckRequired=YES";
    return this.commonService.getAll(url, {});
  }
  getUserDocument(id: number) {
    return this.commonService.download(
      this.getUserDocumentURL + "?id=" + id,
      {}
    );
  }
  deleteUserDocument(id: number) {
    return this.commonService.patch(
      this.deleteUserDocumentURL + "?id=" + id,
      {}
    );
  }
  deleteWoundImage(id: number) {
    return this.commonService.patch(this.deleteWoundImg + "?id=" + id, {});
  }
  downloadWoundImage(id: number) {
    return this.commonService.download(this.downloadWoundImg + "?id=" + id, {});
  }

  uploadUserDocuments(data: AddUserDocumentModel) {
    return this.commonService.post(this.uploadUserDocumentURL, data);
  }
  uploadPatientResultLabDocuments(data: any) {
    return this.commonService.post(this.patientResultLabDocumentURL, data);
  }
  uploadPatientResultRadiologyDicom(data: any) {
    return this.commonService.post(this.patientResultRadiologyDicomURL, data);
  }
  uploadPatientResultRadiologyReports(data: any) {
    return this.commonService.post(this.PatientResultRadiologyReportsURL, data);
  }
  uploadPatientCardiacReports(data: any) {
    return this.commonService.post(this.PatientCardiacReportsURL, data);
  }
  uploadPatientPathologyReports(data: any) {
    return this.commonService.post(this.PatientPathologyReportsURL, data);
  }
  uploadPatientOthersReports(data: any) {
    return this.commonService.post(this.PatientOthersReportsURL, data);
  }
  downloadPatientLabDocuments(id: number) {
    return this.commonService.download(
      this.getPatientLabDocumentURL + "?id=" + id,
      {}
    );
  }
  downloadPatientRadiologyDicoms(id: number) {
    return this.commonService.download(
      this.getPatientRadiologyDicomURL + "?id=" + id,
      {}
    );
  }
  downloadPatientRadiologyReports(id: number) {
    return this.commonService.download(
      this.getPatientRadiologyReportURL + "?id=" + id,
      {}
    );
  }
  downloadPatientCardiacReports(id: number) {
    return this.commonService.download(
      this.getPatientCardiacReportURL + "?id=" + id,
      {}
    );
  }
  downloadPatientPathologyReports(id: number) {
    return this.commonService.download(
      this.getPatientPathologyReportURL + "?id=" + id,
      {}
    );
  }
  downloadPatientOtherReports(id: number) {
    return this.commonService.download(
      this.getPatientOtherReportURL + "?id=" + id,
      {}
    );
  }

  getUserScreenActionPermissions(moduleName: string, screenName: string): any {
    return this.commonService.getUserScreenActionPermissions(
      moduleName,
      screenName
    );
  }
  //chat
  getChatHistory(fromUserId: number, toUserId: number) {
    return this.commonService.getAll(
      `${this.getChatHistoryURL}?FromUserId=${fromUserId}&ToUserId=${toUserId}`,
      {}
    );
  }
  importCCDA(data: any) {
    return this.commonService.post(this.importCCDAURL, { file: data });
  }
  getStateByCountryId(id: number) {
    return this.commonService.getById(
      this.getStateByCountryIdUrl + "?countryID=" + id,
      {}
    );
  }

  getRoomsByUnitId(id: number, isRoomBooked: Boolean, patientId?: number) {
    return this.commonService.getById(
      this.getRoomNUmberByUnitId +
      "?unitId=" +
      id +
      "&isRoomBooked=" +
      isRoomBooked +
      "&patientId=" +
      patientId,
      {}
    );
  }

  getRoomsByRoomId(id: number, isRoomBooked: Boolean, patientId?: number) {
    return this.commonService.getById(
      this.getRoomNUmberByRoomId +
      "?roomId=" +
      id +
      "&isRoomBooked=" +
      isRoomBooked +
      "&patientId=" +
      patientId,
      {}
    );
  }

  createVitalAdd(data: VitalModelAdd) {
    return this.commonService.post(this.createVitalURL, data);
  }
  createAddTRA(data: TARModel) {
    return this.commonService.post(this.createTARUrl, data);
  }

  updateTarCrossOutReason(tarId:number,crossOutReasonId:number) {
    return this.commonService.post(this.updateTarCrossOutReasonUrl + `?tarId=${tarId}&crossOutReasonId=${crossOutReasonId}`, 
      '');
  }
  createReferForm(data: any) {
    return this.commonService.post(this.createReferURL, data);
  }

  createIncomingReferForm(data: any) {
    return this.commonService.post(this.createIncomingReferURL, data);
  }

  createMealPlanForm(data: any) {
    return this.commonService.post(this.createMealPlanURL, data);
  }

  createMealCategoryForm(data: any) {
    return this.commonService.post(this.createMealCategoryURL, data);
  }

  createMealItemForm(data: any) {
    return this.commonService.post(this.createMealItemURL, data);
  }

  createPatientTransferForm(data: any) {
    return this.commonService.post(this.createPatientTransferURL, data);
  }

  getPatientTransferDocument(id:any)
  {
    return this.commonService.download(
        this.getPatientTransferDocumentURL + "?id=" + id,
        { }
      );
  }

  createIncidentLogReport(data: any) {
    return this.commonService.post(this.createIncidentLogReportURL, data);
  }

  createCarePlanGoal(data: CarePlanGoalModel) {
    return this.commonService.post(this.createCarePlanGoalURl, data);
  }
  createCardiovascularProcessNote(data: CardiovascularModel) {
    return this.commonService.post(this.createVitalURL, data);
  }
  checkHealthCardPatient(phcNumber: string, patientId: number) {
    return this.commonService.getById(
      this.getCheckHealthCardPatientUrl +
      "?phcnumber=" +
      phcNumber +
      "&patientId=" +
      patientId,
      {}
    );
  }

  getProgressNotes(clientId: number, IsDraft: boolean) {
    // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
    return this.commonService.getAll(
      this.getProgressNoteURL +
      "?PatientId=" +
      clientId +
      "&IsDraft=" +
      IsDraft,
      {}
    );
  }
  getTAR(filterModel: IncidentReportFilter) {
    // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
    return this.commonService.getdatata(this.getTARUrl, filterModel);
  }
  getProgressNotes1(filterModel: ProgressNoteFilterModel) {
    return this.commonService.getdatata(
      this.getProgressNoteSearchURL,
      filterModel
    );
  }

  getTARSearch(filterModel: ProgressNoteFilterModel) {
    return this.commonService.getdatata(this.getTARSearchURL, filterModel);
  }

  DeleteTARById(id: number) {
    return this.commonService.patch(
      this.deleteTarByIdtUrl + "?Id=" + id,
      {}
    );
  }
  getTARById(id: number) {
    return this.commonService.getById(
      this.getTARByIdUrl +
      "?Id=" +
      id,
      {}
    );
  }
  getAllIncidentReports(filterModel: IncidentReportFilter) {
    return this.commonService.getdatata(
      this.getAllIncidentReportURL,
      filterModel
    );
  }

  getIncidentReportById(id: number) {
    return this.commonService.getById(
      this.getIncidentReportByIdUrl +
      "?Id=" +
      id,
      {}
    );
  }
  deleteIncident(id: number) {
    return this.commonService.patch(
      this.deleteIncidentReportUrl + "?Id=" + id,
      {}
    );
  }

  getProgressNotesWithDate(filterModel: ProgressNoteFilterModel) {
    return this.commonService.getdatata(
      this.getProgressNoteWithDateSearchURL,
      filterModel
    );
  }

  getTARwithDate(filterModel: ProgressNoteFilterModel) {
    return this.commonService.getdatata(this.getTARbyDateURL, filterModel);
  }

  createNewNotes(data: CreateNewNotesModel) {
    return this.commonService.post(this.postSaveProgressNoteURL, data);
  }

  updateProgressNoteCrossOutReason(progressNoteId:number,crossOutReasonId:number) {
    return this.commonService.post(this.updateProgressNoteCrossOutReasonUrl + `?progressNoteId=${progressNoteId}&crossOutReasonId=${crossOutReasonId}`, 
      '');
  }

  createNewNotesSaveASDraft(data: CreateNewNotesModel) {
    return this.commonService.post(
      this.postSaveNoteAsDraftProgressNoteURL,
      data
    );
  }

  getGastroIntestinalProgressNotes(ProgressNotesId: number, IsDraft: boolean) {
    return this.commonService.getAll(
      this.getGastroIntestinalProgressNoteURL +
      "?PatientsProgressNotesId=" +
      ProgressNotesId +
      "&IsDraft=" +
      IsDraft,
      {}
    );
  }
  getGenitourinaryProgressNotes(ProgressNotesId: number, IsDraft: boolean) {
    return this.commonService.getAll(
      this.getGenitourinaryProgressNoteURL +
      "?PatientsProgressNotesId=" +
      ProgressNotesId +
      "&IsDraft=" +
      IsDraft,
      {}
    );
  }
  getCardiovascularProgressNotes(ProgressNotesId: number, IsDraft: boolean) {
    // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
    return this.commonService.getAll(
      this.getCardiovascularProgressNoteURL +
      "?PatientsProgressNotesId=" +
      ProgressNotesId +
      "&IsDraft=" +
      IsDraft,
      {}
    );
  }
  getRespiratoryProgressNotes(ProgressNotesId: number, IsDraft: boolean) {
    // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
    return this.commonService.getAll(
      this.getRespiratoryProgressNoteURL +
      "?PatientsProgressNotesId=" +
      ProgressNotesId +
      "&IsDraft=" +
      IsDraft,
      {}
    );
  }
  getNeurologicalProgressNotes(ProgressNotesId: number, IsDraft: boolean) {
    // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
    return this.commonService.getAll(
      this.getNeurologicalProgressNotesURL +
      "?PatientsProgressNotesId=" +
      ProgressNotesId +
      "&IsDraft=" +
      IsDraft,
      {}
    );
  }
  gettMusculokeletalProgressNotes(ProgressNotesId: number, IsDraft: boolean) {
    // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
    return this.commonService.getAll(
      this.getMusculokeletalProgressNoteURL +
      "?PatientsProgressNotesId=" +
      ProgressNotesId +
      "&IsDraft=" +
      IsDraft,
      {}
    );
  }

  getpatientAdminissionDroDownList() {
    return this.commonService.getAll(
      this.getpatientAdminissionDropDownListURL,
      {}
    );
  }
  createpatientAdminission(data: PatientsMDSAdmissionModel) {
    return this.commonService.post(this.createMDSAdmissionURL, data);
  }

  createAdtAdmission(data: AddAdmissionModel) {
    return this.commonService.post(this.createAdtAdmissionURL, data);
  }

  getAllAdmissionList(filterModel: ADTPatientFilterModel) {
    return this.commonService.post(this.getAllAdmissionListURL, filterModel);
  }

  getAdmissionById(Id: number) {
    return this.commonService.getById(
      this.getAdmissionByIdURL + "?id=" + Id,
      {}
    );
  }

  deleteADTAdmissionDetails(id: number) {
    return this.commonService.patch(
      this.deleteAdmissionDetailsURL + "?id=" + id,
      {}
    );
  }

  createAdtDischarge(data: AddDischargeModel) {
    return this.commonService.post(this.createAdtDischargeURL, data);
  }

  getAllDischargeList(filterModel: ADTPatientFilterModel) {
    return this.commonService.post(this.getAllDischargeListURL, filterModel);
  }

  getDischargeDetailsById(Id: number) {
    return this.commonService.getById(
      this.getDischargeDetailsByIdURL + "?id=" + Id,
      {}
    );
  }

  deleteADTDischargeDetails(id: number) {
    return this.commonService.patch(
      this.deleteDischargeDetailsURL + "?id=" + id,
      {}
    );
  }

  getAllTransferList(filterModel: ADTPatientFilterModel) {
    return this.commonService.post(this.getAllTransferListURL, filterModel);
  }

  getTransferById(Id: number) {
    return this.commonService.getById(
      this.getTransferDetailsByIdURL + "?id=" + Id,
      {}
    );
  }

  deleteADTTransferDetails(id: number) {
    return this.commonService.patch(
      this.deleteTransferDetailsURL + "?id=" + id,
      {}
    );
  }

  getpatientAdminissionbyHeathCardNo(HealthCardNumber: string) {
    console.log("post hCard ", HealthCardNumber);
    return this.commonService.getAll(
      this.getHeathCardNumberMDSAdmissionURL +
      "?healthCardNumber=" +
      HealthCardNumber,
      {}
    );
  }
  getUnitsByLocationId(id: number) {
    return this.commonService.getById(
      this.getUnitsByLocationIdUrl + "?locationId=" + id,
      {}
    );
  }

  getStaffAndPatientByLocation(
    unitId: number,
    permissionKey: string = "SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES") {
    const queryParams = `?locationIds=0&permissionKey=${permissionKey}&isActiveCheckRequired=YES&unitId=${unitId}&isActiveCheckRequired=YES&shiftId=0`;
    return this.commonService.getAll(
      this.getStaffAndPatientByLocationUrl + queryParams,
      {}
    );
  }

  getpatientsearchRecord(searchRecord: string) {
    console.log("post hCard ", searchRecord);
    return this.commonService.getAll(
      this.getPatientRecordSearchURL + "?searchRecord=" + searchRecord,
      {}
    );
  }

  getstaffsearchRecord(searchRecord: string) {
    console.log("post hCard ", searchRecord);
    return this.commonService.getAll(
      this.getStaffRecordSearchURL + "?searchRecord=" + searchRecord,
      {}
    );
  }

  getdiagnosisBySearch(DiagnosisName: string) {
    console.log("post hCard ", DiagnosisName);
    return this.commonService.getAll(
      this.getDiagnosisListtURL + "?DiagnosisName=" + DiagnosisName,
      {}
    );
  }

  getSurgeryBySearch(DiagnosisName: string) {
    return this.commonService.getAll(
      this.getSurgeryListURL + "?surgeryName=" + DiagnosisName,
      {}
    );
  }

  createpatientdischarge(data: PatientsMDSAdmissionModel) {
    return this.commonService.post(this.createpatientdischargeURL, data);
  }
  createpatienteRX(data: eRxmodel) {
    return this.commonService.post(this.createRXURL, data);
  }
  createpatientsRX(data: pRxmodel) {
    return this.commonService.post(this.creatpRXURL, data);
  }
  getpatientAdminissionbyHeathCardNobyData(HealthCardNumber: string) {
    console.log("post hCard ", HealthCardNumber);
    return this.commonService.getAll(
      this.getHeathCardNumberMDSAdmissionCardByDataURL +
      "?healthCardNumber=" +
      HealthCardNumber,
      {}
    );
  }

  getStaffDetailByUsingName(HealthCardNumber: string) {
    console.log("post hCard ", HealthCardNumber);
    return this.commonService.getAll(
      this.getHeathCardNumberMDSAdmissionCardByDataURL +
      "?healthCardNumber=" +
      HealthCardNumber,
      {}
    );
  }

  createCareSummary(data: CareSummaryModel) {
    return this.commonService.post(this.createCarePlanURL, data);
  }
  getCareSummarybyPatientID(PatientID: number) {
    return this.commonService.getAll(
      this.getCareSummaryURL + "?patientId=" + PatientID,
      {}
    );
  }

  GetTagsByPatientId(PatientID: number) {
    return this.commonService.getAll(
      this.getTagsByPatientId + "?patientID=" + PatientID,
      {}
    );
  }

  createRoutineCarePlan(data: routinesCarePlanModel) {
    return this.commonService.post(this.createRoutineCarePlanURL, data);
  }

  getRoutines(patientId) {
    return this.commonService.getAll(
      this.getRoutineCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createCognitiveStatusCarePlan(data: CognitiveCarePlanModel) {
    return this.commonService.post(this.createCognitiveStatusCarePlanURL, data);
  }

  getMasterDataForTransferTo(masterData: any): Observable<any> {
    return this.commonService.post(this.getllMasterProviderListURL, masterData);
  }

  getStaffList(masterData: any): Observable<any> {
    return this.commonService.post(this.getAllStaffListtURL, masterData);
  }

  getMasterStatus(masterData: any): Observable<any> {
    return this.commonService.post(this.getMasterStatusListURL, masterData);
  }

  getMasterMealCategory(masterData: any): Observable<any> {
    return this.commonService.post(
      this.getMasterMealCategoryListURL,
      masterData
    );
  }

  // getMasterMealItem(masterData: any): Observable<any> {
  getAllLocalResourcesMasterForDropDown(masterData: any): Observable<any> {
    return this.commonService.post(
      this.getAllLocalResourcesMasterForDropDownURL,
      masterData
    );
  }

  getMasterMealItem(masterData: any): Observable<any> {
    return this.commonService.post(this.getMasterMealItemListURL, masterData);
  }

  getMasterTypeOfFood(masterData: any): Observable<any> {
    return this.commonService.post(this.getMasterTypeofFoodListURL, masterData);
  }

  getMasterFluidConsistency(masterData: any): Observable<any> {
    return this.commonService.post(
      this.getMasterFluidConsistencyListURL,
      masterData
    );
  }

  getIncomingReferral(masterData: any): Observable<any> {
    return this.commonService.post(this.getIncomingReferralListURL, masterData);
  }

  getMasterDataForResidentTransferTo(masterData: any): Observable<any> {
    return this.commonService.post(
      "api/MasterData/MasterDataByName",
      masterData
    );
  }

  getCognitiveStatusCarePlan(patientId) {
    return this.commonService.getAll(
      this.getCognitiveStatusCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createBehavioursCarePlan(data: BehaviorsCarePlanModel) {
    return this.commonService.post(this.createBehavioursCarePlanURL, data);
  }

  getBehavioursCarePlan(patientId) {
    return this.commonService.getAll(
      this.getBehavioursCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createDressingCarePlan(data: DressingCarePlanModel) {
    return this.commonService.post(this.createDressingCarePlanURL, data);
  }

  getDressingCarePlan(patientId) {
    return this.commonService.getAll(
      this.getDressingCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createSafetyCarePlan(data: SafetyCarePlanModel) {
    return this.commonService.post(this.createSafetyCarePlanURL, data);
  }

  getSafetyCarePlan(patientId) {
    return this.commonService.getAll(
      this.getSafetyCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createBathingCarePlan(data: BathingCarePlanModel) {
    return this.commonService.post(this.createBathingCarePlanURL, data);
  }

  getBathingCarePlan(patientId) {
    return this.commonService.getAll(
      this.getBathingCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createNutritionCarePlan(data: NutritionCarePlanModel) {
    return this.commonService.post(this.createNutritionCarePlanURL, data);
  }

  getNutritionCarePlan(patientId) {
    return this.commonService.getAll(
      this.getNutritionCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createHygieneCarePlan(data: HygieneCarePlanModel) {
    return this.commonService.post(this.createHygieneCarePlanURL, data);
  }

  getHygieneCarePlan(patientId) {
    return this.commonService.getAll(
      this.getHygieneCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createSkinCarePlan(data: SkinCarePlanModel) {
    return this.commonService.post(this.createSkinCarePlanURL, data);
  }

  getSkinCarePlan(patientId) {
    return this.commonService.getAll(
      this.getSkinCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createMobilityCarePlan(data: MobilityCarePlanModel) {
    return this.commonService.post(this.createMobilityCarePlanURL, data);
  }

  getMobilityCarePlan(patientId) {
    return this.commonService.getAll(
      this.getMobilityCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createTransferCarePlan(data: TransferCarePlanModel) {
    return this.commonService.post(this.createTransferCarePlanURL, data);
  }

  getTransferCarePlan(patientId) {
    return this.commonService.getAll(
      this.getTransferCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createToiletingCarePlan(data: ToiletingCarePlanModel) {
    return this.commonService.post(this.createToiletingCarePlanURL, data);
  }

  getToiletingCarePlan(patientId) {
    return this.commonService.getAll(
      this.getToiletingCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }
  createBladderCarePlan(data: BladderCarePlanModel) {
    return this.commonService.post(this.createBladderCarePlanURL, data);
  }

  getBladderCarePlan(patientId) {
    return this.commonService.getAll(
      this.getBladderCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  createBowelCarePlan(data: BowelCarePlanModel) {
    return this.commonService.post(this.createBowelCarePlanURL, data);
  }

  getBowelCarePlan(patientId) {
    return this.commonService.getAll(
      this.getBowelCarePlanURL + "?patientId=" + patientId,
      {}
    );
  }

  changeTaskStatus(taskId, statusId) {
    return this.commonService.getAll(
      this.changeTaskStatusURL +
      "?taskId=" +
      taskId +
      "&taskStatusId=" +
      statusId,
      {}
    );
  }

  getrelationshipData(SearchKey: any) {
    return this.commonService.getAll(
      this.getrelationshipDataURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getSubjectiveList(SearchKey: any) {
    return this.commonService.getAll(
      this.getSubjectiveListListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getRouteList(SearchKey: any) {
    return this.commonService.getAll(
      this.getRouteListListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getReasonList(SearchKey: any) {
    return this.commonService.getAll(
      this.getReasonListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getFrequencyList(SearchKey: any) {
    return this.commonService.getAll(
      this.getFrequencyListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getMedicationsList(SearchKey: any) {
    return this.commonService.getAll(
      this.getMedicationsListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getALlInventory(filterModel: InventoryListFilterModel) {
    return this.commonService.post(this.getAllInventoryList, filterModel);
  }

  GetConsentList(filterModel: EmarFilterModel) {
    return this.commonService.post(this.GetAllConcentList, filterModel);
  }

  getAllInvantoryTrack(filterModel: InventoryTrackFilterModel) {
    return this.commonService.post(this.getAllInventoryTrackList, filterModel);
  }

  getAllReorderTrack(filterModel: ReorderTrackFilterModel) {
    return this.commonService.post(this.getAllReorderTrackList, filterModel);
  }

  getInventoryTrackById(id: number) {
    return this.commonService.getById(
      this.GetByInventoryTrackIdURL + "?Id=" + id,
      {}
    );
  }

  getDrugnameList(SearchKey: any) {
    return this.commonService.getAll(
      this.getDrugNameURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getLanguage(SearchKey: any) {
    return this.commonService.getAll(
      this.getMasterLanguages + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getInventoryName(SearchKey: any) {
    return this.commonService.getAll(
      this.getInventoryNameURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  createUpdateStockOut(model: StockOutModel) {
    return this.commonService.post(this.saveUpdateStockOutUrl, model);
  }

  GetPatientName(SearchKey: any) {
    return this.commonService.getAll(
      this.GetPatientNameURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  deleteInventoryMaster(id: number) {
    return this.commonService.patch(
      this.deleteInventorymasterURL + "?Id=" + id,
      {}
    );
  }

  deleteIncomingFax(fileName: string) {
    return this.commonService.patch(
      this.deleteIncomingFaxURL + "?fileName=" + fileName,
      {}
    );
  }

  saveIncomingFaxDocument(data: IncomingFaxDocumentModel) {
    return this.commonService.post(this.saveIncomingFaxURL,data);
}

  getProductsList(SearchKey: any) {
    return this.commonService.getAll(
      this.getProductsListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getReligionalList(SearchKey: any) {
    return this.commonService.getAll(
      this.getReligionListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }
  getUnitList(SearchKey: any) {
    return this.commonService.getAll(
      this.getUnitListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  GetAssessmentList(SearchKey: any) {
    return this.commonService.getAll(
      this.getGetAssessmentListURL + "?SearchKey=" + SearchKey,
      {}
    );
  }

  getAllergyTypeList(searchText: string): Observable<any> {
    const queryParams = `?SearchKey=${searchText}`;
    return this.commonService.getAll(
      this.getAllergyTypeListtURL + queryParams,
      {}
    );
  }
  getallergenList(searchText: string): Observable<any> {
    const queryParams = `?SearchKey=${searchText}`;
    return this.commonService.getAll(this.getAllernListtURL + queryParams, {});
  }

  getdiagnosisList(searchText: string): Observable<any> {
    const queryParams = `?SearchKey=${searchText}`;
    return this.commonService.getAll(
      this.getDiagnosisListtURL + queryParams,
      {}
    );
  }

  getSeverityList(searchText: string): Observable<any> {
    const queryParams = `?SearchKey=${searchText}`;
    return this.commonService.getAll(
      this.getSeverityListtURL + queryParams,
      {}
    );
  }
  getallergyStatusList(searchText: string): Observable<any> {
    const queryParams = `?SearchKey=${searchText}`;
    return this.commonService.getAll(
      this.getAllergyStatusListURL + queryParams,
      {}
    );
  }

  getEmrPatientList(filterModel: EmarFilterModel) {
    let url =
      this.getEMRPatientListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&searchDate=" +
      filterModel.searchDate +
      "&UnitId=" +
      filterModel.unitId+
      "&Date=" +
      filterModel.date;
    return this.commonService.getAll(url, {});
  }
  getEmrPatientDateFilterList(filterModel: EmarFilterModel) {
    let url =
      this.getEMRPatientDateListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&searchTime=" +
      filterModel.searchTime +
      "&UnitId=" +
      filterModel.unitId +
      "&SearchDate=" +
      filterModel.date+
      "&PrescriptionTypeId=" +
      filterModel.prescriptionTypeId+
      "&ShiftStartDate="+
      filterModel.shiftStartDate+
      "&ShiftEndDate="+
      filterModel.shiftEndDate;
    return this.commonService.getAll(url, {});
  }

  getEMRPatientMedicationPassProgressList(
    filterModel: FilterModelMedicationPassed
  ) {
    let url =
      this.getEMRPatientMedicationPassProgressListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&searchDate=" +
      filterModel.searchDate +
      "&searchTime=" +
      filterModel.searchDdateTime;
    return this.commonService.getAll(url, {});
  }

  getEMRPatientMissedMedicationList(filterModel: TrackingFilterModel) {
    let url =
      this.getEMRPatientMissedMedicationListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder+
      "&fromDate=" +
      filterModel.fromDate+
      "&toDate=" +
      filterModel.toDate;
    return this.commonService.getAll(url, {});
  }

  getMissedMedicationListNotification(filterModel: FilterModel) {
    let url =
      this.getEMRPatientMissedMedicationListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder;
    return this.commonService.getAll(url, {});
  }

  getEMRPatientReminderList(filterModel: FilterNewModelReminder) {
    let url =
      this.getEMRPatientReminderListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&SearchDate=" +
      filterModel.searchDate +
      "&SearchText=" +
      filterModel.searchText;
    return this.commonService.getAll(url, {});
  }

  getEMRPatientDiscountinueOrderList(filterModel: TrackingFilterModel) {
    let url =
      this.getEMRPatientMedicationDiscontinueOrderListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&searchText=" +
      filterModel.searchText+
      "&fromDate=" +
      filterModel.fromDate+
      "&toDate=" +
      filterModel.toDate;
    return this.commonService.getAll(url, {});
  }

  getEMRPatientMedicationIncidentReportLogList(filterModel: TrackingFilterModel) {
    let url =
      this.getEMRPatientMedicationIncidentReportLogListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder+
      "&fromDate=" +
      filterModel.fromDate+
      "&toDate=" +
      filterModel.toDate;
    return this.commonService.getAll(url, {});
  }

  getEMRMedicationReorderList(filterModel: TrackingFilterModel) {
    let url =
      this.getEMRMedicationReorderListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder+
      "&fromDate=" +
      filterModel.fromDate+
      "&toDate=" +
      filterModel.toDate;
    return this.commonService.getAll(url, {});
  }

  // getOutgoingReferralList(filterModel: FilterModel) {
  //   let url =
  //     this.getOutgoingReferralListURL +
  //     "?pageNumber=" +
  //     filterModel.pageNumber +
  //     "&pageSize=" +
  //     filterModel.pageSize +
  //     "&sortColumn=" +
  //     filterModel.sortColumn +
  //     "&sortOrder=" +
  //     filterModel.sortOrder;
  //   return this.commonService.getAll(url, {});
  // }

  getOutgoingReferralList(filterModel: FilterModel) {
    return this.commonService.post(
      this.getOutgoingReferralListURL,
      filterModel
    );
  }

  getIncomingReferralList(filterModel: FilterModel) {
    return this.commonService.post(
      this.getIncomingReferralDataURL,
      filterModel
    );
  }

  getFaxAchievesList(filterModel: FilterModel) {
    return this.commonService.post(
      this.getFaxAchievesDataURL,
      filterModel
    );
  }

  getCurrentMedicationList(filterModel: MedicationFilterModel) {
    let url =
      this.getAllCurrentMedicationPrescribingHistoryURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&patientId=" +
      filterModel.patientId +
      "&searchDate=" +
      filterModel.searchDate;
    return this.commonService.getAll(url, {});
  }

  getPreviousMedicationList(filterModel: MedicationFilterModel) {
    let url =
      this.getAllPreviousMedicationPrescribingHistoryURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&patientId=" +
      filterModel.patientId +
      "&fromDate=" +
      filterModel.fromDate +
      "&toDate=" +
      filterModel.toDate +
      "&searchText=" +
      filterModel.searchText;
    return this.commonService.getAll(url, {});
  }
  getEMRPatientPRNAdministeredList(filterModel: TrackingFilterModel) {
    let url =
      this.getEMRPatientPRNAdministeredListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&searchText=" +
      filterModel.searchText+
      "&fromDate=" +
      filterModel.fromDate +
      "&toDate=" +
      filterModel.toDate;
    return this.commonService.getAll(url, {});
  }

  // deleteCurrentMedication(id: number) {
  //   return this.commonService.post(this.deleteCurrentmedicationURL + "?id=" + id, {});
  // }
  discontinueCurrentMedication(id: number,discontinueReasonId:number) {
    return this.commonService.post(
      this.discontinueCurrentmedicationURL + "?id=" + id + "&discontinueReasonId=" + discontinueReasonId,
      {}
    );
  }

  getEMRPatientMedicationOrderChangeList(filterModel: FilterModel) {
    let url =
      this.getEMRPatientMedicationOrderChangeListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder;
    return this.commonService.getAll(url, {});
  }

  deleteCurrentMedication(id: number) {
    return this.commonService.post(
      this.deleteCurrentmedicationURL + "?id=" + id,
      {}
    );
  }
  getEmrDetails(data: emrModel) {
    return this.commonService.post(this.GetEmrDetailsURL, data);
  }
  getEmrtimeDetails(data: emrModel) {
    return this.commonService.post(this.GetEmrtimeDetailsURL, data);
  }

  getFoodDiaryDetails(data: foodDiaryModel) {
    return this.commonService.post(this.GetFoodDiaryDetailsURL, data);
  }

  getFoodDiaryDetailsByPatientId(data: foodDiaryModel) {
    return this.commonService.post(
      this.GetFoodDiaryDetailsByPatientIdURL,
      data
    );
  }

  getEmrDetails1(PatientId: number) {
    return this.commonService.getById(
      this.GetEmrDetailsURL1 + "?PatientId=" + PatientId,
      {}
    );
  }

  //EAMR Medication Method
  createEMARMedication(data: emrPopupModel) {
    return this.commonService.post(this.createEmarMedicationURL, data);
  }

  deleteReferrlabyId(id: number) {
    return this.commonService.post(this.deleteReferralURL + "?id=" + id, {});
  }

  getReferralListById(id: number) {
    return this.commonService.getById(
      this.getReferralByIdURL + "?id=" + id,
      {}
    );
  }

  private resendReferalByIdURL = "PatientReferral/ResendReferalById";
  resendReferalById(id: number) {
    return this.commonService.getById(
      this.resendReferalByIdURL + "?id=" + id,
      {}
    );
  }

  getCurrentmedicationById(Id: number) {
    return this.commonService.getById(
      this.getCurrentmedicationIdURL + "?Id=" + Id,
      {}
    );
  }
  //EAMR Medication Method
  getEMARMedicationpopup(data: emrPopupModel) {
    return this.commonService.post(this.geteEmarMedicationURL, data);
  }

  getMealPlanpopup(data: MealManagmentModel) {
    return this.commonService.post(this.geteMealPlanPopUpDataURL, data);
  }

  getDrugSideEffects(drugIds: any) {
    return this.commonService.getAll(
      this.GetDrugSideEffectBulk + "?drugId=" + drugIds,
      {}
    );
  }
  getDrugdiseass(drugIds: any) {
    return this.commonService.getAll(
      this.GetDrugDiseaseBulk + "?drugId=" + drugIds,
      {}
    );
  }
  GetMinMaxDose(drugIds: any) {
    return this.commonService.postLoader(this.GetMinMaxDoseBulk, drugIds);
  }
  getDrugIndication(data: any) {
    return this.commonService.getAll(
      this.GetDrugIndicationBulk + "?drugId=" + data,
      {}
    );
  }
  getDrugAllergy(data: any) {
    return this.commonService.getdatata(this.GetDrugAllergyBulk, data);
  }
  getDrugDrugGeriatricsPrecaution(data: any) {
    return this.commonService.getdatata(
      this.GetDrugGeriatricsPrecautionBulk,
      data
    );
  }
  getDrugFoodFamilyEducationBulk(data: any) {
    return this.commonService.getdatata(
      this.GetDrugFoodFamilyEducationBulk,
      data
    );
  }
  updateFlagEmrDetails(PatientId: number) {
    return this.commonService.getById(
      this.updateEmrDetailsURL + "?PatientId=" + PatientId,
      {}
    );
  }

  setPasswordbyUserId(id: number) {
    return this.commonService.getAll(this.setPassword + "?UserId=" + id, {});
  }

  getDrugDrugInteraction(data: any) {
    return this.commonService.getdatata(this.GetDrugDrugIntration, data);
  }

  getDrugDrugInteractionBulk(data: any) {
    return this.commonService.getdatata(this.GetDrugDrugInterationBulk, data);
  }

  getPatientDrug(id: number) {
    return this.commonService.getById(
      this.GetPatientDrugs + "?patientId=" + id,
      {}
    );
  }
  getDrugDuplicateTherapyBulk(data: any) {
    return this.commonService.getdatata(this.GetDrugDuplicateTherapyBulk, data);
  }

  private saveUpdateDementiaUrl =
    "PatientQualityMesure/SaveUpdatePatientDementia";
  saveUpdateDementia(data: DementiaModel) {
    return this.commonService.post(this.saveUpdateDementiaUrl, data);
  }

  private saveUpdateHeartFailUrl =
    "PatientQualityMesure/SaveUpdatePatientHeartFail";
  saveUpdateHeartFail(data: HeartFailModel) {
    return this.commonService.post(this.saveUpdateHeartFailUrl, data);
  }

  private saveUpdateCOPDUrl = "PatientQualityMesure/SaveUpdatePatientCOPD";
  saveUpdateCOPD(data: DementiaModel) {
    return this.commonService.post(this.saveUpdateCOPDUrl, data);
  }

  private getDementiaByPatientIdUrl =
    "PatientQualityMesure/GetPatientLatestDemintia";
  getDementiaByPatientId(patientId: number) {
    return this.commonService.getById(
      this.getDementiaByPatientIdUrl + "?patientId=" + patientId,
      {}
    );
  }

  private getHeartFailByPatientIdUrl =
    "PatientQualityMesure/GetPatientLatestHeartFail";
  getHeartFailByPatientId(patientId: number) {
    return this.commonService.getById(
      this.getHeartFailByPatientIdUrl + "?patientId=" + patientId,
      {}
    );
  }

  private getCopdByPatientIdUrl = "PatientQualityMesure/GetPatientLatestCOPD";
  getCopdByPatientId(patientId: number) {
    return this.commonService.getById(
      this.getCopdByPatientIdUrl + "?patientId=" + patientId,
      {}
    );
  }

  private getCdssMessagesUrl = "CDSS/GetOxygenMessageCDS";
  getCdssMessages(patientId: number) {
    return this.commonService.getById(
      this.getCdssMessagesUrl + "?patientId=" + patientId,
      {}
    );
  }
  createMasterTax(data: TaxMastersModel) {
    return this.commonService.post(this.createMasterTaxURL, data);
  }
  createMasterDiscount(data: DiscountMastersModel) {
    return this.commonService.post(this.createMasterDiscountURL, data);
  }

  createSubscriptionPayment(data: SubscriptionPaymentModel) {
    return this.commonService.post(this.subscriptionDetailsURL, data);
  }
  checkIfExistUrl = "/CheckUserNameAlreadyExist?userName=";
  validate(userName: string, userId: number = 0) {
    return this.commonService.getAll(
      this.checkIfExistUrl + userName + "&userID=" + userId,
      {}
    );
  }

  getFromByCategoryIdURL = "/FillablePDF/GetFormsByCategoryId";
  getFormByCategoryId(id: number) {
    return this.commonService.getAll(
      this.getFromByCategoryIdURL + "?Id=" + id,
      {}
    );
  }

  getFromByProviderIdURL = "/FillablePDF/GetFormsByProviderId";
  getFormByProviderId(id: number) {
    return this.commonService.getAll(
      this.getFromByProviderIdURL + "?Id=" + id,
      {}
    );
  }

  getImagesWithFieldURL = "/FillablePDF/GetPdfImagesFromForm";
  getImagesWithField(id: number): Observable<any> {
    let url = `${this.getImagesWithFieldURL}?Id=${id}`;
    return this.commonService.getById(url, {});
  }

  pdfResponseSubmitURL = "/PdfFormResponse/SubmitPdfForm";
  pdfResponseSubmit(model: any): Observable<any> {
    return this.commonService.post(this.pdfResponseSubmitURL, model);
  }
  pdfResponseSubmitListingURL = "/PdfFormResponse/GetAllFormsWithCategoryId";
  pdfResponseSubmitListing(model: any): Observable<any> {
    return this.commonService.post(this.pdfResponseSubmitListingURL, model);
  }

  ResubmitFormByIdURL = "PdfFormResponse/ResubmitFormById";
  resubmitFormById(id: number) {
    return this.commonService.getAll(
      this.ResubmitFormByIdURL + "?Id=" + id,
      {}
    );
  }

  private reviewLabResultURL = "/PatientReferral/ReviewLabResult";
  reviewLabResult(model: any): Observable<any> {
    return this.commonService.post(this.reviewLabResultURL, model);
  }

  private reviewPrnUrl = "/PatientsMedication/ReviewPRNStatusOfMedication";
  reviewPrn(model: any): Observable<any> {
    return this.commonService.post(this.reviewPrnUrl, model);
  }

  private deletePDFFormURL = "PdfFormResponse/DeletePDFFormById";
  deletePDFFormById(id: number) {
    return this.commonService.getAll(this.deletePDFFormURL + "?Id=" + id, {});
  }

  GetStaffAvailableTime(
    date: any,
    staffId: number,
    locationId: number,
    shiftId: number
  ) {
    return this.commonService.getById(
      this.getStaffAvailableTimeURL +
      "?date=" +
      date +
      "&staffId=" +
      staffId +
      "&locationId=" +
      locationId +
      "&shiftId=" +
      shiftId +
      "&isLeaveNeeded=false",
      {}
    );
  }

  getAvailableDates(data: any): Observable<any> { 
    return this.commonService.getdatata(this.GetStaffAvailabilityMobileURL, data); 
  }

  getOpenShifts(data: any): Observable<any> { 
    return this.commonService.getdatata(this.GetOpenShiftURL, data); 
  }

  getStaffAvailability(data: any): Observable<any> {
    return this.commonService.getdatata(this.GetStaffAvailabilityURL, data);
  }
  getStaffUnAvailability(data: any): Observable<any> {
    return this.commonService.getdatata(this.GetStaffUnAvailabilityURL, data);
  }
  approveStaffAvailability(id: number) {
    return this.commonService.post(this.approveStaffAvailabilityURL + "?Id=" + id, {});
  }
  approveStaffUnAvailability(id: number) {
    return this.commonService.post(this.approveStaffUnAvailabilityURL + "?Id=" + id, {});
  }
  getColorById(Id: number) {
    return this.commonService.getById(
      this.GetColorByIdUrl + "?id=" + Id,
      {}
    );
  }
  public UpdateColorName(modalData: ColorModel) {
    return this.commonService.post(this.UpdateColorUrl, modalData);
  }

  delete(id: number) {
    return this.commonService.patch(`${this.deleteURL}?notificationId=${id}`, {})
}

deleteMissedMedicationNotification(id: number) {
  return this.commonService.patch(`${this.deleteMissedMedNotificationURL}?id=${id}`, {})
}
deleteTaskNotification(id: number) {
  return this.commonService.patch(`${this.deleteTaskNotificationURL}?taskId=${id}`, {})
}

getTaskNotifications(filterModel: FilterModel) {
  let url =
  this.taskNotificationUrl +
  "?pageNumber=" +
  filterModel.pageNumber +
  "&pageSize=" +
  filterModel.pageSize +
  "&sortColumn=" +
  filterModel.sortColumn +
  "&sortOrder=" +
  filterModel.sortOrder;

  return this.commonService.getAll(url, {});
}

getTaskDetailsById(taskId:number){
  let url = this.taskDetailsUrl + "?taskId=" + taskId;
  return this.commonService.getAll(url,{});
}

updateTask(eventId:number,statusId:any){
  let url = 
  this.updateTaskStatus +
  "?taskId=" +
   eventId +  
  "&taskStatusId=" +
  statusId;

  return this.commonService.getAll(url,{});
}

deleteStaffExpiredDocNotification(id: number) {
  return this.commonService.patch(`${this.deleteStaffExpiredDocNotificationURL}?id=${id}`, {})
}

deleteResidentExpiredDocNotification(id: number) {
  return this.commonService.patch(`${this.deleteResidentExpiredDocNotificationURL}?id=${id}`, {})
}

  getPatientsList(filterModel: PatientReportingModel) {
    let url =
      this.getPatientListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&sortColumn=" +
      filterModel.sortColumn +
      "&sortOrder=" +
      filterModel.sortOrder +
      "&unitId=" +
      filterModel.unitIds +
      "&clientId=" +
      filterModel.clientIds +
      "&reportId=" +
      filterModel.reportIds +
      "&fromDate=" +
      filterModel.fromDate +
      "&toDate=" +
      filterModel.toDate;
    return this.commonService.getAll(url, {});
  }

  getPatientReportDetails(clientId: number, reportIds: string, fromDate: string, toDate: string) {
    let url = this.getPatientReportDetailsURL +
      "?patientId=" +
      clientId +
      "&reportIds=" +
      reportIds +
      "&fromDate=" +
      fromDate +
      "&toDate=" +
      toDate;
    return this.commonService.getAll(url, {});
  }

  //for Notifications
  turnOffReadedNotification(id:number,messageType:string) {
    return this.commonService.post(`${this.TurnOffNotificationListURL}?id=${id}&messageType=${messageType}`,{});
  } 
}
