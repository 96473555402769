import { OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { HubConnection } from "../../hubconnection.service";
import { ScrollbarComponent } from 'ngx-scrollbar';
var GroupChatWidgetComponent = /** @class */ (function () {
    function GroupChatWidgetComponent() {
        this.imgSource = '';
        this.title = '';
        this.sessionId = '';
        this.subTitle = '';
        this.showCloseButton = false;
        this.autoFocus = true;
        this.onDocumentGet = new EventEmitter();
        this.fileList = [];
        this.showChatModal = false;
        this.message = '';
        this.hubConnection = new HubConnection();
    }
    GroupChatWidgetComponent.prototype.onToggleChatModal = function () {
        this.showChatModal = !this.showChatModal;
    };
    GroupChatWidgetComponent.prototype.ngOnInit = function () {
        console.log('console log', this.allMessageArray);
        this.createHubConnection();
    };
    GroupChatWidgetComponent.prototype.createHubConnection = function () {
        var _this = this;
        if (this.hubConnection) {
            this.hubConnection.createGroupHubConnection(JSON.parse(localStorage.getItem('access_token')))
                .then(function (response) {
                _this.hubConnection.getHubConnection().onclose(function () {
                    _this.ReconnectOnClose(_this.fromUserId);
                });
                _this.hubConnection.ConnectToServerWithUserIdAndSessionId(_this.fromUserId, _this.sessionId).then(function (res) {
                    //  alert('called')
                    _this.getMessageNotifications();
                });
            });
        }
    };
    GroupChatWidgetComponent.prototype.ngAfterViewInit = function () {
        // this.scrollbarRef.scrollState.subscribe(e => console.log(e))
        console.log('this.sessionId', this.sessionId);
    };
    GroupChatWidgetComponent.prototype.handleImageChange = function (e) {
        var _this = this;
        //if (this.commonService.isValidFileType(e.target.files[0].name, "image")) {
        var fileExtension = e.target.files[0].name
            .split(".")
            .pop()
            .toLowerCase();
        var input = e.target;
        var reader = new FileReader();
        reader.onload = function () {
            _this.dataURL = reader.result;
            _this.fileList.push({
                data: _this.dataURL,
                ext: fileExtension,
                fileName: e.target.files[0].name
            });
        };
        reader.readAsDataURL(input.files[0]);
    };
    GroupChatWidgetComponent.prototype.removeFile = function (index) {
        this.fileList.splice(index, 1);
    };
    GroupChatWidgetComponent.prototype.sendMessage = function (event) {
        var model = {
            fromUserId: this.fromUserId,
            sessionId: this.sessionId,
            message: this.message,
            // base64:this.fileList,
            isFileMessage: false,
            documentTitle: "",
            Extenstion: ''
        };
        var dic = [];
        // model.base64.forEach((element, index) => {
        //   dic.push(
        //     `"${element.data.replace(
        //       /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,/,
        //       ""
        //     )}": "${element.ext}"`
        //   );
        // });
        // let newObj = dic.reduce((acc, cur, index) => {
        //   acc[index] = cur;
        //   return acc;
        // }, {});
        // model.base64 = newObj;
        if (!this.message || !this.message.trim())
            return false;
        this.handleNewUserMessage(model);
        var messageObj = {
            message: this.message,
            isRecieved: false,
        };
        this.allMessageArray.push(messageObj);
        this.message = '';
    };
    GroupChatWidgetComponent.prototype.handleNewUserMessage = function (message) {
        var _this = this;
        console.log(JSON.stringify(message));
        if (this.hubConnection.isConnected()) {
            this.hubConnection.getHubConnection()
                .invoke('SendMessageMobile', message.message, message.fromUserId, message.sessionId)
                .catch(function (err) { return console.error(err, 'ReceiveMessageReceiveMessageerror'); });
            return message;
        }
        else {
            this.hubConnection.restartHubConnection().then(function () {
                _this.hubConnection.getHubConnection()
                    .invoke('SendMessageMobile', message)
                    .catch(function (err) { return console.error(err, 'ReceiveMessageReceiveMessageerror'); });
                return message;
            });
        }
    };
    GroupChatWidgetComponent.prototype.getMessageNotifications = function () {
        var _this = this;
        this.hubConnection.getHubConnection().on('ReceiveGroupMessage', function (result, fromUserId, username) {
            console.log('message from server', result, fromUserId, _this.toUserId);
            var actionObj = {
                result: result,
                fromUserId: fromUserId, username: username
            };
            _this.onDocumentGet.emit(actionObj);
            // if (fromUserId == this.toUserId) {
            var messageObj = {
                message: result,
                isRecieved: true,
                userName: username
            };
            _this.allMessageArray.push(messageObj);
            // }
        });
    };
    GroupChatWidgetComponent.prototype.getFileNotifiatiopn = function () {
        var _this = this;
        this.hubConnection.getHubConnection().on('ReceiveChatDocument', function (result, fromUserId, username) {
            console.log('file resoponse form server from server', result, fromUserId, _this.toUserId);
            // if (fromUserId == this.toUserId) {
            var messageObj = {
                message: result,
                isRecieved: true,
                userName: username
            };
            _this.allMessageArray.push(messageObj);
            // }
        });
    };
    GroupChatWidgetComponent.prototype.ReconnectOnClose = function (fromUserId) {
        var _this = this;
        setTimeout(function () {
            _this.hubConnection.restartHubConnection().then(function () {
                _this.hubConnection.ConnectToServerWithUserIdAndSessionId(fromUserId, _this.sessionId).then(function () {
                    // console.log('Restart Connection: user id sent to server : ' + fromUserId);
                });
            });
        }, 5000);
    };
    return GroupChatWidgetComponent;
}());
export { GroupChatWidgetComponent };
