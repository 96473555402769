/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./image-popup.component";
var styles_ImagePopupComponent = [i0.styles];
var RenderType_ImagePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagePopupComponent, data: {} });
export { RenderType_ImagePopupComponent as RenderType_ImagePopupComponent };
function View_ImagePopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Popup Image"], ["style", "width: 100%; height: auto;"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.itemName; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.data.imageUrl; _ck(_v, 6, 0, currVal_2); }); }
function View_ImagePopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Image Not Found"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.itemName; _ck(_v, 2, 0, currVal_1); }); }
export function View_ImagePopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagePopupComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noImage", 2]], null, 0, null, View_ImagePopupComponent_2)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.imageUrl; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); }); }
export function View_ImagePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-popup", [], null, null, null, View_ImagePopupComponent_0, RenderType_ImagePopupComponent)), i1.ɵdid(1, 114688, null, 0, i9.ImagePopupComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImagePopupComponentNgFactory = i1.ɵccf("app-image-popup", i9.ImagePopupComponent, View_ImagePopupComponent_Host_0, {}, {}, []);
export { ImagePopupComponentNgFactory as ImagePopupComponentNgFactory };
