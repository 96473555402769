import { OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { MatDialogRef } from "@angular/material";
import { CommonService } from "src/app/platform/modules/core/services";
import { Router } from "@angular/router";
var TaskCompletedComponent = /** @class */ (function () {
    function TaskCompletedComponent(data, commonService, dialogModalRef, notifier, router) {
        this.data = data;
        this.commonService = commonService;
        this.dialogModalRef = dialogModalRef;
        this.notifier = notifier;
        this.router = router;
        this.updateTaskStatus = "api/PatientAppointments/UpdateTaskStatus";
        this.eventId = data.taskId;
        this.patientId = data.patientId;
    }
    TaskCompletedComponent.prototype.ngOnInit = function () {
        if (this.patientId != 0) {
            this.isResidentSelected = true;
        }
    };
    TaskCompletedComponent.prototype.updateTask = function () {
        var _this = this;
        var statusId = 1;
        this.commonService
            .getAll(this.updateTaskStatus +
            "?taskId=" +
            this.eventId +
            "&taskStatusId=" +
            statusId, {})
            .subscribe(function (response) {
            if (response && response.statusCode == 200) {
                _this.closeDialog("close");
                if ((response.data.taskStatusId = 1)) {
                    _this.notifier.notify("success", "Task Completed!");
                }
            }
        });
    };
    TaskCompletedComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    TaskCompletedComponent.prototype.redirectToForm = function () {
        var businessName = this.commonService.getBusinessUrl();
        this.router.navigate(["/" + businessName + "/web/client/patient-details"], {
            queryParams: {
                id: this.commonService.encryptValue(this.patientId, true),
                tab: this.commonService.encryptValue("Point of care", true),
                nextTab: this.commonService.encryptValue("TAR", true),
                childTab: this.commonService.encryptValue("Create New", true),
                taskId: this.commonService.encryptValue(this.eventId, true)
            },
        });
        this.closeDialog("close");
    };
    return TaskCompletedComponent;
}());
export { TaskCompletedComponent };
