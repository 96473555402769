import { MatDialog } from '@angular/material';
import { DialogComponent } from './dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
    }
    DialogService.prototype.confirm = function (message, data) {
        var modalRef = this.dialog.open(DialogComponent, { data: data, hasBackdrop: true, backdropClass: 'no-backdrop', disableClose: false, width: 'auto', minWidth: 'auto', maxWidth: 'auto', position: { bottom: '10px', right: '10px' } });
        modalRef.componentInstance.message = message;
        return modalRef.afterClosed();
    };
    DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
