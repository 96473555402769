import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { format } from "date-fns";
import { CommonService } from "src/app/platform/modules/core/services";
import { AppointmentDetailsModel } from "../appointment.details.model";

@Component({
  selector: "app-notification-details",
  templateUrl: "./notification-details.component.html",
  styleUrls: ["./notification-details.component.css"],
})
export class NotificationDetailsComponent implements OnInit {
  appointmentDetails: AppointmentDetailsModel;
  private getClientHeaderInfoURL = "Patients/GetPatientHeaderInfo";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogModalRef: MatDialogRef<NotificationDetailsComponent>,
    private commonService: CommonService
  ) {
    console.log("Details Data : ", data.appointmentDetails);
    this.appointmentDetails = new AppointmentDetailsModel();
    this.appointmentDetails = data.appointmentDetails;
  }

  ngOnInit() {
    let userInfo = this.commonService.getLoginUserInfo();
    let combinedNames = "";
    if (this.appointmentDetails.staffNames) {
      combinedNames = combinedNames + this.appointmentDetails.staffNames;
    }
    if (this.appointmentDetails.patientNames) {
      combinedNames = combinedNames + this.appointmentDetails.patientNames;
    }
    if (userInfo && userInfo.data.value) {
      combinedNames = combinedNames.replace(userInfo.data.value, "");
      combinedNames = combinedNames.replace(",,", ",");
      combinedNames = combinedNames.replace("  ", " ");
      this.appointmentDetails.message = this.appointmentDetails.message.replace(
        "[DATE]",
        format(this.appointmentDetails.appointmentDate, "MM/DD/YYYY")
      );
      this.appointmentDetails.message = this.appointmentDetails.message.replace(
        "[TIME]",
        format(this.appointmentDetails.appointmentDate, "hh:mm a")
      );
      combinedNames = combinedNames.replace("  ", " ");
      this.appointmentDetails.message = this.appointmentDetails.message.replace(
        "[Names]",
        combinedNames
      );
    } else {
      this.getClientHeaderInfoApi(userInfo.data.id).subscribe(
        (response: any) => {
          if (response != null && response.statusCode == 200) {
            let res = response.data.patientBasicHeaderInfo;
            if (res && res.name) {
              combinedNames = combinedNames.replace(res.name, "");
              combinedNames = combinedNames.replace(",,", ",");
              combinedNames = combinedNames.replace("  ", " ");
              this.appointmentDetails.message = this.appointmentDetails.message.replace(
                "[DATE]",
                format(this.appointmentDetails.appointmentDate, "MM/DD/YYYY")
              );
              this.appointmentDetails.message = this.appointmentDetails.message.replace(
                "[TIME]",
                format(this.appointmentDetails.appointmentDate, "hh:mm a")
              );
              combinedNames = combinedNames.replace("  ", " ");
              this.appointmentDetails.message = this.appointmentDetails.message.replace(
                "[Names]",
                combinedNames
              );
            }
          }
        }
      );
    }
    console.log("Combined Name", combinedNames);
  }
  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
  getClientHeaderInfoApi(id: number) {
    return this.commonService.getById(
      this.getClientHeaderInfoURL + "?id=" + id,
      {}
    );
  }
}
