import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ResponseModel } from "src/app/super-admin-portal/core/modals/common-model";
import { CommonService } from "src/app/platform/modules/core/services";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-terms-conditions",
  templateUrl: "./terms-conditions.component.html",
  styleUrls: ["./terms-conditions.component.css"],
})
export class TermsConditionsComponent implements OnInit {
  acceptTermsAndConditionsURL = "/Login/UpdateAgreemetnAndFistLoginStatus";
  acceptTerms: FormGroup;
  content: string;

  isValid: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private dialogModalRef: MatDialogRef<TermsConditionsComponent>,
    private formBuilder: FormBuilder
  ) {
    this.content = data.content;
  }

  ngOnInit() {
    this.acceptTerms = this.formBuilder.group({
      isChecked: ["", Validators.required],
    });
    this.acceptTerms.controls.isChecked.valueChanges.subscribe((value) => {
      if (value) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    });
  }

  onSubmit() {
    if (!this.acceptTerms.invalid) {
      this.commonService
        .post(this.acceptTermsAndConditionsURL, {})
        .subscribe((response: ResponseModel) => {
          if (response && response.statusCode == 200) {
            this.closeDialog("ok");
          }
        });
    }
  }

  declineTerms() {
    this.closeDialog("close");
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
}
