import { Component, OnInit } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { SubDomainService } from "../subDomain.service";
import { AuthenticationService } from "../super-admin-portal/auth/auth.service";
import { ResponseModel } from "../super-admin-portal/core/modals/common-model";
import { CommonService } from "../super-admin-portal/core/services";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.css"],
  providers: [AuthenticationService, CommonService],
})
export class LandingPageComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private subDomainService: SubDomainService,
    private router: Router,
    private commonService: CommonService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      organizationName: [
        "",
        {
          validators: [Validators.required],
          asyncValidators: [this.validateOrganization.bind(this)],
          updateOn: "change",
        },
      ],
      rememberOrg: [false],
    });

    if (
      localStorage.getItem("RememberOrganization") == "true" &&
      localStorage.getItem("business-url") &&
      localStorage.getItem("business-url") !== "organization-selec"
    ) {
      let businessName = localStorage.getItem("business-url");
      this.router.navigate(["/" + businessName + "/web/login"]);
    }
  }

  validateOrganization(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return new Promise((resolve) => {
      if (!ctrl.dirty) {
        resolve();
      } else {
        this.authenticationService
          .validateOrganization(ctrl.value)
          .subscribe((response: any) => {
            if (response.statusCode !== 202) resolve({ uniqueName: true });
            else resolve();
          });
      }
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    let businessName = this.f.organizationName.value;

    this.authenticationService
      .validateOrganization(businessName)
      .subscribe((respo: ResponseModel) => {
        if (respo.statusCode == 202) {
          console.log("Response", respo.data);
          localStorage.clear();
          localStorage.setItem("business-url", respo.data.businessUrl);
          if (this.f.rememberOrg.value) {
            localStorage.setItem("RememberOrganization", "true");
          } else {
            localStorage.setItem("RememberOrganization", "false");
          }
          this.subDomainService
            .verifyBusinessName(respo.data.businessUrl)
            .subscribe();
          this.router.navigate(["/" + respo.data.businessUrl + "/web/login"]);
        }
      });

    // location.reload()
  }
}
