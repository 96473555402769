<div class="custom_modal">
  <button class="close" mat-button (click)="closeDialog('close')">
    <mat-icon style="color: rgb(3, 3, 3)">close</mat-icon>
  </button>
  <h3 mat-dialog-title>Notification Of Subscription Expiration</h3>
  <div mat-dialog-content>
    <div class="custom_modal">
      <div class="col-sm-12">
        <div class="Record-patient-title">
          <h3>Organization Details</h3>
        </div>

        <table>
          <tbody>
            <tr>
              <td colspan="1">Name :</td>
              <td>&nbsp;{{ organizationName }}</td>
            </tr>
            <tr>
              <td colspan="1">Address:</td>
              <td>&nbsp;{{ orgAddress }}</td>
            </tr>
            <tr>
              <td colspan="1">Email :</td>
              <td>&nbsp;{{ email }}</td>
            </tr>
            <tr>
              <td>Phone :</td>
              <td>&nbsp;{{ phone }}</td>
            </tr>
            <tr>
              <td>Fax :</td>
              <td>&nbsp;{{ fax }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-12">
        <div class="Record-patient-title">
          <h3>Plan Details</h3>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th style="text-align: center">Date</th>
              <th style="text-align: center">Type of plan</th>
              <th style="text-align: center">Price</th>
              <th style="text-align: center">Last Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="text-align: center">{{ startDate }}</td>
              <td style="text-align: center">{{ planName }}</td>
              <td style="text-align: center">
                {{ currency }}&nbsp; {{ price }}
              </td>
              <td style="text-align: center">{{ endDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-12" *ngIf="note != undefined">
        <b>Note: </b><span style="color: red">&nbsp;{{ note }}</span>
      </div>
    </div>

    <div mat-dialog-actions>
      <div>
        <button
          style="margin-right: 15px"
          class="cancel-btn"
          mat-raised-button
          (click)="closeDialog('closes')"
          cdkFocusInitial
        >
          Exit
        </button>
      </div>
      <button mat-raised-button color="primary" (click)="openDialog()">
        Pay Now
      </button>
    </div>
  </div>
</div>
