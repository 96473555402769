import { OnInit, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators, } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { SubscriptionPaymentModel } from "../subscriptionpayment.model";
import { CommonService } from "../../../../core/services";
import { ClientsService } from "../../../clients/clients.service";
import { UsersService } from "../../../users/users.service";
import { PharmacyService } from "../../pharmacy/pharmacy.service";
var AddSubsciptionComponent = /** @class */ (function () {
    function AddSubsciptionComponent(careproviderDialogModalRef, commonService, formBuilder, clientService, pharmacyService, userService, data, notifier) {
        this.careproviderDialogModalRef = careproviderDialogModalRef;
        this.commonService = commonService;
        this.formBuilder = formBuilder;
        this.clientService = clientService;
        this.pharmacyService = pharmacyService;
        this.userService = userService;
        this.data = data;
        this.notifier = notifier;
        this.getSubscriptionExpireUrl = "/TermsAndConditions/GetSubscriptionExpirationDetails";
        //card: FormGroup;
        this.submitted = false;
        this.headerText = "Add Tax";
        this.formatting = true;
        this.debug = false;
        this.masterLocation = [];
        this.refreshGrid = new EventEmitter();
        this.saveAndMore = "close";
        this.masterTaxModel = new SubscriptionPaymentModel();
        this.masterTaxModel = data.unitmastersModel;
        if (this.masterTaxModel.id != null && this.masterTaxModel.id > 0)
            this.headerText = "Edit Tags";
        else
            this.headerText = "Add Tags";
    }
    AddSubsciptionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getMasterData();
        this.subscription = this.commonService.currentLoginUserInfo.subscribe(function (user) {
            if (user) {
                _this.clientId = user.id;
            }
        });
        this.careproviderForm = this.formBuilder.group({
            id: [this.masterTaxModel.id],
            cardNumber: [this.masterTaxModel.cardNumber],
            expMonth: [this.masterTaxModel.expMonth],
            expYear: [this.masterTaxModel.expYear],
            cardName: [this.masterTaxModel.cardName],
            cardCVV: [this.masterTaxModel.cardCVV],
            streetAddress: [this.masterTaxModel.streetAddress],
            city: [this.masterTaxModel.city],
            state: [this.masterTaxModel.state],
            countryId: [this.masterTaxModel.countryId, Validators.required],
            zipCode: [this.masterTaxModel.zipCode],
            typeCard: [this.masterTaxModel.typeCard],
            amountofsubscription: [this.masterTaxModel.amountofsubscription],
            plantype: [this.masterTaxModel.plantype],
            iscardsavedetails: [this.masterTaxModel.iscardsavedetails],
        });
        this.getSubscriptionExpireUrlAnynomusly();
    };
    Object.defineProperty(AddSubsciptionComponent.prototype, "formControls", {
        //get the form controls on html page
        get: function () {
            return this.careproviderForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddSubsciptionComponent.prototype.getMasterData = function () {
        var _this = this;
        var data = "MASTERCOUNTRY";
        this.pharmacyService.getMasterData(data).subscribe(function (response) {
            if (response != null) {
                _this.masterCountry =
                    response.masterCountry != null ? response.masterCountry : [];
            }
            else {
                _this.masterCountry = [];
            }
        });
    };
    AddSubsciptionComponent.prototype.getSubscriptionExpireUrlAnynomusly = function () {
        var _this = this;
        this.commonService
            .getAll(this.getSubscriptionExpireUrl, {})
            .subscribe(function (response) {
            if (response && response.statusCode == 200) {
                _this.email = response.data.email;
                _this.fax = response.data.fax;
                _this.orgAddress = response.data.orgAddress;
                _this.phone = response.data.phone;
                _this.careproviderForm.controls.plantype.setValue(response.data.planName);
                _this.fax = response.data.fax;
                _this.startDate = response.data.startDate;
                _this.organizationName = response.data.organizationName;
                _this.currency = response.data.iso;
                _this.careproviderForm.controls.amountofsubscription.setValue(response.data.price);
            }
        });
    };
    AddSubsciptionComponent.prototype.onCardtypeChange = function (value) { };
    AddSubsciptionComponent.prototype.onSubmit = function (event) {
        var _this = this;
        if (!this.careproviderForm.invalid) {
            var clickType_1 = event.currentTarget.name;
            this.submitted = true;
            this.masterTaxModel = this.careproviderForm.value;
            //this.patientsTagModel.patientId = this.clientId;
            this.clientService
                .createSubscriptionPayment(this.masterTaxModel)
                .subscribe(function (response) {
                _this.submitted = false;
                if (response.statusCode == 200) {
                    _this.notifier.notify("success", response.message);
                    if (clickType_1 == "Save")
                        _this.closeDialog("save");
                    // else if (clickType == "SaveAddMore") {
                    //   this.refreshGrid.next();
                    //   this.saveAndMore='save'
                    //   this.unitmasterForm.reset();
                    // }
                }
                else {
                    _this.notifier.notify("error", response.message);
                }
            });
        }
    };
    //close popup
    AddSubsciptionComponent.prototype.closeDialog = function (action) {
        this.careproviderDialogModalRef.close(action);
    };
    return AddSubsciptionComponent;
}());
export { AddSubsciptionComponent };
