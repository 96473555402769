import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { SubDomainService } from "./subDomain.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;

  title = "healthcare-frontend-angular";
  private subscription: Subscription;
  subDomainInfo: any;
  isLoadingSubdomain: boolean = false;
  isDarkTheme: boolean = false;

  private _mobileQueryListener: () => void;

  constructor(
    private subDomainService: SubDomainService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    var pathString = location.path();
    let localPart = pathString.slice(1, pathString.indexOf("/w"));
    localStorage.removeItem("business-url");
    localStorage.setItem("business-url", localPart);
  }

  ngOnInit() {
    this.redirectToSecurePort();
    const subDomainUrl = this.subDomainService.getSubDomainUrl();
    if (subDomainUrl) {
      this.isLoadingSubdomain = true;
      this.subDomainService.verifyBusinessName(subDomainUrl).subscribe();
      this.subscription = this.subDomainService
        .getSubDomainInfo()
        .subscribe((domainInfo) => {
          if (domainInfo)
            this.subDomainService.updateFavicon(domainInfo.favicon64);
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  private redirectToSecurePort() {
    if (environment.production) {
      if (location.protocol === "http:") {
        window.location.href = location.href.replace("http", "https");
      }
    }
  }
}
