import { OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { NotifierService } from "angular-notifier";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/platform/modules/core/services";
import { ClientsService } from "src/app/platform/modules/agency-portal/clients/clients.service";
import { PatientPreferenceFilterModel, } from "src/app/platform/modules/core/modals/common-model";
import { ReferralModel, } from "src/app/platform/modules/agency-portal/refference/outgoing-referal/add-referal/add-referral.model";
import { of } from "rxjs";
import { startWith, debounceTime, tap, switchMap, finalize, map, catchError, } from "rxjs/operators";
import { autoCompleteValidatorAsync$$ } from "src/app/platform/modules/agency-portal/clients/diagnosis/diagnosis-modal/diagnosis-modal.component";
import { OpenLabComponent } from "./open-lab/open-lab.component";
import { OpenReferralComponent } from "./open-referral/open-referral.component";
import { OpenDocumentComponent } from "./open-document/open-document.component";
import { DialogService } from "../dialog/dialog.service";
var AcceptRejectReferalComponent = /** @class */ (function () {
    function AcceptRejectReferalComponent(dialogRef, notifier, formBuilder, activatedRoute, commonService, clientService, router, openLabDialog, notifierService, data, clientsService, dialogService) {
        this.dialogRef = dialogRef;
        this.notifier = notifier;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.commonService = commonService;
        this.clientService = clientService;
        this.router = router;
        this.openLabDialog = openLabDialog;
        this.notifierService = notifierService;
        this.data = data;
        this.clientsService = clientsService;
        this.dialogService = dialogService;
        this.caption = "";
        this.submitted = false;
        this._clickType = "";
        this.isloadingPatients = false;
        this.isloadingStaff = false;
        this.faxFileName = "";
        this.faxFileData = "";
        this.referralModel = new ReferralModel();
        //  console.log("Patient Id from cont",data)
        this.clientId = data.patientId;
    }
    AcceptRejectReferalComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userInfo = this.commonService.getLoginUserInfo();
        this.tabName = this.commonService.getOrganizationTypeName();
        // console.log(userInfo);
        this.ReferralForm = this.formBuilder.group({
            id: [this.referralModel.id],
            isLab: [""],
            isReferral: [""],
            isDocument: [""],
            isInBox: [""],
            isCarePlan: [""],
            healthCardNumberSearch: [""],
            staffNameSearch: [
                "",
                autoCompleteValidatorAsync$$.valueSelected([], "staffNameSearch", true),
            ],
        });
        this.filterModel = new PatientPreferenceFilterModel();
        this.filterModel.patientId = this.clientId;
        this.filterModel.pageNumber = 1;
        this.filterModel.pageSize = 10;
        this.getClientHeaderInfo();
        this._filter("");
        this.filterHealthCardNo$ = this.ReferralForm.get("healthCardNumberSearch").valueChanges.pipe(startWith(""), debounceTime(100), tap(function (value) {
            value.length > 3
                ? (_this.isloadingPatients = true)
                : (_this.isloadingPatients = false);
        }), switchMap(function (value) {
            if (value.length > 2) {
                var list = void 0;
                list = _this._filter(value).pipe(finalize(function () { return (_this.isloadingPatients = false); }));
                list.subscribe(function (_value) {
                    _this.ReferralForm.controls.healthCardNumberSearch.setValidators([
                        autoCompleteValidatorAsync$$.valueSelected(_value, "healthCardNumber", true),
                    ]);
                    // this.ReferralForm.controls.healthCardNumberSearch.setValidators(
                    //   [autoCompleteValidatorAsync$$.valueSelected(_value, 'healthCardNumber', true)]);
                });
                return list;
                // return this._filter(value).pipe(finalize(() => (this.isloadingPatients = false)));
            }
            else {
                return of(null);
            }
        }));
        this._filterStaff("");
        this.filterStaffName$ = this.ReferralForm.get("staffNameSearch").valueChanges.pipe(startWith(""), debounceTime(100), tap(function (value) {
            value.length > 3
                ? (_this.isloadingStaff = true)
                : (_this.isloadingStaff = false);
        }), switchMap(function (value) {
            if (value.length > 2) {
                var list = void 0;
                list = _this._filterStaff(value).pipe(finalize(function () { return (_this.isloadingStaff = false); }));
                list.subscribe(function (_value) {
                    _this.ReferralForm.controls.staffNameSearch.setValidators([
                        autoCompleteValidatorAsync$$.valueSelected(_value, "staffNameSearch", true),
                    ]);
                    // this.ReferralForm.controls.healthCardNumberSearch.setValidators(
                    //   [autoCompleteValidatorAsync$$.valueSelected(_value, 'healthCardNumber', true)]);
                });
                return list;
                // return this._filter(value).pipe(finalize(() => (this.isloadingPatients = false)));
            }
            else {
                return of(null);
            }
        }));
        this.ReferralForm.controls.isLab.valueChanges.subscribe(function (value) {
            if (value == true) {
                _this.createOpenLabModal();
            }
        });
        this.ReferralForm.controls.isReferral.valueChanges.subscribe(function (value) {
            if (value == true) {
                _this.createOpenReferralModal();
            }
        });
        this.ReferralForm.controls.isDocument.valueChanges.subscribe(function (value) {
            if (value == true) {
                _this.createOpenDocumentModal(false);
            }
        });
        this.ReferralForm.controls.isCarePlan.valueChanges.subscribe(function (value) {
            if (value == true) {
                _this.createOpenDocumentModal(true);
            }
        });
    };
    AcceptRejectReferalComponent.prototype.displayFn = function (client) {
        this.patientId = client.patientId;
        return client ? client.fullName : undefined;
    };
    AcceptRejectReferalComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        return this.clientService.getpatientsearchRecord(filterValue).pipe(map(function (response) {
            if (response.statusCode == 201)
                return of(null);
            else
                return (response.data || []).map(function (clientObj) {
                    var Obj = clientObj;
                    return Obj;
                });
        }), catchError(function (_) {
            return of(null);
        }));
    };
    AcceptRejectReferalComponent.prototype.displayFnStaff = function (client) {
        this.StaffId = client.staffId;
        return client ? client.fullName : undefined;
    };
    AcceptRejectReferalComponent.prototype._filterStaff = function (value) {
        var filterValue = value.toLowerCase();
        return this.clientService.getstaffsearchRecord(filterValue).pipe(map(function (response) {
            if (response.statusCode == 201)
                return of(null);
            else
                return (response.data || []).map(function (clientObj) {
                    var Obj = clientObj;
                    return Obj;
                });
        }), catchError(function (_) {
            return of(null);
        }));
    };
    Object.defineProperty(AcceptRejectReferalComponent.prototype, "formControls", {
        get: function () {
            return this.ReferralForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcceptRejectReferalComponent.prototype, "ReferralValidation", {
        get: function () {
            return (!this.formControls.ReferralDate.value &&
                !this.formControls.ReferredTo.value &&
                !this.formControls.ReasonsForReferral.value &&
                !this.formControls.ReferralNotes.value);
        },
        enumerable: true,
        configurable: true
    });
    AcceptRejectReferalComponent.prototype.getClientHeaderInfo = function () {
        var _this = this;
        console.log("Check patient id", this.clientId);
        this.clientsService
            .getClientHeaderInfo(this.clientId)
            .subscribe(function (response) {
            if (response != null && response.statusCode == 200) {
                // console.log('header info', response.data.patientBasicHeaderInfo);
                if (response.data.patientBasicHeaderInfo) {
                    _this.referralModel.patientID =
                        response.data.patientBasicHeaderInfo.patientID;
                    _this.referralModel.patientName =
                        response.data.patientBasicHeaderInfo.name;
                    _this.referralModel.patientdob =
                        response.data.patientBasicHeaderInfo.dob;
                    _this.referralModel.sex =
                        response.data.patientBasicHeaderInfo.gender;
                    _this.referralModel.healthCardNumber =
                        response.data.patientBasicHeaderInfo.phcNo;
                    // console.log('header info bind', this.referralModel);
                }
            }
        });
    };
    AcceptRejectReferalComponent.prototype.onPatientSelectedHCNumber = function (event) {
        this.clientId = event.option.value.patientId;
        // this.SearchHealthCardNumber(event.option.value);
    };
    AcceptRejectReferalComponent.prototype.onStaffSelectedName = function (event) {
        this.StaffId = event.option.value.staffId;
        // this.SearchStaffNameWiseData(event.option.value);
    };
    AcceptRejectReferalComponent.prototype.SearchHealthCardNumber = function (inputval) {
        var _this = this;
        if (inputval.healthCardNumber != "") {
            this.clientService
                .getpatientAdminissionbyHeathCardNobyData(inputval.healthCardNumber)
                .subscribe(function (response) {
                if (response.statusCode == 200) {
                    if (response != null &&
                        response.data != null &&
                        response.data.length > 0) {
                        //console.log("patient name wise data get", response.data);
                        // this.referralModel.patientID = response.data[0].patientID;
                        //this.referralModel.patientName = response.data[0].residentName;
                        // this.referralModel.healthCardNumber = response.data[0].healthCardNumber;
                        _this.clientId = response.data[0].patientID;
                    }
                    else {
                        // console.log("patient name wise data", response.data);
                        _this.notifierService.notify("error", response.message);
                        _this.ReferralForm.reset();
                        // this.referralModel.patientName = null;
                        // this.referralModel.healthCardNumber = null;
                    }
                }
            });
        }
        else {
            this.notifierService.notify("error", "Please enter " + this.commonService.getOrganizationTypeName() + " name");
        }
    };
    AcceptRejectReferalComponent.prototype.onSubmit = function (event) {
        var _this = this;
        var clickType = event.currentTarget.name;
        if (!this.ReferralForm.invalid) {
            this.submitted = true;
            this.refModel = this.ReferralForm.value;
            this.refModel.patientID = this.clientId;
            this.refModel.staffId = this.StaffId;
            this.clientService
                .createIncomingReferForm(this.refModel)
                .subscribe(function (response) {
                _this.submitted = false;
                if (response.statusCode == 200) {
                    _this.notifier.notify("success", response.message);
                    if (clickType == "save")
                        _this.closeDialog("save");
                }
                else {
                    _this.notifier.notify("error", response.message);
                }
            });
        }
    };
    AcceptRejectReferalComponent.prototype.closeDialog = function (action) {
        this.dialogRef.close(action);
    };
    AcceptRejectReferalComponent.prototype.accept = function () {
        this.router.navigate(["web/refferal"], {});
        this.closeDialog("close");
    };
    AcceptRejectReferalComponent.prototype.getUserDocument = function () {
        var _this = this;
        this.clientService.fetchFaxDetails(this.data.faxFileName).subscribe(function (res) {
            _this.faxFileData = res.data;
            var pdfWindow = window.open("");
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(res.data) + "'></iframe>");
            // const source = `data:application/pdf;base64,${res.data}`;
            // const link = document.createElement("a");
            // link.href = source;
            // link.download = 'new_fax_recieved_' + new Date().toString() + '.pdf'
            // link.click();
        });
    };
    AcceptRejectReferalComponent.prototype.createOpenLabModal = function () {
        var _this = this;
        if (this.faxFileData) {
            var userRoleModalPopup = this.openLabDialog.open(OpenLabComponent, {
                hasBackdrop: true,
                data: {
                    patientId: this.clientId,
                    staffId: this.StaffId,
                    faxFileBase64: this.faxFileData
                },
                maxHeight: 1400,
                maxWidth: 1000,
            });
            userRoleModalPopup.afterClosed().subscribe(function (result) {
                if (result === "save") {
                    _this.ReferralForm.controls.isLab.setValue(false);
                    _this.openLabDialog.closeAll();
                }
            });
        }
        else {
            this.ReferralForm.controls.isLab.setValue(false);
            alert('Please review document first');
        }
    };
    AcceptRejectReferalComponent.prototype.createOpenReferralModal = function () {
        var _this = this;
        var userRoleModalPopup = this.openLabDialog.open(OpenReferralComponent, {
            hasBackdrop: true,
            data: {
                patientId: this.clientId,
                staffId: this.StaffId,
                faxFileBase64: this.faxFileData
            },
            maxHeight: 1400,
            maxWidth: 1000,
        });
        userRoleModalPopup.afterClosed().subscribe(function (result) {
            if (result === "save") {
                _this.ReferralForm.controls.isReferral.setValue(false);
                _this.openLabDialog.closeAll();
            }
        });
    };
    AcceptRejectReferalComponent.prototype.createOpenDocumentModal = function (isCarePlan) {
        var _this = this;
        if (this.faxFileData) {
            var userRoleModalPopup = this.openLabDialog.open(OpenDocumentComponent, {
                hasBackdrop: true,
                data: {
                    patientId: this.clientId,
                    staffId: this.StaffId,
                    isCarePlan: isCarePlan,
                    faxFileBase64: this.faxFileData
                },
                maxHeight: 1400,
                maxWidth: 1000,
            });
            userRoleModalPopup.afterClosed().subscribe(function (result) {
                if (result === "save" && isCarePlan) {
                    _this.ReferralForm.controls.isCarePlan.setValue(false);
                    _this.openLabDialog.closeAll();
                }
                else if (result === "save" && !isCarePlan) {
                    _this.ReferralForm.controls.isDocument.setValue(false);
                }
            });
        }
        else {
            this.ReferralForm.controls.isCarePlan.setValue(false);
            alert('Please review document first');
            this.ReferralForm.controls.isDocument.setValue(false);
            alert('Please review document first');
        }
    };
    AcceptRejectReferalComponent.prototype.checkedInbox = function (event) {
        var _this = this;
        if (event.checked) {
            this.dialogService
                .confirm("Are you sure you want to go to Add/Edit Task screen?")
                .subscribe(function (result) {
                if (result == true) {
                    var businessName = localStorage.getItem("business-url");
                    _this.router.navigate(["/" + businessName + "/web/add-edit-task"], {
                        queryParams: {
                            faxFileName: _this.commonService.encryptValue(_this.data.faxFileName, true)
                        }
                    });
                    _this.closeDialog("save");
                }
                else {
                    _this.ReferralForm.get('isInBox').setValue(false);
                }
            });
        }
    };
    return AcceptRejectReferalComponent;
}());
export { AcceptRejectReferalComponent };
