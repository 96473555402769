<div class="custom_modal">
  <button class="close" mat-button (click)="closeDialog('close')">
    <mat-icon style="color: rgb(3, 3, 3)">close</mat-icon>
  </button>
  <h3 mat-dialog-title>Notification Of Subscription Expiration</h3>
  <div mat-dialog-content>
    <div class="custom_modal">
      <div class="col-sm-12">
        <div class="Record-patient-title">
          <h3>Organization Details</h3>
        </div>

        <table>
          <tbody>
            <tr>
              <td colspan="1">Name :</td>
              <td>&nbsp;{{ organizationName }}</td>
            </tr>
            <tr>
              <td colspan="1">Address:</td>
              <td>&nbsp;{{ orgAddress }}</td>
            </tr>
            <tr>
              <td colspan="1">Email :</td>
              <td>&nbsp;{{ email }}</td>
            </tr>
            <tr>
              <td>Phone :</td>
              <td>&nbsp;{{ phone }}</td>
            </tr>
            <tr>
              <td>Fax :</td>
              <td>&nbsp;{{ fax }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-12">
        <div>
          <h3>
            Your program subscription has expired. Please contact your
            administrator.
          </h3>
        </div>
      </div>

      <div mat-dialog-actions>
        <div>
          <button
            style="margin-right: 15px"
            class="cancel-btn"
            mat-raised-button
            (click)="closeDialog('closes')"
            cdkFocusInitial
          >
            Exit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
