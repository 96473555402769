import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthenticationService } from 'src/app/super-admin-portal/auth/auth.service';
import { CommonService } from '../../core/services';
import { ResponseModel } from 'src/app/super-admin-portal/core/modals/common-model';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  getTermsAndConditionUrl = "/TermsAndConditions/GetTermsAndCondition";
  content: any;
  constructor(
   // private authService:AuthenticationService,
    private commonService: CommonService,
    private dialogModalRef: MatDialogRef<TermsAndConditionsComponent>,
  ) { }

  ngOnInit() {
    this.getTermsAndConditionAnynomusly();
  }

  getTermsAndConditionAnynomusly(){
    this.commonService
        .getAll(
          this.getTermsAndConditionUrl,
          {}
        )
        .subscribe((response: ResponseModel) => {
          if (response && response.statusCode == 200) {
            this.content = response.data.content;
          }
        });
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
}
