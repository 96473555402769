import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/platform/modules/core/services';
import { ClientsService } from 'src/app/platform/modules/agency-portal/clients/clients.service';
import { OpenReferralModel } from './open-referral.model';
import { catchError, debounceTime, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import client from '@opentok/client';
import { Observable, of } from 'rxjs';
import { autoCompleteValidatorAsync$$ } from 'src/app/platform/modules/agency-portal/clients/diagnosis/diagnosis-modal/diagnosis-modal.component';


@Component({
  selector: 'app-open-referral',
  templateUrl: './open-referral.component.html',
  styleUrls: ['./open-referral.component.css'],
  providers: [ClientsService]
})
export class OpenReferralComponent implements OnInit {
  clientId: number;
  StaffId: number;
  openReferralModel: OpenReferralModel;
  submitted: boolean = false;
  ReferralForm: FormGroup;
  patientId: number;
  staffId: number;
  staffList: Array<any> = [];
  filterStaffName: Observable<any[]>;
  isloadingStaff: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<OpenReferralComponent>,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private clientService: ClientsService,
    private router: Router,
    private notifierService: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientsService: ClientsService) {
    this.openReferralModel = new OpenReferralModel();
    this.openReferralModel = data;
    this.patientId = data.patientId;
    this.staffId = data.staffId;
  }

  ngOnInit() {
    var userInfo = this.commonService.getLoginUserInfo();

    // console.log(userInfo);   

    this.ReferralForm = this.formBuilder.group({
      id: [this.openReferralModel.id],
      //typeOfTest: [this.openReferralModel.typeOfTest],		
      referredBy: [this.openReferralModel.referredBy],
      referredDate: [this.openReferralModel.referredDate],
      referredToId: [this.openReferralModel.referredToId]
      //	physicianName: [this.openReferralModel.physicianName]			
    });
    this.GetStaffList();
    this.filterStaffName = this.ReferralForm.get('referredToId').valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.staffList.slice())),
    );
  }
  private _filter(name: string): any {
    const filterValue = name.toLowerCase();
    return this.staffList.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  //get the form controls on html page
  get formControls() { return this.ReferralForm.controls; }
  save = 'save';
  close='close';

  closeDialog(action: string): void {
    this.dialogRef.close(action);
  }

  onSubmit(event: any) {
      if (!this.ReferralForm.invalid) {
        let clickType = event.currentTarget.name;
        this.submitted = true;
        let submittedData = this.ReferralForm.value;
        this.openReferralModel = this.ReferralForm.value;
        this.openReferralModel.referredToId = submittedData.referredToId.id;
        this.openReferralModel.patientId = this.patientId;
        this.openReferralModel.staffId = this.staffId;
        this.openReferralModel.base64 = this.commonService.getFaxFileObj(this.data.faxFileBase64);
        this.clientService.createReferralResult(this.openReferralModel).subscribe((response: any) => {
          this.submitted = false;
          if (response.statusCode == 200) {
            this.notifier.notify('success', response.message)
            if (clickType == "Save")
              this.closeDialog('save');
          } else {
            this.notifier.notify('error', response.message)
          }
        });
      }
  }
  GetStaffList() {
    // this.loadingMasterData = true;
    const masterData = { masterdata: "MASTERSTAFF" };
    this.clientService.getStaffList(masterData).subscribe((response: any) => {
      // this.loadingMasterData = false;       
      this.staffList = response.data || [];
    });
  }
  onReferredToSelectedName(event) {
    this.StaffId = event.option.value.id;
    this.staffId = event.option.value.id;
  }
  displayFnStaff(client?: any): string | undefined {
    return client ? client.fullName : undefined;
  }
}
