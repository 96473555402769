/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clock-out.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./clock-out.component";
import * as i9 from "angular-notifier";
import * as i10 from "../../core/services/common.service";
import * as i11 from "../users/users.service";
var styles_ClockOutComponent = [i0.styles];
var RenderType_ClockOutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClockOutComponent, data: {} });
export { RenderType_ClockOutComponent as RenderType_ClockOutComponent };
export function View_ClockOutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "custom_modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "readmin-form custom_form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h2", [["style", "font-size: 16px; text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "cancel-btn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog("close") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" No "])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "text-uppercase"], ["color", "primary"], ["mat-raised-button", ""], ["name", "Save"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(18, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_7 = _co.submitted; var currVal_8 = "primary"; _ck(_v, 17, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.headerText; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.messageText; _ck(_v, 10, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 14).disabled || null); var currVal_4 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_5 = (i1.ɵnov(_v, 17).disabled || null); var currVal_6 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_5, currVal_6); var currVal_9 = (_co.submitted ? "btnText" : "Yes"); _ck(_v, 18, 0, currVal_9); }); }
export function View_ClockOutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clock-out", [], null, null, null, View_ClockOutComponent_0, RenderType_ClockOutComponent)), i1.ɵdid(1, 245760, null, 0, i8.ClockOutComponent, [i9.NotifierService, i2.MatDialogRef, i10.CommonService, i11.UsersService, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClockOutComponentNgFactory = i1.ɵccf("app-clock-out", i8.ClockOutComponent, View_ClockOutComponent_Host_0, {}, {}, []);
export { ClockOutComponentNgFactory as ClockOutComponentNgFactory };
