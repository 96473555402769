/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-conditions-reminder.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./terms-conditions-reminder.component";
var styles_TermsConditionsReminderComponent = [i0.styles];
var RenderType_TermsConditionsReminderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsConditionsReminderComponent, data: {} });
export { RenderType_TermsConditionsReminderComponent as RenderType_TermsConditionsReminderComponent };
export function View_TermsConditionsReminderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "custom_modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Terms & Conditions"])), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "readmin-form custom_form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "h2", [["style", "font-size: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["By continuing you agree to the terms and conditions of "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "font-blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createReminderModel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["confidentiality and information access agreement"])), (_l()(), i1.ɵeld(13, 0, null, null, 8, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["class", "cancel-btn"], ["mat-raised-button", ""], ["style", " margin-right: 15px;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog("close") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Exit "])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog("ok") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Continue"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_5 = "primary"; _ck(_v, 20, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 17).disabled || null); var currVal_2 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 20).disabled || null); var currVal_4 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 19, 0, currVal_3, currVal_4); }); }
export function View_TermsConditionsReminderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms-conditions-reminder", [], null, null, null, View_TermsConditionsReminderComponent_0, RenderType_TermsConditionsReminderComponent)), i1.ɵdid(1, 114688, null, 0, i8.TermsConditionsReminderComponent, [i2.MatDialogRef, i2.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsConditionsReminderComponentNgFactory = i1.ɵccf("app-terms-conditions-reminder", i8.TermsConditionsReminderComponent, View_TermsConditionsReminderComponent_Host_0, {}, {}, []);
export { TermsConditionsReminderComponentNgFactory as TermsConditionsReminderComponentNgFactory };
