<div style="height:100%; width: 100%;">
    <div style="display: flex; margin-left: 120px; align-items: center;">
      <h1>
      </h1>
        
      <div class="choose_file">
        Choose Your DICOM Files 
        <input class="hide_file" type="file" multiple accept="application/dicom" id="imagens" title="selecione arquivos de imagens"
        (change)="loadDICOMImages($event.target.files)">
      </div>
  
        
  
    </div>
  
    <div style="display: flex;height: calc(100% - 90px); width:100%;">
      <dicom-viewer [enableViewerTools]="true" [maxImagesToLoad]="20" style="height:100%; width:100%; margin: 10px;"></dicom-viewer>
    </div>
    <button mat-raised-button class="cancel-btn" (click)="closeDialog('close')">Close</button>

  </div>

  
<!-- <div id="dicomWrapper"

     class='disable-selection noIbar'
     unselectable='on'
     onselectstart='return false;'
     onmousedown='return false;'>

  <div cornerstone
       [image]="imageData"
       id="dicomImage"
       style="">
  </div>

</div> -->
<!-- <div id="dwv">
  <mat-progress-bar mode="determinate" value="{{ loadProgress }}"></mat-progress-bar>
  <div class="button-row">
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu" [disabled]="!dataLoaded">{{ selectedTool }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false" yPosition="below">
      <button *ngFor="let tool of tools" mat-menu-item (value)="tool" (click)="onChangeTool(tool)">{{ tool }}</button>
    </mat-menu>

    <button mat-raised-button color="primary" (click)="onReset()" [disabled]="!dataLoaded">Reset</button>
    <button mat-raised-button color="primary" (click)="openTagsDialog()" [disabled]="!dataLoaded">Tags</button>
  </div>
  <div class="layerContainer">
    <div class="dropBox">Drag and drop data here.</div>
    <canvas class="imageLayer">Only for HTML5 compatible browsers...</canvas>
    <div class="drawDiv"></div>
  </div>
  <div class="legend mat-small">
    <p>Powered by
    <a href="https://github.com/ivmartel/dwv">dwv</a>
    {{ versions.dwv }}
    and Angular {{ versions.angular }}</p></div>
</div> -->
<!-- <div class="hdr-login-right">
  <a href="javascript:void(0)" id="LoginButton" class="hdr-btn hdr-login-btn" data-toggle="modal" data-target="#loginModal">+
    Image</a>
</div>
<div id="loginModal" class="modal fade home-popup-login-outer" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal" #loginModal>&times;</button>
      <div class="home-popup-login-in">
        <div class="home-lgn-form-hdr">
          <figure>
            <a href="javascript:void(0)"><img src="assets/img/logo-blue-black.png" alt="" /></a>
          </figure>
          <h2>Dicom</h2>
        </div>
        <form [formGroup]="imageForm" (submit)="diacomSubmit()">
          <div class="home-lgn-form-body">
            <div class="form-group">
              <div class="pro-input-bx">
                <div class="profile-left-col mrt-3">
                  <div class="box-cover-cus">
                    <div class="profile-left-img-outer img-outer-custom">
                      <label><i class="fa fa-picture-o" aria-hidden="true"></i>Upload Image</label>
                      <div class="upload-pic-pencil">
                        <input type="file" ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" />
                        <i class="fa fa-pencil"></i>
                      </div>
                     // <!-- <figure class="profile-left-img"><img src="{{ diacomImage }}" alt="" /></figure> -->
                    <!-- </div>
                  </div>
                </div>
                <mat-form-field appearance="outline">
                  <mat-label>Description</mat-label>
                  <input matInput placeholder="Description" formControlName="Description">
                </mat-form-field>
              </div>
            </div>
            <div class="btn-with-text-right">
              <button type="submit" class="pro-btn-1 text-uppercase">Submit</button>
              <!-- <a class=""  (click)="close()">Back to Login</a> -->
            <!-- //</div> --> -->
          <!-- </div>
        </form>
      </div>

    </div>
  </div>
</div>

 
  
<div  class="white-bx-shadow p-a-25 mart-3">
    <div class="white-bx-shadow-hdd">
        <h3>Dicom</h3>
    </div> -->

<!-- 
    <a class="redirect-edit-page-pointer"
    [routerLink]="['', 'dashboard', 'lab-assistant']">
    <i class="fa fa-pencil" aria-hidden="true"></i>
  </a> -->


<!-- 
    <div class="row" style="margin-left:0px; margin-right:0px;">
            <div class="col">
                <mat-form-field>
                    <input matInput (keyup)="search($event.target.value)" placeholder="Search">
                </mat-form-field>
            </div>
        </div>

    <div class="mat-table-box">
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                    <td mat-cell *matCellDef="let element">{{element.created_at | date }}</td>
                  </ng-container>

                    <ng-container matColumnDef="File_Description">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                      <td mat-cell *matCellDef="let element">{{element.Description}}</td>
                    </ng-container>
  
      
                  <ng-container matColumnDef="_id">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element" class="action-link">
                       
                     <!-- <button class="action-button" (click)="delete(element._id)"> <i class="fa fa-trash"></i> </button>&nbsp; -->
                     <!-- <a class="redirect-edit-page-pointer"
                     [routerLink]="['', 'dashboard', 'lab-assistant']">
                     <i class="fa fa-eye" aria-hidden="true"></i>
                   </a>
 -->



                    <!-- </td>
                   
                </ng-container>
  
              
  
                  
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  <mat-paginator [length]="pager.totalRecords" [pageIndex]="pager.currentPage-1" [pageSize]="pager.pageSize"
                  [pageSizeOptions]="[5, 10, 20]" (page)="handlePagination($event)" showFirstLastButtons>
              </mat-paginator>
    </div>
  </div> --> --> -->



