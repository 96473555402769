import { Component, OnInit, EventEmitter, Output, Inject } from "@angular/core";
import { DiagnosisModel } from "../diagnosis.model";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ClientsService } from "../../clients.service";
import { NotifierService } from "angular-notifier";
import { format } from "date-fns";
import { Observable, of, Subscription } from "rxjs";
import {
  startWith,
  map,
  debounceTime,
  tap,
  switchMap,
  finalize,
  catchError,
} from "rxjs/operators";
import { ResponseModel } from "src/app/platform/modules/core/modals/common-model";
import { CommonService } from "src/app/platform/modules/core/services";

export class autoCompleteValidatorAsync$$ {
  static valueSelected(
    autoCompleteList: any[],
    keyName: string,
    required: boolean
  ): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      let selectedValue = c.value;
      if (
        selectedValue == null ||
        selectedValue == undefined ||
        selectedValue == ""
      ) {
        if (required) return { required: true };
        else return null;
      }
      if (
        selectedValue.length < 3 ||
        autoCompleteList == null ||
        autoCompleteList.length == 0
      )
        return { match: true };
      let pickedOrNot = autoCompleteList.filter(
        (obj) => obj[keyName] == selectedValue[keyName]
      );
      if (pickedOrNot.length > 0) return null;
      else return { match: true };
    };
  }
}

@Component({
  selector: "app-diagnosis-modal",
  templateUrl: "./diagnosis-modal.component.html",
  styleUrls: ["./diagnosis-modal.component.css"],
})
export class DiagnosisModalComponent implements OnInit {
  statusList: any[];
  diagnosisModel: DiagnosisModel;
  diagnosisForm: FormGroup;
  submitted: boolean = false;
  headerText: string = "Add Diagnosis";
  masterICD: any[];
  patientId: number;
  maxDate = new Date();

  //isActivefilteredOptions: Observable<string[]>;

  diagnosisfilteredOptions: Observable<string[]>;
  isloadingPatients: boolean = false;

  @Output() refreshGrid: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private formBuilder: FormBuilder,
    private diagnosisDialogModalRef: MatDialogRef<DiagnosisModalComponent>,
    private clientService: ClientsService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifier: NotifierService
  ) {
    this.diagnosisModel = data.diagnosis;
    this.refreshGrid.subscribe(data.refreshGrid);

    if (this.diagnosisModel.id != null && this.diagnosisModel.id > 0)
      this.headerText = "Edit Diagnosis";
    else this.headerText = "Add Diagnosis";

    this.patientId = this.diagnosisModel.patientID;

    this.statusList = [
      { id: 1, value: "Active" },
      { id: 0, value: "Inactive" },
    ];
  }

  ngOnInit() {    
    this.diagnosisForm = this.formBuilder.group({
      id: [this.diagnosisModel.id],
      icdidnew: [
        this.diagnosisModel.icdidnew, //,autoCompleteValidatorAsync$$.valueSelected([], "term", true),
      ],
      isActive: [this.diagnosisModel.isActive],
      diagnosisDate: [this.diagnosisModel.diagnosisDate],
      resolveDate: [this.diagnosisModel.resolveDate],
      diagnosisType:[this.diagnosisModel.diagnosisType],
      note:[this.diagnosisModel.note]
    });
    //this.diagnosisListData();
    // this.getMasterData();
    this.formControlValueChanged();

    // this._filter("");
    this.diagnosisfilteredOptions = this.diagnosisForm
      .get("icdidnew")
      .valueChanges.pipe(
        startWith(""),
        debounceTime(100),
        tap((value) => {
          value.length > 3
            ? (this.isloadingPatients = true)
            : (this.isloadingPatients = false);
        }),
        switchMap((value) => {
          if (value.length > 2) {
            let list: Observable<any>;
            list = this._filter(value).pipe(
              finalize(() => (this.isloadingPatients = false))
            );
            list.subscribe((_value) => {
              this.diagnosisForm.controls.icdidnew.setValidators([
                autoCompleteValidatorAsync$$.valueSelected(
                  _value,
                  "term",
                  true
                ),
              ]);
            });
            return list;
          } else {
            if (value.length < 3) {
              this.diagnosisForm.controls.icdidnew.setValidators([
                autoCompleteValidatorAsync$$.valueSelected([], "term", true),
              ]);
            }
            return of(null);
          }
        })
      );

    if (this.diagnosisModel.id > 0) {
      let modelfordiagnosis = {
        id: this.data.diagnosis.icdid,
        term: this.data.diagnosis.diagnosisName,
      };
      this.diagnosisForm.controls.icdidnew.setValue(modelfordiagnosis);
    }
    if(this.diagnosisModel.diagnosisType)
    this.diagnosisForm.controls.diagnosisType.setValue(this.diagnosisModel.diagnosisType=='primaryDiagnosis'?true:false);
  }

  //
  diagnosisdisplayFn(client?: any): string | undefined {
    return client ? client.term : undefined;
  }
  private _filter(value: string): Observable<any> {
    const filterValue = value.toLowerCase();
    return this.clientService.getdiagnosisBySearch(filterValue).pipe(
      map((response: any) => {
        if (response.statusCode == 201) return of(null);
        else
          return (response.data || []).map((clientObj: any) => {
            const Obj: any = clientObj;
            return Obj;
          });
      }),
      catchError((_) => {
        return of(null);
      })
    );
  }

  // diagnosisdisplayFn(FilterKeyValue: any): string {
  //   console.log("ko", FilterKeyValue);
  //   return FilterKeyValue && FilterKeyValue.term ? FilterKeyValue.term : "";
  // }

  // private diagnosis_filter(term: string): any[] {
  //   const filterValue = this._normalizeValue(term);
  //   return this.diagnosisList.filter(option =>
  //     this._normalizeValue(option.term).includes(filterValue)
  //   );
  // }

  // private _normalizeValue(value: string): string {
  //   return value.toLowerCase().replace(/\s/g, "");
  // }

  CheckHCNumber() {
    // form clear when not empty value in box
    if (this.diagnosisForm.get("icdidnew").value == "") {
      this.diagnosisForm.reset();
      // this.PatientsMDSAdmissionModel = new PatientsMDSAdmissionModel();
      //this.AdmissionInfoForm.patchValue(this.PatientsMDSAdmissionModel);
    }
  }

  diagnosisList: any = [];
  diagnosisListData() {
    this.clientService
      .getdiagnosisList("diagnosisList")
      .subscribe((response: any) => {
        if (response != null && response.statusCode === 200) {
          console.log("diagnosis list ", response);
          this.diagnosisList = response.data;
        } else {
          console.log("diagnosis list ", response);
        }
      });
  }

  get formControls() {
    return this.diagnosisForm.controls;
  }
  onSubmit(event: any) {
    if (!this.diagnosisForm.invalid) {
      let clickType = event.currentTarget.name;
      this.submitted = true;
      this.diagnosisModel = this.diagnosisForm.value;

      let formvalue1 = this.diagnosisForm.value;
      if (formvalue1.icdidnew.id) {
        this.diagnosisModel.diagnosisName = formvalue1.icdidnew.term;
        this.diagnosisModel.icdid = Number(formvalue1.icdidnew.id);
      }

      this.diagnosisModel.patientID = this.patientId;
      this.diagnosisModel.resolveDate = this.diagnosisModel.isActive
        ? null
        : this.diagnosisModel.resolveDate;

      ///just for design
      // this.closeDialog("save");
      //return
      if(this.diagnosisModel.diagnosisType != null && this.diagnosisModel.diagnosisType != undefined)
      this.diagnosisModel.diagnosisType=  this.diagnosisModel.diagnosisType.toString()=='true'?'primaryDiagnosis':'secondaryDiagnosis';
      this.clientService
        .createDiagnosis(this.diagnosisModel)
        .subscribe((response: ResponseModel) => {
          this.submitted = false;
          if (response.statusCode == 200) {
            this.notifier.notify(
              "success",
              response.message.replace(
                "Client's",
                this.commonService.getOrganizationTypeName()
              )
            );
            if (clickType == "Save") this.closeDialog("save");
            else if (clickType == "SaveAddMore") {
              this.refreshGrid.next();
              this.diagnosisForm.reset();
              this.diagnosisForm.controls.icdidnew.setValue("");
            }
          } else {
            this.notifier.notify("error", response.message);
          }
        });
    }
  }
  formControlValueChanged() {
    const resolveDateControl = this.diagnosisForm.get("resolveDate");
    this.diagnosisForm.get("isActive").valueChanges.subscribe((mode: any) => {
      if (mode == false) {
        resolveDateControl.clearValidators();
      } else {
        resolveDateControl.clearValidators();
      }
      resolveDateControl.updateValueAndValidity();
    });
  }
  closeDialog(action: string): void {
    this.diagnosisDialogModalRef.close(action);
  }

  getMasterData() {
    let data = "MASTERICD";
    this.clientService.getMasterData(data).subscribe((response: any) => {
      if (response != null) {
        this.masterICD = response.masterICD != null ? response.masterICD : [];
      }
    });
  }
}
export interface FilterKeyValue {
  term: string;
}
