var SharedService = /** @class */ (function () {
    function SharedService() {
        this.getIncomingFaxURL = "PatientReferral/GetIncomingFax";
    }
    SharedService.prototype.setSidenav = function (sidenav) {
        this.sidenav = sidenav;
    };
    SharedService.prototype.open = function () {
        return this.sidenav.open();
    };
    SharedService.prototype.close = function () {
        return this.sidenav.close();
    };
    SharedService.prototype.toggle = function () {
        this.sidenav.toggle();
    };
    return SharedService;
}());
export { SharedService };
