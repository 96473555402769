import { OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { ClientsService } from '../../../../clients.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NotifierService } from "angular-notifier";
var CrossOutReasonComponent = /** @class */ (function () {
    function CrossOutReasonComponent(data, dialogModalRef, clientService, notifier) {
        this.data = data;
        this.dialogModalRef = dialogModalRef;
        this.clientService = clientService;
        this.notifier = notifier;
        this.adlTrackingToolId = data.adlTrackingId;
        this.patientId = data.patientId;
    }
    CrossOutReasonComponent.prototype.ngOnInit = function () {
        this.getMasterData();
        this.reasonForm = new FormGroup({
            crossOutReasonId: new FormControl()
        });
    };
    CrossOutReasonComponent.prototype.getMasterData = function () {
        var _this = this;
        var data = "MEDICATION";
        this.clientService.getMasterData(data).subscribe(function (response) {
            if (response != null) {
                _this.masterCrossOutReasonlist = response.medicationsList.filter(function (s) { return s.type == "CrossOut Reason"; });
            }
        });
    };
    CrossOutReasonComponent.prototype.onSubmit = function (event) {
        var _this = this;
        if (!this.reasonForm.invalid) {
            this.submitted = true;
            this.crossOutReasonId = this.reasonForm.value.crossOutReasonId;
            // this.crossOutReasonModel.id = this.adlTrackingToolId; 
            // this.crossOutReasonModel.patientID=this.patientId;
            this.clientService.updateAdlTrackingToolCrossOutReason(this.adlTrackingToolId, this.crossOutReasonId)
                .subscribe(function (response) {
                if (response.statusCode == 200) {
                    var model = {
                        adlTrackingToolId: _this.adlTrackingToolId
                    };
                    _this.clientService
                        .crossADLTrackingTool(model)
                        .subscribe(function (response) {
                        _this.submitted = false;
                        _this.closeDialog("close");
                    });
                    _this.notifier.notify("success", response.message);
                }
                else {
                    _this.submitted = false;
                    _this.notifier.notify("error", response.message);
                }
            });
        }
    };
    CrossOutReasonComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    return CrossOutReasonComponent;
}());
export { CrossOutReasonComponent };
