import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "../environments/environment";
import { map } from "rxjs/operators";

@Injectable()
export class SubDomainService {
  private subject = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}

  getSubDomainUrl() {
    let hostName = window.location.host;
    let subdomain = null;
    // console.log(hostName)

    // @if running on localhost or (34.211.31.84:8017) server
    if (
      hostName === "localhost:4300" ||
      hostName === "localhost:4200" ||
      hostName === "localhost:3000" ||
      hostName === "34.211.31.84:8069" ||
      hostName === "75.126.168.31:9452" ||
      hostName === "75.126.168.31:9450" ||
      hostName === "75.126.168.31:9460" ||
      hostName === "ms.stagingsdei.com:9247" ||  //before the port was 9452 
      hostName === "www.stagingwin.com:9521" ||
      hostName === "liveaccessemr.azurewebsites.net" ||
      hostName === "accessehealth.com"
    ) {
      // hostName = 'hc_patient_merging.smarthealth.net.in';
      hostName = "hc_patient_merging";
    } else if (
      hostName === "localhost:4400" ||
      hostName === "ms.stagingsdei.com:9248" //before the port was 9450 
    ) {
      hostName = "AccessEMR_Home_Care";
    } else if (
      hostName === "localhost:4500" ||
      hostName === "75.126.168.31:9432"
    ) {
      hostName = "CPKR";
    }
    const splitHostName = hostName.split(".");
    if (splitHostName.length >= 4) {
      // subdomain = 'WoundPros';

      subdomain = splitHostName[0];
    }
    // return subdomain;

    return hostName;
    // retngurn 'AccessEMR_Home_Care';
  }

  getSubDomainInfo(): Observable<any> {
    return this.subject.asObservable();
  }

  verifyBusinessName(domainName: string) {
    console.log(2);
    if (localStorage.getItem("business-url")) {
      domainName = localStorage.getItem("business-url");
      console.log("business-url", domainName);
    }
    return this.http
      .get<any>(
        `${environment.api_url}/VerifyBusinessName?BusinessName=${domainName}`
      )
      .pipe(
        map((response) => {
          if (response.statusCode === 200) {
            localStorage.setItem("business_token", response.data.businessToken);
            localStorage.setItem("business-url", domainName);
            localStorage.setItem(
              "business-type",
              response.data.organizationType
            );
            // add business object as tojken
            let businessObject = JSON.stringify(response.data);
            localStorage.setItem("business_token_object", businessObject);
            this.subject.next({ ...response.data });
          } else {
            localStorage.removeItem("business_token");
            this.subject.next(null);
          }
        })
      );
  }

  updateFavicon(faviconUrl: string) {
    let link: any =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = faviconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
}
