import { OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material";
import { TermsAndConditionsComponent } from "src/app/platform/modules/auth/terms-and-conditions/terms-and-conditions.component";
var TermsConditionsReminderComponent = /** @class */ (function () {
    function TermsConditionsReminderComponent(dialogModalRef, inventoryDialog) {
        this.dialogModalRef = dialogModalRef;
        this.inventoryDialog = inventoryDialog;
    }
    TermsConditionsReminderComponent.prototype.ngOnInit = function () { };
    TermsConditionsReminderComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    TermsConditionsReminderComponent.prototype.declineTerms = function () {
        this.closeDialog("close");
    };
    TermsConditionsReminderComponent.prototype.createReminderModel = function () {
        var userRoleModalPopup = this.inventoryDialog.open(TermsAndConditionsComponent, {
            hasBackdrop: true,
            backdropClass: "backdropBackground",
        });
        userRoleModalPopup.afterClosed().subscribe(function (result) { });
    };
    return TermsConditionsReminderComponent;
}());
export { TermsConditionsReminderComponent };
