import { OnInit, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, ValidatorFn, AbstractControl, } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { ClientsService } from "../../clients.service";
import { NotifierService } from "angular-notifier";
import { Observable, of } from "rxjs";
import { startWith, map, debounceTime, tap, switchMap, finalize, catchError, } from "rxjs/operators";
import { CommonService } from "src/app/platform/modules/core/services";
var autoCompleteValidatorAsync$$ = /** @class */ (function () {
    function autoCompleteValidatorAsync$$() {
    }
    autoCompleteValidatorAsync$$.valueSelected = function (autoCompleteList, keyName, required) {
        return function (c) {
            var selectedValue = c.value;
            if (selectedValue == null ||
                selectedValue == undefined ||
                selectedValue == "") {
                if (required)
                    return { required: true };
                else
                    return null;
            }
            if (selectedValue.length < 3 ||
                autoCompleteList == null ||
                autoCompleteList.length == 0)
                return { match: true };
            var pickedOrNot = autoCompleteList.filter(function (obj) { return obj[keyName] == selectedValue[keyName]; });
            if (pickedOrNot.length > 0)
                return null;
            else
                return { match: true };
        };
    };
    return autoCompleteValidatorAsync$$;
}());
export { autoCompleteValidatorAsync$$ };
var DiagnosisModalComponent = /** @class */ (function () {
    function DiagnosisModalComponent(formBuilder, diagnosisDialogModalRef, clientService, commonService, data, notifier) {
        this.formBuilder = formBuilder;
        this.diagnosisDialogModalRef = diagnosisDialogModalRef;
        this.clientService = clientService;
        this.commonService = commonService;
        this.data = data;
        this.notifier = notifier;
        this.submitted = false;
        this.headerText = "Add Diagnosis";
        this.maxDate = new Date();
        this.isloadingPatients = false;
        this.refreshGrid = new EventEmitter();
        this.diagnosisList = [];
        this.diagnosisModel = data.diagnosis;
        this.refreshGrid.subscribe(data.refreshGrid);
        if (this.diagnosisModel.id != null && this.diagnosisModel.id > 0)
            this.headerText = "Edit Diagnosis";
        else
            this.headerText = "Add Diagnosis";
        this.patientId = this.diagnosisModel.patientID;
        this.statusList = [
            { id: 1, value: "Active" },
            { id: 0, value: "Inactive" },
        ];
    }
    DiagnosisModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.diagnosisForm = this.formBuilder.group({
            id: [this.diagnosisModel.id],
            icdidnew: [
                this.diagnosisModel.icdidnew,
            ],
            isActive: [this.diagnosisModel.isActive],
            diagnosisDate: [this.diagnosisModel.diagnosisDate],
            resolveDate: [this.diagnosisModel.resolveDate],
            diagnosisType: [this.diagnosisModel.diagnosisType],
            note: [this.diagnosisModel.note]
        });
        //this.diagnosisListData();
        // this.getMasterData();
        this.formControlValueChanged();
        // this._filter("");
        this.diagnosisfilteredOptions = this.diagnosisForm
            .get("icdidnew")
            .valueChanges.pipe(startWith(""), debounceTime(100), tap(function (value) {
            value.length > 3
                ? (_this.isloadingPatients = true)
                : (_this.isloadingPatients = false);
        }), switchMap(function (value) {
            if (value.length > 2) {
                var list = void 0;
                list = _this._filter(value).pipe(finalize(function () { return (_this.isloadingPatients = false); }));
                list.subscribe(function (_value) {
                    _this.diagnosisForm.controls.icdidnew.setValidators([
                        autoCompleteValidatorAsync$$.valueSelected(_value, "term", true),
                    ]);
                });
                return list;
            }
            else {
                if (value.length < 3) {
                    _this.diagnosisForm.controls.icdidnew.setValidators([
                        autoCompleteValidatorAsync$$.valueSelected([], "term", true),
                    ]);
                }
                return of(null);
            }
        }));
        if (this.diagnosisModel.id > 0) {
            var modelfordiagnosis = {
                id: this.data.diagnosis.icdid,
                term: this.data.diagnosis.diagnosisName,
            };
            this.diagnosisForm.controls.icdidnew.setValue(modelfordiagnosis);
        }
        if (this.diagnosisModel.diagnosisType)
            this.diagnosisForm.controls.diagnosisType.setValue(this.diagnosisModel.diagnosisType == 'primaryDiagnosis' ? true : false);
    };
    //
    DiagnosisModalComponent.prototype.diagnosisdisplayFn = function (client) {
        return client ? client.term : undefined;
    };
    DiagnosisModalComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        return this.clientService.getdiagnosisBySearch(filterValue).pipe(map(function (response) {
            if (response.statusCode == 201)
                return of(null);
            else
                return (response.data || []).map(function (clientObj) {
                    var Obj = clientObj;
                    return Obj;
                });
        }), catchError(function (_) {
            return of(null);
        }));
    };
    // diagnosisdisplayFn(FilterKeyValue: any): string {
    //   console.log("ko", FilterKeyValue);
    //   return FilterKeyValue && FilterKeyValue.term ? FilterKeyValue.term : "";
    // }
    // private diagnosis_filter(term: string): any[] {
    //   const filterValue = this._normalizeValue(term);
    //   return this.diagnosisList.filter(option =>
    //     this._normalizeValue(option.term).includes(filterValue)
    //   );
    // }
    // private _normalizeValue(value: string): string {
    //   return value.toLowerCase().replace(/\s/g, "");
    // }
    DiagnosisModalComponent.prototype.CheckHCNumber = function () {
        // form clear when not empty value in box
        if (this.diagnosisForm.get("icdidnew").value == "") {
            this.diagnosisForm.reset();
            // this.PatientsMDSAdmissionModel = new PatientsMDSAdmissionModel();
            //this.AdmissionInfoForm.patchValue(this.PatientsMDSAdmissionModel);
        }
    };
    DiagnosisModalComponent.prototype.diagnosisListData = function () {
        var _this = this;
        this.clientService
            .getdiagnosisList("diagnosisList")
            .subscribe(function (response) {
            if (response != null && response.statusCode === 200) {
                console.log("diagnosis list ", response);
                _this.diagnosisList = response.data;
            }
            else {
                console.log("diagnosis list ", response);
            }
        });
    };
    Object.defineProperty(DiagnosisModalComponent.prototype, "formControls", {
        get: function () {
            return this.diagnosisForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    DiagnosisModalComponent.prototype.onSubmit = function (event) {
        var _this = this;
        if (!this.diagnosisForm.invalid) {
            var clickType_1 = event.currentTarget.name;
            this.submitted = true;
            this.diagnosisModel = this.diagnosisForm.value;
            var formvalue1 = this.diagnosisForm.value;
            if (formvalue1.icdidnew.id) {
                this.diagnosisModel.diagnosisName = formvalue1.icdidnew.term;
                this.diagnosisModel.icdid = Number(formvalue1.icdidnew.id);
            }
            this.diagnosisModel.patientID = this.patientId;
            this.diagnosisModel.resolveDate = this.diagnosisModel.isActive
                ? null
                : this.diagnosisModel.resolveDate;
            ///just for design
            // this.closeDialog("save");
            //return
            if (this.diagnosisModel.diagnosisType != null && this.diagnosisModel.diagnosisType != undefined)
                this.diagnosisModel.diagnosisType = this.diagnosisModel.diagnosisType.toString() == 'true' ? 'primaryDiagnosis' : 'secondaryDiagnosis';
            this.clientService
                .createDiagnosis(this.diagnosisModel)
                .subscribe(function (response) {
                _this.submitted = false;
                if (response.statusCode == 200) {
                    _this.notifier.notify("success", response.message.replace("Client's", _this.commonService.getOrganizationTypeName()));
                    if (clickType_1 == "Save")
                        _this.closeDialog("save");
                    else if (clickType_1 == "SaveAddMore") {
                        _this.refreshGrid.next();
                        _this.diagnosisForm.reset();
                        _this.diagnosisForm.controls.icdidnew.setValue("");
                    }
                }
                else {
                    _this.notifier.notify("error", response.message);
                }
            });
        }
    };
    DiagnosisModalComponent.prototype.formControlValueChanged = function () {
        var resolveDateControl = this.diagnosisForm.get("resolveDate");
        this.diagnosisForm.get("isActive").valueChanges.subscribe(function (mode) {
            if (mode == false) {
                resolveDateControl.clearValidators();
            }
            else {
                resolveDateControl.clearValidators();
            }
            resolveDateControl.updateValueAndValidity();
        });
    };
    DiagnosisModalComponent.prototype.closeDialog = function (action) {
        this.diagnosisDialogModalRef.close(action);
    };
    DiagnosisModalComponent.prototype.getMasterData = function () {
        var _this = this;
        var data = "MASTERICD";
        this.clientService.getMasterData(data).subscribe(function (response) {
            if (response != null) {
                _this.masterICD = response.masterICD != null ? response.masterICD : [];
            }
        });
    };
    return DiagnosisModalComponent;
}());
export { DiagnosisModalComponent };
