import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/platform/modules/core/services';
import { ClientsService } from 'src/app/platform/modules/agency-portal/clients/clients.service';
import { OpenDocumentModel } from './open-document.model';
import { ifStmt } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-open-document',
  templateUrl: './open-document.component.html',
  styleUrls: ['./open-document.component.css'],
  providers: [ClientsService]
})
export class OpenDocumentComponent implements OnInit {
  clientId: number;
  StaffId: number;
  openDocumentModel: OpenDocumentModel;
  submitted: boolean = false;
  docFaxForm: FormGroup;
  patientId: number;
  staffId: number;
  isCarePlan: boolean;
  masterDocumentTypes: any = [];

  constructor(
    private dialogRef: MatDialogRef<OpenDocumentComponent>,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private clientService: ClientsService,
    private router: Router,
    private notifierService: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientsService: ClientsService) {
    this.openDocumentModel = new OpenDocumentModel();
    this.openDocumentModel = data;
    this.patientId = data.patientId;
    this.staffId = data.staffId;
    this.isCarePlan = data.isCarePlan;
  }

  ngOnInit() {
    var userInfo = this.commonService.getLoginUserInfo();

    // console.log(userInfo);   

    this.docFaxForm = this.formBuilder.group({
      id: [this.openDocumentModel.id],
      documentTypeId: [this.openDocumentModel.documentTypeId],
      expirationDate: [this.openDocumentModel.expirationDate],
      title: [this.openDocumentModel.title]
    });

    this.getMasterData();
  }

  //get the form controls on html page
  get formControls() { return this.docFaxForm.controls; }
  save = 'save';
  close='close';

  closeDialog(action: string): void {
    this.dialogRef.close(action);
  }

  onSubmit(event: any) {
    if (!this.docFaxForm.invalid) {
      let clickType = event.currentTarget.name;
      this.submitted = true;
      this.openDocumentModel = this.docFaxForm.value;
      this.openDocumentModel.patientId = this.patientId;
      this.openDocumentModel.staffId = this.staffId;
      this.openDocumentModel.base64 = this.commonService.getFaxFileObj(this.data.faxFileBase64);
      this.clientService.createDocumentResult(this.openDocumentModel).subscribe((response: any) => {
        this.submitted = false;
        if (response.statusCode == 200) {
          this.notifier.notify('success', response.message)
          if (clickType == "Save")
            this.closeDialog('save');
        } else {
          this.notifier.notify('error', response.message)
        }
      });
    }
  }
  getMasterData() {
    this.clientService.getMasterData("MASTERDOCUMENTTYPESSTAFF").subscribe((response: any) => {
      if (response != null)
        this.masterDocumentTypes = response.masterDocumentTypesStaff = response.masterDocumentTypesStaff != null ? response.masterDocumentTypesStaff : [];
      if (this.isCarePlan) {
        this.masterDocumentTypes = this.masterDocumentTypes.filter(s => s.type == 'Care Plan');
        this.docFaxForm.controls.documentTypeId.setValue(14);
      } else {
        this.masterDocumentTypes = this.masterDocumentTypes.filter(s => s.type != 'Care Plan');
      }
    });
  }
}
