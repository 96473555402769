export class CommonFilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
}
export class FilterModel {
  pageNumber: number = 1;
  pageSize: number = 6;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
}
export class ADTPatientFilterModel {
  pageNumber: number = 1;
  pageSize: number = 6;
  sortColumn: string = "";
  sortOrder: string = "";
  fromDate: string;
  toDate: string;
}

export class TrackingFilterModel {
  pageNumber: number = 1;
  pageSize: number = 6;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  fromDate:string;
  toDate:string;
}
export class InventoryFilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  patientId: number;
}

export class InventoryListFilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  patientId: number;
  fromDate:string;
  toDate:string;
}

export class InventoryTrackFilterModel {
  pageNumber: number = 1;
  pageSize: number = 6;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  fromDate:string;
  toDate:string;
}

export class ReorderTrackFilterModel {
  pageNumber: number = 1;
  pageSize: number = 6;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  fromDate:string;
  toDate:string;
}
export class PatientPreferenceFilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  patientId: number;
  categoryId?: number;
  toDate?: Date = null;
  fromDate?: Date = null;
}

export class PatientFormFilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  patientId: number;
  categoryId: number;
  categoryIds: string = "";
}

export class PatientVitalDateFilter {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  patientId: number;
  toDate?: Date = null;
  fromDate?: Date = null;
}

export class PatientVitalDateFilter2 {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  patientId: number;
  toDate?: Date;
  fromDate?: Date;
}

export class MonthFilterModel {
  month: number;
  year: number;
}
export class ResponseModel {
  data: any = [];
  statusCode: number;
  message: string = "";
  appError: string = "";
  meta: Metadata;
}
export class Metadata {
  totalRecords: number;
  currentPage: number;
  pageSize: number = 5;
  defaultPageSize: number = 5;
  totalPages: number;
}

export class EmarFilterModel {
  pageNumber: number = 1;
  pageSize: number = 6;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  searchDate: Date;
  searchTime: string;
  patientId: number;
  unitId:number;
  date:Date;
  prescriptionTypeId:number;
  shiftStartDate:Date;
  shiftEndDate:Date;
}

export class TimeSheetFilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
}

export class OpenShiftFilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  fromDate:Date;
  toDate:Date;
  unitId:number;
  shiftId:number;
}
