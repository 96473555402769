<div class="image">
    <div *ngIf="data.imageUrl;else noImage">
        <h1 mat-dialog-title>{{data.itemName}}</h1>
        <div mat-dialog-content>
            <img [src]="data.imageUrl" alt="Popup Image" style="width: 100%; height: auto;">
        </div>
    </div>
    
    <ng-template #noImage>
            <h1 mat-dialog-title>{{data.itemName}}</h1>
            <p>Image Not Found</p>
    </ng-template>
    
    
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Close</button>
    </div>
</div>
